import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from 'utils/hooks/store';

import AutoplayAudio from 'components/NotificationBar/AutoplayAudio';
import Audio from 'components/Audio';

const ActivityTabs: React.FC = () => {
  const { pendingRedAlerts, ui } = useStore();
  const numAlerts = pendingRedAlerts.alerts.length;

  return (
    <>
      <AutoplayAudio />
      {ui.canAutoplayAudio && (
        <Audio play={!!numAlerts} src={process.env.REACT_APP_ALARM_NOTIFICATION as string} loop />
      )}
    </>
  );
};

export default observer(ActivityTabs);
