import React from 'react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { Button } from 'vatix-ui/lib/components/Button';

import AddIcon from '@mui/icons-material/Add';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import { ColumnDefinition } from 'components/Grid/types';

import Grid, { defaultRenderers } from 'components/Grid';

import { TaskStatus } from 'core/constants';
import { StyledTaskChip } from 'containers/Tasks/components/TasksTable/styles';
import { TaskStatusOperator } from 'components/Grid/operators/Status';

import AddActionModal from 'containers/FormsDetails/components/RelatedTab/AddActionModal';

import routes from 'core/routes';

import { EntitiesStores } from 'components/Entities/types';

import { useStore } from 'utils/hooks/store';

import AbstractWrapper from './AbstractWrapper';
import { defaultProps } from './types';

const TasksColumns = ([
  {
    field: 'name',
    headerName: 'Task name',
    nativeFilter: true,
  },
  {
    field: 'assigned',
    headerName: 'Assigned to',
    renderCell: defaultRenderers.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }: { value: TaskStatus }) => <StyledTaskChip status={value} />,
    filterOperators: TaskStatusOperator(),
    nativeFilter: true,
  },
] as unknown) as ColumnDefinition[];

const TasksRelatedToEntities: React.FunctionComponent<{
  onError: (error: VatixError) => void;
  store: EntitiesStores;
}> = ({ onError, store }) => {
  const { routing } = useStore();
  const { details, entityType } = store;

  const [openModal, setOpenModal] = React.useState(false);
  const refreshRef = React.useRef<(() => void) | null>(null);
  const onClose = (): void => {
    refreshRef.current?.();
    setOpenModal(false);
  };
  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    history.push(reverse(routes.dashboard.tasks.details, { taskId: task.row.uuid }));
  };

  const redirectToIncidents = (): void => {
    routing.push(reverse(routes.dashboard[entityType].relatedActions, { entityId: details?.uuid || '' }));
  };

  return (
    <>
      <AbstractWrapper
        headerTitle="Actions"
        headerAction={
          <Button onClick={() => setOpenModal(true)} variant="text" size="medium" startIcon={<AddIcon />}>
            New Action
          </Button>
        }
        gridElement={
          <Grid
            onError={onError}
            onRowClick={onRowClick}
            onRefreshCallback={(callback): void => {
              refreshRef.current = callback;
            }}
            basicColumns={TasksColumns}
            dataURL={`/api/entities/${entityType}/${details?.uuid}/tasks/`}
            {...defaultProps}
          />
        }
        footerAction={
          <Button variant="text" size="medium" onClick={redirectToIncidents}>
            View all...
          </Button>
        }
      />
      <AddActionModal
        openModal={openModal}
        onClose={onClose}
        extraParams={{ [entityType]: store.details ? store.details.uuid : '' }}
      />
    </>
  );
};

export default TasksRelatedToEntities;
