import React from 'react';

import { Skeleton } from '@mui/material';

import { HomeSectionHeader, HomeSectionHeaderContainer } from 'containers/Home/styles';

import { CardButtonsContainer } from './styles';

const ManagerAlarmsPaneSkeleton: React.FunctionComponent = () => (
  <>
    <HomeSectionHeaderContainer>
      <HomeSectionHeader>Lone Working</HomeSectionHeader>
    </HomeSectionHeaderContainer>
    <CardButtonsContainer>
      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton key={`lone_working_card_button_skeleton${index}`} variant="rounded" style={{ flex: 1, height: 64 }} />
      ))}
    </CardButtonsContainer>
    <HomeSectionHeaderContainer>
      <HomeSectionHeader>Recent Alarms</HomeSectionHeader>
      <Skeleton variant="rounded" style={{ flex: 1, height: 36, maxWidth: 86 }} />
    </HomeSectionHeaderContainer>
    <Skeleton variant="rounded" style={{ flex: 1, maxHeight: 360 }} />
  </>
);

export default ManagerAlarmsPaneSkeleton;
