import { eventBus, subscribe } from 'mobx-event-bus2';
import React from 'react';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { reverse } from 'named-urls';

import routes from 'core/routes';

import API from '../../utils/api';
import {
  AcceptedRedAlertPayload,
  ActionEvent,
  CreatedRedAlertPayload,
  DeletedUsersPayload,
} from '../../utils/events/types';
import Logger from '../../utils/logger';
import { canHandleRedAlert } from '../../utils/roles/permissions';
import RootStore from '../Root';
import { NUM_POP_UP_ALERTS_TO_DISPLAY } from './constants';
import PendingRedAlert from './PendingRedAlert';
import { EventType } from '../../utils/events/constants';

export default class PendingRedAlertsStore {
  store: RootStore;

  api: typeof API;

  iconRef = React.createRef<HTMLImageElement>();

  @observable alerts: PendingRedAlert[] = [];

  @observable isOpen = false;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    eventBus.register(this);
  }

  @action.bound
  async loadAlerts(): Promise<void> {
    try {
      const response = await this.api.loadPendingRedAlerts()();
      runInAction(() => {
        this.alerts = response.data.map((alert) => new PendingRedAlert(this.store, this.api, alert));
      });
    } catch (e) {
      Logger.error('Invalid load pending red alerts API response', e);
    }
  }

  @subscribe(EventType.AcceptedRedAlert)
  @action
  acceptedRedAlert({ payload }: ActionEvent<AcceptedRedAlertPayload>): void {
    this.alerts = this.alerts.filter((alert) => {
      if (alert.uuid !== payload.uuid) {
        return true;
      }
      eventBus.unregister(alert);
      return false;
    });
  }

  @subscribe(EventType.CreatedRedAlert)
  @action
  addAlert({ payload }: ActionEvent<CreatedRedAlertPayload>): void {
    if (canHandleRedAlert(this.store.session.user, payload.user)) {
      this.alerts = [
        ...this.alerts,
        new PendingRedAlert(this.store, this.api, {
          ...payload,
          ignored: false,
        }),
      ];
    }
  }

  @subscribe(EventType.DeletedUsers)
  @action
  deletedUsers({ payload }: ActionEvent<DeletedUsersPayload>): void {
    this.alerts = this.alerts.filter((alert) => {
      if (payload.users.includes(alert.user.uuid)) {
        eventBus.unregister(alert);
        return false;
      }
      return true;
    });
  }

  @action.bound
  toggleAlerts(): void {
    this.isOpen = !this.isOpen;
  }

  @action.bound
  seeAllAlerts(): void {
    this.isOpen = false;
    this.store.routing.push(reverse(routes.dashboard.alarms.red.toString()));
  }

  @action.bound
  removeAlerts(): void {
    this.alerts.forEach((alert) => eventBus.unregister(alert));
    this.alerts = [];
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.removeAlerts();
    this.isOpen = false;
  }

  @computed get popUpAlerts(): PendingRedAlert[] {
    return this.alerts.filter((alert) => !alert.ignored).slice(-NUM_POP_UP_ALERTS_TO_DISPLAY);
  }
}
