import React from 'react';

import { ChipProps } from '@mui/material';

import { TaskStatus } from 'core/constants';

import Chip from 'components/Chip';
import { VatixChipProps } from 'components/Chip/types';

const StatusChipPropsMapping: Record<string, VatixChipProps> = {
  [TaskStatus.CantDo]: {
    colors: {
      background: '#e0e0e0',
      foreground: '#000000',
    },
    label: 'On hold',
  },
  [TaskStatus.Completed]: {
    colors: {
      background: '#c0d8c1',
      foreground: '#000000',
    },
    label: 'Completed',
  },
  [TaskStatus.InProgress]: {
    colors: {
      background: 'rgba(237, 108, 2, 0.3)',
      foreground: '#000000',
    },
    label: 'In progress',
  },
  [TaskStatus.ToDo]: {
    colors: {
      background: 'rgba(7, 114, 229, 0.12)',
      foreground: '#000000',
    },
    label: 'To do',
  },
} as const;

const TaskStatusChip: React.FunctionComponent<{ status: TaskStatus } & ChipProps> = ({
  status,
  ...props
}): React.ReactElement => (
  <Chip id={`task-status-${status}`} size={props.size || 'medium'} {...StatusChipPropsMapping[status]} {...props} />
);

export default TaskStatusChip;
