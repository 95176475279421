import { prepareIcon } from './Icon';
import amberAlert from '../../assets/icons/Amber Alert.png';
import amberAlert2x from '../../assets/icons/Amber Alert@2x.png';
import amberAlert3x from '../../assets/icons/Amber Alert@3x.png';

const AmberAlert = prepareIcon({
  alt: 'Timed Alert',
  src: amberAlert,
  srcSet: `
    ${amberAlert2x} 2x,
    ${amberAlert3x} 3x
  `,
});

export default AmberAlert;
