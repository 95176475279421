import React from 'react';

import Chip from 'components/Chip';

import { DeviceListRowProps } from '../../types';
import { DeviceTypeContent, DeviceTypeIDText, DeviceTypeNameText } from './styles';

const DeviceTypeCell: React.FunctionComponent<DeviceListRowProps> = ({
  value: { customId, type },
}): React.ReactElement => (
  <Chip
    label={
      <DeviceTypeContent>
        {type && <DeviceTypeNameText>{`${type.brand} ${type.model}`}</DeviceTypeNameText>}
        <DeviceTypeIDText>({customId})</DeviceTypeIDText>
      </DeviceTypeContent>
    }
    iconLeft={<img src={type.image} alt={`${type.brand} ${type.model}`} />}
    size="medium"
    variant="filled"
    colors={{ background: 'transparent' }}
  />
);

export default DeviceTypeCell;
