import React from 'react';
import { RouteProps } from 'react-router';

import routes from 'core/routes';

import SecureRoute from '../SecureRoute';

const PrivateRoute: React.FunctionComponent<RouteProps> = (
  props
): React.ReactElement => (
  <SecureRoute
    isAuthorized={(session) => session.isAuthenticated}
    redirectTo={routes.login}
    {...props}
  />
);

export default PrivateRoute;
