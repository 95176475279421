import React from 'react';

import { Switch, FormControlLabel } from '@mui/material';

import { GridValidRowModel } from '@mui/x-data-grid';

const AdminCell: React.FunctionComponent<{ isAdmin: boolean; row: GridValidRowModel }> = ({ isAdmin, row }) => {
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    // @ts-ignore
    row.setAdmin(event.target.checked);
    event.stopPropagation();
  };

  return (
    <FormControlLabel
      control={<Switch checked={isAdmin} color="primary" onClick={handleClick} />}
      label="Admin"
      id="admin-toggle"
    />
  );
};

export default AdminCell;
