/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { DisableableSvgIconProps } from './types';

const PhoneIphone = (props: DisableableSvgIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M15.5 1.5H7.5C6.12 1.5 5 2.62 5 4V21C5 22.38 6.12 23.5 7.5 23.5H15.5C16.88 23.5 18 22.38 18 21V4C18 2.62 16.88 1.5 15.5 1.5ZM11.5 22.5C10.67 22.5 10 21.83 10 21C10 20.17 10.67 19.5 11.5 19.5C12.33 19.5 13 20.17 13 21C13 21.83 12.33 22.5 11.5 22.5ZM16 18.5H7V4.5H16V18.5Z"
      // eslint-disable-next-line react/destructuring-assignment
      fill={props.disabled ? '##00000099' : '#4BA0FD'}
    />
  </SvgIcon>
);

export default PhoneIphone;
