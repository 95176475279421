import React from 'react';

import { Divider, IconButton } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import UserCell from 'containers/Users/components/UserCell';

import { TeamAssignedUserResponse } from 'utils/api/types';

import { MembersHeader, MembersRow, RemoveBtn, SpaceBetweenContainer, Container } from './styles';

const MembersContainer: React.FC<{
  members: TeamAssignedUserResponse[];
  setMembers: (members: TeamAssignedUserResponse[]) => void;
}> = ({ members, setMembers }): React.ReactElement => (
  <>
    <SpaceBetweenContainer>
      <MembersHeader>Members</MembersHeader>
      <RemoveBtn onClick={() => setMembers([])}>Remove all</RemoveBtn>
    </SpaceBetweenContainer>
    <Container>
      {members.map((i, index) => (
        <>
          <MembersRow key={`${i.name}_${i.uuid}`}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <UserCell name={i.name} uuid={i.uuid} />
            </div>
            <IconButton
              onClick={() => {
                const newArr = members.filter((o) => o.uuid !== i.uuid);
                setMembers(newArr);
              }}
            >
              <ClearIcon />
            </IconButton>
          </MembersRow>
          {index !== members.length - 1 && <Divider />}
        </>
      ))}
    </Container>
  </>
);

export default MembersContainer;
