import * as React from 'react';

function FormIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M16.6667 10.8333H3.33333C2.875 10.8333 2.5 11.2083 2.5 11.6667V16.6667C2.5 17.125 2.875 17.5 3.33333 17.5H16.6667C17.125 17.5 17.5 17.125 17.5 16.6667V11.6667C17.5 11.2083 17.125 10.8333 16.6667 10.8333ZM5.83333 15.8333C4.91667 15.8333 4.16667 15.0833 4.16667 14.1667C4.16667 13.25 4.91667 12.5 5.83333 12.5C6.75 12.5 7.5 13.25 7.5 14.1667C7.5 15.0833 6.75 15.8333 5.83333 15.8333ZM16.6667 2.5H3.33333C2.875 2.5 2.5 2.875 2.5 3.33333V8.33333C2.5 8.79167 2.875 9.16667 3.33333 9.16667H16.6667C17.125 9.16667 17.5 8.79167 17.5 8.33333V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5ZM5.83333 7.5C4.91667 7.5 4.16667 6.75 4.16667 5.83333C4.16667 4.91667 4.91667 4.16667 5.83333 4.16667C6.75 4.16667 7.5 4.91667 7.5 5.83333C7.5 6.75 6.75 7.5 5.83333 7.5Z"
        fill="#4BA0FD"
      />
    </svg>
  );
}

export default FormIcon;
