import styled from 'styled-components';
import { Button } from 'vatix-ui/lib/components/Button';

export const AddButton = styled(Button)`
  background-color: #fff;
  color: #0772e5;
  border: 1px solid rgba(7, 114, 229, 0.5);
  margin-bottom: 15px;
`;

export const StyledContainer = styled.div`
  background-color: white;
  margin: 15px 20px 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #f5f5f5;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
`;
