import styled from 'styled-components';
import Link from '@mui/material/Link';

export const Text = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  margin: 0;
`;

export const GoToLink = styled(Link)`
  color: #0160c7;
  font-size: 16px;
  font-weight: 500;
`;
