import React from 'react';

import AmberAlarmStatusChip from 'components/Chips/AmberAlarmStatusChip';
import { AmberAlertStatus } from 'core/constants';

import { AmberAlarmStatusSelectProps } from './types';
import { StyledMenuItem, StyledSelect } from '../styles';

const AmberAlarmStatusSelect: React.FunctionComponent<AmberAlarmStatusSelectProps> = ({ onChange, ...props }) => (
  <StyledSelect {...props} fullWidth onChange={(event) => onChange(event.target.value as AmberAlertStatus)}>
    {Object.values(AmberAlertStatus).map((status) => (
      <StyledMenuItem key={status} value={status}>
        <AmberAlarmStatusChip status={status} />
      </StyledMenuItem>
    ))}
  </StyledSelect>
);

export default AmberAlarmStatusSelect;
