import React from 'react';
import { observer } from 'mobx-react';

import { useCSVDownloader } from 'react-papaparse';

import './styles.css';
import { useStore } from 'utils/hooks/store';
import { UserRole } from 'core/constants';
import { CSVImportColumns } from 'utils/api/types';

const basicCsvData = [
  {
    [CSVImportColumns.firstName]: '',
    [CSVImportColumns.lastName]: '',
    [CSVImportColumns.email]: '',
    [CSVImportColumns.role]: 'Account Owner/Manager/User',
    [CSVImportColumns.dateOfBirth]: 'DD/MM/YYYY',
    [CSVImportColumns.phoneNumber]:
      'Enter the phone number in international format. Include the country code with a + or 00 prefix.',
    [CSVImportColumns.teams]: 'You can add a user to multiple teams by listing the team names with commas between them',
    [CSVImportColumns.memorableWord]: '',
    [CSVImportColumns.loneWorkingLicense]: 'True/False',
    [CSVImportColumns.auditsLicense]: 'True/False',
    [CSVImportColumns.incidentsLicense]: 'True/False',
  },
];

const adminCsvData = [
  {
    ...basicCsvData[0],
    Account: '',
  },
];

const DownloadButton: React.FC = (): React.ReactElement => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { session } = useStore();

  return (
    <CSVDownloader
      className="csvDownloader"
      type={Type.Link}
      bom
      filename="template"
      delimiter=","
      data={session.user?.role === UserRole.Admin ? adminCsvData : basicCsvData}
    >
      Download this template
    </CSVDownloader>
  );
};

export default observer(DownloadButton);
