/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { User } from '@sentry/types';

import { ConsoleLogLevel, LogLevel } from '../constants';
import { Handler } from './types';

export default class Console implements Handler {
  user: User | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(level: LogLevel, message: string, error: any, params: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const normalized: any[] = [];

    if (this.user && this.user.username) {
      normalized.push(`${this.user.username} : `);
    }
    normalized.push(message);
    if (error) {
      normalized.push(error);
    }
    if (params.length) {
      normalized.push(...params);
    }

    // @ts-ignore
    // eslint-disable-next-line no-console
    console[ConsoleLogLevel[level]](...normalized);
  }

  setUser(user: User | null): void {
    this.user = user;
  }
}
