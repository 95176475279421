/* eslint-disable max-len */
import * as React from 'react';

export function EmptyTaskIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <g clipPath="url(#clip0_19866_198016)">
        <path
          d="M29.6875 0H5.72917C2.5625 0 0 2.5625 0 5.72917V38.0208C0 41.1875 2.5625 43.75 5.72917 43.75H19.4583C18.3333 41.375 17.7083 38.7292 17.7083 35.9375C17.7083 33.5417 18.1667 31.25 19.0417 29.1458C19.4583 28.0833 19.9792 27.0625 20.5833 26.125C21.9375 24.0208 23.6875 22.2292 25.75 20.8333C26.8125 20.1042 27.9375 19.5 29.1458 19.0417C31.0833 18.2292 33.2083 17.7708 35.4167 17.7292V5.72917C35.4167 2.5625 32.8542 0 29.6875 0Z"
          fill="#E0E7EC"
        />
        <path
          d="M29.1667 18.7513C29.1667 18.8555 29.1667 18.9596 29.1458 19.043C27.9375 19.5013 26.8125 20.1055 25.75 20.8346H8.33333C7.1875 20.8346 6.25 19.8971 6.25 18.7513C6.25 17.6055 7.1875 16.668 8.33333 16.668H27.0833C28.2292 16.668 29.1667 17.6055 29.1667 18.7513Z"
          fill="#90A4AE"
        />
        <path
          d="M20.5833 26.125C19.9792 27.0625 19.4583 28.0833 19.0417 29.1458C18.9583 29.1667 18.8542 29.1667 18.75 29.1667H8.33333C7.1875 29.1667 6.25 28.2292 6.25 27.0833C6.25 25.9375 7.1875 25 8.33333 25H18.75C19.5417 25 20.25 25.4583 20.5833 26.125Z"
          fill="#90A4AE"
        />
        <path
          d="M16.6667 12.5007H8.33333C7.18333 12.5007 6.25 11.5673 6.25 10.4173C6.25 9.26732 7.18333 8.33398 8.33333 8.33398H16.6667C17.8167 8.33398 18.75 9.26732 18.75 10.4173C18.75 11.5673 17.8167 12.5007 16.6667 12.5007Z"
          fill="#90A4AE"
        />
        <path
          d="M35.9375 50C28.1833 50 21.875 43.6917 21.875 35.9375C21.875 28.1833 28.1833 21.875 35.9375 21.875C43.6917 21.875 50 28.1833 50 35.9375C50 43.6917 43.6917 50 35.9375 50Z"
          fill="#4BA0FD"
        />
        <path
          d="M35.9378 43.75C34.7878 43.75 33.8545 42.8167 33.8545 41.6667V30.2083C33.8545 29.0583 34.7878 28.125 35.9378 28.125C37.0878 28.125 38.0212 29.0583 38.0212 30.2083V41.6667C38.0212 42.8167 37.0878 43.75 35.9378 43.75Z"
          fill="white"
        />
        <path
          d="M41.6667 38.0202H30.2083C29.0583 38.0202 28.125 37.0868 28.125 35.9368C28.125 34.7868 29.0583 33.8535 30.2083 33.8535H41.6667C42.8167 33.8535 43.75 34.7868 43.75 35.9368C43.75 37.0868 42.8167 38.0202 41.6667 38.0202Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_19866_198016">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
