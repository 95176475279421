/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Phone = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon width="20" height="20.003" viewBox="0 0 20 20.003" {...props}>
    {/* <path fill="none" opacity="0" d="M0,0H24V24H0Z" /> */}
    <path
      d="M6768,14161a2.006,2.006,0,0,1-2-2v-16a2,2,0,0,1,2-2h10a2,2,0,0,1,2,2v16a2,2,0,0,1-2,2Zm0-2h10v-1h-10Zm10-3v-10h-10v10Zm0-12v-1h-10v1Z"
      transform="translate(-6762.998 -14141)"
    />
  </SvgIcon>
);

export default Phone;
