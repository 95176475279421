import styled from 'styled-components';

import VatixTheme from 'theme/vatix';

export const Value = styled.p`
  color: ${VatixTheme.palette.text.secondary};
  text-align: left;
  font: normal normal normal 14px/20px Roboto;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
  opacity: 1;
  white-space: pre-line;
  word-break: break-word;
`;
