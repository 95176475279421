import React from 'react';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import { observer } from 'mobx-react';

import { HomeSectionHeader, HomeSectionHeaderButton, HomeSectionHeaderContainer } from 'containers/Home/styles';

import { useStore } from 'utils/hooks/store';

import ExpandMoreFilled from 'components/Icons/ExpandMoreFilled';
import AddActionModal from 'containers/IncidentDetails/components/AddTaskModal';
import routes from 'core/routes';

import {
  CreateNewTaskButton,
  EmptyTasksListContainer,
  EmptyTasksListMessage,
  StyledEmptyTasksListIcon,
  TaskListCell,
  TaskListCellStatusChip,
  TaskListCellTitle,
  TaskSummaryCell,
  TaskSummaryRow,
  TasksContainer,
  TasksListContainer,
  TasksSummaryContainer,
  TasksSummaryContent,
  TasksSummaryHeader,
} from './styles';
import TaskListCellDueDate from './components/TaskListCellDueDate';
import TasksPaneSkeleton from './TasksPane.skeleton';

const TasksPane: React.FunctionComponent = () => {
  const history = useHistory();
  const { home } = useStore();
  const [addActionModalOpened, setAddActionModalOpened] = React.useState(false);

  if (home.content === undefined) {
    if (!home.hasError && home.isLoading) {
      return <TasksPaneSkeleton />;
    }

    if (home.hasError && !home.isLoading) {
      return null;
    }
  }

  if (!home.content) {
    return null;
  }

  const viewAllTasks = (): void => {
    history.push(reverse(routes.dashboard.tasks.my.toString()));
  };

  const viewTask = (uuid: string): void => {
    history.push(reverse(routes.dashboard.tasks.details.toString(), { taskId: uuid }));
  };

  const createNewTask = (): void => {
    setAddActionModalOpened(true);
  };

  const onCloseAddActionModal = async (): Promise<void> => {
    await home.loadHomeContent();
    setAddActionModalOpened(false);
  };

  return (
    <>
      <HomeSectionHeaderContainer>
        <HomeSectionHeader>My Actions</HomeSectionHeader>
        {home.content.recentTasks !== undefined && home.content.recentTasks.length > 0 ? (
          <HomeSectionHeaderButton onClick={viewAllTasks}>View all</HomeSectionHeaderButton>
        ) : null}
      </HomeSectionHeaderContainer>
      <TasksContainer>
        <TasksSummaryContainer>
          <TaskSummaryRow>
            <TaskSummaryCell>
              <TasksSummaryHeader>Actions assigned to me</TasksSummaryHeader>
              <TasksSummaryContent>
                {home.content.tasksSummary?.assignedToUser !== undefined
                  ? home.content.tasksSummary.assignedToUser
                  : 'N/A'}
              </TasksSummaryContent>
            </TaskSummaryCell>
            <TaskSummaryCell>
              <TasksSummaryHeader>Actions in progress</TasksSummaryHeader>
              <TasksSummaryContent>
                {home.content.tasksSummary?.inProgress !== undefined ? home.content.tasksSummary.inProgress : 'N/A'}
              </TasksSummaryContent>
            </TaskSummaryCell>
          </TaskSummaryRow>
          <TaskSummaryRow>
            <TaskSummaryCell>
              <TasksSummaryHeader>Actions due this week</TasksSummaryHeader>
              <TasksSummaryContent>
                {home.content.tasksSummary?.dueThisWeek !== undefined ? home.content.tasksSummary.dueThisWeek : 'N/A'}
              </TasksSummaryContent>
            </TaskSummaryCell>
            <TaskSummaryCell>
              <TasksSummaryHeader>Actions completed this week</TasksSummaryHeader>
              <TasksSummaryContent>
                {home.content.tasksSummary?.completedThisWeek !== undefined
                  ? home.content.tasksSummary.completedThisWeek
                  : 'N/A'}
              </TasksSummaryContent>
            </TaskSummaryCell>
          </TaskSummaryRow>
        </TasksSummaryContainer>
        {home.content.recentTasks === undefined || home.content.recentTasks.length === 0 ? (
          <EmptyTasksListContainer>
            <StyledEmptyTasksListIcon />
            <EmptyTasksListMessage>You have no outstanding actions</EmptyTasksListMessage>
            <CreateNewTaskButton onClick={createNewTask}>Create New</CreateNewTaskButton>
            {addActionModalOpened && <AddActionModal onClose={onCloseAddActionModal} />}
          </EmptyTasksListContainer>
        ) : (
          <TasksListContainer>
            {home.content.recentTasks.map((task) => (
              <TaskListCell
                key={task.uuid}
                onClick={() => {
                  viewTask(task.uuid);
                }}
              >
                <TaskListCellStatusChip status={task.status} />
                <TaskListCellTitle>{task.name}</TaskListCellTitle>
                <TaskListCellDueDate dueDate={task.dueDate} />
                <ExpandMoreFilled />
              </TaskListCell>
            ))}
          </TasksListContainer>
        )}
      </TasksContainer>
    </>
  );
};

export default observer(TasksPane);
