import styled from 'styled-components';

export const ModalText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin: 0;
`;
