import { Tooltip } from '@mui/material';
import React from 'react';

import { NumberChip, ChipContainer, TextChip, CircleScore, EnumItemText } from '../Question/styles';

export const Options: React.FunctionComponent<{ enumItem: string; score: string | null }> = ({
  enumItem,
  score,
}): React.ReactElement => (
  <TextChip key={enumItem}>
    {enumItem.length > 6 ? (
      <Tooltip title={enumItem} placement="top">
        <EnumItemText>{enumItem}</EnumItemText>
      </Tooltip>
    ) : (
      <EnumItemText>{enumItem}</EnumItemText>
    )}

    {score === null || score === '-' ? null : (
      <Tooltip title="Score" placement="top">
        <CircleScore>{score}</CircleScore>
      </Tooltip>
    )}
  </TextChip>
);

const Chips: React.FunctionComponent<{ enumItems: string[]; scoring: string[] | null }> = ({
  enumItems,
  scoring,
}): React.ReactElement => (
  <ChipContainer>
    {enumItems.slice(0, 3).map((enumItem, index) => (
      <Options enumItem={enumItem} score={(scoring && scoring[index]) || null} />
    ))}
    {enumItems.length > 3 && <NumberChip key="number">+{enumItems.length - 3}</NumberChip>}
  </ChipContainer>
);

export default Chips;
