import React from 'react';

import { Dropdown } from 'vatix-ui/lib/components/Dropdown';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';

import { GridColDef, GridFilterOperator } from '@mui/x-data-grid';

import { SelectChangeEvent } from '@mui/material';

import Delete from 'components/Icons/Delete';

import { PickerProps } from 'components/Grid/operators/types';

import { EmptyFilterValues } from 'components/Grid/operators/EmptyNotEmpty/EmptyNotEmpty';

import UserOperators from '../../../../operators/User';

import {
  ColumnsContainer,
  EntityContainer,
  EntityName,
  FilterItemContainer,
  OperatorContainer,
  InputContainer,
  StyledInputLabel,
  StyledMenuItem,
  DisabledTextInput,
  StyledDropdown,
} from './styles';
import { FilterItemProps } from './types';

const FilterItem = ({ columns, filter, onChange, onDelete }: FilterItemProps): React.ReactElement => {
  const column = (filter.field && columns.lookup[filter.field]) as GridColDef | null;
  const operator = column?.filterOperators?.find((f) => f.label === filter.operator);
  const InputComponent = operator?.InputComponent as React.FunctionComponent<PickerProps>;

  const onChangeColumn = (event: SelectChangeEvent<unknown>): void => {
    const newColumn = columns.lookup[event.target.value as string];
    const [newOperator] = (newColumn.filterOperators as unknown) as GridFilterOperator[];
    onChange({
      field: newColumn.field,
      operator: newOperator.label as string,
      value: null,
    });
  };

  const onChangeOperator = (event: SelectChangeEvent<unknown>): void => {
    if (event.target.value === EmptyFilterValues.is || event.target.value === EmptyFilterValues.isNot) {
      onChange({
        ...filter,
        operator: event.target.value as string,
        value: event.target.value === EmptyFilterValues.is ? 'empty' : 'not_empty',
      });
    } else {
      onChange({
        ...filter,
        operator: event.target.value as string,
        value: null,
      });
    }
  };

  const onChangeValue = (item: { value: unknown }): void => {
    let newValue = item.value;
    if (operator?.label === 'range' && (newValue as string[])[1] === null) {
      return;
    }
    if (operator?.label === 'is not') {
      newValue = `-${newValue}`;
    }
    if (operator && newValue && UserOperators().indexOf(operator) !== -1) {
      newValue = (newValue as string).replace('user:', '');
    }
    onChange({
      ...filter,
      value: newValue,
    });
  };

  return (
    <FilterItemContainer>
      <EntityContainer>
        <EntityName>Columns</EntityName>
        <ColumnsContainer>
          <FormControl fullWidth>
            {!filter.field && <StyledInputLabel>Columns</StyledInputLabel>}
            <StyledDropdown
              fullWidth
              label="Columns"
              onChange={onChangeColumn}
              value={filter.field}
              data-testid="filter-column"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                style: {},
              }}
            >
              {Object.values(columns.lookup)
                .filter((i) => i.filterable)
                .map((p) => (
                  <StyledMenuItem key={p.field} value={p.field}>
                    {p.headerName}
                  </StyledMenuItem>
                ))}
            </StyledDropdown>
          </FormControl>
        </ColumnsContainer>
      </EntityContainer>
      <EntityContainer>
        <EntityName>Operator</EntityName>
        <OperatorContainer>
          <Dropdown
            disabled={!column}
            fullWidth
            label="Operator"
            onChange={onChangeOperator}
            value={filter.operator}
            variant="outlined"
          >
            {column?.filterOperators?.map(({ label }) => (
              <StyledMenuItem key={label} value={label}>
                {label}
              </StyledMenuItem>
            ))}
          </Dropdown>
        </OperatorContainer>
      </EntityContainer>
      {operator?.value !== EmptyFilterValues.is && operator?.value !== EmptyFilterValues.isNot && (
        <EntityContainer>
          <EntityName>Value</EntityName>
          <InputContainer>
            {operator ? (
              <InputComponent
                {...operator.InputComponentProps}
                applyValue={onChangeValue}
                item={{ value: filter.value }}
              />
            ) : (
              <DisabledTextInput disabled />
            )}
          </InputContainer>
        </EntityContainer>
      )}
      <IconButton
        onClick={onDelete}
        aria-label="Remove"
        size="large"
        sx={{
          alignSelf: 'flex-end',
          padding: '8px',
        }}
      >
        <Delete />
      </IconButton>
    </FilterItemContainer>
  );
};

export default FilterItem;
