import React from 'react';

import { DataGridContainer, Footer, Header, Wrapper } from './styles';

type AbstractWrapperProps = {
  headerTitle: string;
  headerAction?: React.ReactElement;
  gridElement: React.ReactElement;
  footerAction?: React.ReactElement;
};

const AbstractWrapper: React.FC<AbstractWrapperProps> = ({
  headerAction,
  headerTitle,
  gridElement,
  footerAction,
}): React.ReactElement => (
  <Wrapper>
    <Header>
      <h1>{headerTitle}</h1>
      {headerAction}
    </Header>
    <DataGridContainer>{gridElement}</DataGridContainer>
    <Footer>{footerAction}</Footer>
  </Wrapper>
);

export default AbstractWrapper;
