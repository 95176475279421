import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M21.64 21.999H2.363A2.006 2.006 0 01.601 19.04l9.643-17.795A1.959 1.959 0 0111.998.198a1.982 1.982 0 011.762 1.047l9.643 17.795a2.008 2.008 0 01-1.762 2.959zm-9.643-6a1 1 0 101 1 1 1 0 00-.999-1zm0-8a1 1 0 00-1 1v5a1 1 0 002 0v-5a1 1 0 00-.999-1z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
