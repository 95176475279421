import React from 'react';

import { Grid } from '@mui/material';

import Row from 'containers/AlarmDetails/components/Row';
import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import { RowValue } from './styles';

import { PersonalDetailsProps } from './types';

const PersonalDetails: React.FunctionComponent<PersonalDetailsProps> = ({ details }) => (
  <>
    <Grid container alignItems="center">
      <Row name="Medical Conditions" id="medical-conditions">
        {details?.personalDetails?.medicalConditions ? (
          <RowValue>{details?.personalDetails?.medicalConditions}</RowValue>
        ) : (
          <EmptyValue>N/A</EmptyValue>
        )}
      </Row>

      <Row name="Allergies" id="allergies">
        {details?.personalDetails?.allergies ? (
          <RowValue>{details?.personalDetails?.allergies}</RowValue>
        ) : (
          <EmptyValue>N/A</EmptyValue>
        )}
      </Row>

      <Row name="Current Medication" id="current-medication">
        {details?.personalDetails?.currentMedication ? (
          <RowValue>{details?.personalDetails?.currentMedication}</RowValue>
        ) : (
          <EmptyValue>N/A</EmptyValue>
        )}
      </Row>

      <Row name="Blood Type" id="blood-type">
        {details?.personalDetails?.bloodType ? (
          <RowValue>{details?.personalDetails?.bloodType}</RowValue>
        ) : (
          <EmptyValue>N/A</EmptyValue>
        )}
      </Row>

      <Row name="Weight" id="weight">
        {details?.personalDetails?.weight ? (
          <RowValue>{details?.personalDetails?.weight}</RowValue>
        ) : (
          <EmptyValue>N/A</EmptyValue>
        )}
      </Row>

      <Row name="Height" id="height">
        {details?.personalDetails?.height ? (
          <RowValue>{details?.personalDetails?.height}</RowValue>
        ) : (
          <EmptyValue>N/A</EmptyValue>
        )}
      </Row>
    </Grid>
  </>
);

export default PersonalDetails;
