import React from 'react';

import { FormControlLabel, Switch, SelectChangeEvent, IconButton, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { TextInput } from 'vatix-ui/lib/components/TextInput';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import Collapse from '@mui/material/Collapse';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { observer } from 'mobx-react';

import { KindOfQuestionTypes, QuestionType } from 'stores/TemplateDetails/types';
import { useStore } from 'utils/hooks/store';
import { isMultipleAnswerQuestionType } from 'utils/api/types';

import {
  QuestionRow,
  QuestionsLeftSide,
  QuestionsRightSide,
  StyledDropdown,
  StyledMenuItem,
  StyledText,
  StyledListItemButton,
  MultiAllowed,
  Row,
} from './styles';
import MultipleAnswers from '../MultipleAnswers';
import Chips from '../Chips';

const Question: React.FunctionComponent<{ questionId: string; sectionId: string }> = ({
  questionId,
  sectionId,
}): React.ReactElement => {
  const { templateDetails } = useStore();

  const question = templateDetails.details?.content.properties[sectionId].properties[questionId] as QuestionType;

  const [questionType, setQuestionType] = React.useState(question.workflowsType);

  const [isMultiOpen, setMultiOpen] = React.useState(questionType === 'multi');

  const [status, setStatus] = React.useState(templateDetails.details?.isQuestionMandatory(questionId, sectionId));
  const [open, setOpen] = React.useState(false);

  const [options, setOptions] = React.useState(isMultipleAnswerQuestionType(question) ? question.items.enum : []);

  const [scoring, setScoring] = React.useState((isMultipleAnswerQuestionType(question) && question.scoring) || null);

  const [allowMulti, setAllowMulti] = React.useState(!!(isMultipleAnswerQuestionType(question) && !question.maxItems));

  React.useEffect(() => {
    if (questionType === 'multi') {
      templateDetails.details?.changeQuestionType(
        questionId,
        questionType,
        undefined,
        {
          maxItems: allowMulti ? undefined : 1,
          minItems: templateDetails.details?.isQuestionMandatory(questionId, sectionId) ? 1 : 0,
          items: {
            type: 'string',
            enum: options,
          },
          scoring,
        },
        sectionId
      );
    } else if (questionType === 'signature') {
      templateDetails.details?.changeQuestionType(
        questionId,
        questionType,
        undefined,
        {
          contentEncoding: 'base64',
          contentMediaType: 'image/png',
          workflowsType: 'signature',
        },
        sectionId
      );
    } else {
      templateDetails.details?.changeQuestionType(
        questionId,
        questionType as KindOfQuestionTypes,
        undefined,
        undefined,
        sectionId
      );
    }
  }, [options, allowMulti, questionType, scoring]);

  const handleClickList = (): void => {
    setMultiOpen(!isMultiOpen);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setStatus((prevState) => !prevState);
    status
      ? templateDetails.details?.setQuestionAsOptional(questionId, sectionId)
      : templateDetails.details?.setQuestionAsMandatory(questionId, sectionId);
    event.stopPropagation();
  };

  const onChange = (event: SelectChangeEvent<unknown>): void => {
    setQuestionType(event.target.value as KindOfQuestionTypes);
    if (event.target.value !== 'multi' && allowMulti) {
      setAllowMulti(false);
    }
  };

  const setValue = (value: string): void => {
    setQuestionType(value as KindOfQuestionTypes);
    setOpen(false);
  };

  const updateOptions = (newOptions: string[], newScoring: string[] | null): void => {
    setOptions(newOptions);
    if (newScoring?.length) {
      setScoring(newScoring);
    }
  };

  return (
    <QuestionRow key={`${sectionId}_${questionId}`}>
      <QuestionsLeftSide>
        <DragIndicatorIcon color="disabled" />
        <TextInput
          onChange={(event) => templateDetails.details?.updateDescription(questionId, event.target.value, sectionId)}
          placeholder="Type question here..."
          value={question.description}
          id="question-description-text-input"
          key={questionId}
        />
      </QuestionsLeftSide>
      <QuestionsRightSide>
        <StyledDropdown
          value={questionType}
          onChange={onChange}
          onClose={() => {
            updateOptions(options, scoring);
            setOpen(false);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          open={open}
          $type={questionType === 'multi'}
          data-testid="select-option"
          key={`select-${sectionId}-${questionId}`}
        >
          <StyledMenuItem value="multi" style={{ padding: 0 }}>
            <StyledListItemButton
              onClick={(e) => {
                handleClickList();
                e.stopPropagation();
              }}
            >
              {isMultipleAnswerQuestionType(question) && !open ? (
                <Chips enumItems={options} scoring={scoring} />
              ) : (
                <>
                  <StyledText>Single / Multiple Choice</StyledText>
                  {isMultiOpen ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
                </>
              )}
            </StyledListItemButton>
          </StyledMenuItem>
          <Collapse in={isMultiOpen} timeout="auto" unmountOnExit>
            <MultipleAnswers
              options={questionType === 'multi' ? options : []}
              setValue={setValue}
              setOptions={setOptions}
              scoring={scoring}
              setScoring={setScoring}
              allowMulti={allowMulti}
              setAllowMulti={setAllowMulti}
            />
          </Collapse>

          <StyledMenuItem value="boolean">Yes / No / N/A</StyledMenuItem>
          <StyledMenuItem value="string">Text</StyledMenuItem>
          <StyledMenuItem value="location">Location</StyledMenuItem>
          <StyledMenuItem value="date">Date</StyledMenuItem>
          <StyledMenuItem value="signature">Signature</StyledMenuItem>
        </StyledDropdown>
        <Row>
          {allowMulti ? (
            <MultiAllowed data-testid="multi-allowed">
              <CheckCircleIcon style={{ color: 'green' }} />
              <p>Multiple Answers Allowed</p>
            </MultiAllowed>
          ) : (
            <MultiAllowed />
          )}
          <div>
            <Tooltip title="Duplicate Question" arrow>
              <IconButton
                style={{ marginRight: '16px' }}
                onClick={() => {
                  templateDetails.details?.duplicateQuestion(questionId, sectionId);
                }}
                disabled={question.description === ''}
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
            <FormControlLabel
              control={<Switch checked={status} color="primary" onClick={handleClick} />}
              label="Required"
              id="required-switch"
            />
          </div>
        </Row>
      </QuestionsRightSide>
      <IconButton onClick={() => templateDetails.details?.removeQuestion(questionId, sectionId)} id="delete-button">
        <DeleteOutlineIcon />
      </IconButton>
    </QuestionRow>
  );
};

export default observer(Question);
