import { Grid } from '@mui/material';
import styled from 'styled-components';

export const HeaderGrid = styled(Grid)`
  background-color: #fff;
  padding: 16px 20px;
  max-height: 60px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Title = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const TaskRecordContainer = styled.div<{ $editing: boolean }>`
  padding: 16px 20px 20px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: ${(props) => props.$editing && '0'};
  border-top: 0;
`;

export const TaskRecordRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RecordRow = styled.div`
  display: flex;
  align-items: baseline;
  min-height: 24px;
`;

export const FirstColumn = styled.div`
  flex: 1;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
`;

export const SecondColumn = styled.div`
  flex: 3;
  padding: 10px 0;
`;

export const StyledRedirect = styled.div<{ $isClickable: boolean }>`
  color: #0772e5;
  font-size: 16px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: ${(props) => (props.$isClickable ? 'pointer' : 'default')};
`;

export const StyledName = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const DescriptionField = styled.p`
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  word-break: break-word;
  p {
    margin: 0;
  }
`;

export const RelatedToIncidentValue = styled.p`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;
