import { AxiosError } from 'axios';

import API from 'utils/api';
import { UpdateUserRequest } from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import { postMessage } from 'utils/events/broadcast';
import { useStore } from 'utils/hooks/store';
import Logger from 'utils/logger';
import { formErrors, isBadRequest } from 'utils/api/errors';

import { LicenseType, LicenseTypeName } from 'core/constants';

import { getLicenseTypeText } from 'utils/formatters/licenses';

function getShowSafety(error: AxiosError): string | null {
  if (isBadRequest(error)) {
    const { fieldErrors } = formErrors(error);
    if (fieldErrors && 'showSafety' in fieldErrors) {
      return fieldErrors.showSafety[0];
    }
  }
  return null;
}

export function getLicenseType(error: AxiosError): LicenseType | null {
  if (isBadRequest(error)) {
    const { fieldErrors } = formErrors(error);
    if (fieldErrors && 'licenses' in fieldErrors) {
      return getLicenseTypeText((fieldErrors.licenses as unknown) as string);
    }
  }
  return null;
}

type UpdateUserHook = (
  userUuid: string,
  payload: Partial<UpdateUserRequest>,
  onClickCallBack: () => void,
  apiRequestCallBack: () => Promise<void>
) => Promise<void>;

const useUpdateUser = (): UpdateUserHook => {
  const { notification } = useStore();

  return async (userUuid, payload, onClickCallBack, apiRequestCallBack) => {
    API.updateUser(userUuid, payload)()
      .then(({ data }) => {
        postMessage(EventType.UpdatedUser, data);
        // eslint-disable-next-line no-prototype-builtins
        if (apiRequestCallBack.hasOwnProperty('then')) {
          apiRequestCallBack().then(() => {
            notification.enqueueSuccessSnackbar('User updated successfully');
          });
        } else {
          apiRequestCallBack();
          notification.enqueueSuccessSnackbar('User updated successfully');
        }
      })
      .catch((e) => {
        // @ts-ignore
        const licenseMissing = getLicenseType(e);
        // @ts-ignore
        const showSafetyError = getShowSafety(e);
        if (licenseMissing) {
          notification.enqueueErrorSnackbar(
            `User could not be updated. Insufficient number of ${LicenseTypeName[licenseMissing]} licenses.`
          );
        } else if (showSafetyError) {
          notification.enqueueErrorSnackbar(`${showSafetyError} Add this in the Admin Console in the 'Users' tab.`);
        } else {
          Logger.error('Invalid update user details API response', e);
          let message = 'User could not be updated';
          // @ts-ignore
          if (isBadRequest(e)) {
            // @ts-ignore
            const { nonFieldErrors } = formErrors(e);
            if (nonFieldErrors) {
              for (const error of nonFieldErrors) {
                message += `. ${error}`;
              }
            }
          }
          notification.enqueueErrorSnackbar(message);
        }
      })
      .finally(() => {
        onClickCallBack();
      });
  };
};

export default useUpdateUser;
