import { prepareIcon } from './Icon';
import deviceWhite from '../../assets/icons/Device - White.png';
import deviceWhite2x from '../../assets/icons/Device - White@2x.png';
import deviceWhite3x from '../../assets/icons/Device - White@3x.png';

const DeviceWhite = prepareIcon({
  alt: 'Device White',
  src: deviceWhite,
  srcSet: `
    ${deviceWhite2x} 2x,
    ${deviceWhite3x} 3x
  `,
});

export default DeviceWhite;
