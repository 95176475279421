import React, { useState } from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';

import { CancelButton, Button } from 'vatix-ui/lib/components/Button';

import { useStore } from 'utils/hooks/store';

import {
  ConfirmationDialogWrapper,
  ConfirmationDialogTitle,
  ConfirmationDialogContent,
  ConfirmationDialogContentText,
  StyledFormControl,
  PrimaryDialogActions,
} from './styles';

type DeleteSectionDialogType = {
  section: string;
  openModal: boolean;
  handleCloseModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const DeleteSectionDialog: React.FunctionComponent<DeleteSectionDialogType> = ({
  section,
  openModal,
  handleCloseModal,
}): React.ReactElement => {
  const {
    templateDetails: { details },
  } = useStore();

  const [questionRemovalStrategy, setQuestionRemovalStrategy] = useState<string>('');

  const handleDeletingSection = (): void => {
    details?.removeSection(section, questionRemovalStrategy === 'removeQuestion');
  };

  return (
    <ConfirmationDialogWrapper open={openModal} onClose={handleCloseModal}>
      <ConfirmationDialogTitle>Delete Section</ConfirmationDialogTitle>
      <ConfirmationDialogContent>
        <ConfirmationDialogContentText>
          If you delete this section, we need to know what you wish to do with all the questions that are within the
          section.
        </ConfirmationDialogContentText>

        <StyledFormControl>
          <RadioGroup
            defaultValue="removeQuestion"
            name="radio-buttons-group"
            value={questionRemovalStrategy}
            onChange={(event) => {
              setQuestionRemovalStrategy((event.target as HTMLInputElement).value);
            }}
          >
            <FormControlLabel
              value="removeQuestion"
              control={<Radio />}
              label={
                <ConfirmationDialogContentText>Remove both the section and the questions</ConfirmationDialogContentText>
              }
            />
            <FormControlLabel
              value="keepQuestion"
              control={<Radio />}
              label={
                <ConfirmationDialogContentText>Remove the section but keep the questions</ConfirmationDialogContentText>
              }
            />
          </RadioGroup>
        </StyledFormControl>
      </ConfirmationDialogContent>
      <PrimaryDialogActions>
        <Button onClick={handleDeletingSection} disabled={questionRemovalStrategy === ''}>
          Delete
        </Button>
        <CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
      </PrimaryDialogActions>
    </ConfirmationDialogWrapper>
  );
};

export default DeleteSectionDialog;
