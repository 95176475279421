import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';
import { escapeRegExp } from 'lodash';
import { GridFilterOperator } from '@mui/x-data-grid';

import { DatePicker } from '../Date/DatePicker';

export enum EmptyFilterValues {
  is = 'is empty',
  isNot = 'is not empty',
}

const EmptyNotEmpty = (): GridFilterOperator[] =>
  ([
    {
      label: EmptyFilterValues.is,
      value: EmptyFilterValues.is,
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterItemValue = filterItem.value.trim();

        const filterRegex = new RegExp(escapeRegExp(filterItemValue), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: DatePicker,
      getValueAsString: () => '',
    },
    {
      label: EmptyFilterValues.isNot,
      value: EmptyFilterValues.isNot,
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterItemValue = filterItem.value.trim();

        const filterRegex = new RegExp(escapeRegExp(filterItemValue), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: DatePicker,
      getValueAsString: () => '',
    },
  ] as unknown) as GridFilterOperator[];

export default EmptyNotEmpty;
