import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  font-size: 14px;
`;
