import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';
import { Button } from 'vatix-ui/lib/components/Button';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import Record from 'vatix-ui/lib/containers/Record/Record';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import IncidentList from './components/IncidentList';

const Incidents: React.FunctionComponent = (): React.ReactElement => {
  const history = useHistory();
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      Header={
        <MainHeader
          title="Overview"
          breadcrumbs={[
            { label: 'Incidents', link: routes.dashboard.incidents.toString() },
            { label: 'Overview', link: routes.dashboard.incidents.toString() },
          ]}
          action={
            <Button
              onClick={() => history.push(reverse(routes.dashboard.incidents.report))}
              variant="contained"
              size="large"
            >
              New
            </Button>
          }
        />
      }
      Content={(onError) => <IncidentList onError={onError} />}
      navBarHeight={navBarHeight}
    />
  );
};

export default Incidents;
