import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import NavBarBase from 'vatix-ui/lib/components/NavBar/NavBar';
import { observer } from 'mobx-react';

import { reverse } from 'named-urls';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { IconButton } from '@mui/material';

import API from 'utils/api';
import Logger from 'utils/logger';
import { useStore } from 'utils/hooks/store';

import SessionUser from 'stores/Session/SessionUser';
import routes from 'core/routes';
import { HELP_CENTER_URL } from 'core/constants';

const NavBar: React.FunctionComponent<RouteComponentProps> = () => {
  const {
    session,
    notification,
    ui: { setSubSideBarOpen, subSideBarOpen },
  } = useStore();

  const onLogout = async (): Promise<void> => {
    try {
      const resp = await API.getFusionAuthApplicationData({ username: session.user?.email as string })();
      const { applicationClientId } = resp.data;

      await API.logout()();
      session.logout();
      document.body.style.backgroundColor = '#FFF';

      const searchParams = new URLSearchParams({
        client_id: applicationClientId as string,
      });

      const redirectUrl = applicationClientId
        ? `${process.env.REACT_APP_FUSION_AUTH_URL}/oauth2/logout/?${searchParams.toString()}`
        : `${process.env.REACT_APP_WORKSPACE_URL}/login/`;

      window.location.assign(redirectUrl);
    } catch (e) {
      notification.enqueueErrorSnackbar('You cannot log out right now');
      Logger.error('Invalid logout API response', e);
    }
  };

  const redirectToSettings = (): void => {
    const route =
      session.user && (session.user.licenses.protectorIncidents || session.user.licenses.workflows)
        ? routes.dashboard.profileSettings.notifications.toString()
        : routes.dashboard.profileSettings.security.toString();
    window.location.assign(reverse(route));
  };

  const redirectToUser = (): void => {
    window.location.assign(reverse(routes.dashboard.admin.users.details, { userId: session.user?.uuid as string }));
  };

  const redirectToHelpCenter = (): void => {
    window.open(HELP_CENTER_URL);
  };

  return (
    <NavBarBase
      onRedirectToUserDetails={redirectToUser}
      collapseSubSideBar={setSubSideBarOpen}
      isSubSideBarCollapsed={!subSideBarOpen}
      onLogout={onLogout}
      onRedirectToSettings={redirectToSettings}
      user={{ ...(session.user as SessionUser), userId: session.user?.organisation.accountNumber || '' }}
      helpPanel={
        <IconButton onClick={redirectToHelpCenter}>
          <HelpOutlineIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </IconButton>
      }
    />
  );
};

export default withRouter(observer(NavBar));
