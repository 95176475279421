import React from 'react';
import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { useStore } from 'utils/hooks/store';

import API from 'utils/api';

import { formatDateForDisplay, useTimeZone } from 'utils/formatters/time';

import AlarmStatusChip from 'components/Chips/AlarmStatusChip';
import UserField from 'containers/IncidentDetails/components/UserField';

import { AlarmDetailsResponse } from 'utils/api/types';
import AlarmSource from 'components/AlarmSource';

import routes from 'core/routes';

import AlarmActions from '../AlarmActions';

import { MainInformation } from './styles';

const AlarmDetailsHeader: React.FC = (): React.ReactElement => {
  const {
    alarmDetails: { details: detailsRaw },
  } = useStore();

  const details = detailsRaw as AlarmDetailsResponse;

  const tz = useTimeZone();

  const handleExportAlarm = React.useCallback(async (): Promise<void> => {
    if (!details) {
      return;
    }
    await API.exportAlarm(details.uuid, { time_zone: tz })();
  }, [details, tz]);

  if (details === undefined) {
    return <></>;
  }

  return (
    <>
      <MainHeader
        title={details.subject}
        breadcrumbs={[
          {
            label: 'Lone Working',
            link: String(routes.dashboard.alarms.red),
          },
          {
            label: 'Overview',
            link: String(routes.dashboard.alarms.red),
          },
          {
            label: 'Alarm',
            link: 'Alarms',
          },
        ]}
        action={
          <Button onClick={handleExportAlarm} variant="contained" size="large">
            Export
          </Button>
        }
      />
      <AlarmActions />
      <MainInformation>
        <div>
          <h1>Name</h1>
          <p id="alarm-subject">{details.subject}</p>
        </div>

        <div>
          <h1>Started by</h1>
          <UserField userId={details.user.uuid} />
        </div>

        <div>
          <h1>Status</h1>
          <AlarmStatusChip status={details.alertStatus} />
        </div>

        <div>
          <h1>Raised at</h1>
          <p>{formatDateForDisplay(details.activated)}</p>
        </div>

        <div>
          <h1>Source</h1>
          {details.device ? <AlarmSource device={details.device} /> : <p>N/A</p>}
        </div>
      </MainInformation>
    </>
  );
};

export default observer(AlarmDetailsHeader);
