import React from 'react';
import punycode from 'punycode';

export const PunycodeComponentDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
): React.ReactNode => (
  <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
    {punycode.toASCII(decoratedText)}
  </a>
);
