import { prepareIcon } from './Icon';
import amberAlertCompleted from '../../assets/icons/Amber Alert - Completed.png';
import amberAlertCompleted2x from '../../assets/icons/Amber Alert - Completed@2x.png';
import amberAlertCompleted3x from '../../assets/icons/Amber Alert - Completed@3x.png';

const FinishedAmberAlertIcon = prepareIcon({
  alt: 'Finished Timed Alert',
  src: amberAlertCompleted,
  srcSet: `
    ${amberAlertCompleted2x} 2x,
    ${amberAlertCompleted3x} 3x
  `,
});

export default FinishedAmberAlertIcon;
