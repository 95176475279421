import React from 'react';
import { observer } from 'mobx-react';

import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import FeedComponent from 'vatix-ui/lib/components/Feed';

import API from 'utils/api';

import { useStore } from 'utils/hooks/store';

import { SessionStoreWithUser } from 'stores/Session/types';

import AlarmActivitiesStore from 'stores/AlarmDetails/AlarmActivities';

import { ActivityType } from 'utils/stores/Activities/constants';

import VoiceMemoActivity from 'components/DetailsActivities/VoiceMemoActivity';

import { ActivityMapping } from 'utils/api/types';

import AmberAlertActivity from './components/AmberAlertActivity';
import RedAlertActivity from './components/RedAlertActivity';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.AmberAlert]: AmberAlertActivity,
  [ActivityType.RedAlert]: RedAlertActivity,
  [ActivityType.VoiceMemo]: VoiceMemoActivity,
} as unknown) as ActivityMapping;

const AlarmActivitiesContent: React.FunctionComponent = (): React.ReactElement => {
  const { alarmDetails, session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  const activities = alarmDetails.activities as AlarmActivitiesStore;

  return (
    <FeedComponent
      originalActivities={activities}
      mapping={activityTypeToComponent}
      onDeleteComment={activities.deleteMessage}
      onDeleteFile={activities.deleteFile}
      sessionUser={session.user}
      usersSearch={API.searchUsers}
      downloadFile={API.downloadFile}
    />
  );
};

export default observer(AlarmActivitiesContent);
