import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { reverse } from 'named-urls';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { UserRole } from 'core/constants';

import { TeamResponse } from 'utils/api/types';

import { ColumnActions } from 'components/Grid/types';

import api from 'utils/api';

import { MenuItem } from './styles';

const ActionCell: React.FunctionComponent<{ rowData: TeamResponse; actions: ColumnActions }> = ({
  rowData,
  actions,
}) => {
  const { session } = useStore();

  const userRole = session.user?.role as UserRole;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const { routing } = useStore();

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    routing.push(reverse(routes.dashboard.admin.teams.details, { teamId: rowData.uuid }));
  };

  const handleRemoveClick = async (): Promise<void> => {
    await api.deleteTeam(rowData.uuid)();
    await actions.refreshData();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <GridActionsCellItem icon={<MoreVert />} label="" color="inherit" onClick={handleOpen} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          id="edit-button"
          onClick={async (e) => {
            handleEditClick(e);
          }}
        >
          Edit
        </MenuItem>
        {[UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(userRole) && (
          <MenuItem id="remove-button" onClick={handleRemoveClick}>
            Delete
          </MenuItem>
        )}
      </Popover>
    </>
  );
};

export default observer(ActionCell);
