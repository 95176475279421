import { Grid } from '@mui/material';
import styled from 'styled-components';

export const HeaderGrid = styled(Grid)`
  background-color: #fff;
  padding: 16px 20px;
  max-height: 60px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Title = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const TaskRecordContainer = styled.div<{ $editing?: boolean }>`
  padding: 16px 20px 20px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: ${(props) => props.$editing && '0'};
  border-top: 0;
`;
