/* eslint-disable @typescript-eslint/no-shadow */
import { Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react';
import { Collapse } from '@mui/material';

import { useStore } from 'utils/hooks/store';

import Question from '../Question/Question';
import { AddQuestionButton, NonQuestionRow, getSectionContainerStyle, getItemContainerStyle } from './styles';

import QuestionsEditorHeader from '../QuestionEditorHeader/QuestionsEditorHeader';

const DroppableSections: React.FC<{ section: string; index: number }> = ({ section, index }): React.ReactElement => {
  const [expanded, setExpanded] = React.useState(true);
  const { templateDetails } = useStore();

  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <Draggable key={section} draggableId={section} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={section !== '0' ? getSectionContainerStyle(snapshot.isDragging, provided.draggableProps.style) : {}}
        >
          {section !== '0' ? (
            <QuestionsEditorHeader
              section={section}
              provided={provided}
              handleExpandClick={handleExpandClick}
              expanded={expanded}
            />
          ) : null}

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Droppable droppableId={`droppable${section}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {templateDetails.details?.content.properties[section].order.map((key, i) => (
                    <Draggable key={key} draggableId={key} index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemContainerStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <Question questionId={key} sectionId={section} key={`${section}_${key}`} />
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                  <NonQuestionRow>
                    <AddQuestionButton
                      onClick={() => templateDetails.details?.addQuestion(section)}
                      id="add-question-button"
                    >
                      + Add Question
                    </AddQuestionButton>
                  </NonQuestionRow>
                </div>
              )}
            </Droppable>
          </Collapse>
        </div>
      )}
    </Draggable>
  );
};

export default DroppableSections;
