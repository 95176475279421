import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import { reverse } from 'named-urls';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { EntityValueType, EntityDetailsType } from 'utils/api/types';
import { isNotFound } from 'utils/api/errors';

import { EntityModules } from 'core/constants';

import routes from 'core/routes';

import EntitiesActivities from 'stores/EntitiesActivities';

import RootStore from '../Root';
import EntityDetails from '../EntityDetails/EntityDetails';

export default class SuppliersDetailsStore extends EntityDetails {
  @observable error?: AxiosError;

  @observable details?: EntityDetailsType;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, EntityModules.Suppliers, routes.dashboard.suppliers.toString(), (uuid: string): string =>
      reverse(routes.dashboard.suppliers.details, { entityId: uuid })
    );

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(supplierId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEntitiesDetails(this.entityType, supplierId)();
      runInAction(() => {
        this.activities = new EntitiesActivities(this.store, this.api, supplierId, this.entityType);
        this.error = undefined;
        this.details = data;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load supplier details API response. Task id: ${supplierId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  create = async (supplierName: string): Promise<boolean> => {
    try {
      await this.api.createEntity(this.entityType, { [`${this.prefix}supplierName`]: supplierName })();
      return true;
    } catch (e) {
      Logger.error('Failed to create supplier', e);

      return false;
    }
  };

  delete = async (supplierId: string): Promise<boolean> => {
    try {
      await this.api.deleteEntity(this.entityType, supplierId)();
    } catch (e) {
      Logger.error('Failed to delete supplier', e);
      return false;
    }
    return true;
  };

  edit = async (data: Record<string, EntityValueType>): Promise<boolean> => {
    if (this.details?.uuid === undefined) {
      return false;
    }
    try {
      const edited = await this.api.editEntity(this.entityType, this.details?.uuid, data)();
      this.details = edited.data;
      this.store.notification.enqueueSuccessSnackbar('Supplier edited successfully');
      return true;
    } catch (e) {
      const error = e as AxiosError;
      this.store.notification.enqueueErrorSnackbar(
        error.response?.data[0] || `The supplier could not be updated. Something went wrong.`
      );
      Logger.error('Failed to edit supplier', e);
      return false;
    }
  };

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }

  @action.bound
  async loadCount(): Promise<number> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEntitiesCount(EntityModules.Suppliers)();
      runInAction(() => {
        this.error = undefined;
      });
      return data.count;
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      return 0;
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }
}
