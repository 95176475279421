import React from 'react';

import { GridFilterOperator } from '@mui/x-data-grid';
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';

import { escapeRegExp } from 'lodash';

import styled from 'styled-components';

import { MenuItem } from '@mui/material';

import VatixTheme from 'theme/vatix';
import { StyledSelect } from 'components/Input/styles';

import { PickerProps } from 'components/Grid/operators/types';

export const StyledMenuItem = styled(MenuItem)`
  color: ${VatixTheme.palette.grey[200]};
  font-size: 1.3rem;
  line-height: 2rem;
  p {
    margin: 0;
  }
`;

const ActiveFilterOperator: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <StyledSelect
    {...props}
    value={item.value}
    fullWidth
    onChange={(newValue) => applyValue({ ...item, value: newValue.target.value })}
  >
    <StyledMenuItem key="active" value="true">
      <p>Active</p>
    </StyledMenuItem>
    <StyledMenuItem key="inactive" value="false">
      <p>Inactive</p>
    </StyledMenuItem>
  </StyledSelect>
);

export default (): GridFilterOperator[] =>
  ([
    {
      label: 'is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: ActiveFilterOperator,
      InputComponentProps: { value: null },
      getValueAsString: (value: string) => value,
    },
  ] as unknown) as GridFilterOperator[];
