import React from 'react';

import Linkify from 'react-linkify';
import Grid from '@mui/material/Grid';

import { MonitoringMethod } from 'core/constants';
import { PunycodeComponentDecorator } from 'utils/formatters/punycode';

import { EscalationInstruction } from 'utils/api/types';

import DividerRow from 'containers/AlarmDetails/components/DividerRow';

import { AlarmResponseProfileProps } from './types';
import {
  AlarmResponseProfileContainer,
  AlarmResponseProfileDetails,
  AlarmResponseProfileField,
  AlarmResponseProfileHeader,
  AlarmResponseProfileOrder,
  AlarmResponseProfileTitle,
  AlarmResponseProfileValue,
  Empty,
  SectionContainer,
} from './styles';

const AlarmResponseProfile: React.FunctionComponent<AlarmResponseProfileProps> = ({
  alarmResponseProfile,
}): React.ReactElement => (
  <SectionContainer>
    <DividerRow name="Alarm Response Profile" />
    {alarmResponseProfile?.escalationType === MonitoringMethod.SelfMonitored && (
      <>
        {alarmResponseProfile.actions.length === 0 && <Empty>Alarm response profile has no actions.</Empty>}
        {alarmResponseProfile.actions.map((instruction, order) => (
          <AlarmResponseProfileContainer key={instruction.uuid} first={order === 0}>
            <AlarmResponseProfileOrder>{order + 1}</AlarmResponseProfileOrder>
            <AlarmResponseProfileDetails>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <AlarmResponseProfileField>
                    <AlarmResponseProfileTitle>
                      <AlarmResponseProfileHeader>Contact name</AlarmResponseProfileHeader>
                      <AlarmResponseProfileValue>{instruction.contactName}</AlarmResponseProfileValue>
                    </AlarmResponseProfileTitle>
                  </AlarmResponseProfileField>
                </Grid>
                <Grid item xs={5}>
                  <AlarmResponseProfileField data-test-id="phone-number">
                    <AlarmResponseProfileHeader>Phone number</AlarmResponseProfileHeader>
                    <AlarmResponseProfileValue>{instruction.phoneNumber}</AlarmResponseProfileValue>
                  </AlarmResponseProfileField>
                </Grid>
              </Grid>
            </AlarmResponseProfileDetails>
          </AlarmResponseProfileContainer>
        ))}
      </>
    )}
    {alarmResponseProfile?.escalationType === MonitoringMethod.ArcMonitored && (
      <>
        {alarmResponseProfile.escalationInstructions.length === 0 && (
          <Empty>Alarm response profile has no escalatation instructions.</Empty>
        )}
        {alarmResponseProfile.escalationInstructions.map((instruction, order) => (
          <AlarmResponseProfileContainer key={instruction.uuid} first={order === 0}>
            <AlarmResponseProfileOrder>{order + 1}</AlarmResponseProfileOrder>

            <AlarmResponseProfileDetails>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <AlarmResponseProfileField>
                    <AlarmResponseProfileTitle>
                      <AlarmResponseProfileHeader>Contact name</AlarmResponseProfileHeader>
                      <AlarmResponseProfileValue>{instruction.contactName}</AlarmResponseProfileValue>
                    </AlarmResponseProfileTitle>
                  </AlarmResponseProfileField>
                </Grid>
                <Grid item xs={5}>
                  <AlarmResponseProfileField>
                    <AlarmResponseProfileHeader>Instructions</AlarmResponseProfileHeader>
                    <AlarmResponseProfileValue>
                      <Linkify componentDecorator={PunycodeComponentDecorator}>
                        {(instruction as EscalationInstruction).description}
                      </Linkify>
                    </AlarmResponseProfileValue>
                  </AlarmResponseProfileField>
                </Grid>
                <Grid item xs={5}>
                  <AlarmResponseProfileField data-test-id="phone-number">
                    <AlarmResponseProfileHeader>Phone number</AlarmResponseProfileHeader>
                    <AlarmResponseProfileValue>{instruction.phoneNumber}</AlarmResponseProfileValue>
                  </AlarmResponseProfileField>
                </Grid>
              </Grid>
            </AlarmResponseProfileDetails>
          </AlarmResponseProfileContainer>
        ))}
      </>
    )}
  </SectionContainer>
);

export default AlarmResponseProfile;
