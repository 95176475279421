import React from 'react';

import { Popover } from '@mui/material';

import Chip from 'components/Chip';

import { Container, Item, PopoverContent, EmptyText, PlusChipContainer } from './styles';
import { ListProps, ValueType } from './types';

const GridListRenderer: React.FunctionComponent<ListProps> = ({
  maxItems,
  options,
  renderer,
  rendererItem,
  value,
  noMargins,
  disableFormatting,
}) => {
  const [maxItemsRendered, setMaxItemsRendered] = React.useState(maxItems);
  const popoverAnchor = React.useRef(null);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [openedPopover, setOpenedPopover] = React.useState(false);

  const popoverEnter = (): void => {
    setOpenedPopover(true);
  };

  const popoverLeave = (): void => {
    setOpenedPopover(false);
  };

  const defaultRenderer = (items: unknown[]): React.ReactNode =>
    items.map((v) => <React.Fragment key={JSON.stringify(v)}>{rendererItem({ value: { value: v } })}</React.Fragment>);

  React.useEffect(() => {
    const handleResize = (): void => {
      if (containerRef.current && containerRef.current.offsetWidth <= 300) {
        setMaxItemsRendered(1);
      } else if (containerRef.current && containerRef.current.offsetWidth <= 600) {
        setMaxItemsRendered(2);
      } else {
        setMaxItemsRendered(maxItems);
      }
    };

    if (disableFormatting !== true) {
      window.addEventListener('resize', handleResize);
      handleResize();
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (value === undefined || value.length === 0) {
    return (
      <Container>
        <EmptyText>{options?.customEmptyText || 'N/A'}</EmptyText>
      </Container>
    );
  }

  return (
    <Container
      $noMargins={noMargins}
      ref={containerRef}
      $setMaxWidth={maxItemsRendered !== maxItems ? maxItemsRendered * 100 - 20 : undefined}
    >
      {value.length === 0 && options?.empty}
      {(renderer || defaultRenderer)(value.slice(0, maxItemsRendered))}
      {value.length > maxItemsRendered && (
        <>
          <PlusChipContainer ref={popoverAnchor}>
            <Chip
              label={`+${value.length - maxItemsRendered}`}
              colors={{
                background: 'rgba(0, 0, 0, 0.08)',
                foreground: 'rgba(0, 0, 0, 0.87)',
                hoverBackground: 'rgba(0, 0, 0, 0.12)',
              }}
              variant="filled"
              size="small"
              onMouseEnter={popoverEnter}
              onMouseLeave={popoverLeave}
            />
          </PlusChipContainer>
          <Popover
            sx={{
              pointerEvents: 'none',
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            slotProps={{
              paper: {
                onMouseEnter: popoverEnter,
                onMouseLeave: popoverLeave,
                sx: {
                  pointerEvents: 'auto',
                },
              },
            }}
          >
            <PopoverContent>
              {value.slice(maxItemsRendered).map((v) => (
                <Item key={JSON.stringify(v)}>{rendererItem({ value: { value: v } })}</Item>
              ))}
            </PopoverContent>
          </Popover>
        </>
      )}
    </Container>
  );
};

export const listRenderer = (
  rendererItem: (props: ValueType<{ value: unknown }>) => React.ReactNode,
  maxItems: number,
  renderer?: (items: unknown[]) => React.ReactNode,
  options?: {
    empty: React.ReactNode;
    customEmptyText?: string;
  }
) => ({ value }: { value: unknown[] }) => (
  <GridListRenderer
    maxItems={maxItems}
    options={options}
    renderer={renderer}
    rendererItem={rendererItem}
    value={value}
  />
);

export default GridListRenderer;
