import React from 'react';

import CreateIcon from '@mui/icons-material/Create';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { MultipleContainer, AddNewButton, ChipContainer, StyledIconButton } from './styles';
import EditMultipleAnswers from './components/EditMultipleAnswers/EditMultipleAnswers';
import { MultipleAnswersProps } from './types';
import Chips from '../Chips';

const MultipleAnswers: React.FunctionComponent<MultipleAnswersProps> = ({
  options,
  setValue,
  setOptions,
  allowMulti,
  setAllowMulti,
  scoring,
  setScoring,
}): React.ReactElement => {
  const [edit, setEdit] = React.useState(false);
  const [editedScore, setEditedScore] = React.useState<string[] | null>(null);
  const [choice, setChoice] = React.useState<string[]>([]);
  const {
    templateDetails: { details },
  } = useStore();

  const optionsToolbox = (details && details.formOptionsObject) || [];

  const deleteOptionFromOptions = (option: string[]): void => {
    if (details?.deleteOption(option)) {
      setEdit(false);
    }
  };

  const addOptions = (opt: string[]): void => {
    if (editedScore) {
      details?.addNewOption(opt, editedScore);
    } else if (details) {
      details?.editOption(opt, choice, editedScore);
    }
    setValue('multi');
    setOptions(opt);
    setScoring(editedScore);
  };

  const isCurrentlySelectedOption = (optionsItems: string[], scoringItems: string[] | null): boolean =>
    options.every((o, i) => o === optionsItems[i]) &&
    !scoring === !scoringItems &&
    (!scoring || scoring.every((o, i) => scoringItems && o === scoringItems[i])) &&
    options.length === optionsItems.length &&
    (scoring || []).length === (scoringItems || []).length;

  return edit ? (
    <EditMultipleAnswers
      options={choice}
      setOptions={setOptions}
      allowMulti={allowMulti}
      setAllowMulti={setAllowMulti}
      deleteOptionFromOptions={deleteOptionFromOptions}
      addOptions={addOptions}
      scoring={editedScore}
      setScoring={setEditedScore}
    />
  ) : (
    <MultipleContainer>
      {optionsToolbox.map((toolboxOptions, index) => (
        <ChipContainer
          key={`${toolboxOptions.options[0]}_${index}`}
          onClick={() => {
            setValue('multi');
            setOptions(toolboxOptions.options);
            setScoring(toolboxOptions.scoring);
          }}
          $selected={isCurrentlySelectedOption(toolboxOptions.options, toolboxOptions.scoring)}
        >
          <Chips enumItems={toolboxOptions.options} scoring={toolboxOptions.scoring} />
          <StyledIconButton
            onClick={(e) => {
              e.stopPropagation();
              setEdit(true);
              setChoice(toolboxOptions.options);
              setEditedScore(toolboxOptions.scoring);
            }}
          >
            <CreateIcon color="primary" />
          </StyledIconButton>
        </ChipContainer>
      ))}

      <AddNewButton
        onClick={() => {
          setEdit(true);
          setChoice([]);
          setEditedScore(null);
        }}
      >
        + Add New
      </AddNewButton>
    </MultipleContainer>
  );
};

export default observer(MultipleAnswers);
