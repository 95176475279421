import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { InformationContainer, DownloadInfoText } from './styles';
import DownloadErrors from '../DownloadErrors';

type ErrorAfterUploadProps = {
  uploadSuccess: number;
  uploadErrors: number;
};

const ErrorAfterUpload: React.FC<ErrorAfterUploadProps> = ({ uploadSuccess, uploadErrors }) => (
  <>
    {uploadSuccess > 0 ? (
      <InformationContainer $error={false}>
        <CheckCircleOutlineIcon style={{ color: 'rgba(46, 125, 50)' }} />
        <p>{uploadSuccess} users were successfully imported</p>
      </InformationContainer>
    ) : null}
    <InformationContainer $error>
      <ErrorOutlineIcon style={{ color: 'rgba(211, 47, 47)' }} />
      <p>{uploadErrors} users failed to import</p>
    </InformationContainer>
    <DownloadInfoText>
      Please download the error log for details on the users that failed to import. Correct the file and try uploading
      it again.
    </DownloadInfoText>

    <DownloadErrors />
  </>
);

export default ErrorAfterUpload;
