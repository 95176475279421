import React from 'react';

import { RedAlertStatus } from 'core/constants';
import Chip from 'components/Chip';
import { VatixChipProps } from 'components/Chip/types';

import { AlarmChipProps } from './types';

const StatusChipPropsMapping: Record<string, VatixChipProps> = {
  [RedAlertStatus.Pending]: {
    id: 'alarm-status-started',
    colors: {
      background: '#ed6c024d',
      foreground: '#000000de',
    },
    label: 'Started',
  },
  [RedAlertStatus.Accepted]: {
    id: 'alarm-status-in-progress',
    colors: {
      background: '#e0e0e0',
      foreground: '#000000de',
    },
    label: 'In progress',
  },
  [RedAlertStatus.Completed]: {
    id: 'alarm-status-completed',
    colors: {
      background: '#c0d8c1',
      foreground: '#000000de',
    },
    label: 'Completed',
  },
} as const;

const AlarmStatusChip: React.FunctionComponent<AlarmChipProps> = ({ status, ...props }): React.ReactElement => (
  <Chip size="small" {...StatusChipPropsMapping[status]} {...props} id={`alarm-status-${status}`} />
);

export default AlarmStatusChip;
