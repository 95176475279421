import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';

import { useRouteMatch } from 'react-router-dom';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { useStore } from 'utils/hooks/store';

import { isNotFound } from 'utils/api/errors';

import FormDetailsHeader from './components/FormDetailsHeader';
import FormDetailsContent from './components/FormDetailsContent';

const FormsDetails: React.FC = (): JSX.Element => {
  const { formDetails } = useStore();
  const match = useRouteMatch();
  const navBarHeight = useNavBarHeight();
  return (
    <DetailsLoader
      avoidReset
      match={match}
      // @ts-ignore
      routeParam="formId"
      store={formDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }

        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        return (
          <Record
            navBarHeight={navBarHeight}
            disableMaxContentHeight
            Content={() => <FormDetailsContent />}
            Header={<FormDetailsHeader />}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default FormsDetails;
