import Pusher from 'pusher-js';
import axios, { AxiosResponse } from 'axios';

import rootStore from 'stores';
import Logger from 'utils/logger';
import { isUnauthorized } from 'utils/api/errors';

export function initLogging(): void {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  Pusher.log = (msg) => {
    Logger.info(msg);
  };
}

export function initAxiosInterceptors(): void {
  axios.interceptors.response.use(
    (response: AxiosResponse<unknown>) => response,
    (error) => {
      if (isUnauthorized(error) && rootStore.session.user) {
        Logger.info(
          'User is no longer authenticated. Automatically logging out.'
        );
        rootStore.session.logout();
      }
      return Promise.reject(error);
    }
  );
}
