/* eslint-disable max-len */
import React from 'react';

export function MyActionsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73865)">
        <path
          d="M17.0101 6.30006L16.3801 6.90006C16.3801 6.90006 16.2601 7.14006 16.2901 7.26006C16.4101 7.80006 16.5001 8.34006 16.5001 8.91006C16.5601 12.3901 14.3401 15.4201 10.9501 16.2901C5.58006 17.6401 0.81006 13.2001 1.59006 7.92006C1.95006 5.43006 3.57006 3.27006 5.85006 2.22006C8.13006 1.17006 10.6201 1.38006 12.5101 2.40006C12.6601 2.49006 12.8101 2.46006 12.9301 2.34006L13.5001 1.80006C13.6801 1.62006 13.6501 1.32006 13.4401 1.20006C11.0401 -0.14994 8.01006 -0.50994 5.04006 0.93006C2.07006 2.37006 0.24006 5.22006 6.01169e-05 8.40006C-0.35994 13.6501 3.84006 18.0001 8.97006 18.0001C14.1001 18.0001 17.9701 13.9801 17.9701 9.00006C17.9701 8.13006 17.8201 7.26006 17.5801 6.45006C17.4901 6.18006 17.1601 6.12006 16.9801 6.30006H17.0101Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.7597 2.7L16.4397 3.93L15.3297 4.98L9.5097 10.41C9.5097 10.41 9.1797 10.62 8.9997 10.62C8.8197 10.62 8.6397 10.56 8.4897 10.41L5.6097 7.65C5.3097 7.38 5.2797 6.9 5.5797 6.60001C5.7297 6.45001 5.9097 6.39001 6.1197 6.39001C6.2997 6.39001 6.5097 6.45001 6.6297 6.57L8.4897 8.34C8.4897 8.34 8.8197 8.55 8.9997 8.55C9.1797 8.55 9.3897 8.49 9.5097 8.34L14.3997 3.78L15.4797 2.76L16.7397 1.59C16.7397 1.59 17.0697 1.38 17.2497 1.38C17.4597 1.38 17.6397 1.47 17.7897 1.62C18.0897 1.92 18.0597 2.4 17.7597 2.7Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73865">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveMyActionsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73515)">
        <path
          d="M18.0009 8.99996C18.0009 13.98 13.9509 18 9.00086 18C3.66086 18 -0.629141 13.32 0.0908592 7.79996C0.450859 5.00996 2.13086 2.54996 4.59086 1.16996C7.62086 -0.540036 11.0109 -0.240036 13.5609 1.22996C13.9809 1.46996 14.0709 2.09996 13.6809 2.42996L9.57086 6.29996C9.27086 6.56996 8.82086 6.56996 8.55086 6.29996L7.71086 5.48996C7.26086 5.09996 6.72086 4.88996 6.15086 4.88996C5.55086 4.88996 4.95086 5.12996 4.53086 5.57996C4.11086 5.99996 3.90086 6.56996 3.90086 7.16996C3.93086 7.76996 4.17086 8.33996 4.59086 8.75996L7.47086 11.52C7.89086 11.91 8.46086 12.12 9.03086 12.12C9.60086 12.12 10.1409 11.91 10.5609 11.52L16.4109 6.05996C16.8009 5.69996 17.4909 5.84996 17.6409 6.35996C17.9109 7.19996 18.0309 8.09996 18.0309 8.99996H18.0009Z"
          fill="white"
        />
        <path
          d="M17.7597 2.70003L16.4397 3.93003L9.5097 10.44C9.5097 10.44 9.1797 10.62 8.9997 10.62C8.8197 10.62 8.6397 10.56 8.4897 10.41L5.6097 7.68003C5.3097 7.38003 5.2797 6.90003 5.5797 6.60003C5.7297 6.45003 5.9097 6.39003 6.1197 6.39003C6.2997 6.39003 6.5097 6.45003 6.6297 6.60003L8.4897 8.37003C8.4897 8.37003 8.8197 8.55003 8.9997 8.55003C9.1797 8.55003 9.3897 8.49003 9.5097 8.37003L15.4797 2.76003L16.7397 1.59003C16.7397 1.59003 17.0697 1.41003 17.2497 1.41003C17.4597 1.41003 17.6397 1.47003 17.7897 1.65003C18.0897 1.95003 18.0597 2.40003 17.7597 2.70003Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73515">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
