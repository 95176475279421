import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import React from 'react';

import { formatDateTime, formatDuration } from 'vatix-ui/lib/utils/formatters/time';

import AlarmStatusChip from 'components/Chips/AlarmStatusChip';
import { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import {
  HomeSectionHeader,
  HomeSectionHeaderButton,
  HomeSectionHeaderContainer,
  ViewAllButtonLink,
} from 'containers/Home/styles';
import { StyledCreated } from 'containers/Incidents/components/IncidentList/styles';
import { RedAlertStatus } from 'core/constants';
import routes from 'core/routes';
import NoRowsOverlay from 'components/Grid/components/NoRowsOverlay';

import { AlarmResponse, HomeResponse, JSONSchemaValue } from 'utils/api/types';
import { useStore } from 'utils/hooks/store';

import { CardButton, CardButtonsContainer, CardButtonText, DataGridContainer, StyledDataGrid } from './styles';
import { AlarmsIcon, TimedAlertsIcon, DevicesIcon, AlarmResponseProfilesIcon } from './Icons';

const columns = ([
  {
    field: 'subject',
    headerName: 'Alarm name',
    flex: 1,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    valueGetter: ({ value }: GridValueGetterParams) => formatDuration(value),
    maxWidth: 80,
  },
  {
    field: 'user',
    headerName: 'Started by',
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    flex: 1,
  },
  {
    field: 'responder',
    headerName: 'Responder',
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    flex: 1,
  },
  {
    field: 'alertStatus',
    headerName: 'Status',
    renderCell: ({ value }: { value: RedAlertStatus }) => <AlarmStatusChip status={value} size="medium" />,
  },
  {
    field: 'activated',
    filterOperators: defaultOperators.date_time(),
    headerName: 'Started',
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    renderCell: ({ value }: { value: JSONSchemaValue<string> }) => (
      <StyledCreated>{formatDateTime(value?.value)}</StyledCreated>
    ),
    valueFormatter: defaultFormatters.date,
    flex: 1,
  },
] as unknown) as GridColDef[];

const ManagerAlarmsPane: React.FunctionComponent = () => {
  const { home, routing } = useStore();

  const content = home.content as HomeResponse;

  return (
    <div aria-label="manager-role-alarms-pane">
      <HomeSectionHeaderContainer>
        <HomeSectionHeader>Lone Working</HomeSectionHeader>
      </HomeSectionHeaderContainer>
      <CardButtonsContainer>
        <CardButton
          onClick={() => {
            routing.push(reverse(routes.dashboard.alarms.red.toString()));
          }}
        >
          <AlarmsIcon />
          <CardButtonText>Alarms</CardButtonText>
        </CardButton>
        <CardButton
          onClick={() => {
            routing.push(reverse(routes.dashboard.alarms.amber.toString()));
          }}
        >
          <TimedAlertsIcon />
          <CardButtonText>Timed alerts</CardButtonText>
        </CardButton>
        <CardButton
          onClick={() => {
            routing.push(reverse(routes.dashboard.alarms.devices.toString()));
          }}
        >
          <DevicesIcon />
          <CardButtonText>Devices</CardButtonText>
        </CardButton>
        <CardButton
          onClick={() => {
            routing.push(reverse(routes.dashboard.alarms.toString()));
          }}
        >
          <AlarmResponseProfilesIcon />
          <CardButtonText>Alarm response profiles</CardButtonText>
        </CardButton>
      </CardButtonsContainer>
      <>
        <HomeSectionHeaderContainer>
          <HomeSectionHeader>Recent Alarms</HomeSectionHeader>
          {content.recentAlarms !== undefined && content.recentAlarms.length > 0 ? (
            <ViewAllButtonLink href={reverse(routes.dashboard.alarms.red.toString())}>
              <HomeSectionHeaderButton>View all</HomeSectionHeaderButton>
            </ViewAllButtonLink>
          ) : null}
        </HomeSectionHeaderContainer>
        {content.recentAlarms !== undefined ? (
          <DataGridContainer>
            <StyledDataGrid
              columns={columns}
              rows={content.recentAlarms || []}
              getRowId={(row: AlarmResponse) => row.uuid}
              hideFooter
              disableColumnMenu
              onRowClick={({ row }: { row: AlarmResponse }) => {
                routing.push(reverse(routes.dashboard.alarms.details.toString(), { alarmId: row.uuid }));
              }}
              slots={{
                noRowsOverlay: () => <NoRowsOverlay entity="alarm" />,
              }}
            />
          </DataGridContainer>
        ) : null}
      </>
    </div>
  );
};

export default ManagerAlarmsPane;
