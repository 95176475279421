import React from 'react';

const AddFilled = (): React.ReactElement => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="AddFilled">
      <path
        id="Vector"
        // eslint-disable-next-line max-len
        d="M15.8332 10.8333H10.8332V15.8333H9.1665V10.8333H4.1665V9.16663H9.1665V4.16663H10.8332V9.16663H15.8332V10.8333Z"
        fill="#9E9E9E"
      />
    </g>
  </svg>
);

export default AddFilled;
