/* eslint-disable max-len */
import React from 'react';

export function SuppliersIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.5 16.86C11.82 17.04 12.18 17.04 12.5 16.86L19 13.12C19.32 12.94 19.5 12.6 19.5 12.24V4.74C19.5 4.38 19.32 4.06 19 3.88L12.5 0.12C12.18 -0.04 11.82 -0.04 11.5 0.12L8.82 1.68L6.82 2.82L5 3.88C4.68 4.06 4.5 4.38 4.5 4.74V12.24C4.5 12.6 4.68 12.94 5 13.12L11.5 16.86ZM16.24 6.08V7.2L16.74 6.9C17.08 6.7 17.5 6.94 17.5 7.34V11.38C17.5 11.56 17.4 11.72 17.24 11.8L13.76 13.82C13.42 14.02 13 13.78 13 13.4V9.36C13 9.2 13.1 9.02 13.24 8.94L14.24 8.36V10.58C14.24 10.78 14.44 10.9 14.62 10.8L16.12 9.94C16.12 9.94 16.24 9.8 16.24 9.72V6.08C16.24 6.08 16.18 5.92 16.12 5.88C16.12 5.88 16.24 6 16.24 6.08ZM10.82 2.82L11.76 2.28C11.9 2.2 12.1 2.2 12.24 2.28L15.76 4.32C16.08 4.5 16.08 4.98 15.76 5.18L15.34 5.42L10.82 2.82ZM8.26 4.32L8.84 3.98L13.32 6.58L12.24 7.2C12.1 7.28 11.9 7.28 11.76 7.2L8.26 5.18C7.92 4.98 7.92 4.5 8.26 4.32ZM6.5 7.34C6.5 6.94 6.92 6.7 7.24 6.9L10.76 8.94C10.9 9.02 11 9.2 11 9.36V13.4C11 13.78 10.58 14.02 10.24 13.82L6.76 11.8C6.6 11.72 6.5 11.56 6.5 11.38V7.34Z"
        fill="#C0C7D3"
      />
      <path
        d="M21 18C19.7 18 18.58 18.84 18.18 20H14.28C13.9 19.12 13.02 18.5 12 18.5C10.98 18.5 10.1 19.12 9.72 20H5.82C5.42 18.84 4.3 18 3 18C1.34 18 0 19.34 0 21C0 22.66 1.34 24 3 24C4.3 24 5.42 23.16 5.82 22H9.72C10.1 22.88 10.98 23.5 12 23.5C13.02 23.5 13.9 22.88 14.28 22H18.18C18.58 23.16 19.7 24 21 24C22.66 24 24 22.66 24 21C24 19.34 22.66 18 21 18ZM3 22C2.44 22 2 21.56 2 21C2 20.44 2.44 20 3 20C3.56 20 4 20.44 4 21C4 21.56 3.56 22 3 22ZM12 21.5C11.72 21.5 11.5 21.28 11.5 21C11.5 20.72 11.72 20.5 12 20.5C12.28 20.5 12.5 20.72 12.5 21C12.5 21.28 12.28 21.5 12 21.5ZM21 22C20.44 22 20 21.56 20 21C20 20.44 20.44 20 21 20C21.56 20 22 20.44 22 21C22 21.56 21.56 22 21 22Z"
        fill="#C0C7D3"
      />
    </svg>
  );
}

export function ActiveSuppliersIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_15882_161436)">
        <path
          d="M19.5 4.74V12.24C19.5 12.6 19.32 12.94 19 13.12L12.5 16.86C12.18 17.04 11.82 17.04 11.5 16.86L5 13.12C4.68 12.94 4.5 12.6 4.5 12.24V4.74C4.5 4.38 4.68 4.06 5 3.88L6.56 2.96C6.72 2.86 6.9 2.86 7.06 2.96L13.3 6.58L14.1 7.04C14.1 7.04 14.22 7.16 14.22 7.24V10.58C14.22 10.78 14.42 10.9 14.6 10.8L16.1 9.94C16.1 9.94 16.22 9.8 16.22 9.72V6.08C16.22 6.08 16.16 5.92 16.1 5.88L15.32 5.42L9.2 1.9C9.04 1.8 9.04 1.56 9.2 1.46L11.5 0.12C11.82 -0.04 12.18 -0.04 12.5 0.12L19 3.88C19.32 4.06 19.5 4.38 19.5 4.74Z"
          fill="white"
        />
        <path
          d="M24 21C24 22.66 22.66 24 21 24C19.7 24 18.58 23.16 18.18 22H14.28C13.9 22.88 13.02 23.5 12 23.5C10.98 23.5 10.1 22.88 9.72 22H5.82C5.42 23.16 4.3 24 3 24C1.34 24 0 22.66 0 21C0 19.34 1.34 18 3 18C4.3 18 5.42 18.84 5.82 20H9.72C10.1 19.12 10.98 18.5 12 18.5C13.02 18.5 13.9 19.12 14.28 20H18.18C18.58 18.84 19.7 18 21 18C22.66 18 24 19.34 24 21Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_15882_161436">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
