import NotificationSettingsStore from 'stores/NotificationSettings/NotificationSettings';
import { SessionStoreWithUser } from 'stores/Session/types';
import { UserRole } from 'core/constants';

export interface InjectedRoutingProps {
  session: SessionStoreWithUser;
  isDetailsView: boolean;
  notificationSettings: NotificationSettingsStore;
}

export const NON_ARC_OPERATORS = [UserRole.Admin, UserRole.Manager, UserRole.AccountManager, UserRole.User];

export const NON_USER = [UserRole.Admin, UserRole.Manager, UserRole.AccountManager, UserRole.ArcOperator];

export const ADMIN_AND_MANAGERS = [UserRole.Admin, UserRole.Manager, UserRole.AccountManager];

export const MANAGERS = [UserRole.AccountManager, UserRole.AccountManager];
