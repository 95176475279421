import React from 'react';

import { observer } from 'mobx-react';

import Record from 'vatix-ui/lib/containers/Record/Record';
import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { useNavBarHeight } from 'utils/hooks/navbar';
import LicensesStatus from 'containers/Users/components/LicensesStatus';
import routes from 'core/routes';

import ManageList from './components/ManageList';

const Manage = (): React.ReactElement => {
  const refreshRef = React.useRef<Function | null>(null);
  const navBarHeight = useNavBarHeight();

  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={(onError) => <ManageList onError={onError} onRefreshCallback={onRefreshCallback} />}
      Header={
        <>
          <MainHeader
            title="Manage"
            breadcrumbs={[
              { label: 'Lone Working', link: routes.dashboard.alarms.red.toString() },
              { label: 'Manage', link: routes.dashboard.alarms.manage.toString() },
            ]}
          />
          <LicensesStatus />
        </>
      }
    />
  );
};

export default observer(Manage);
