import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { observer } from 'mobx-react';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';
import { UserLicensesResponse } from 'utils/api/types';

import { HomeHeader } from './styles';
import TasksPane from './panes/TasksPane';
import SolutionsPane from './panes/SolutionsPane';
import AlarmsPane from './panes/AlarmsPane';

enum AccessiblePanes {
  MyTasks,
  Alarms,
}

const Home: React.FunctionComponent = () => {
  const navBarHeight = useNavBarHeight();
  const {
    session: { user },
  } = useStore();

  const getAccessiblePanes = (licenses?: UserLicensesResponse): AccessiblePanes[] => {
    const accessiblePanes = [];
    if (licenses) {
      if (licenses.workflows || licenses.protectorIncidents) {
        accessiblePanes.push(AccessiblePanes.MyTasks);
      }

      if (licenses.protectorAlarms) {
        accessiblePanes.push(AccessiblePanes.Alarms);
      }
    }
    return accessiblePanes;
  };

  const accessiblePanes = getAccessiblePanes(user?.licenses);

  const HomeContent: React.FunctionComponent = () => {
    const { home } = useStore();

    React.useEffect(() => {
      if (home.content === undefined && !home.hasError) {
        home.loadHomeContent();
      }
    }, [home]);

    return (
      <>
        {accessiblePanes.includes(AccessiblePanes.MyTasks) ? <TasksPane aria-label="tasks pane" /> : null}
        {accessiblePanes.includes(AccessiblePanes.Alarms) ? <AlarmsPane aria-label="alarms pane" /> : null}
        <SolutionsPane aria-label="solutions pane" />
      </>
    );
  };

  return (
    <Record
      Header={<HomeHeader title={`Welcome back, ${user?.name || user?.email}!`} breadcrumbs={[]} />}
      Content={() => <HomeContent />}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(Home);
