import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
`;

export const TabsContainer = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  overflow-y: auto;
`;
