import React from 'react';

import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';

import { PickerProps } from '../types';

export const DatePicker: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <CustomDatePicker
    description=""
    {...props}
    onChange={(newValue) => {
      if (newValue !== 'Invalid date') {
        applyValue({ ...item, value: newValue });
      }
    }}
    value={item?.value as string}
  />
);
