import React from 'react';

import { TaskStatus } from 'core/constants';
import TaskStatusChip from 'containers/TaskDetails/components/TaskStatusChip';

import { TaskStatusSelectProps } from './types';
import { StyledMenuItem, StyledSelect } from '../styles';

const TaskStatusSelect: React.FunctionComponent<TaskStatusSelectProps> = ({ onChange, ...props }) => (
  <StyledSelect {...props} fullWidth onChange={(event) => onChange(event.target.value as TaskStatus)}>
    {Object.values(TaskStatus).map((status) => (
      <StyledMenuItem key={status} value={status}>
        <TaskStatusChip status={status} size="small" />
      </StyledMenuItem>
    ))}
  </StyledSelect>
);

export default TaskStatusSelect;
