/* eslint-disable max-len */
import React from 'react';

export function UsersIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
      <g clipPath="url(#clip0_14025_73676)">
        <path
          d="M10.71 9.06C10.47 8.94 10.41 8.64 10.62 8.46C11.55 7.62 12.12 6.42 12.18 5.1C12.27 2.4 10.08 0.03 7.35 0C4.65 0 2.43 2.19 2.43 4.89C2.43 6.33 3.06 7.62 4.05 8.49C4.23 8.67 4.2 8.97 3.99 9.09C1.59 10.38 0 12.96 0 15.84V16.5C0 17.34 0.69 18 1.5 18H13.14C13.95 18 14.64 17.34 14.64 16.5V15.57C14.64 12.75 13.05 10.32 10.71 9.06ZM3.93 4.89C3.93 3.03 5.46 1.5 7.32 1.5C9.18 1.5 10.68 3.03 10.68 4.89C10.68 6.75 9.18 8.25 7.32 8.25C5.46 8.25 3.93 6.75 3.93 4.89ZM13.14 15.75C13.14 16.17 12.78 16.5 12.39 16.5H2.25C1.86 16.5 1.5 16.17 1.5 15.75V15.57C1.5 12.3 4.23 9.63 7.56 9.78C10.68 9.9 13.14 12.57 13.14 15.72V15.75Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73676">
          <rect width="14.64" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveUsersIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
      <g clipPath="url(#clip0_14025_73664)">
        <path
          d="M2.54969 5.88C2.45969 5.55 2.42969 5.22 2.42969 4.89C2.42969 3.54 2.96969 2.34 3.86969 1.44C4.73969 0.54 5.96969 0 7.31969 0H7.43969C8.06969 0 8.66969 0.18 9.23969 0.42C10.0797 0.78 10.7997 1.41 11.3397 2.16C11.3397 2.16 11.3397 2.19 11.3397 2.22C11.4897 2.49 11.6397 2.76 11.7597 3.06C11.8797 3.36 11.9997 3.66 12.0597 3.99C12.1197 4.29 12.1497 4.59 12.1497 4.89V5.1C12.1497 5.1 12.1497 5.31 12.0897 5.4C12.0897 5.64 12.0897 5.85 11.9997 6.06C11.9397 6.3 11.8797 6.57 11.7597 6.78C11.6397 7.08 11.5197 7.35 11.3097 7.59V7.62C11.2197 7.74 11.1297 7.89 11.0097 8.01C10.8897 8.19 10.7397 8.31 10.5897 8.46C9.71969 9.27 8.57969 9.75 7.28969 9.75C5.99969 9.75 4.88969 9.27 4.01969 8.49C4.01969 8.49 4.01969 8.49 4.01969 8.46C3.86969 8.34 3.71969 8.19 3.59969 8.01C3.47969 7.89 3.38969 7.74 3.29969 7.62V7.59C3.11969 7.35 2.99969 7.08 2.87969 6.78C2.75969 6.51 2.63969 6.18 2.57969 5.88H2.54969Z"
          fill="white"
        />
        <path
          d="M14.64 15.57V16.5C14.64 17.34 13.95 18 13.14 18H1.5C0.69 18 0 17.34 0 16.5V15.84C0 13.53 0.99 11.43 2.61 10.02C2.88 9.77998 3.27 9.80998 3.54 10.02C4.59 10.77 5.91 11.25 7.29 11.25C8.67 11.25 10.02 10.77 11.1 9.98998C11.37 9.77998 11.76 9.74998 12.03 9.98998C13.62 11.31 14.61 13.32 14.61 15.57H14.64Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73664">
          <rect width="14.64" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
