/* eslint-disable max-len */
import * as React from 'react';

export function SuccessToast(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
        fill="#2E7D32"
      />
    </svg>
  );
}

export function CloseSuccessToast(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M15.8327 5.34163L14.6577 4.16663L9.99935 8.82496L5.34102 4.16663L4.16602 5.34163L8.82435 9.99996L4.16602 14.6583L5.34102 15.8333L9.99935 11.175L14.6577 15.8333L15.8327 14.6583L11.1743 9.99996L15.8327 5.34163Z"
        fill="#2E7D32"
      />
      <path
        d="M15.8327 5.34163L14.6577 4.16663L9.99935 8.82496L5.34102 4.16663L4.16602 5.34163L8.82435 9.99996L4.16602 14.6583L5.34102 15.8333L9.99935 11.175L14.6577 15.8333L15.8327 14.6583L11.1743 9.99996L15.8327 5.34163Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
}
