import { useFormikContext } from 'formik';

import React from 'react';

import { StyledTextField, useStyles } from './styles';
import { TwoFactorAuthSetupFormValues } from '../../types';

const DigitField: React.FunctionComponent<{
  index: number;
  onValueEntered: (index: number) => void;
  onBackSpacePressed: (index: number) => void;
  confirmationCodeSubmitted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.Ref<any> | undefined;
}> = ({ index, onValueEntered, onBackSpacePressed, confirmationCodeSubmitted, inputRef }) => {
  const classes = useStyles();
  const { setFieldValue, values: contextFormValues, errors } = useFormikContext<TwoFactorAuthSetupFormValues>();
  const handleVerificationCodeChange = (value: string, digitIndex: number): void => {
    let newCode = contextFormValues.verificationCode;

    const digit = value.replace(/\D/g, '');
    newCode = newCode.substring(0, index) + digit + newCode.substring(index + 1);
    setFieldValue('verificationCode', newCode);

    if (digit && index < 5) {
      onValueEntered(digitIndex);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, digitIndex: number): void => {
    if (event.key === 'Backspace') {
      if (index > 0 && !contextFormValues.verificationCode[index]) {
        onBackSpacePressed(digitIndex);
      }
    }
  };

  return (
    <StyledTextField
      data-testid={`digit-${index}`}
      key={`confirm_verification_code_digit_${index}`}
      type="number"
      variant="outlined"
      size="small"
      className={classes.digitInput}
      autoComplete="off"
      onKeyDown={(e) => handleKeyDown(e, index)}
      inputProps={{
        style: { textAlign: 'center' },
        autoFocus: index === 0,
      }}
      onChange={(e) => {
        handleVerificationCodeChange(e.target.value, index);
      }}
      value={contextFormValues.verificationCode.length > index ? contextFormValues.verificationCode[index] : '-'}
      inputRef={inputRef}
      hasError={errors.verificationCode !== undefined && confirmationCodeSubmitted}
    />
  );
};

export default DigitField;
