import React from 'react';

import { formatFullDateWithDots } from 'utils/formatters/time';

import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';

import { AnswerTypes } from 'utils/api/types';

import { QuestionRowProps } from 'containers/FormsDetails/types';

import { AnswerText } from '../styles';

const DateAnswer: React.FunctionComponent<QuestionRowProps> = ({
  answer,
  isSubmitted,
  updateAnswer,
}): React.ReactElement => {
  const [value, setValue] = React.useState(answer ? (answer.answer as string) : '');

  const onChange = (val: AnswerTypes): void => {
    setValue(val as string);
    updateAnswer(answer?.questionId as string, val as string);
  };

  if (!isSubmitted) {
    return (
      <div role="button" aria-label="date-answer-field" tabIndex={0} onClick={(e) => e.stopPropagation()}>
        <CustomDatePicker value={value} description="" onChange={onChange} />
      </div>
    );
  }

  return <AnswerText>{formatFullDateWithDots(answer!.answer as string)}</AnswerText>;
};

export default DateAnswer;
