import React from 'react';
import { observer } from 'mobx-react';
import { useGoogleMap } from '@react-google-maps/api';

import SearchControl from '../SearchControl';
import { UserSearchControlContainer } from './styles';
import { UserSearchControlProps } from './types';

const UserSearchControl = ({
  searchStore,
}: UserSearchControlProps): React.ReactElement | null => {
  const map = useGoogleMap();

  if (!map) {
    return null;
  }

  return (
    <UserSearchControlContainer mapWidth={map.getDiv().clientWidth}>
      <SearchControl
        placeholder="Search For Company Account"
        value={searchStore.searchQuery}
        onChange={searchStore.changeSearchQuery}
      />
    </UserSearchControlContainer>
  );
};

export default observer(UserSearchControl);
