/* eslint-disable max-len */
import { action, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosResponse, CancelTokenSource } from 'axios';

import DetailsActivities from 'vatix-ui/lib/utils/stores/DetailsActivities';

import Logger from 'vatix-ui/lib/utils/logger';

import {
  ActivityListResponse,
  ActivityType,
  ActivityType as VatixActivityType,
  PaginationParams,
} from 'vatix-ui/lib/utils/api/types';

import { EventType } from 'utils/events/constants';
import { ActionEvent, CreatedActivityPayload } from 'utils/events/types';
import API from 'utils/api';
import { postMessage } from 'utils/events/broadcast';
import RootStore from 'stores/Root';

export default class DeviceActivities extends DetailsActivities<RootStore, typeof API> {
  deviceId: string;

  constructor(rootStore: RootStore, api: typeof API, deviceId: string) {
    super(rootStore, api);

    makeObservable(this);

    this.deviceId = deviceId;

    eventBus.register(this);
  }

  async loadActivitiesData(params: PaginationParams): Promise<AxiosResponse<ActivityListResponse>> {
    return this.api.loadDeviceActivities(this.deviceId, params)();
  }

  @action.bound
  async addMessage(message: string): Promise<void> {
    if (this.error || !this.activities) {
      Logger.error(
        `Message cannot be sent because activities are not loaded. Device ID: ${this.deviceId}. Message: ${message}`
      );
      return;
    }
    try {
      const { data } = await this.api.addDeviceMessage(this.deviceId, message)();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, { ...data, type: ActivityType.Message as VatixActivityType }];
          postMessage(EventType.AddedDeviceMessage, {
            ...data,
            deviceId: this.deviceId,
          });
        }
      });
    } catch (e) {
      Logger.error(`Invalid add message API result: Device ID: ${this.deviceId}. Message: ${message}`, e);
    }
  }

  @action.bound
  async updateMessage(messageId: string, newMessage: string): Promise<void> {
    if (this.activities === undefined) {
      Logger.error(
        `Message cannot be updated because activities are not loaded. Device ID: ${this.deviceId}. MessageID: ${messageId}. New message: ${newMessage}`
      );
      return;
    }
    try {
      const { data } = await this.api.updateDeviceMessage(this.deviceId, messageId, newMessage)();
      runInAction(() => {
        const messageIndex = this.activities?.findIndex((activity) => activity.uuid === messageId);
        if (messageIndex !== undefined && messageIndex >= 0 && this.activities) {
          this.activities[messageIndex] = {
            ...data,
            type: ActivityType.Message as VatixActivityType,
          };
        }
      });
    } catch (e) {
      Logger.error(`Invalid update message API result: Device ID: ${this.deviceId}. Message ID: ${messageId}`, e);
    }
  }

  @action.bound
  async deleteMessage(messageId: string): Promise<void> {
    if (this.activities === undefined) {
      Logger.error(
        `Message cannot be deleted because activities are not loaded. Device ID: ${this.deviceId}. MessageID: ${messageId}`
      );
      return;
    }
    const message = this.activities.find((activity) => activity.uuid === messageId);
    if (message) {
      try {
        await this.api.removeDeviceMessage(this.deviceId, messageId)();
        runInAction(() => {
          if (this.activities !== undefined) {
            this.activities = this.activities.filter((activity) => activity.uuid !== messageId);
          }
          postMessage(EventType.DeletedDeviceMessage, {
            deviceId: this.deviceId,
            messageId,
          });
        });
      } catch (e) {
        this.rootStore.notification.enqueueErrorSnackbar(
          'Unable to delete the message at this time. Please try again later');
        Logger.error(`Invalid delete message API result: Device ID: ${this.deviceId}. Message ID: ${messageId}`, e);
      }
    }
  }

  @action.bound
  async deleteFile(fileId: string): Promise<void> {
    if (this.activities === undefined) {
      Logger.error(
        `File cannot be deleted because activities are not loaded. Device ID: ${this.deviceId}. FileID: ${fileId}`
      );
      return;
    }
    const file = this.activities.find((activity) => activity.uuid === fileId);
    if (file) {
      try {
        await this.api.removeDeviceFile(this.deviceId, fileId)();
        runInAction(() => {
          if (this.activities !== undefined) {
            this.activities = this.activities.filter((activity) => activity.uuid !== fileId);
          }
        });
      } catch (e) {
        this.rootStore.notification.enqueueErrorSnackbar(
          'Unable to delete the file at this time. Please try again later');
        Logger.error(`Invalid delete file API result: Device ID: ${this.deviceId}. File ID: ${fileId}`, e);
      }
    }
  }

  @subscribe(EventType.CreatedActivity)
  @action
  createdActivity({ payload }: ActionEvent<CreatedActivityPayload>): void {
    if (this.error || !this.activities || !payload.device || payload.device.uuid !== this.deviceId) {
      return;
    }

    this.activities = undefined;
    this.loadActivities();
  }

  async doUploadFile(
    file: File,
    onUpdateProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): Promise<void> {
    try {
      const { data } = await this.api.uploadDeviceFile(this.deviceId, file, onUpdateProgress, cancelTokenSource)();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, data];
          postMessage(EventType.AddedFile, {
            ...data,
            deviceId: this.deviceId,
          });
        }
      });
    } catch (e) {
      Logger.error(`Invalid add file API result: Device ID: ${this.deviceId}.`, e);
      this.rootStore.notification.enqueueErrorSnackbar('Failed to upload file');
    }
  }
}
