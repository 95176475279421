import { MenuItem as MuiMenuItem, Popover } from '@mui/material';
import styled from 'styled-components';

export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 0.5rem;
`;

export const MenuItem = styled(MuiMenuItem)`
  width: 120px;
  font-size: 14px;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #fff;
  height: 36px;
  padding: 6px 16px;
  :hover {
    background-color: #fff;
  }
`;

export const ReporterCell = styled.div`
  max-width: 210px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    margin-left: 1rem;
  }
`;

export const MainInformation = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  display: flex;
  padding: 20px;
  padding-right: 40px;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-self: stretch;
  margin-top: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  h1 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
    line-height: 143%;
    letter-spacing: 0.17px;
    margin: 0;
    margin-bottom: 6px;
  }
  p {
    font-weight: 400;
    letter-spacing: 0.15px;
    margin: 0;
  }
`;

export const TasksLabel = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
`;

export const TasksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const TasksIconContainer = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
`;
