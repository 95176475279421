/* eslint-disable max-len */
import React, { useEffect } from 'react';

import { useStore } from 'utils/hooks/store';

import { MAX_LOGO_SIZE } from 'core/constants';

import { UploadIcon } from 'components/Icons/UploadIcon';

import { UploadContainer, UploadButton } from './styles';

const UploadFile: React.FunctionComponent<{ setNewLogo: (file: File) => void; uploadFile: () => void }> = ({
  setNewLogo,
  uploadFile,
}): React.ReactElement => {
  const { notification } = useStore();
  const [dragActive, setIsDragOver] = React.useState(false);
  useEffect(() => {
    const dropZone = document.getElementById('drop-zone');

    const handleDragEnter = (e: DragEvent): void => {
      e.preventDefault();
      setIsDragOver(true);
    };

    const handleDragOver = (e: DragEvent): void => {
      e.preventDefault();
      setIsDragOver(true);
    };

    const handleDragLeave = (): void => {
      setIsDragOver(false);
    };

    const handleDrop = (e: DragEvent): void => {
      e.preventDefault();

      const files = e.dataTransfer?.files;

      if (files && files.length > 0) {
        handleDroppedFiles(files[0]);
      }
    };

    dropZone?.addEventListener('dragenter', handleDragEnter);
    dropZone?.addEventListener('dragover', handleDragOver);
    dropZone?.addEventListener('dragleave', handleDragLeave);
    dropZone?.addEventListener('drop', handleDrop);

    return () => {
      dropZone?.removeEventListener('dragenter', handleDragEnter);
      dropZone?.removeEventListener('dragleave', handleDragLeave);
      dropZone?.removeEventListener('dragover', handleDragOver);
      dropZone?.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDroppedFiles = (file: File): void => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      if (file.size > MAX_LOGO_SIZE) {
        notification.enqueueErrorSnackbar('File size exceeds the limit of 2 MB.');
        return;
      }
      setNewLogo(file);
    } else {
      notification.enqueueErrorSnackbar('Unsupported file format. Please select a PNG or JPEG file.');
    }
  };

  return (
    <UploadContainer id="drop-zone" data-testid="drop-zone" $dragActive={dragActive}>
      <UploadIcon />
      <h1>
        Drag and drop or{' '}
        <UploadButton type="button" onClick={uploadFile}>
          upload
        </UploadButton>{' '}
        your logo here
      </h1>
      <p>
        The logo should be in PNG or JPG format and have a maximum file size of 2 MB. Ideally, it should be horizontal
        and proportioned to fit a space of 106 x 30 pixels.
      </p>
    </UploadContainer>
  );
};

export default UploadFile;
