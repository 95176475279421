import React from 'react';
import { TextInput } from 'vatix-ui/lib/components/TextInput';
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';
import { escapeRegExp } from 'lodash';
import { GridFilterOperator } from '@mui/x-data-grid';

import { PickerProps } from '../types';

const TextInputInput: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <TextInput
    {...props}
    placeholder="Field Value"
    onChange={(event) => applyValue({ ...item, value: event.target.value })}
    value={(item.value as string) ?? ''}
  />
);

export default (): GridFilterOperator[] =>
  ([
    {
      label: 'contains',
      value: 'contains',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterItemValue = filterItem.value.trim();

        const filterRegex = new RegExp(escapeRegExp(filterItemValue), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: TextInputInput,
      getValueAsString: (value: string) => value,
    },
  ] as unknown) as GridFilterOperator[];
