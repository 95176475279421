/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { observer } from 'mobx-react';

import { Circle } from '@mui/icons-material';

import { DeviceAnalyticsType } from 'utils/api/types';

import { formatFullDateWithDots } from 'utils/formatters/time';

import { useAnalyticsData, OPTIONS, COMMON, CHART_COLORS, FilterSelected } from './types';

import { StyledContainer, StyledFooter, StyledTitle } from './styles';

Chart.register(...registerables);

const UsageReportChart: React.FunctionComponent<{
  type: 'mobile' | 'device';
  deviceAnalytics: DeviceAnalyticsType[];
  onUpdateSelected: (newFilters: FilterSelected[]) => void;
}> = ({ type, deviceAnalytics, onUpdateSelected }): React.ReactElement => {
  const [version, setVersion] = React.useState(0);
  const { date, active, inactive } = useAnalyticsData(deviceAnalytics);

  React.useLayoutEffect(() => {
    const onResize = (): void => {
      setVersion((v) => v + 1);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setVersion]);

  const labels = date.map((el) => formatFullDateWithDots(el));

  const summedActiveInactiveValues = active.map((value, index) => value + inactive[index]);

  const maxY = Math.floor(Math.max(...summedActiveInactiveValues) * 1.3);

  const dynamicOptions = {
    ...OPTIONS,
    scales: {
      ...OPTIONS.scales,
      y: {
        ...OPTIONS.scales.y,
        min: 0,
        max: maxY,

        title: {
          display: true,
          text: type === 'device' ? 'NUMBER OF DEVICES' : 'NUMBER OF APPS',
          font: {
            size: 12,
          },
          padding: {
            bottom: 10,
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Active',
        data: active,
        backgroundColor: CHART_COLORS.active,
        ...COMMON,
      },
      {
        label: 'Inactive',
        data: inactive,
        backgroundColor: CHART_COLORS.inactive,
        ...COMMON,
      },
    ],
  };

  return (
    <StyledContainer>
      <StyledTitle>Usage</StyledTitle>
      <div>
        <Bar
          key={version}
          // @ts-ignore
          options={{
            ...dynamicOptions,
            onClick(evt, element) {
              if (element.length > 0) {
                const isActive = element[0].element.options.backgroundColor === CHART_COLORS.active;
                const value = date[element[0].index];
                onUpdateSelected([
                  {
                    filter: 'date_before',
                    value,
                    labelFilter: 'Date Before',
                    labelValue: value,
                  },
                  {
                    filter: 'date_after',
                    value,
                    labelFilter: 'Date After',
                    labelValue: date[element[0].index],
                  },
                  {
                    filter: 'status',
                    value: isActive ? 'active' : 'inactive',
                    labelFilter: 'Status',
                    labelValue: isActive ? 'Active' : 'Inactive',
                  },
                  {
                    filter: 'type',
                    value: type,
                    labelFilter: 'Type',
                    labelValue: type,
                  },
                ]);
              }
              evt.native?.preventDefault();
            },
          }}
          data={data}
          width={10}
          height={474}
        />
      </div>
      <StyledFooter>
        <div>
          <Circle sx={{ fontSize: '8px', color: '#4BA0FD' }} /> Online
        </div>
        <div>
          <Circle sx={{ fontSize: '8px', color: '#E0E0E0' }} /> Offline
        </div>
      </StyledFooter>
    </StyledContainer>
  );
};

export default observer(UsageReportChart);
