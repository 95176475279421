import React from 'react';

import { Container, OptionContainer, OptionLabel, IconContainer, OptionLine, Line } from './styles';
import {TabsProps} from './types';

const Tabs: React.FunctionComponent<TabsProps> = ({
  options,
  onChange,
  value,
}) => (
  <Container>
    {options.map(({id, icon: Icon, label, to}) => (
      <OptionContainer key={id} to={to as string} onClick={onChange && (() => onChange(id))}>
        <OptionLine>
          {Icon && (
            <IconContainer>
              <Icon
                fill={value === id ? "#0772E5" : "#6C6C6C"}
                height="18px"
                width="18px"
              />
            </IconContainer>
          )}
          <OptionLabel active={value === id}>
            {label}
          </OptionLabel>
        </OptionLine>
        {value === id && (<Line />)}
      </OptionContainer>
    ))}
  </Container>
);

export default Tabs;
