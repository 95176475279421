import React from 'react';

import { observer } from 'mobx-react';

import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { AssignmentStatus, Gender } from 'core/constants';

import { AssignmentProps } from './types';

const Assignment: React.FunctionComponent<AssignmentProps> = ({ activity, sessionUser }) => {
  const assignedText = activity.status === AssignmentStatus.Assigned ? 'assigned device to' : 'unassigned device from';
  let assignedPerson = activity.user.name;
  if (activity.user.uuid === sessionUser.uuid) {
    if (activity.user.gender === Gender.Female) {
      assignedPerson = 'herself';
    } else if (activity.user.gender === Gender.Male) {
      assignedPerson = 'himself';
    }
  }
  return (
    <EventItem activity={activity} sessionUser={sessionUser} id="activity-device-assignment">
      {assignedText} {assignedPerson}
    </EventItem>
  );
};

export default observer(Assignment);
