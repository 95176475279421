import * as React from "react"

function MapPinUser(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        // eslint-disable-next-line max-len
        d="M5 22a2 2 0 01-2-2 8 8 0 018-8h2a8 8 0 018 8 2 2 0 01-2 2zM8 6a4 4 0 014-4 4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4z"
        fill="#fff"
      />
    </svg>
  )
}

export default MapPinUser;
