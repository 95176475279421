import React from 'react';
import withGoogMaps from 'vatix-ui/lib/components/Map/withGoogleMaps';
import Map from 'vatix-ui/lib/components/Map';
import { observer } from 'mobx-react';
import BreadCrumbs from 'vatix-ui/lib/components/Map/BreadCrumbs';
import RoundMarker from 'vatix-ui/lib/components/Map/RoundMarker/RoundMarker';

import MapControls from './components/MapControls';

import { Location, UuidableLocation } from '../../../../utils/stores/LocationHistory/types';
import { PersonalMapProps } from './types';

const MapComponentWithLoader = withGoogMaps(Map, (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as unknown) as string);

const PersonalMap: React.FunctionComponent<PersonalMapProps> = ({
  position,
  icon,
  locationHistory,
  toolTip,
  mapStyle,
  roundColor,
}) => {
  const [moved, setMoved] = React.useState(false);

  return (
    <MapComponentWithLoader
      aria-label="personal-map"
      radius={120}
      center={moved ? undefined : position}
      onDrag={() => setMoved(true)}
      mapContainerStyle={{
        minWidth: 200,
        minHeight: 200,
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#D3D6D9',
        ...mapStyle,
      }}
    >
      <RoundMarker
        icon={icon}
        roundColor={roundColor || '#0772E5'}
        location={position as Location}
        open={toolTip ? undefined : false}
        tooltipTitle={toolTip || ''}
      />

      {Boolean(locationHistory) && <BreadCrumbs path={locationHistory as UuidableLocation[]} />}
      <MapControls position={position as Location} />
    </MapComponentWithLoader>
  );
};

export default observer(PersonalMap);
