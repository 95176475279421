import { Autocomplete, Checkbox } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';

import React from 'react';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { SearchResponse } from 'vatix-ui/lib/utils/api/types';
import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import API from 'utils/api';
import { StyledTextField } from 'components/Input/styles';

import Chip from 'components/Chip';

import { StyledOption } from '../styles';
import { TeamsAutocompleteProps } from './types';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;

const TeamsAutocomplete: React.FunctionComponent<TeamsAutocompleteProps> = ({ groups, setFieldValue }) => {
  const {
    results: groupsSearchResults,
    searchQuery: groupsSearchQuery,
    loading: groupsLoading,
  } = useSearch(API.searchGroups, undefined, { limit: 5 });

  return (
    <Autocomplete
      multiple
      id="team"
      options={groupsSearchResults}
      value={groups}
      disableCloseOnSelect
      renderOption={(props, option: SearchResponse, { selected }) =>
        option.uuid === loadingOption.uuid ? (
          <StyledOption>
            <CenteredCircularProgress />
          </StyledOption>
        ) : (
          <StyledOption {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
              checkedIcon={<CheckBoxIcon fontSize="medium" />}
              checked={selected}
            />
            {option.name}
          </StyledOption>
        )
      }
      getOptionLabel={(option: SearchResponse | null) => option?.name as string}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder={groups.length === 0 ? 'Choose option(s)' : ''}
          onChange={(e) => {
            groupsSearchQuery(e.target.value);
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={`${option.name}`}
            variant="outlined"
            size="small"
            colors={{
              background: '#FFF',
              border: 'rgba(128, 135, 158, 0.14)',
            }}
            iconRight={<ClearIcon />}
            iconRightDisableInnerMargin
          />
        ))
      }
      loading={groupsLoading}
      noOptionsText="-"
      onChange={(_, value) => {
        setFieldValue('groups', value);
        groupsSearchQuery('');
      }}
      isOptionEqualToValue={(option: SearchResponse | null, val: SearchResponse | null) =>
        val?.uuid !== undefined && val?.uuid === option?.uuid
      }
      fullWidth
    />
  );
};

export default TeamsAutocomplete;
