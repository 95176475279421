import React from 'react';
import { useAudio } from 'react-use';

import { AudioProps } from './types';

const Audio: React.FunctionComponent<AudioProps> = ({ play, loop, src }): React.ReactElement => {
  const [audio, , controls] = useAudio({
    src,
    loop,
  });
  React.useEffect(() => {
    if (play) {
      controls.play();
    } else {
      controls.pause();
      controls.seek(0);
    }
  }, [play]);

  return audio;
};

export default Audio;
