import React from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button } from 'vatix-ui/lib/components/Button';

import { observer } from 'mobx-react';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { useStore } from 'utils/hooks/store';

import { SessionStoreWithUser } from 'stores/Session/types';
import { isNotFound } from 'utils/api/errors';

import routes from 'core/routes';

import { UserRole } from 'core/constants';

import QuestionsEditor from './components/QuestionsEditor';
import { ErrorMessage } from './styles';

const TemplateDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const { templateDetails, session: sessionRaw } = useStore();

  // check if all fields has a name or description
  const savingCondition = (): boolean =>
    isSubmitting ||
    templateDetails.details?.name.length === 0 ||
    templateDetails.details?.content.order.length === 0 ||
    !templateDetails.details?.haveDescription();

  const match = useRouteMatch();
  const history = useHistory();
  const session = sessionRaw as SessionStoreWithUser;
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const [isSaveCreateDisabled, setSaveCreateDisabled] = React.useState<boolean>(false);

  const onCancel = (): void => {
    history.goBack();
  };

  React.useEffect(() => {
    const saveCreateDisabled = savingCondition();

    if (!saveCreateDisabled) {
      setSaveCreateDisabled(saveCreateDisabled);
    }
  }, [
    templateDetails.details?.name.length,
    templateDetails.details?.content.order.length,
    templateDetails.details?.haveDescription(),
  ]);

  const onClick = async (create: boolean): Promise<void> => {
    const saveCreateDisabled = savingCondition();

    setSaveCreateDisabled(saveCreateDisabled);

    if (!saveCreateDisabled) {
      try {
        setSubmitting(true);
        const response = await templateDetails.createSaveTemplate(create);
        if (response !== null) {
          history.push(routes.dashboard.audits.templates.toString());
        }
      } finally {
        setSubmitting(false);
      }
    }
  };

  const canEdit = [UserRole.Admin, UserRole.AccountManager].includes(session.user.role);
  const isNew = templateDetails.details?.uuid === 'new';

  let actionButtonText;
  if (isSubmitting) {
    actionButtonText = isNew ? 'Creating...' : 'Saving...';
  } else {
    actionButtonText = isNew ? 'Create' : 'Save';
  }

  return (
    <Record
      disableMaxContentHeight
      navBarHeight={navBarHeight}
      Content={() => (
        <DetailsLoader
          match={match}
          // @ts-ignore
          routeParam="templateId"
          store={templateDetails}
        >
          {({ isLoaded, error, reload }): React.ReactElement => {
            if (!isLoaded && templateDetails.details === undefined) {
              return <CenteredCircularProgress />;
            }

            if (error) {
              if (isNotFound(error)) {
                return <NotFound />;
              }
              return <DataError onReload={reload} />;
            }

            return (
              <>
                {!isSubmitting && isSaveCreateDisabled ? (
                  <ErrorMessage>
                    You cannot save the template because at least one question or section name is empty. Please make
                    sure all questions and sections are named and try again.
                  </ErrorMessage>
                ) : null}
                <QuestionsEditor />
              </>
            );
          }}
        </DetailsLoader>
      )}
      Header={
        <MainHeader
          title={isNew ? 'Create New' : 'Edit Template'}
          breadcrumbs={[
            { label: 'Audits', link: routes.dashboard.audits.templates.toString() },
            { label: 'Form Templates', link: routes.dashboard.audits.templates.toString() },
            {
              label: isNew ? 'Create New' : 'Edit',
              link: isNew ? routes.dashboard.audits.templates.toString() : routes.dashboard.audits.templates.toString(),
            },
          ]}
          action={
            <div style={{ display: 'flex' }}>
              <Button disabled={isSubmitting} onClick={onCancel} id="cancel-button" variant="outlined" size="large">
                Cancel
              </Button>
              {canEdit && (
                <Button
                  onClick={() => onClick(isNew)}
                  id={isNew ? 'create-button' : 'save-button'}
                  variant="contained"
                  size="large"
                  style={{ marginLeft: '16px' }}
                  disabled={templateDetails.details?.getSavingCondition(templateDetails.details)}
                >
                  {actionButtonText}
                </Button>
              )}
            </div>
          }
        />
      }
    />
  );
};

export default observer(TemplateDetails);
