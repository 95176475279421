import React from 'react';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { Button } from 'vatix-ui/lib/components/Button';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import { useStore } from 'utils/hooks/store';

import AddEntityModal from 'components/Entities/AddEntityModal/AddEntityModal';

import SitesTable from './components/SitesTable';

const Sites: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const [openModal, setOpenModal] = React.useState(false);
  const refreshRef = React.useRef<Function | null>(null);
  const { session, siteDetails, notification } = useStore();

  const [sitesCount, setSitesCount] = React.useState(0);

  React.useEffect(() => {
    const fetchLoadCount = async (): Promise<void> => {
      const count = await siteDetails.loadCount();
      setSitesCount(count);
    };

    fetchLoadCount();
  }, []);

  const onClose = (created: boolean): void => {
    setOpenModal(false);
    if (refreshRef.current && created) {
      refreshRef.current();
    }
  };
  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  const handleNewButtonClick = (): void => {
    if (session.user && sitesCount >= session.user.organisation.maximumSitesAllowed) {
      notification.enqueueErrorSnackbar('Maximum site limit reached. Please contact us to increase your limit.');
    } else {
      setOpenModal(true);
    }
  };

  return (
    <>
      <Record
        Header={
          <MainHeader
            title="Sites"
            breadcrumbs={[
              { label: 'Sites', link: routes.dashboard.sites.toString() },
              { label: 'Overview', link: routes.dashboard.sites.toString() },
            ]}
            action={
              <Button variant="contained" size="large" onClick={handleNewButtonClick}>
                New
              </Button>
            }
          />
        }
        Content={(onError) => <SitesTable onError={onError} onRefreshCallback={onRefreshCallback} />}
        navBarHeight={navBarHeight}
      />
      <AddEntityModal onClose={onClose} store={siteDetails} openModal={openModal} />
    </>
  );
};

export default Sites;
