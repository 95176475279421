import styled from 'styled-components';
import { Tab, Tabs } from '@mui/material';

export const QuestionHeader = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: 0.15px;
  margin: 0;
  padding: 0;
`;

export const DefaultContainer = styled.div`
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

export const HeaderContainer = styled.div`
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e4e6;
`;

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

export const StyledTabs = styled(Tabs)`
  width: 100%;

  .MuiTabs-scroller {
    border-bottom: 1px solid #e0e0e0;
  }

  button {
    padding: 9px 16px;
  }
  > *:first-child {
    padding-left: 16px;
  }
`;

export const StyledTab = styled(Tab)`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.4px;
  text-transform: capitalize;
`;
