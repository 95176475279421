import { observer } from 'mobx-react';
import React from 'react';

import { Grid } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

import EditIcon from '@mui/icons-material/Edit';

import UserDetailsData from 'stores/UserDetails/UserDetailsData';

import { SectionGrid } from 'components/BackgroundInformation/styles';

import PersonalDetails from 'components/PersonalDetails';

import { useStore } from '../../../../utils/hooks/store';
import { TabTitle, PersonalDetailsWrapper } from './styles';
import EditMedicalInformation from '../EditMedicalInformation';

export const MedicalInformation: React.FunctionComponent = () => {
  const {
    userDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as UserDetailsData;
  const [editing, setEditing] = React.useState(false);

  return (
    <>
      <Grid
        container
        style={{ padding: '16px 20px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', minHeight: '68px' }}
        alignItems="center"
      >
        <SectionGrid item container xs={10} alignContent="flex-start">
          <TabTitle>Medical Information</TabTitle>
        </SectionGrid>
        <SectionGrid item container xs={2} justifyContent="end">
          {!editing && (
            <Button size="medium" variant="text" onClick={() => setEditing(true)}>
              <EditIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
              Edit
            </Button>
          )}
        </SectionGrid>
      </Grid>
      {editing ? (
        <EditMedicalInformation setEditing={setEditing} />
      ) : (
        <PersonalDetailsWrapper>
          <PersonalDetails details={details} />
        </PersonalDetailsWrapper>
      )}
    </>
  );
};

export default observer(MedicalInformation);
