/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { FilterType } from 'components/Grid/types';

import { useStore } from 'utils/hooks/store';

import UsageReportTable from '../UsageReportTable';
import UsageReportChart from '../UsageReportChart';

import { FilterSelected } from '../UsageReportChart/types';

const DeviceTab: React.FC<{ onError: (error: any) => void }> = ({ onError }): React.ReactElement => {
  const { deviceAnalytics } = useStore();

  const refreshRef = React.useRef<(() => void) | null>(null);
  const [filters, setFilters] = React.useState<FilterType[]>([]);

  const updateFilters = (newFilters: FilterSelected[]): void => {
    const filers: FilterType[] = newFilters.map(({ filter, value }) => ({
      field: filter,
      value,
      operator: 'is',
    }));
    setFilters(filers);

    refreshRef.current?.();
  };

  React.useEffect(() => {
    deviceAnalytics.loadData();
  }, []);

  return (
    <>
      <UsageReportChart
        type="device"
        deviceAnalytics={deviceAnalytics.deviceAnalytics}
        onUpdateSelected={(newFilters) => updateFilters(newFilters)}
      />
      <UsageReportTable
        onError={onError}
        filters={filters}
        onRefreshCallback={(callback) => {
          refreshRef.current = callback;
        }}
      />
    </>
  );
};

export default DeviceTab;
