/* eslint-disable max-len */
import React from 'react';

export function DevicesIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <g clipPath="url(#clip0_14025_73527)">
        <path
          d="M10 0H5C2.26667 0 0 2.23333 0 5V15C0 17.7667 2.26667 20 5 20H10C12.7667 20 15 17.7667 15 15V5C15 2.23333 12.7667 0 10 0ZM13.3333 15C13.3333 16.8333 11.8667 18.3333 10 18.3333H5C3.16667 18.3333 1.66667 16.8333 1.66667 15V5C1.66667 3.16667 3.16667 1.66667 5 1.66667H10C11.8667 1.66667 13.3333 3.16667 13.3333 5V15Z"
          fill="white"
          fillOpacity="0.54"
        />
        <path
          d="M7.50091 6.26672C5.43424 6.26672 3.76758 7.93339 3.76758 10.0001C3.76758 12.0667 5.43424 13.7667 7.50091 13.7667C9.56758 13.7667 11.2676 12.0667 11.2676 10.0001C11.2676 7.93339 9.60091 6.26672 7.50091 6.26672ZM7.50091 12.1001C6.36758 12.1001 5.43424 11.1667 5.43424 10.0001C5.43424 8.83339 6.36758 7.93339 7.50091 7.93339C8.63424 7.93339 9.60091 8.86672 9.60091 10.0001C9.60091 11.1334 8.66758 12.1001 7.50091 12.1001Z"
          fill="white"
          fillOpacity="0.54"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73527">
          <rect width="15" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveDevicesIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none">
      <g clipPath="url(#clip0_14025_73520)">
        <path
          d="M7.5 12.5C8.88071 12.5 10 11.3807 10 10C10 8.61929 8.88071 7.5 7.5 7.5C6.11929 7.5 5 8.61929 5 10C5 11.3807 6.11929 12.5 7.5 12.5Z"
          fill="white"
        />
        <path
          d="M10 0H5C2.26667 0 0 2.23333 0 5V15C0 17.7667 2.26667 20 5 20H10C12.7667 20 15 17.7667 15 15V5C15 2.23333 12.7667 0 10 0ZM7.5 14.1667C5.2 14.1667 3.33333 12.3 3.33333 10C3.33333 7.7 5.2 5.83333 7.5 5.83333C9.8 5.83333 11.6667 7.7 11.6667 10C11.6667 12.3 9.83333 14.1667 7.5 14.1667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73520">
          <rect width="15" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
