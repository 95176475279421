import React from 'react';

import { format } from 'date-fns';

import { useStore } from 'utils/hooks/store';
import { IncidentResponse } from 'utils/api/types';

import { Value } from '../EditableTextInput/styles';

const DetailsCreated: React.FunctionComponent = (): React.ReactElement => {
  const {
    incidentDetails: { details: detailsRaw },
  } = useStore();

  const details = detailsRaw as IncidentResponse;

  return <Value data-testid="incident-created-date">{format(new Date(details.created), 'd MMM yyyy, HH:mm')}</Value>;
};

export default DetailsCreated;
