import React from 'react';

import { observer } from 'mobx-react';

import { UserRole } from 'core/constants';
import { useStore } from 'utils/hooks/store';

import UserAlarmsPane from './components/UserAlarmsPane';

import ManagerAlarmsPane from './components/ManagerAlarmsPane';
import ManagerAlarmsPaneSkeleton from './components/ManagerAlarmsPane/ManagerAlarmsPane.skeleton';

const AlarmsPane: React.FunctionComponent = () => {
  const {
    session: { user },
    home,
  } = useStore();

  if (user === null) {
    return null;
  }

  if (user.role === UserRole.ArcOperator) {
    return null;
  }

  // user only has access to lone working
  if (user.role === UserRole.User && user.hasOnlyLoneWorkingLicenseEnabled()) {
    return <UserAlarmsPane />;
  }

  if (user.role === UserRole.User) {
    return null;
  }

  if (home.content === undefined) {
    if (!home.hasError && home.isLoading) {
      return <ManagerAlarmsPaneSkeleton />;
    }

    if (home.hasError && !home.isLoading) {
      return null;
    }
  }

  if (!home.content) {
    return null;
  }

  if (user === null || !user.role) {
    return null;
  }

  return <ManagerAlarmsPane />;
};

export default observer(AlarmsPane);
