import styled from 'styled-components';

export const InformationContainer = styled.div<{ $error: boolean }>`
  display: flex;
  align-items: flex-start;
  background: ${(props) => (props.$error ? '#fbeaea' : '#EAF2EA')};
  color: ${(props) => (props.$error ? '#541313' : '#123214')};
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 2px;

  h1 {
    padding: 0;
    margin: 0;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
  }

  p {
    color: #123214;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 12px;
  }
`;
