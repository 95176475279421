import styled from 'styled-components';

export const BaseInfoText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
`;

export const StyledModalActions = styled.div<{ $justify: string }>`
  display: flex;
  justify-content: ${({ $justify }) => $justify};
  margin-top: 30px;
`;
