import { action, observable, runInAction, makeObservable, computed } from 'mobx';

import { eventBus } from 'mobx-event-bus2';

import Logger from 'vatix-ui/lib/utils/logger';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { NotificationSettings, NotificationSettingsUpdateData } from 'utils/api/types';

import RootStore from 'stores/Root';
import API from 'utils/api';
import { NotificationTarget } from 'core/constants';

class NotificationSettingsStore extends DataMixin<typeof API, RootStore> {
  @observable isLoading = false;

  @observable notificationsSettings?: NotificationSettings[];

  @observable isUpdating = false;

  private isNotificationTargetActiveForEmail(target: NotificationTarget): boolean {
    const notificationsSetting = this.notificationsSettings?.find((settings) => settings.target === target);
    if (notificationsSetting) {
      if (notificationsSetting.isActive && notificationsSetting.email) {
        return true;
      }
      return false;
    }
    return false;
  }

  @computed get isNewAssignmentsEmailSettingsEnabled(): boolean {
    return (
      this.isNotificationTargetActiveForEmail(NotificationTarget.NEW_TASK_ASSIGNED_TO_ME) &&
      this.isNotificationTargetActiveForEmail(NotificationTarget.NEW_INCIDENT_ASSIGNED_TO_ME)
    );
  }

  @computed get isMentionsEmailSettingEnabled(): boolean {
    return (
      this.isNotificationTargetActiveForEmail(NotificationTarget.NEW_TASK_MESSAGE) &&
      this.isNotificationTargetActiveForEmail(NotificationTarget.NEW_INCIDENT_MESSAGE) &&
      this.isNotificationTargetActiveForEmail(NotificationTarget.NEW_INCIDENT_SUBMISSION_MESSAGE)
    );
  }

  @computed get isNewIncidentsEmailSettingEnabled(): boolean {
    return this.isNotificationTargetActiveForEmail(NotificationTarget.NEW_INCIDENT_REPORTED);
  }

  @computed get isNewAuditsEmailSettingEnabled(): boolean {
    return this.isNotificationTargetActiveForEmail(NotificationTarget.NEW_FORM_SUBMITTED);
  }

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadData(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      const response = await this.api.loadNotificationSettings()();
      const result = response.data;
      runInAction(() => {
        this.notificationsSettings = result;
        this.isFailure = false;
        this.isLoaded = true;
      });
    } catch (e) {
      runInAction(() => {
        this.isFailure = true;
        this.isLoaded = false;
      });
      Logger.error('Invalid form list API response', e);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action.bound
  async updatedNotificationSettings(
    notificationTarget: NotificationTarget,
    data: Partial<NotificationSettings>,
    targetsLength: number,
    index: number
  ): Promise<void> {
    try {
      const { data: result } = await this.api.updateNotificationSetting(notificationTarget, data)();
      if (this.notificationsSettings) {
        const resultIndexInSettings = this.notificationsSettings.findIndex(
          (settings) => settings.target === result.target
        );
        if (resultIndexInSettings > -1) {
          const updatedNotificationSettings = this.notificationsSettings;
          updatedNotificationSettings[resultIndexInSettings] = result;
          runInAction(() => {
            this.notificationsSettings = updatedNotificationSettings;
          });
        } else {
          runInAction(() => {
            this.notificationsSettings = [...(this.notificationsSettings || []), result];
          });
        }
      } else {
        runInAction(() => {
          this.notificationsSettings = [result];
        });
      }
      targetsLength - 1 === index &&
        this.store.notification.enqueueSuccessSnackbar('Notification settings successfully updated');
    } catch (ex) {
      Logger.error('Invalid update notification settings API response.', ex);
      targetsLength - 1 === index &&
        this.store.notification.enqueueErrorSnackbar('Notification settings could not be updated');
    }
  }

  async updateSettingsHelper(
    targets: NotificationTarget[],
    updatedSettings: NotificationSettingsUpdateData
  ): Promise<void> {
    try {
      this.isUpdating = true;
      if (Object.keys(updatedSettings).length === 0) {
        throw new Error('Cannot update settings with empty object');
      }
      targets.forEach(async (target, index) => {
        await this.updatedNotificationSettings(target, updatedSettings, targets.length, index);
      });
    } catch (e) {
      // @ts-ignore
      Logger.error(e);
      this.store.notification.enqueueErrorSnackbar('Unable to update notification settings. Please try again shortly.');
    } finally {
      this.isUpdating = false;
    }
  }

  @action.bound
  async updateNewAssignmentsSettings(updatedSettings: NotificationSettingsUpdateData): Promise<void> {
    await this.updateSettingsHelper(
      [NotificationTarget.NEW_TASK_ASSIGNED_TO_ME, NotificationTarget.NEW_INCIDENT_ASSIGNED_TO_ME],
      updatedSettings
    );
  }

  @action.bound
  async updateMentionsSettings(updatedSettings: NotificationSettingsUpdateData): Promise<void> {
    await this.updateSettingsHelper(
      [
        NotificationTarget.NEW_TASK_MESSAGE,
        NotificationTarget.NEW_INCIDENT_MESSAGE,
        NotificationTarget.NEW_INCIDENT_SUBMISSION_MESSAGE,
      ],
      updatedSettings
    );
  }

  @action.bound
  async updateNewIncidentsSettings(updatedSettings: NotificationSettingsUpdateData): Promise<void> {
    await this.updateSettingsHelper([NotificationTarget.NEW_INCIDENT_REPORTED], updatedSettings);
  }

  @action.bound
  async updateNewAuditsSettings(updatedSettings: NotificationSettingsUpdateData): Promise<void> {
    await this.updateSettingsHelper([NotificationTarget.NEW_FORM_SUBMITTED], updatedSettings);
  }
}

export default NotificationSettingsStore;
