import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { TemplateListResponse } from 'utils/api/types';
import { isNotFound } from 'utils/api/errors';

import { SessionStoreWithUser } from 'stores/Session/types';

import RootStore from '../Root';
import DetailsTemplate from './DetailsTemplate';

export default class TemplateDetailsStore extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable details?: DetailsTemplate;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(templateId: string): Promise<void> {
    if (templateId === 'new') {
      this.loadNewTemplate();
    } else {
      this.loadDetailsFromBackend(templateId);
    }
  }

  loadNewTemplate(): void {
    this.details = new DetailsTemplate(
      {
        uuid: 'new',
        name: '',
        isActive: true,
        lastUpdate: Date.now().toString(),
        creator: (this.store.session as SessionStoreWithUser).user,
        content: {
          order: ['0'],
          properties: {
            '0': {
              description: '',
              // @ts-ignore
              type: 'object',
              properties: {},
              workflowsType: 'sectionZero',
              mandatory: [],
              order: [],
            },
          },
        },
      },
      this.store
    );
    this.isLoaded = true;
    this.error = undefined;
  }

  async loadDetailsFromBackend(templateId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadTemplateDetails(templateId)();
      runInAction(() => {
        this.error = undefined;
        this.details = new DetailsTemplate(data, this.store);
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load template details API response. Template id: ${templateId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @action.bound
  async createSaveTemplate(create: boolean): Promise<TemplateListResponse | null> {
    if (this.details === undefined) {
      throw Error(`You cannot ${create ? 'create' : 'save'} a template that is not opened`);
    }
    this.isLoaded = false;
    try {
      const requestContent = { name: this.details.name, content: this.details.content };
      const { data } = create
        ? await this.api.createTemplate(requestContent)()
        : await this.api.updateTemplate(this.details.uuid, requestContent)();

      this.error = undefined;
      this.store.notification.enqueueSuccessSnackbar(`Template ${create ? 'created' : 'updated'} successfully`);
      return data;
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid ${create ? 'create' : 'save'} template API response.`, e);
        this.store.notification.enqueueErrorSnackbar(`Template cannot be ${create ? 'created' : 'saved'} right now`);
      }
      return null;
    } finally {
      this.isLoaded = true;
    }
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }
}
