import { useRouteMatch } from 'react-router-dom';

import routes from 'core/routes';

const useFieldManagerParams = (): string => {
  const match = useRouteMatch(routes.dashboard.objectManager.details.fieldManager);
  const { moduleName } = match?.params as { moduleName: string };

  return moduleName;
};

export default useFieldManagerParams;
