import styled from 'styled-components';
import { MenuItem as MuiMenuItem, Popover } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

export const CompletedButton = styled(Button)`
  pointer-events: none;
  svg {
    margin-right: 8px;
  }
`;

export const MarkCompletedButton = styled(Button)`
  width: max-content;
  svg {
    margin-right: 8px;
  }
  width: max-content;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    margin-left: 1rem;
  }
`;
export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 8px;
`;

export const MenuItem = styled(MuiMenuItem)`
  font-size: 14px;
  height: 50px;
  padding: 6px 16px;
  background: #fff;
  display: flex;
  width: 124px;
  box-shadow: 0px 8px 50px 0px rgba(7, 5, 26, 0.07);
`;

export const ModalText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin: 0;
`;
