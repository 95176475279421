/* eslint-disable max-len */
import * as React from 'react';

export function InfoToast(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#0772E5"
      />
    </svg>
  );
}

export function CloseInfoToast(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M15.8327 5.34169L14.6577 4.16669L9.99935 8.82502L5.34102 4.16669L4.16602 5.34169L8.82435 10L4.16602 14.6584L5.34102 15.8334L9.99935 11.175L14.6577 15.8334L15.8327 14.6584L11.1743 10L15.8327 5.34169Z"
        fill="#0772E5"
      />
      <path
        d="M15.8327 5.34169L14.6577 4.16669L9.99935 8.82502L5.34102 4.16669L4.16602 5.34169L8.82435 10L4.16602 14.6584L5.34102 15.8334L9.99935 11.175L14.6577 15.8334L15.8327 14.6584L11.1743 10L15.8327 5.34169Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
}
