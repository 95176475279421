import { InputBaseProps } from '@mui/material/InputBase';
import React from 'react';

import DebounceInput from '../../Input/DebounceInput';
import { SearchInput } from '../../Input/styles';
import { SearchControlPaper } from './styles';
import { SearchControlProps } from './types';
import SearchAdornment from '../../Adornment/SearchAdornment';

const SearchControl: React.FunctionComponent<SearchControlProps> = (props): React.ReactElement => (
  <SearchControlPaper elevation={3}>
    <DebounceInput<InputBaseProps> InputComponent={SearchInput} startAdornment={<SearchAdornment />} {...props} />
  </SearchControlPaper>
);

export default SearchControl;
