import styled from 'styled-components';

export const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

export const TaskContainer = styled.div`
  margin-bottom: 16px;
  padding: 4px 16px;
  padding-right: 0;
  border-radius: 8px;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

export const DataContainer = styled.div<{ $withoutPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${({ $withoutPadding }) => ($withoutPadding ? '0' : '20px')};
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  p {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
    margin: 0;
    padding: 0;
  }
`;
