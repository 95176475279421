import { Typography } from '@mui/material';
import styled from 'styled-components';

import vatix from 'theme/vatix';

export const Container = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 0 16px;
  padding-bottom: 10px;
  @media (min-width: 680px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  justify-content: space-between;
`;

export const IncidentOverview = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 90%;
  background-color: #f5f5f5;
`;

export const SubmissionContainer = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: 1200px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  gap: 20px;
  align-self: stretch;
`;

export const SectionTitle = styled.p`
  font-size: 24px;
  line-height: 160%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 49px;
  background-color: #f5f5f5;
  padding-top: 20px;
`;

export const HeaderTitle = styled(Typography).attrs({
  variant: 'h2',
})`
  margin-bottom: 5px;
  color: ${vatix.palette.text.secondary};
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 49px;
  background-color: white;
  padding 20px;
`;
