/* eslint-disable max-len */
import React from 'react';

export function HomeIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M19.9 7.33333L11.5667 0.383333C11.25 0.133333 10.8833 0 10.5 0C10.1167 0 9.75 0.133333 9.43333 0.383333L1.1 7.33333C0.716667 7.65 0.5 8.11667 0.5 8.61667V18.3333C0.5 19.25 1.25 20 2.16667 20H18.8333C19.75 20 20.5 19.25 20.5 18.3333V8.61667C20.5 8.11667 20.2833 7.65 19.9 7.33333ZM12.45 18.3333V12.0833C12.45 11.85 12.6333 11.6667 12.8667 11.6667H15.3667C15.5833 11.6667 15.7833 11.85 15.7833 12.0833V18.3333H12.45ZM18.8333 17.5C18.8333 17.9667 18.4667 18.3333 18 18.3333H17.45V11.5167C17.45 10.6833 16.7833 10 15.9833 10H12.2333C11.4333 10 10.7833 10.6833 10.7833 11.5167V18.3333H3C2.53333 18.3333 2.16667 17.9667 2.16667 17.5V9C2.16667 8.75 2.28333 8.51667 2.46667 8.36667L9.96667 2.11667C10.2667 1.85 10.7167 1.85 11.0333 2.11667L18.5333 8.36667C18.7167 8.51667 18.8333 8.75 18.8333 9V17.5Z"
        fill="#C0C7D3"
      />
      <path
        d="M8.41745 10H4.53411C3.98411 10 3.55078 10.4333 3.55078 10.9667V14.8667C3.55078 15.4 3.98411 15.8333 4.53411 15.8333H8.41745C8.95078 15.8333 9.38411 15.4 9.38411 14.8667V10.9667C9.38411 10.4333 8.95078 10 8.41745 10ZM7.71745 13.55C7.71745 13.8833 7.45078 14.1667 7.10078 14.1667H5.85078C5.50078 14.1667 5.21745 13.8833 5.21745 13.55V12.3C5.21745 11.95 5.50078 11.6667 5.85078 11.6667H7.10078C7.45078 11.6667 7.71745 11.95 7.71745 12.3V13.55Z"
        fill="#C0C7D3"
      />
    </svg>
  );
}

export function ActiveHomeIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M19.9 7.33333L11.5667 0.383333C11.25 0.133333 10.8833 0 10.5 0C10.1167 0 9.75 0.133333 9.43333 0.383333L1.1 7.33333C0.716667 7.65 0.5 8.11667 0.5 8.61667V18.3333C0.5 19.25 1.25 20 2.16667 20H10.3667C10.6 20 10.7833 19.8167 10.7833 19.5833V12.35C10.7833 11.5167 11.4333 10.8333 12.2333 10.8333H15.15C15.95 10.8333 16.6167 11.5167 16.6167 12.35V19.5833C16.6167 19.8167 16.8 20 17.0333 20H18.8333C19.75 20 20.5 19.25 20.5 18.3333V8.61667C20.5 8.11667 20.2833 7.65 19.9 7.33333ZM8.55 11.8V14.8667C8.55 15.4 8.11667 15.8333 7.58333 15.8333H4.53333C3.98333 15.8333 3.55 15.4 3.55 14.8667V11.8C3.55 11.2667 3.98333 10.8333 4.53333 10.8333H7.58333C8.11667 10.8333 8.55 11.2667 8.55 11.8Z"
        fill="white"
      />
    </svg>
  );
}
