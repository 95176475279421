/* eslint-disable max-len */
import React from 'react';

export function MapIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 12 16" fill="none">
      <g clipPath="url(#clip0_14588_39426)">
        <path
          d="M1.73567 10.939L1.74351 10.9433L1.75149 10.9473C1.77513 10.9591 1.79612 10.9818 1.80701 11.0144C1.81789 11.0471 1.8147 11.0778 1.80288 11.1015L1.7978 11.1116L1.79319 11.122C1.67963 11.3775 1.5751 11.701 1.5751 12.0251C1.5751 12.8254 2.05757 13.5105 2.71096 13.9736C3.37002 14.4408 4.25384 14.7251 5.2001 14.7251C6.14358 14.7251 7.02733 14.4485 7.68773 13.9841C8.34451 13.5222 8.8251 12.8361 8.8251 12.0251C8.8251 11.7057 8.76032 11.3998 8.59494 11.0964C8.58511 11.0735 8.58307 11.0448 8.59319 11.0144C8.60408 10.9818 8.62507 10.9591 8.6487 10.9473C8.67234 10.9355 8.70307 10.9323 8.73573 10.9432C8.7684 10.9541 8.79106 10.9751 8.80288 10.9987C8.96795 11.3288 9.0501 11.6772 9.0501 12.0501C9.0501 13.5635 7.42904 14.9894 5.2001 15C2.97115 14.9894 1.3501 13.5635 1.3501 12.0501C1.3501 11.6772 1.43225 11.3288 1.59731 10.9987C1.60481 10.9837 1.62745 10.9583 1.67092 10.9441C1.70964 10.9315 1.73183 10.938 1.73544 10.9391C1.73606 10.9393 1.73613 10.9393 1.73567 10.939Z"
          stroke="white"
          strokeOpacity="0.56"
        />
        <path
          d="M5.225 0.5C2.625 0.5 0.5 2.625 0.5 5.225C0.5 7.125 2.85 10.375 4.25 12.15C4.475 12.475 4.825 12.65 5.225 12.65C5.625 12.65 5.975 12.475 6.2 12.15C7.6 10.375 9.95 7.125 9.95 5.225C9.95 2.625 7.825 0.5 5.225 0.5ZM5.225 1.75C7.125 1.75 8.7 3.3 8.7 5.225C8.7 6.3 7.375 8.675 5.225 11.4C3.075 8.675 1.75 6.3 1.75 5.225C1.75 3.3 3.325 1.75 5.225 1.75Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M5.2249 3.0498C4.0249 3.0498 3.0249 4.0248 3.0249 5.2248C3.0249 6.4248 4.0249 7.4248 5.2249 7.4248C6.4249 7.4248 7.4249 6.4248 7.4249 5.2248C7.4249 4.0248 6.4249 3.0498 5.2249 3.0498ZM5.2249 6.1748C4.6999 6.1748 4.2749 5.7498 4.2749 5.2248C4.2749 4.6998 4.6999 4.2998 5.2249 4.2998C5.7499 4.2998 6.1749 4.6998 6.1749 5.2248C6.1749 5.7498 5.7499 6.1748 5.2249 6.1748Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14588_39426">
          <rect width="9.45" height="15" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveMapIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 12 16" fill="none">
      <g clipPath="url(#clip0_14588_71010)">
        <path
          d="M9.6251 11.9251C9.6251 13.9001 7.5751 15.5001 5.0501 15.5001C2.5251 15.5001 0.475098 13.9001 0.475098 11.9251C0.475098 11.2501 0.700098 10.6001 1.1751 10.0751H1.2001C1.2001 10.0751 1.2501 9.9501 1.3251 9.9001C1.3751 9.8751 1.4001 9.8751 1.4501 9.8751C1.5251 9.8751 1.6251 9.9251 1.6751 9.9751C1.7001 10.0001 1.7251 10.0251 1.7501 10.0751C1.7751 10.1251 1.8251 10.1501 1.8501 10.2001C1.9501 10.3751 2.0751 10.5751 2.2251 10.7501C2.6501 11.3501 3.0501 11.9001 3.4751 12.4251C3.8501 12.9001 4.4251 13.1751 5.0251 13.1751C5.6251 13.1751 6.2001 12.9001 6.5751 12.4251C7.0001 11.9001 7.4001 11.3501 7.8251 10.7501C7.9501 10.5751 8.0751 10.3751 8.2001 10.2001L8.3251 10.0501C8.3251 10.0501 8.3501 9.9751 8.3751 9.9501C8.4251 9.8751 8.5251 9.8501 8.6001 9.8501C8.6751 9.8501 8.7751 9.8751 8.8251 9.9501C8.8501 9.9751 8.8751 10.0001 8.9001 10.0501C9.3501 10.6251 9.5751 11.2501 9.5751 11.9251H9.6251Z"
          fill="#E3F2FD"
        />
        <path
          d="M5.0749 0.5C2.7249 0.5 0.774902 2.425 0.774902 4.775C0.774902 5.85 1.6249 7.45 2.5749 8.925C2.6999 9.1 2.7999 9.3 2.9499 9.45C3.0749 9.625 3.1999 9.85 3.3249 10C3.7999 10.65 4.2499 11.225 4.5499 11.625C4.6749 11.775 4.8999 11.875 5.0749 11.875C5.2499 11.875 5.4749 11.775 5.5999 11.625C5.9249 11.225 6.3499 10.65 6.7999 10C6.9249 9.85 7.0499 9.65 7.1999 9.475C7.2999 9.3 7.4249 9.1 7.5749 8.9C8.5249 7.45 9.3749 5.85 9.3749 4.775C9.3749 2.425 7.4499 0.5 5.0999 0.5L5.0749 0.5ZM5.0749 6.825C3.9499 6.825 3.0499 5.9 3.0499 4.8C3.0499 3.7 3.9749 2.8 5.0749 2.8C6.1749 2.8 7.0999 3.675 7.0999 4.8C7.0999 5.925 6.1749 6.825 5.0749 6.825Z"
          fill="#E3F2FD"
        />
      </g>
      <defs>
        <clipPath id="clip0_14588_71010">
          <rect width="9.125" height="15" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
