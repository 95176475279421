import { observable, runInAction, action, makeObservable } from 'mobx';

import API from 'utils/api';

import { DeviceAnalyticsType } from 'utils/api/types';

import { formatFullDate } from 'utils/formatters/time';

import RootStore from '../Root';

export default class DeviceAnalyticsStore {
  @observable deviceAnalytics: DeviceAnalyticsType[] = [];

  isAnalyticsDataLoaded = false;

  store: RootStore;

  api: typeof API;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;
  }

  async loadData(): Promise<void> {
    if (!this.isAnalyticsDataLoaded) {
      this.loadDeviceAnalytics();
      this.isAnalyticsDataLoaded = true;
    }
  }

  @action.bound
  async loadDeviceAnalytics(): Promise<void> {
    const afterDate = new Date(new Date().setDate(new Date().getDate() - 30));

    const { data } = await this.api.loadDeviceAnalytics({
      date_after: formatFullDate(afterDate),
      type: 'device',
    })();
    runInAction(() => {
      this.deviceAnalytics = data;
    });
  }
}
