import React from 'react';

import { RedAlertStatus } from 'core/constants';
import AlarmStatusChip from 'components/Chips/AlarmStatusChip';

import { AlarmStatusSelectProps } from './types';
import { StyledMenuItem, StyledSelect } from '../styles';

const AlarmStatusSelect: React.FunctionComponent<AlarmStatusSelectProps> = ({ onChange, ...props }) => (
  <StyledSelect {...props} fullWidth onChange={(event) => onChange(event.target.value as RedAlertStatus)}>
    {Object.values(RedAlertStatus).map((status) => (
      <StyledMenuItem key={status} value={status}>
        <AlarmStatusChip status={status} size="small" />
      </StyledMenuItem>
    ))}
  </StyledSelect>
);

export default AlarmStatusSelect;
