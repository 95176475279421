import { prepareIcon } from './Icon';
import note from '../../assets/icons/Note.png';
import note2x from '../../assets/icons/Note@2x.png';
import note3x from '../../assets/icons/Note@3x.png';

const Note = prepareIcon({
  alt: 'Note',
  src: note,
  srcSet: `
    ${note2x} 2x,
    ${note3x} 3x
  `,
});

export default Note;
