import { prepareIcon } from './Icon';
import amberAlertCancelled from '../../assets/icons/Amber Alert - Cancelled.png';
import amberAlertCancelled2x from '../../assets/icons/Amber Alert - Cancelled@2x.png';
import amberAlertCancelled3x from '../../assets/icons/Amber Alert - Cancelled@3x.png';

const CancelledAmberAlert = prepareIcon({
  alt: 'Cancelled Timed Alert',
  src: amberAlertCancelled,
  srcSet: `
    ${amberAlertCancelled2x} 2x,
    ${amberAlertCancelled3x} 3x
  `,
});

export default CancelledAmberAlert;
