import React from 'react';
import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { reverse } from 'named-urls';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import LinkedTasksCell from 'containers/Incidents/components/LinkedTasksCell';
import { LinkedTaskType } from 'utils/api/types';
import { formatFullDateTime } from 'utils/formatters/time';
import { useStore } from 'utils/hooks/store';
import routes from 'core/routes';

export const formsListBasicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    nativeFilter: true,
  },
  {
    field: 'location',
    headerName: 'Location',
    renderCell: defaultRenderers.location,
    valueFormatter: defaultFormatters.location,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: true,
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'reporter',
    headerName: 'Reporter',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'reporters',
  },
  {
    field: 'linkedTasks',
    headerName: 'Tasks',
    renderCell: ({ value }: { value: LinkedTaskType }) => <LinkedTasksCell linkedTasks={value} />,
    maxWidth: 100,
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'submitted',
    headerName: 'Submitted',
    renderCell: ({ value }: { value: string | null }) => (value !== null ? formatFullDateTime(value) : 'Not submitted'),
    filterOperators: defaultOperators.date(),
    nativeFilter: 'submitted',
  },
] as unknown) as ColumnDefinition[];

const FormsList: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({
  onError,
}): React.ReactElement => {
  const { routing } = useStore();
  const onRowClick: GridEventListener<'rowClick'> = (form: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.audits.forms.details, { formId: form.row.uuid }));
  };

  return (
    <Grid
      sortBy={{ field: 'submitted', sort: 'desc' }}
      basicColumns={formsListBasicColumns}
      entity="forms"
      onError={onError}
      dataURL={`${process.env.REACT_APP_WORKFLOWS_API_URL}inspections/`}
      disableColumnSelector
      onRowClick={onRowClick}
      customNoRowsText="No forms have been created yet"
    />
  );
};

export default FormsList;
