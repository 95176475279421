import React from 'react';

import Box from '@mui/material/Box';
import { circularProgressClasses } from '@mui/material/CircularProgress';

import { EnhancedTableRowCell } from 'vatix-ui/lib/components/Table/EnhancedTableRow/styles';

import Line from 'components/Icons/Line';

import { LinkedTaskType } from 'utils/api/types';

import { Container, StyledCircularProgress, TasksLabel } from './styles';

const LinkedTasksCell: React.FunctionComponent<{
  linkedTasks: LinkedTaskType;
}> = ({ linkedTasks: { completed, total } }): React.ReactElement => (
  <EnhancedTableRowCell>
    {total ? (
      <Container>
        <Box sx={{ position: 'relative' }}>
          <StyledCircularProgress value={100} sx={{ color: 'rgba(224, 224, 224, 1)' }} />
          <StyledCircularProgress
            data-test-id="styled-circular-progress"
            value={total === 0 ? 100 : (completed * 100) / total}
            sx={{
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
          />
        </Box>

        <TasksLabel>
          {completed} / {total}
        </TasksLabel>
      </Container>
    ) : (
      <Container>
        <StyledCircularProgress value={100} sx={{ color: 'rgba(224, 224, 224, 1)' }} />
        <Line style={{ fontSize: '17px', marginLeft: '8px' }} />
      </Container>
    )}
  </EnhancedTableRowCell>
);

export default LinkedTasksCell;
