import React from 'react';
import styled from 'styled-components';
import RadioBase from '@mui/material/Radio';
import RadioGroupBase from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export const RadioGroup = styled(RadioGroupBase).attrs({
  classes: {
    root: 'root',
  },
})`
  &.root {
    align-items: flex-start;
    margin-bottom: 8px;
    position: relative;
  }
  margin-top: 16px;
`;

export const Radio = styled(RadioBase).attrs(() => ({
  color: 'primary',
  disableRipple: true,
  classes: {
    root: 'root',
  },
}))`
  &.root {
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
  }
  &.Mui-checked {
    color: #0772e5;
  }
`;

export const RadioButton = styled(FormControlLabel).attrs({
  control: <Radio />,
  classes: {
    label: 'label',
  },
})`
  .label {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
`;
