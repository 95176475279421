import React from 'react';
import { RouteProps } from 'react-router';

import { reverse } from 'named-urls';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import SecureRoute from '../SecureRoute';

const PublicRoute: React.FunctionComponent<RouteProps> = (props): React.ReactElement => {
  const { session } = useStore();
  const { path, location } = props;
  const organisationId = location?.pathname.slice(18, -1) as string;
  const redirectTo =
    path === routes.publicSubmission.details.toString()
      ? reverse(routes.publicSubmission.details, { organisationId })
      : routes.dashboard.home.toString();

  const isAuthorized = redirectTo === routes.dashboard.home.toString() ? () => !session.isAuthenticated : () => true;

  return <SecureRoute isAuthorized={isAuthorized} redirectTo={redirectTo} {...props} />;
};

export default PublicRoute;
