import styled from 'styled-components';

export const SectionIncidentOverview = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 8px 8px;
  @media (max-width: 768px) {
    border-radius: 10px;
    margin: 0 16px;
  }
`;

export const SectionContainerHeader = styled.div<{ $onlyOneSection: boolean }>`
  z-index: 10;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ $onlyOneSection }) => ($onlyOneSection ? '0' : '20px 20px 20px 0')};
`;

export const SectionTitle = styled.p`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  text-transform: capitalize;
  padding: 0 20px;
`;

export const SectionDescription = styled.p`
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 0 10px 0;
  white-space: break-spaces;
`;
