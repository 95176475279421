import { AxiosResponse, CancelTokenSource } from 'axios';
import { action, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import {
  ActivityListResponse,
  ActivityResponse,
  ActivityType,
  ActivityType as VatixActivityType,
  PaginationParams,
  UserSearchResponse,
} from 'vatix-ui/lib/utils/api/types';
import DetailsActivities from 'vatix-ui/lib/utils/stores/DetailsActivities';

import RootStore from 'stores/Root';
import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { ActionEvent, CreatedActivityPayload } from 'utils/events/types';
import Logger from 'utils/logger';

export default class InternalActivities extends DetailsActivities<RootStore, typeof API> {
  incidentId: string;

  constructor(rootStore: RootStore, api: typeof API, incidentId: string) {
    super(rootStore, api);

    makeObservable(this);

    this.incidentId = incidentId;

    eventBus.register(this);
  }

  async loadActivitiesData(params: PaginationParams): Promise<AxiosResponse<ActivityListResponse>> {
    return this.api.loadIncidentReporterActivities(this.incidentId, params)();
  }

  @action.bound
  async addMessage(message: string, taggedUsers?: UserSearchResponse[]): Promise<void> {
    if (this.error || !this.activities) {
      return;
    }
    try {
      const { data } = await this.api.addReporterActivitiesMessage(this.incidentId, message, false, taggedUsers)();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, { ...data, type: ActivityType.Message as VatixActivityType }];
        }
      });
    } catch (e) {
      Logger.error(`Invalid add message API result: Incident ID: ${this.incidentId}. Message: ${message}`, e);
    }
  }

  async doUploadFile(
    file: File,
    onUpdateProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): Promise<void> {
    try {
      const { data } = await this.api.uploadReporterActivitiesFile(
        this.incidentId,
        file,
        onUpdateProgress,
        cancelTokenSource,
        false
      )();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, (data as unknown) as ActivityResponse];
        }
      });
    } catch (e) {
      Logger.error(`Invalid add file API result: Incident ID: ${this.incidentId}.`, e);
      this.rootStore.notification.enqueueErrorSnackbar('Failed to upload file');
    }
  }

  @action.bound
  async deleteMessage(messageId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }
    const message = this.activities.find((activity) => activity.uuid === messageId);
    if (message) {
      try {
        await this.api.removeReporterIncidentMessage(this.incidentId, messageId)();
        runInAction(() => {
          if (this.activities !== undefined) {
            this.activities = this.activities.filter((activity) => activity.uuid !== messageId);
          }
        });
      } catch (e) {
        this.rootStore.notification.enqueueErrorSnackbar(
          'Unable to delete the comment at this time. Please try again later');
        Logger.error(`Invalid delete message API result: Incident ID: ${this.incidentId}. Message ID: ${messageId}`, e);
      }
    }
  }

  @action.bound
  async deleteFile(fileId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }
    const file = this.activities.find((activity) => activity.uuid === fileId);
    if (file) {
      try {
        await this.api.removeReporterIncidentFile(this.incidentId, fileId)();
        runInAction(() => {
          this.removeActivity(file.uuid);
        });
      } catch (e) {
        this.rootStore.notification.enqueueErrorSnackbar(
          'Unable to delete the file at this time. Please try again later');
        Logger.error(`Invalid delete file API result: Incident ID: ${this.incidentId}. File ID: ${fileId}`, e);
      }
    }
  }

  @subscribe(EventType.CreatedActivity)
  @action
  createdActivity({ payload }: ActionEvent<CreatedActivityPayload>): void {
    if (this.error || !this.activities || !payload.incident || payload.incident.uuid !== this.incidentId) {
      return;
    }

    this.activities = undefined;
    this.loadActivities();
  }
}
