import React from 'react';
import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import routes from 'core/routes';

import { EntityModules } from 'core/constants';

import { Linked } from './styles';

const GridLookupRenderer: React.FunctionComponent<{
  entity: EntityModules;
  instance: {
    uuid: string;
    name: string;
  };
}> = ({ entity, instance }): React.ReactElement => {
  const history = useHistory();

  const navigateTo = (event: React.MouseEvent): void => {
    if (Object.values(EntityModules).includes(entity as EntityModules)) {
      const module = entity as EntityModules;

      const path = reverse(routes.dashboard[module].details, {
        entityId: instance.uuid || '',
      });

      history.push(path);
    }
    event.stopPropagation();
  };

  return <Linked onClick={navigateTo}>{instance.name}</Linked>;
};

export default GridLookupRenderer;
