import React from 'react';

import { useStore } from 'utils/hooks/store';

import { UserCellAvatar, UserCellName, UserCellNameContainer, UserCellSelfIndicator } from './styles';

const UserCell: React.FunctionComponent<{ name: string; uuid: string }> = ({ name, uuid }) => {
  const { session } = useStore();

  return (
    <>
      <UserCellAvatar name={name} size="small" />
      <UserCellNameContainer>
        <UserCellName id={`table-user-${name}`.replace(/ /g, '-').toLowerCase()} aria-label="user-name-cell">
          {name}
        </UserCellName>
        {session.user?.uuid === uuid ? <UserCellSelfIndicator>(You)</UserCellSelfIndicator> : null}
      </UserCellNameContainer>
    </>
  );
};
export default UserCell;
