import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from 'utils/hooks/store';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import { QuestionType } from 'stores/TemplateDetails/types';

import { getAnswerComponent } from 'containers/FormsDetails/utils';

import { QuestionHeader, QuestionTitle, MandatoryQuestion, AnswerRow } from './styles';

import { EmptyAnswerText } from '../styles';

import { QuestionRowProps } from './types';
import QuestionStats from '../../QuestionStats';

const QuestionRow: React.FunctionComponent<QuestionRowProps> = ({
  questionId,
  showStats,
  sectionId,
  isSubmitted,
}): React.ReactElement => {
  const {
    formDetails: { details },
  } = useStore();

  const sectionID = details?.template.content.order.indexOf(sectionId) as number;
  const position = details?.questionIds.indexOf(questionId) as number;
  const questionProperties = details?.template.content.properties[sectionId].properties[questionId] as QuestionType;
  const answer = details?.answers[sectionID][questionId] as AnswerDetails;
  const isMandatory = details?.template.content.properties[sectionId].mandatory.includes(questionId);

  return (
    <>
      <QuestionHeader $sectionZero={sectionId === '0'}>
        <QuestionTitle>
          {position + 1} - {questionProperties?.description}
          {isMandatory ? <MandatoryQuestion>*</MandatoryQuestion> : null}
        </QuestionTitle>
      </QuestionHeader>
      <AnswerRow>
        <div style={{ width: !isSubmitted ? '50%' : 'inherit' }}>
          {questionProperties ? (
            getAnswerComponent(
              questionProperties.workflowsType || questionProperties.type,
              answer,
              questionProperties,
              isSubmitted
            )
          ) : (
            <EmptyAnswerText>Not answered</EmptyAnswerText>
          )}
        </div>

        {showStats && <QuestionStats questionId={questionId} sectionId={sectionId} />}
      </AnswerRow>
    </>
  );
};

export default observer(QuestionRow);
