import React, { useState } from 'react';
import { OverlayView, Marker } from '@react-google-maps/api';

import Tooltip from '@mui/material/Tooltip';

import { useStore } from 'utils/hooks/store';
import { AlarmDetailsResponse, DeviceType } from 'utils/api/types';
import { formatDateForDisplay } from 'utils/formatters/time';
import RedAlertIcon from 'components/Icons/RedAlarmIcon';
import UserIcon from 'components/Icons/UserIcon';

import { AlertStatus as AlertStatusEnum } from 'core/constants';

import { CircleMarkerProps } from '../../types';
import { BreadCrumbsContainer, BreadCrumbsContainerHeader, BreadCrumbsContainerText, RoundBox } from './styles';
import { getPixelPositionOffset, formatCoordinates, getColorByAlertStatusType } from '../../utils';

const CircleMarker: React.FC<CircleMarkerProps> = ({ el, lastElement, checkIfNextAlertIsAlsoRed: checkNextAlert }) => {
  const { alarmDetails } = useStore();
  const details = alarmDetails.details as AlarmDetailsResponse;

  const [open, setOpen] = useState(false);
  const handleOpen = (): void => {
    setOpen((prevState) => !prevState);
  };

  const formattedLat = formatCoordinates(el.lat);
  const formattedLng = formatCoordinates(el.lng);
  const elementPosition = { lat: Number(el.lat), lng: Number(el.lng) };

  const renderMarker = (): React.ReactNode => {
    switch (true) {
      case checkNextAlert:
        return (
          <Marker
            key={el.uuid}
            position={elementPosition}
            icon={{
              strokeColor: getColorByAlertStatusType(el.alertsStatus),
              strokeOpacity: 1.0,
              strokeWeight: 2,
              fillColor: '#fff',
              fillOpacity: 1.0,
              path: google.maps.SymbolPath.CIRCLE,
              scale: 6,
              anchor: new google.maps.Point(0, 0),
            }}
            onMouseOver={handleOpen}
            onMouseOut={handleOpen}
          />
        );
      case lastElement && el.alertsStatus !== AlertStatusEnum.RedAlert:
        return (
          <RoundBox $roundColor="#fff" $border="3px solid #0772E5" onMouseOver={handleOpen} onMouseOut={handleOpen}>
            <UserIcon />
          </RoundBox>
        );
      case lastElement && el.alertsStatus === AlertStatusEnum.RedAlert:
      case !lastElement && el.alertsStatus === AlertStatusEnum.RedAlert:
        return (
          <RoundBox $zIndex={10} $roundColor="rgba(211, 47, 47, 1)" onMouseOver={handleOpen} onMouseOut={handleOpen}>
            <RedAlertIcon />
          </RoundBox>
        );
      default:
        return (
          <Marker
            key={el.uuid}
            position={elementPosition}
            icon={{
              strokeColor: getColorByAlertStatusType(el.alertsStatus),
              strokeOpacity: 1.0,
              strokeWeight: 2,
              fillColor: '#fff',
              fillOpacity: 1.0,
              path: google.maps.SymbolPath.CIRCLE,
              scale: 6,
              anchor: new google.maps.Point(0, 0),
            }}
            onMouseOver={handleOpen}
            onMouseOut={handleOpen}
          />
        );
    }
  };

  return (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={elementPosition}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <>
        <Tooltip
          slotProps={{ tooltip: { style: { backgroundColor: '#fff', padding: 0 } } }}
          open={open}
          onClose={handleOpen}
          title={
            <BreadCrumbsContainer>
              <BreadCrumbsContainerHeader>
                {details.user.name} | {formatDateForDisplay(el.occurrenceTime || details.activated)}
              </BreadCrumbsContainerHeader>
              <BreadCrumbsContainerText>
                {formattedLat}, {formattedLng}
              </BreadCrumbsContainerText>
              <BreadCrumbsContainerText>
                Device:{' '}
                <>
                  {details.device?.deviceType === DeviceType.SharedDevice
                    ? `${details.device?.type?.brand} ${details.device.type?.model}`
                    : details.device?.phoneNumber}{' '}
                  {details.device?.customId}
                </>
              </BreadCrumbsContainerText>
            </BreadCrumbsContainer>
          }
        >
          <p />
        </Tooltip>
        {renderMarker()}
      </>
    </OverlayView>
  );
};

export default CircleMarker;
