import React, { useEffect } from 'react';
import { Button } from 'vatix-ui/lib/components/Button';
import { Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { reverse } from 'named-urls';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';
import routes from 'core/routes';

import { StyledMenuItem } from 'core/styles';

const NewProfileButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { routing, licenses, notification } = useStore();

  useEffect(() => {
    licenses.loadData();
  }, [licenses]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const createNewARP = (type: string): void => {
    if (type === 'new-arc' && licenses.details && licenses.details.protectorMonitoringVatix.maxSeats === 0) {
      notification.enqueueInfoSnackbar('Your organisation does not have licences for Vatix 24/7 Monitoring');
    } else if (type === 'new-self' && licenses.details && licenses.details.protectorMonitoringSelf.maxSeats === 0) {
      notification.enqueueInfoSnackbar('Your organisation does not have licences for Self-Monitoring');
    } else {
      routing.push(reverse(routes.dashboard.alarms.escalationProfiles.details, { escalationProfileId: type }));
    }
  };

  return (
    <>
      <Button onClick={handleClick} variant="contained" size="large">
        New Profile
        {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>

      <Popover
        id="popover-add_filter"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledMenuItem onClick={() => createNewARP('new-arc')}>Vatix 24/7 Monitoring</StyledMenuItem>
        <StyledMenuItem onClick={() => createNewARP('new-self')} data-testid="self-btn">
          Self-Monitoring
        </StyledMenuItem>
      </Popover>
    </>
  );
};

export default observer(NewProfileButton);
