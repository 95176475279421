import * as React from 'react';

function AlertFilled(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
    </svg> 
  );
}

export default AlertFilled;
