/* eslint-disable max-len */
import React from 'react';

export function ListIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73845)">
        <path
          d="M2.64 0C1.17 0 0 1.2 0 2.64C0 4.08 1.17 5.25 2.64 5.25C4.11 5.25 5.25 4.08 5.25 2.64C5.25 1.2 4.08 0 2.64 0ZM2.64 3.75C2.01 3.75 1.5 3.27 1.5 2.64C1.5 2.01 2.01 1.5 2.64 1.5C3.27 1.5 3.75 2.01 3.75 2.64C3.75 3.27 3.24 3.75 2.64 3.75Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M2.64 6.39001C1.17 6.39001 0 7.56001 0 9.00001C0 10.44 1.17 11.64 2.64 11.64C4.11 11.64 5.25 10.47 5.25 9.00001C5.25 7.53001 4.08 6.39001 2.64 6.39001ZM2.64 10.14C2.01 10.14 1.5 9.63001 1.5 9.00001C1.5 8.37001 2.01 7.89001 2.64 7.89001C3.27 7.89001 3.75 8.40001 3.75 9.00001C3.75 9.63001 3.24 10.14 2.64 10.14Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M2.64 12.75C1.17 12.75 0 13.95 0 15.39C0 16.83 1.17 18 2.64 18C4.11 18 5.25 16.83 5.25 15.39C5.25 13.95 4.08 12.75 2.64 12.75ZM2.64 16.5C2.01 16.5 1.5 16.02 1.5 15.39C1.5 14.76 2.01 14.25 2.64 14.25C3.27 14.25 3.75 14.76 3.75 15.39C3.75 16.02 3.24 16.5 2.64 16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.25 3.39001H7.5C7.08 3.39001 6.75 3.06001 6.75 2.64001C6.75 2.22001 7.08 1.89001 7.5 1.89001H17.25C17.67 1.89001 18 2.22001 18 2.64001C18 3.06001 17.67 3.39001 17.25 3.39001Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.25 9.75H7.5C7.08 9.75 6.75 9.42 6.75 9C6.75 8.58 7.08 8.25 7.5 8.25H17.25C17.67 8.25 18 8.58 18 9C18 9.42 17.67 9.75 17.25 9.75Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.25 16.14H7.5C7.08 16.14 6.75 15.81 6.75 15.39C6.75 14.97 7.08 14.64 7.5 14.64H17.25C17.67 14.64 18 14.97 18 15.39C18 15.81 17.67 16.14 17.25 16.14Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73845">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveListIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73428)">
        <path
          d="M5.25 2.64C5.25 4.08 4.08 5.25 2.64 5.25C1.2 5.25 0 4.08 0 2.64C0 1.2 1.17 0 2.64 0C4.11 0 5.25 1.2 5.25 2.64Z"
          fill="white"
        />
        <path
          d="M5.25 9.00001C5.25 10.47 4.08 11.64 2.64 11.64C1.2 11.64 0 10.47 0 9.00001C0 7.53001 1.17 6.39001 2.64 6.39001C4.11 6.39001 5.25 7.56001 5.25 9.00001Z"
          fill="white"
        />
        <path
          d="M5.25 15.39C5.25 16.83 4.08 18 2.64 18C1.2 18 0 16.83 0 15.39C0 13.95 1.17 12.75 2.64 12.75C4.11 12.75 5.25 13.95 5.25 15.39Z"
          fill="white"
        />
        <path
          d="M17.25 3.39001H7.5C7.08 3.39001 6.75 3.06001 6.75 2.64001C6.75 2.22001 7.08 1.89001 7.5 1.89001H17.25C17.67 1.89001 18 2.22001 18 2.64001C18 3.06001 17.67 3.39001 17.25 3.39001Z"
          fill="white"
        />
        <path
          d="M17.25 9.75H7.5C7.08 9.75 6.75 9.42 6.75 9C6.75 8.58 7.08 8.25 7.5 8.25H17.25C17.67 8.25 18 8.58 18 9C18 9.42 17.67 9.75 17.25 9.75Z"
          fill="white"
        />
        <path
          d="M17.25 16.14H7.5C7.08 16.14 6.75 15.81 6.75 15.39C6.75 14.97 7.08 14.64 7.5 14.64H17.25C17.67 14.64 18 14.97 18 15.39C18 15.81 17.67 16.14 17.25 16.14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73428">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
