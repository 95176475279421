import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { Button } from 'vatix-ui/lib/components/Button';

import { AxiosError } from 'axios';

import { ColumnActions } from 'components/Grid/types';

import CustomModal from 'components/CustomModal';
import { ModalText } from 'containers/TaskDetails/styles';

import { EntityFieldType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import useFieldManagerParams from 'utils/hooks/useFieldManagerParams';

import { MenuItem } from './styles';

const ActionCell: React.FunctionComponent<{ rowData: EntityFieldType; actions: ColumnActions }> = ({
  rowData,
  actions,
}) => {
  const {
    entityFields: { deleteField, setActiveField },
    notification,
  } = useStore();

  const moduleName = useFieldManagerParams();

  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const handleCloseModal = (): void => {
    if (!deleting) {
      setDeletionModalOpen(false);
      setAnchorEl(null);
    }
  };

  const handleEditClick = (): void => {
    setActiveField(rowData);
    setAnchorEl(null);
  };

  const onDelete = async (): Promise<void> => {
    setDeleting(true);
    try {
      await deleteField(moduleName, rowData.uuid);
      notification.enqueueSuccessSnackbar('Field deleted successfully');
      await actions.refreshData();
      handleCloseModal();
    } catch (e) {
      const error = e as AxiosError;
      notification.enqueueErrorSnackbar(error.response?.data[0] || 'Failed to delete field');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <GridActionsCellItem
        icon={<MoreVert />}
        label=""
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem id="edit-button" onClick={handleEditClick}>
          {rowData.editable ? 'Edit' : 'View'}
        </MenuItem>
        {rowData.removable && (
          <MenuItem id="remove-button" onClick={() => setDeletionModalOpen(true)}>
            Delete
          </MenuItem>
        )}

        <CustomModal
          openModal={deletionModalOpen}
          onClose={handleCloseModal}
          modalTitle="Delete Field"
          modalWidth="xs"
          cancelButton={
            <Button
              disabled={deleting}
              onClick={handleCloseModal}
              autoFocus
              id="cancel-button"
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          }
          submitButton={
            <Button
              onClick={onDelete}
              disabled={deleting}
              id="confirm-delete-button"
              variant="contained"
              size="large"
              color="error"
            >
              Delete
            </Button>
          }
        >
          <ModalText>Are you sure you want to delete this field?</ModalText>
          <ModalText>Once removed, you will not be able to recover this data.</ModalText>
        </CustomModal>
      </Popover>
    </>
  );
};

export default observer(ActionCell);
