import { Severity } from '@sentry/types';

export enum LogLevel {
  debug = 'debug',
  info ='info',
  warning = 'warning',
  error = 'error',
  critical = 'critical',
}

export const LogLevelWeight: {[k: string]: number} = {
  [LogLevel.debug]: 10,
  [LogLevel.info]: 20,
  [LogLevel.warning]: 30,
  [LogLevel.error]: 40,
  [LogLevel.critical]: 50,
};

export const ConsoleLogLevel = {
  [LogLevel.debug]: 'debug',
  [LogLevel.info]: 'info',
  [LogLevel.warning]: 'warn',
  [LogLevel.error]: 'error',
  [LogLevel.critical]: 'error',
};

export const SentryLogLevel = {
  [LogLevel.debug]: Severity.Debug,
  [LogLevel.info]: Severity.Info,
  [LogLevel.warning]: Severity.Warning,
  [LogLevel.error]: Severity.Error,
  [LogLevel.critical]: Severity.Fatal,
};
