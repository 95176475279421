import posed from 'react-pose';

export const PopUpRedAlertAnimated = posed.div({
  preEnter: {
    y: 100,
  },
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});
