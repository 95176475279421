export enum ActivityType {
  Assignment = 'assignment',
  Message = 'message',
  File = 'file',
  State = 'state',
  Status = 'status',

  // These are only affected by the frontend.
  FileUploadProgress = 'file_upload_progress',
}
