import { reverse } from 'named-urls';

import { EntityModules } from 'core/constants';
import routes from 'core/routes';
import { LinksType } from 'utils/api/types';
import { getEntityTranslation } from 'stores/EntityDetails/utils';

export const useEntityDisplayValue = (
  module: EntityModules,
  links: LinksType[]
): { path: string; entityLink?: LinksType; singular: string; plural: string } => {
  const { singular, plural } = getEntityTranslation[module];
  const entityLink = links.find((link) => link.entity === module);

  const path = reverse(routes.dashboard[module].details, {
    entityId: entityLink?.instance.uuid || '',
  });

  return { path, entityLink, singular, plural };
};
