import styled from 'styled-components';

export const Value = styled.p`
  text-align: left;
  margin: 0;
  opacity: 1;
  white-space: pre-line;
  word-break: break-word;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const BackgroundInformationSections = styled.div`
  position: relative;
  overflow-y: scroll;

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
`;
