import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { IncidentLocationChangeProps } from './types';

const IncidentLocationChange: React.FunctionComponent<IncidentLocationChangeProps> = ({ activity, sessionUser }) => {
  if (!activity.previousLocation || !activity.location) {
    return null;
  }

  return (
    <EventItem activity={activity} sessionUser={sessionUser}>
      {`changed the incident location from "${activity.previousLocation.address}" to "${activity.location.address}"`}
    </EventItem>
  );
};

export default observer(IncidentLocationChange);
