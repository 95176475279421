import styled from 'styled-components';

import { MenuItem } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
