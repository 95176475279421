/* eslint-disable max-len */

import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function Location(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M12.5 2C8.63 2 5.5 5.13 5.5 9C5.5 14.25 12.5 22 12.5 22C12.5 22 19.5 14.25 19.5 9C19.5 5.13 16.37 2 12.5 2ZM12.5 11.5C11.12 11.5 10 10.38 10 9C10 7.62 11.12 6.5 12.5 6.5C13.88 6.5 15 7.62 15 9C15 10.38 13.88 11.5 12.5 11.5Z"
        fill="#0772E5"
      />
    </SvgIcon>
  );
}

export default Location;
