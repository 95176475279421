import ConsoleHandler from './Console';
import { HandlerType } from './constants';
import SentryHandler from './Sentry';
import { Handler } from './types';

export const handlerFactory = (type: string | undefined): Handler => {
  switch (type) {
    case HandlerType.console:
      return new ConsoleHandler();
    case HandlerType.sentry:
      // in case somebody is blocking Sentry
      if (window.Sentry !== undefined) {
        return new SentryHandler();
      }
      return new ConsoleHandler();
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown logger handler. ${type} is not valid type. Default to console.`);
      return new ConsoleHandler();
  }
};
