import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from 'utils/hooks/store';

import { UserRole } from 'core/constants';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import { StyledTabs, StyledTab, StyledLock, TabsContainer } from './styles';
import PersonalInformation from '../PersonalInformation';
import ProductAccess from '../ProductAccess';
import MedicalInformation from '../MedicalInformation';

export const UserBackgroundInformation: React.FunctionComponent = () => {
  const { session, userDetails } = useStore();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <TabsContainer>
      <>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Personal Information" id="personal-information-tab" value={0} />
          {session.user?.role !== UserRole.User &&
            !(session.user?.uuid === userDetails.details?.uuid && session.user?.role === UserRole.Manager) && (
              <StyledTab label="Product Access" id="product-access-tab" value={1} />
            )}
          <StyledTab
            disabled={session.user?.uuid !== userDetails.details?.uuid}
            label="Medical Information"
            icon={session.user?.uuid !== userDetails.details?.uuid ? <StyledLock /> : undefined}
            iconPosition="end"
            id="medical-information-tab"
            value={2}
          />
        </StyledTabs>

        <TabPanel
          value={value}
          index={0}
          customStyle={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <PersonalInformation />
        </TabPanel>
        {session.user?.role !== UserRole.User && (
          <TabPanel
            value={value}
            index={1}
            customStyle={{
              backgroundColor: '#fff',
              borderRadius: '8px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <ProductAccess />
          </TabPanel>
        )}
        {session.user?.uuid === userDetails.details?.uuid && (
          <TabPanel
            value={value}
            index={2}
            customStyle={{
              backgroundColor: '#fff',
              borderRadius: '8px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <MedicalInformation />
          </TabPanel>
        )}
      </>
    </TabsContainer>
  );
};

export default observer(UserBackgroundInformation);
