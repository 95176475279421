import React from 'react';
import { observer } from 'mobx-react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { EntitiesStores } from '../types';

import { BackgroundInformationSections } from '../EntityDetailsTab/styles';
import IncidentRelatedToEntities from './components/IncidentRelatedToEntities';
import TasksRelatedToEntities from './components/TasksRelatedToEntities';
import AuditsRelatedToEntities from './components/AuditsRelatedToEntities';

type EntityRelatedTabProps = {
  onError: (error: VatixError) => void;
  store: EntitiesStores;
};

const EntityRelatedTab: React.FunctionComponent<EntityRelatedTabProps> = ({ onError, store }) => (
  <BackgroundInformationSections>
    <IncidentRelatedToEntities onError={onError} store={store} />
    <TasksRelatedToEntities onError={onError} store={store} />
    <AuditsRelatedToEntities onError={onError} store={store} />
  </BackgroundInformationSections>
);

export default observer(EntityRelatedTab);
