import { action, makeObservable, observable } from 'mobx';

import { eventBus, subscribe } from 'mobx-event-bus2';

import RootStore from 'stores/Root/Root';
import API from 'utils/api';
import { HomeResponse } from 'utils/api/types';
import { EventType } from 'utils/events/constants';

export default class HomeStore {
  store: RootStore;

  api: typeof API;

  @observable isLoading = false;

  @observable content?: HomeResponse;

  @observable hasError = false;

  constructor(rootStore: RootStore, api: typeof API) {
    this.store = rootStore;
    this.api = api;

    makeObservable(this);
    eventBus.register(this);
  }

  @action.bound
  async loadHomeContent(): Promise<void> {
    if (!this.isLoading) {
      this.isLoading = true;
      this.hasError = false;
      try {
        const { data } = await this.api.loadHomeContent()();
        this.content = data;
      } catch (error) {
        this.hasError = true;
        this.store.notification.enqueueErrorSnackbar(`Could not load dashboard details. Please try again later`);
      } finally {
        this.isLoading = false;
      }
    }
  }

  @action.bound
  @subscribe(EventType.DeletedTask)
  @subscribe(EventType.CreatedTask)
  reset(): void {
    this.content = undefined;
    this.hasError = false;
  }
}
