import React from 'react';

import { FormControlLabel } from '@mui/material';

import { StyleMUISwitch, StyledTooltip } from './styles';

const MobileToggle: React.FC<{ showSafety: boolean; toggleShowSafety: () => void; disabled: boolean }> = ({
  showSafety,
  toggleShowSafety,
  disabled,
}) => {
  const onClick = (event: React.SyntheticEvent): void => {
    toggleShowSafety();
    event.stopPropagation();
  };

  return (
    <StyledTooltip
      placement="bottom"
      title={
        disabled
          ? 'Phone number required to enable safety tab. Add this in the user profile in the Admin module.'
          : `${showSafety ? 'Disable' : 'Enable'} safety tab`
      }
    >
      <span>
        <FormControlLabel
          control={
            <StyleMUISwitch
              aria-label="Mobile app Safety Tab switch"
              checked={showSafety}
              disabled={disabled}
              onClick={onClick}
            />
          }
          label="Mobile App"
        />
      </span>
    </StyledTooltip>
  );
};

export default MobileToggle;
