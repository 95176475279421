import React from 'react';

import EmptyInbox from 'components/Icons/EmptyInbox';

import { EmptyContainer } from './styles';

const EmptyInboxContainer: React.FunctionComponent = (): React.ReactElement => (
  <EmptyContainer>
    <EmptyInbox />
    <h1>Your inbox is empty</h1>
    <p>You have no notifications</p>
  </EmptyContainer>
);

export default EmptyInboxContainer;
