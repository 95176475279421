import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { IncidentFieldChangeProps } from './types';

const IncidentFieldChange: React.FunctionComponent<IncidentFieldChangeProps> = ({ activity, sessionUser }) => (  
  <EventItem activity={activity} sessionUser={sessionUser}>
    {activity.next && activity.previous && (
        `updated the value of "${activity.fieldDescription}" from "${activity.previous}" to "${activity.next}"`)}
    {activity.next && !activity.previous && `updated the value of "${activity.fieldDescription}" to "${activity.next}"`}
    {!activity.next && activity.previous && `removed "${activity.previous}" from "${activity.fieldDescription}"`}
  </EventItem>
);

export default observer(IncidentFieldChange);
