import { MenuItem as MuiMenuItem, Popper, Tabs, Tab } from '@mui/material';
import styled from 'styled-components';

import VatixTheme from 'theme/vatix';

export const Wrapper = styled.div`
  min-height: 100vh;
`;

export const TabsContainer = styled.div`
  display: flex;
  padding: 10px 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  overflow-y: auto;
`;

export const BackgroundInformationContainer = styled.div`
  margin: 40px 560px 40px 20px;
  @media (max-width: 1600px) {
    margin-right: 510px;
  }
  @media (max-width: 1400px) {
    margin-right: 410px;
  }
  @media (max-width: 1200px) {
    margin-right: 310px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPopover = styled(Popper)`
  background-color: #fff;
  box-shadow: none;
  border: none;
  border-radius: 0.5rem;
`;

export const StyledMenuItem = styled(MuiMenuItem)`
  width: 100px;
  font-size: 14px;
  border: 1px solid ${VatixTheme.ui.chip.border};
  border-radius: 0.5rem;
  font-size: 14px;
  :hover {
    background-color: #fff;
    border-radius: 0.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    margin: 0 1rem;
  }
`;

export const StyledTabs = styled(Tabs)`
  width: 100%;
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
  padding-top: 0px;

  .MuiTabs-scroller {
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const StyledTab = styled(Tab)`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.4px;
  text-transform: capitalize;
`;
