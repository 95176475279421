import React from 'react';

import Chip from 'components/Chip';

import { IncidentStatusChipProps } from './types';

const IncidentStatusChip: React.FunctionComponent<IncidentStatusChipProps> = ({
  schema,
  label,
}): React.ReactElement => {
  const colors = (schema?.protectorSettings?.colors || {})[label as string];
  return (
    <Chip id="incident-status-chip" size="small" variant="filled" colors={colors} label={label || 'Not Answered'} />
  );
};

export default IncidentStatusChip;
