import React from 'react';
// import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { LicenseInfo } from '@mui/x-license-pro';
import mixpanel from 'mixpanel-browser';

import 'typeface-roboto';
import './index.css';
import { createRoot } from 'react-dom/client';

import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { initLogging, initAxiosInterceptors } from './utils/config';

initLogging();
initAxiosInterceptors();
dayjs.extend(advancedFormat);

LicenseInfo.setLicenseKey(
  'd1db48f94e2cc30b97ba2a48240e6ca0Tz04ODkyNyxFPTE3NDU0MDIyNDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// eslint-disable-next-line no-console
console.log(
  `%cversion 🚀️︎🌕: ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`,
  'color:#0772E5; font-size: 28px'
);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
  debug: Boolean(Number(process.env.REACT_APP_ENABLE_MIXPANEL_LOGGING)),
  track_pageview: false, // manual page view tracking by listening to react-router-dom history
  persistence: 'localStorage',
});

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NewApp = require('./containers/App').default;
    root.render(<NewApp />);
  });
}
