import styled from 'styled-components';

export const QuestionAnswer = styled.div`
  display: flex;
  p {
    margin: 0;
    margin-left: 6px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.15px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

