import styled from 'styled-components';

export const DayTitle = styled.p`
  color: #626466;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const ItemContainer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
`;

export const MainContainer = styled.div`
  font-size: 14px;
`;
