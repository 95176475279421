import React from 'react';

import { observer } from 'mobx-react';

import MonitoredDevices from 'components/MonitoredDevices';

import { useStore } from 'utils/hooks/store';

import { MonitoredDevicesCellProps } from './types';

const MonitoredDevicesCell: React.FunctionComponent<MonitoredDevicesCellProps> = ({ rowData, actions }) => {
  const { session } = useStore();
  return <MonitoredDevices rowData={rowData} session={session} actions={actions} />;
};

export default observer(MonitoredDevicesCell);
