import React, { SyntheticEvent } from 'react';
import PlusIcon from 'vatix-ui/lib/components/Icons/Plus';

import SearchPopover from 'vatix-ui/lib/components/Chip/SearchPopover';

import { observer } from 'mobx-react';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import API from 'utils/api';

import Unassign from 'containers/Manage/components/MonitoredDevicesCell/components/UnassignItem';

import { useStore } from 'utils/hooks/store';
import { MonitoringMethod } from 'core/constants';

import { UserListItemResponse } from 'utils/api/types';

import useUpdateUser from 'stores/Manage/hooks/updateUser';

import Chip from 'components/Chip';

import { AlarmResponseProfileChipProps } from './types';

import { StyledSelfMonitoringStatus, StyledVatixMonitoringStatus, ResponseProfileWrapper } from './styles';

import DropDownItem from './components/DropDownItem';

const AlarmResponseProfileChip: React.FunctionComponent<AlarmResponseProfileChipProps> = ({
  user,
  session,
  actions,
  trimmable,
}) => {
  const { notification } = useStore();
  const [updating, setUpdating] = React.useState(false);

  const [anchor, setAnchor] = React.useState<(EventTarget & Element) | null>(null);
  const [search, setSearch] = React.useState('');
  const updateUser = useUpdateUser();

  const { results: alarmResponseProfileSearchList, searchQuery, loading } = useSearch(
    API.searchAlarmResponseProfiles,
    Boolean(anchor)
  );

  const chipClick = (event: SyntheticEvent): void => {
    setAnchor(event.currentTarget);
    event.stopPropagation();
  };

  const close = async (): Promise<void> => {
    setAnchor(null);
    setSearch('');
    await searchQuery('');
  };

  const updateAlarmUserProfile = async (item: string | null): Promise<void> => {
    if (!item && (user as UserListItemResponse).devices.length) {
      notification.enqueueErrorSnackbar('Alarm Response Profile is required when device is assigned');
      close();
    } else if (!item && user.showSafety) {
      notification.enqueueErrorSnackbar('Alarm Response Profile is required when the mobile application is enabled');
      close();
    } else {
      setUpdating(true);
      await updateUser(
        user.uuid,
        { alarmResponseProfile: item },
        () => {
          setUpdating(false);
          close();
        },
        async () => {
          if (session.user?.appCues) {
            window.Appcues.track('ARP Assigned');
          }
          if (actions) {
            await actions.refreshData();
          }
        }
      );
    }
  };

  const alarmResponseProfiles = alarmResponseProfileSearchList.filter((profile) =>
    profile.name.toLowerCase().includes(search.trim().toLowerCase())
  );

  if (updating) {
    return <ResponseProfileWrapper>Updating Alarm Response Profile...</ResponseProfileWrapper>;
  }

  // @ts-ignore
  const alarmResponseProfile = user.alarmResponseProfile || user.alarmResponse;

  const onChange = async (s: string): Promise<void> => {
    setSearch(s);
    await searchQuery(s);
  };

  return (
    <ResponseProfileWrapper>
      {alarmResponseProfile ? (
        <Chip
          iconLeft={
            alarmResponseProfile.escalationType === MonitoringMethod.ArcMonitored ? (
              <StyledVatixMonitoringStatus />
            ) : (
              <StyledSelfMonitoringStatus />
            )
          }
          iconLeftGapWidth={6}
          id={alarmResponseProfile.escalationType}
          label={
            trimmable && alarmResponseProfile.name.length > 30
              ? `${alarmResponseProfile.name.slice(0, 30)}...`
              : alarmResponseProfile.name
          }
          onClick={chipClick}
          colors={{
            background: 'rgba(255, 255, 255, 1)',
            foreground: 'rgba(0, 0, 0, 0.87)',
            iconLeftBackground: 'rgba(2, 136, 209, 0.12)',
          }}
          variant="outlined"
          size="medium"
        />
      ) : (
        <Chip
          iconLeft={<PlusIcon />}
          label="Add profile"
          onClick={chipClick}
          colors={{
            background: 'rgba(255, 255, 255, 1)',
            foreground: 'rgba(0, 0, 0, 0.87)',
            iconLeftBackground: '#EEE',
          }}
          variant="outlined"
          borderStyle="dashed"
          size="medium"
        />
      )}
      <SearchPopover
        ListHeader={
          alarmResponseProfile
            ? () => (
                <Unassign
                  onClick={async () => {
                    await updateAlarmUserProfile(null);
                  }}
                />
              )
            : undefined
        }
        ListItem={DropDownItem}
        handleClose={close}
        handleUpdate={async (item) => {
          await updateAlarmUserProfile(item.uuid);
        }}
        listData={alarmResponseProfiles}
        search={{
          onChange,
          placeholder: 'search here',
          value: search,
        }}
        anchorEl={anchor as HTMLAnchorElement}
        loading={loading}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </ResponseProfileWrapper>
  );
};

export default observer(AlarmResponseProfileChip);
