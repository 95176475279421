import { prepareIcon } from './Icon';
import batteryEmpty from '../../assets/icons/Battery - Empty.png';
import batteryEmpty2x from '../../assets/icons/Battery - Empty@2x.png';
import batteryEmpty3x from '../../assets/icons/Battery - Empty@3x.png';

const BatteryEmpty = prepareIcon({
  alt: 'Battery Low',
  src: batteryEmpty,
  srcSet: `
    ${batteryEmpty2x} 2x,
    ${batteryEmpty3x} 3x
  `,
});

export default BatteryEmpty;
