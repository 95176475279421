import { UserRole } from 'core/constants';
import SessionUser from 'stores/Session/SessionUser';

import { CustomIncidentType } from './api/types';

export function userPermissionForIncident(customIncident: CustomIncidentType, user: SessionUser): boolean {
  const isCreator = customIncident && (customIncident.creator ? user?.uuid === customIncident.creator.uuid : false);

  const isOwner = customIncident && (customIncident.owner ? user?.uuid === customIncident.owner.uuid : false);

  const permissionForNormalUser =
    customIncident &&
    (user?.role === UserRole.User || user?.role === UserRole.AccountManager) &&
    (isCreator || isOwner);

  return permissionForNormalUser;
}
