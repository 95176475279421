/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Delete = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <rect fill="none" opacity="0" width="24" height="24" />
    <path
      fill="#6D7883"
      d="M1193.02,5593.436l-4.95-4.95-4.949,4.95a1,1,0,0,1-1.415-1.415l4.949-4.949-4.949-4.949a1,1,0,0,1,1.415-1.415l4.949,4.949,4.949-4.949a1,1,0,0,1,1.415,1.414l-4.95,4.95,4.95,4.95a1,1,0,0,1-1.415,1.415Z"
      transform="translate(-1176.071 -5575.071)"
    />
  </SvgIcon>
);

export default Delete;
