import React from 'react';

import dayjs from 'dayjs';

import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';

import { Container } from './styles';
import { PickerProps } from '../types';

export const dateRangePickerValue = (value: [number, number]): string =>
  `${dayjs((value[0] as unknown) as string).format('DD/MM/YYYY')} - ${dayjs((value[1] as unknown) as string).format(
    'DD/MM/YYYY'
  )}`;

export const DatePickerRange: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => {
  let [from, to] = (item.value as [string, string]) || [null, null];
  return (
    <Container>
      <p style={{ margin: 0 }}>from</p>
      <CustomDatePicker
        description=""
        {...props}
        onChange={(newValue) => {
          if (newValue !== 'Invalid date') {
            applyValue({ ...item, value: [newValue as string, to] });
            from = newValue as string;
          }
        }}
        value={from}
      />
      <p style={{ margin: 0 }}>to</p>
      <CustomDatePicker
        description=""
        {...props}
        onChange={(newValue) => {
          if (newValue !== 'Invalid date') {
            applyValue({ ...item, value: [from, newValue as string] });
            to = newValue as string;
          }
        }}
        value={to}
      />
    </Container>
  );
};
