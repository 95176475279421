/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LoginError = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <path
      d="M10.083 14.25H11.9163V16.0834H10.083V14.25ZM10.083 6.91671H11.9163V12.4167H10.083V6.91671ZM10.9905 2.33337C5.93051 2.33337 1.83301 6.44004 1.83301 11.5C1.83301 16.56 5.93051 20.6667 10.9905 20.6667C16.0597 20.6667 20.1663 16.56 20.1663 11.5C20.1663 6.44004 16.0597 2.33337 10.9905 2.33337ZM10.9997 18.8334C6.94801 18.8334 3.66634 15.5517 3.66634 11.5C3.66634 7.44837 6.94801 4.16671 10.9997 4.16671C15.0513 4.16671 18.333 7.44837 18.333 11.5C18.333 15.5517 15.0513 18.8334 10.9997 18.8334Z"
      fill="#EF5350"
    />
  </SvgIcon>
);

export default LoginError;
