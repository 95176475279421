import styled from 'styled-components';

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SummaryCounter = styled.p`
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
  margin-right: 8px;
`;

export const ScoreCounter = styled.div`
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 3px 4px;
  align-items: center;
  height: 24px;
  width: max-content;
  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
    margin: 0;
    padding: 0px 6px;
  }
`;
