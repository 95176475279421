import React from 'react';
import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';
import { observer } from 'mobx-react';

import { IncidentStatus } from 'utils/api/types';
import { useStore } from 'utils/hooks/store';
import IncidentActivitiesStore from 'stores/IncidentDetails/IncidentActivities';
import API from 'utils/api';

const IncidentActivitiesFooter: React.FunctionComponent = (): React.ReactElement | null => {
  const { incidentDetails } = useStore();
  const activities = incidentDetails.activities as IncidentActivitiesStore;

  if (incidentDetails.details?.status.value === IncidentStatus.Archived) {
    return null;
  }

  return (
    <AddCommentActivities
      placeholder="Add a comment or drag and drop files"
      uploadFile={activities.uploadFile}
      usersSearch={API.searchUsers}
      sendMessage={activities.addMessage}
    />
  );
};

export default observer(IncidentActivitiesFooter);
