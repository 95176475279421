/* eslint-disable max-len */
import FormHelperText from '@mui/material/FormHelperText';
import Error from '@mui/icons-material/Error';
import styled from 'styled-components';

export const ErrorIcon = styled(Error)`
  font-size: 1.4rem;
  margin-right: 4px;
`;

export const ErrorText = styled(FormHelperText)`
  color: #ff0000;
  font-size: 1rem;
  margin-top: 4px;
  position: absolute;
`;

export const InputWrapper = styled.div`
  margin-bottom: 8px label {
    display: block;
    position: absolute;
    color: #d3d6d9;
    font-weight: bold;
    font-size: 1rem;
    line-height: 2rem;
  }

  &.disabled label {
    opacity: 0.38;
  }

  &:focus-within label {
    color: #0772e5;
  }
`;

export const InputFormControl = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
`;

export const InputErrorIcon = styled(ErrorIcon)`
  position: absolute;
  right: 0;
  bottom: 1.2rem;
`;

export const BaseFieldWrapper = styled.div<{ error: boolean }>`
  background-color: #fff;
  margin-top: 8px;
  position: relative;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid #d3d6d9;
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: ${(props) => (props.error ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #d3d6d9;
    border-bottom-color: ${(props) => (props.error ? '#FF0000' : '#223B65')};
    border-bottom-width: ${(props) => (props.error ? '3px' : '2px')};
    pointer-events: none;
    z-index: 2;
  }

  &:focus-within::after {
    border-bottom-width: 3px;
    border-bottom-color: ${(props) => (props.error ? '#FF0000' : '#223B65')};
    transform: scaleX(1);
  }

  &:hover::before {
    border-bottom: 2px solid#fff;
  }
`;
