import { eventBus } from 'mobx-event-bus2';

import { ActiveUserResponse } from 'utils/api/types';

import ActivityMapStore from './ActivityMap';
import ActivityStatusMixin from '../../utils/stores/ActivityStatusMixin';

export default class ActiveUser extends ActivityStatusMixin {
  activityMap: ActivityMapStore;

  position: { lng: number; lat: number };

  locationId: string;

  constructor(activityMap: ActivityMapStore, data: ActiveUserResponse) {
    super(data);

    this.activityMap = activityMap;

    this.locationId = data.location.uuid;

    this.position = {
      lng: Number(data.location.lng),
      lat: Number(data.location.lat),
    };

    eventBus.register(this);
  }
}
