import React from 'react';
import { inject } from 'mobx-react';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { FullHeightWrapper } from 'vatix-ui/lib/components/Container';

import { SessionState, InjectedSessionProps } from './types';
import API from '../../utils/api';
import { isUnauthorized } from '../../utils/api/errors';
import Logger from '../../utils/logger';

@inject('session')
export default class Session extends React.Component<{ children?: React.ReactNode }, SessionState> {
  state: SessionState = {
    isVerified: false,
  };

  componentDidMount(): void {
    this.syncUser();
  }

  get injected(): InjectedSessionProps {
    return this.props as InjectedSessionProps;
  }

  syncUser = async (): Promise<void> => {
    const { session } = this.injected;

    try {
      const response = await API.profile()();
      session.login(response.data);
    } catch (e) {
      // @ts-ignore
      if (!isUnauthorized(e)) {
        Logger.error('Invalid profile API response', e);
      }
    } finally {
      this.setState({
        isVerified: true,
      });
    }
  };

  render(): React.ReactNode {
    const { children } = this.props;
    const { isVerified } = this.state;

    if (isVerified) {
      return children;
    }
    return (
      <FullHeightWrapper>
        <CenteredCircularProgress />
      </FullHeightWrapper>
    );
  }
}
