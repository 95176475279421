import { Button } from '@mui/material';
import styled from 'styled-components';

export const BaseInfoText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
`;

export const UploadContainer = styled.div<{ $dragActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${({ $dragActive }) => ($dragActive ? 'rgba(7, 114, 229, 0.04)' : '')};
  padding: 30px 0px;
  border-radius: 8px;
  border: ${({ $dragActive }) => ($dragActive ? '2px dashed #0772e5' : '2px dashed #CBD0DC')};
  margin-top: 16px;
`;

export const FileContainer = styled.div`
  margin-top: 16px;
  display: flex;
  padding: 16px;
  align-items: ;
  border-radius: 8px;
  border: 1px solid #cbd0dc;
  background: #f9f9fb;
  z-index: 10;
  flex-direction: column;
  width: 100%;
`;

export const FileName = styled.p`
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const RemoveButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #cbd0dc;
  background: #fff;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
`;

export const InfoMessage = styled.p`
  color: #212121;
  text-align: center;
  font-size: 16px;
  margin: 0;
  margin-top: 16px;
`;

export const SecondaryMessage = styled.p`
  color: #666;
  text-align: center;
  font-size: 14px;
  margin: 0;
  margin-top: 4px;
`;
