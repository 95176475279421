import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { IncidentSubjectChangeProps } from './types';

const IncidentSubjectChange: React.FunctionComponent<IncidentSubjectChangeProps> = ({ activity, sessionUser }) => {
  if ((!activity.previousSubject && activity.subject) || !activity.subject) {
    return null;
  }
  return (
    <EventItem activity={activity} sessionUser={sessionUser}>
      {`has changed the incident name from "${activity.previousSubject}" to "${activity.subject}"`}
    </EventItem>
  );
};

export default observer(IncidentSubjectChange);
