/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExpandMoreFilled = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon viewBox="0 0 24 24" width="12" height="12" {...props}>
    <path
      d="M8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6L8.29492 7.41Z"
      fill="black"
      fillOpacity="0.54"
    />
  </SvgIcon>
);

export default ExpandMoreFilled;
