import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@mui/material';

import { AnswerType, IncidentResponse, ProtectorType } from 'utils/api/types';
import { getIncidentOverviewPropertiesKeys } from 'containers/IncidentDetails/utils';

import { StyledRowTitle, SectionContainer, SectionHeader, BackgroundInformationSections } from './styles';
import Row from '../IncidentInformation/components/Row';
import CustomFieldDisplayValue from '../CustomFieldDisplayValue';

import { StyledImage } from '../CustomImage/styles';
import AdditionalComments from '../IncidentInformation/components/AdditionalComments';
import NoneEditableValuesOriginalView from './components/NoneEditableValuesOriginalView';
import { RowContainer, RowWrapper, StyledDivider } from '../IncidentInformation/styles';

const OriginalIncident: React.FunctionComponent<{
  details: IncidentResponse;
}> = ({
  details,
  details: {
    originalIncident: { schema, content },
  },
}): React.ReactElement => (
  <BackgroundInformationSections key={`Incident-information-${details.owner}`}>
    <SectionContainer>
      <Grid container spacing={0}>
        <NoneEditableValuesOriginalView details={details} />

        {schema.order.map((orderItem, index) => {
          const { protectorType, description: fieldDescription } = schema.properties[orderItem];
          if (protectorType === ProtectorType.Status) {
            return null;
          }
          if (protectorType === ProtectorType.Section) {
            const section = orderItem;
            return (
              <React.Fragment key={`section-${section}`}>
                <SectionHeader item container xs={12}>
                  <p>{schema.properties[section].title}</p>
                </SectionHeader>
                {getIncidentOverviewPropertiesKeys(schema.properties[section].order).map((key, sectionIndex) => {
                  const { protectorType: fieldProtectorType, contentEncoding, description } = (schema.properties || {})[
                    section
                  ].properties[key];
                  return (
                    <React.Fragment key={key}>
                      {fieldProtectorType === ProtectorType.Image ? (
                        <>
                          <StyledRowTitle>{description}</StyledRowTitle>
                          <StyledImage src={contentEncoding} alt={`img-${description}`} />
                        </>
                      ) : (
                        <RowWrapper key={`field-${schema.order.indexOf(key) + 1}`}>
                          <RowContainer>
                            <Row name={description}>
                              <CustomFieldDisplayValue
                                path={[key]}
                                schema={(schema.properties || {})[key]}
                                type={fieldProtectorType as ProtectorType}
                                value={(content[section] as AnswerType)[key]}
                              />
                            </Row>
                          </RowContainer>
                          {contentEncoding ? <StyledImage src={contentEncoding} alt={`img-${description}`} /> : null}
                          {sectionIndex !== schema.properties[section].order.length - 1 && <StyledDivider />}
                        </RowWrapper>
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          }
          return (
            <RowWrapper key={`field-description-${orderItem}`}>
              <RowContainer>
                <Row name={fieldDescription}>
                  <CustomFieldDisplayValue
                    type={protectorType as ProtectorType}
                    value={content[orderItem]}
                    path={[orderItem]}
                    schema={(schema.properties || {})[orderItem]}
                  />
                </Row>
              </RowContainer>
              {index !== schema.order.length - 1 && <StyledDivider />}
            </RowWrapper>
          );
        })}
      </Grid>
    </SectionContainer>
    <AdditionalComments />
  </BackgroundInformationSections>
);

export default observer(OriginalIncident);
