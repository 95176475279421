import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as RedAlertComponent } from 'assets/icons/Red Alert.svg';

const RedAlert = ({ ...props }: SvgIconProps): React.ReactElement => (
  <SvgIcon component={RedAlertComponent} viewBox="0 0 17 21" {...props} />
);

export default RedAlert;
