import Switch from '@mui/material/Switch';
import * as muiStyles from '@mui/material/styles';

// eslint-disable-next-line no-empty-pattern
export const StyleMUISwitch = muiStyles.styled(Switch)(({}) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#0772E5',
    '&:hover': {
      backgroundColor: 'rgba(7, 114, 229, 0.1)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#0772E5',
  },
  '& .MuiTypography-root': {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.16px',
    display: 'flex',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
