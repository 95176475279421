import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'vatix-ui/lib/components/Button';

export const PopUpRedAlertContainer = styled.div<{ isMostRecent: boolean }>`
  border-radius: 2px;
  width: 800px;
  margin-bottom: 10px;
  opacity: ${(props) => (props.isMostRecent ? 1 : 0.4)};
  background-color: #fff;
`;

const PopUpRedAlertText = styled.p`
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #fff;
  flex-shrink: 0;
  margin: 0;
`;
export const PopUpRedAlertUserLink = styled(Link)`
  margin: 0 16px;
  flex-grow: 1;
  color: #fff;
  text-decoration: none;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const PopUpRedAlertPhoneNumber = styled(PopUpRedAlertText)`
  min-width: 200px;
  text-align: center;

  img {
    margin-right: 10px;
  }
`;

export const PopUpRedAlertInfoRow = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  background: #d32f2f;

  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
`;

export const PopUpRedAlertAcceptButton = styled(Button)`
  margin-left: 20px;
  margin-right: 8px;
`;

export const PopUpRedAlertTimePassed = styled(PopUpRedAlertText)`
  min-width: 85px;
  text-align: right;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
`;
