import React from 'react';
import { observer } from 'mobx-react';
import InputAdornment from '@mui/material/InputAdornment';

import { useFormikContext } from 'formik';

import Mail from 'components/Icons/Mail';

import { LoginTextFieldProps, LoginFormValues } from 'containers/Login/types';

import { StyledTextField } from 'components/Input/styles';

import { TitleWrapper, InputTitle, StyledButton, InputSubtitle } from '../../styles';

const EnterEmail: React.FunctionComponent<LoginTextFieldProps> = ({ error, helperText }) => {
  const { values, handleChange, isSubmitting } = useFormikContext<LoginFormValues>();
  return (
    <>
      <TitleWrapper>
        <InputTitle>Log in to your Vatix Workspace account</InputTitle>
        <InputSubtitle>Please enter your details</InputSubtitle>
      </TitleWrapper>
      <StyledTextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Mail fill="#4BA0FD" />
            </InputAdornment>
          ),
        }}
        autoFocus
        fullWidth
        id="username"
        name="username"
        variant="outlined"
        placeholder="Enter your email"
        value={values.username}
        onChange={handleChange}
        error={error}
        helperText={helperText}
      />
      <StyledButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={error || !values.username || isSubmitting}
      >
        Next
      </StyledButton>
    </>
  );
};

export default observer(EnterEmail);
