import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 24px;
`;

export const DetailsRowContainer = styled.div`
  flex-direction: column;
  margin-top: 16px;
  flex-basis: 50%;
`;

export const TitleText = styled.h4`
  color: #101d31;
  font: 14px/16px Roboto;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 0;
  margin-bottom: 5px;
`;
