import React from 'react';

import { AmberAlertStatus, AmberAlertStatusName } from 'core/constants';

import { VatixChipProps } from 'components/Chip/types';
import Chip from 'components/Chip';

import { AlarmChipProps } from './types';

const StatusChipPropsMapping: Record<string, VatixChipProps> = {
  [AmberAlertStatus.Pending]: {
    label: AmberAlertStatusName[AmberAlertStatus.Pending],
    colors: {
      background: '#e0e0e0',
    },
  },
  [AmberAlertStatus.Finished]: {
    label: AmberAlertStatusName[AmberAlertStatus.Finished],
    colors: {
      background: '#ed6c024d',
    },
  },
  [AmberAlertStatus.Cancelled]: {
    label: AmberAlertStatusName[AmberAlertStatus.Cancelled],
    colors: {
      background: '#c0d8c1',
    },
  },
};

const AmberAlarmStatusChip: React.FunctionComponent<AlarmChipProps> = ({ status }): React.ReactElement => (
  <Chip size="small" variant="filled" {...StatusChipPropsMapping[status]} id={`alarm-status-${status}`} />
);

export default AmberAlarmStatusChip;
