import React from 'react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';
import { observer } from 'mobx-react';

import { SiteNameChangeActivityProps } from './types';

const SiteNameChangeActivity: React.FunctionComponent<SiteNameChangeActivityProps> = ({ activity, sessionUser }) => {
  if (activity.name) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-site-name-change">
        changed site name to {activity.name}
      </EventItem>
    );
  }
  if (activity.previousName && activity.name === null) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-site-name-removed">
        removed site name {activity.previousName}
      </EventItem>
    );
  }

  return <></>;
};

export default observer(SiteNameChangeActivity);
