import styled from 'styled-components';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridPagination } from '@mui/x-data-grid';

export const Container = styled.div<{ $withoutBorderRadius: boolean }>`
  background: #ffffff;
  border: 1px solid #e2e4e6;
  border-radius: ${(props) => (props.$withoutBorderRadius ? '0' : '4px')};
`;

export const GridContainer = styled.div`
  width: 100%;
`;

export const StyledDataGrid = styled(DataGridPro)`
  & .MuiDataGrid-columnHeaderTitle {
    font-weight: 500;
  }

  & .MuiDataGrid-cell:first-child,
  & .MuiDataGrid-columnHeader:first-child {
    padding-left: ${(props) => (props.checkboxSelection ? '10px' : '20px')};
  }

  & .MuiDataGrid-cell:last-child,
  & .MuiDataGrid-columnHeader:last-child {
    padding-right: 20px;
  }

  & .MuiDataGrid-cellContent {
    white-space: nowrap;
  }

  & {
    font-size: 14px;
    border-radius: 4px 4px 4px 4px;
    border: none;
    background: #ffffff;
  }

  & .MuiTablePagination-selectLabel,
  & .MuiTablePagination-displayedRows {
    font-size: 14px;
  }

  & .MuiTablePagination-select {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .MuiButton-root {
    color: #4ba0fd;
    text-transform: capitalize;
  }

  & .MuiButton-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }

  & .MuiButton-startIcon {
    color: #4ba0fd;
  }

  & .MuiSvgIcon-fontSizeMedium {
    font-size: 24px;
  }

  .MuiDataGrid-footerContainer {
    max-height: 48px;
    border-radius: 0 0 4px 4px;
  }

  --DataGrid-overlayHeight: 52px;
`;

export const StyledPagination = styled(GridPagination)`
  .MuiTablePagination-menuItem {
    font-size: 14px;
  }
`;

export const Text = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-size: 13px;
`;
