import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import { Button } from 'vatix-ui/lib/components/Button';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import FormTemplatesList from './components/FormTemplatesList/FormTemplatesList';

const FormTemplates = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const history = useHistory();

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={(onError) => <FormTemplatesList onError={onError} />}
      Header={
        <MainHeader
          title="Form Templates"
          breadcrumbs={[
            { label: 'Audits', link: routes.dashboard.audits.forms.toString() },
            { label: 'Form Templates', link: routes.dashboard.audits.templates.toString() },
          ]}
          action={
            <Button
              variant="contained"
              size="large"
              onClick={() =>
                history.push(
                  reverse(routes.dashboard.audits.templates.details, {
                    templateId: 'new',
                  })
                )
              }
            >
              Create Template
            </Button>
          }
        />
      }
    />
  );
};

export default FormTemplates;
