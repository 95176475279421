import React, { ChangeEvent } from 'react';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';

import { DEFAULT_SEARCH_DEBOUNCE } from 'core/constants';

import { DebounceInputProps, DebounceInputState } from './types';

export default class DebounceInput<InputComponentProps> extends React.Component<
  DebounceInputProps<InputComponentProps>,
  DebounceInputState
> {
  static defaultProps = {
    value: '',
    debounceTimeout: DEFAULT_SEARCH_DEBOUNCE,
    InputComponent: 'input',
  };

  constructor(props: DebounceInputProps<InputComponentProps>) {
    super(props);

    this.state = {
      value: props.value,
    };
    this.notifyUpdate = debounce(this.notifyUpdate, props.debounceTimeout);
  }

  onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    this.setState({
      value: e.target.value,
    });
    this.notifyUpdate();
  };

  notifyUpdate = (): void => {
    const { value } = this.state;
    const { onChange } = this.props;

    onChange(value);
  };

  render(): React.ReactNode {
    const { value } = this.state;
    const { InputComponent } = this.props;
    const inputProps = omit(this.props, [
      'debounceTimeout',
      'onChange',
      'value',
      'InputComponent',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ]) as any;

    return (
      <InputComponent value={value} onChange={this.onChange} {...inputProps} />
    );
  }
}
