import styled from 'styled-components';
import { Button } from 'vatix-ui/lib/components/Button';

export const QuestionAnswer = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
`;

export const AnswersContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const AnswerBtn = styled(Button)<{ $picked: boolean; $backgroundColor: string }>`
  width: 100px;
  font-size: 18px;
  font-weight: 400;
  color: ${({ $picked }) => ($picked ? 'white !important' : 'black !important')};
  background-color: ${({ $picked, $backgroundColor }) => ($picked ? $backgroundColor : 'white')};
  border: ${({ $picked, $backgroundColor }) => ($picked ? `1px solid ${$backgroundColor}` : '1px solid #E0E0E0')};
  &:hover {
    background: ${({ $picked, $backgroundColor }) => ($picked ? `${$backgroundColor} !important` : '#fff !important')};
    color: ${({ $picked }) => ($picked ? '#000' : '#fff')};
    border: ${({ $backgroundColor }) => `1px solid ${$backgroundColor}`};
    opacity: 0.8;
  }
`;
