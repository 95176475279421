import React from 'react';

import { observer } from 'mobx-react';

import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { RedAlertActivityStatusName } from 'core/constants';

import { RedAlertActivityStatus } from 'utils/stores/Activities/constants';

import { RedAlertActivityProps } from './types';

const RedAlertActivity: React.FunctionComponent<RedAlertActivityProps> = ({ activity, sessionUser }) => {
  const updatedActivity = {
    ...activity,
    creator: activity.creatorData || activity.creator,
  };
  return (
    <EventItem activity={updatedActivity} sessionUser={sessionUser} id={`activity-comment-log-${activity.status}`}>
      {RedAlertActivityStatusName[activity.status]} alert
      <br />
      {RedAlertActivityStatusName[activity.status] === RedAlertActivityStatus.Completed && activity.message}
    </EventItem>
  );
};

export default observer(RedAlertActivity);
