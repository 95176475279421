/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import { SvgIconProps } from '@mui/material';
import React from 'react';

export function IncidentIcon(props: SvgIconProps): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <g>
        <path
          d="M18.8494 16.7667H2.14943C0.849432 16.7667 0.0660982 15.3167 0.766098 14.2167L9.1161 0.783362C9.7661 -0.249972 11.2494 -0.249972 11.8828 0.783362L20.2328 14.2167C20.9161 15.3334 20.1328 16.7667 18.8494 16.7667ZM3.63277 15.1H17.3661C18.0161 15.1 18.3994 14.3834 18.0661 13.8167L11.1994 2.78336C10.8828 2.2667 10.1328 2.2667 9.8161 2.78336L2.93277 13.8334C2.58277 14.3834 2.98276 15.1167 3.63277 15.1167V15.1Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
        <path
          d="M8.83333 20C8.36667 20 8 19.6333 8 19.1666V15.9333C8 15.4666 8.36667 15.1 8.83333 15.1C9.3 15.1 9.66667 15.4666 9.66667 15.9333V19.1666C9.66667 19.6333 9.3 20 8.83333 20Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
        <path
          d="M12.1673 20C11.7007 20 11.334 19.6333 11.334 19.1666V15.9333C11.334 15.4666 11.7007 15.1 12.1673 15.1C12.634 15.1 13.0007 15.4666 13.0007 15.9333V19.1666C13.0007 19.6333 12.634 20 12.1673 20Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
        <path
          d="M10.916 6.21667H10.0827C9.85256 6.21667 9.66602 6.40322 9.66602 6.63334V10.3667C9.66602 10.5968 9.85256 10.7833 10.0827 10.7833H10.916C11.1461 10.7833 11.3327 10.5968 11.3327 10.3667V6.63334C11.3327 6.40322 11.1461 6.21667 10.916 6.21667Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
        <path
          d="M10.916 12.1167H10.0827C9.85256 12.1167 9.66602 12.3032 9.66602 12.5334V13.3667C9.66602 13.5968 9.85256 13.7834 10.0827 13.7834H10.916C11.1461 13.7834 11.3327 13.5968 11.3327 13.3667V12.5334C11.3327 12.3032 11.1461 12.1167 10.916 12.1167Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13967_156400">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveIncidentIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M20.2484 14.2179L11.8834 0.787339C11.2502 -0.262446 9.75051 -0.262446 9.1173 0.787339L0.752346 14.2179C0.0691521 15.3344 0.852325 16.7674 2.15206 16.7674H18.8486C20.1484 16.7674 20.9316 15.3344 20.2484 14.2179ZM11.5335 14.2179C11.5335 14.5012 11.3169 14.7345 11.0169 14.7345H9.98379C9.68386 14.7345 9.45057 14.5012 9.45057 14.2179V13.1681C9.45057 12.8849 9.68386 12.6516 9.98379 12.6516H11.0169C11.3169 12.6516 11.5335 12.8849 11.5335 13.1681V14.2179ZM11.5335 10.4687C11.5335 10.752 11.3169 10.9853 11.0169 10.9853H9.98379C9.68386 10.9853 9.45057 10.752 9.45057 10.4687V5.78632C9.45057 5.50304 9.68386 5.26976 9.98379 5.26976H11.0169C11.3169 5.26976 11.5335 5.50304 11.5335 5.78632V10.4687Z"
        fill="white"
      />
      <path
        d="M12.999 17.9338V19.1669C12.999 19.6168 12.6324 20 12.1658 20H8.83316C8.36659 20 8 19.6168 8 19.1669V17.9338H12.999Z"
        fill="white"
      />
    </svg>
  );
}
