/* eslint-disable max-len */
import React from 'react';

const EmptyInbox = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
    <path
      d="M61.571 10.4357C61.0091 8.74997 59.4838 7.62601 57.7177 7.62601H12.2018C10.4357 7.62601 8.91057 8.74982 8.34851 10.4357L0 36.6857H14.6903H55.3095H69.9999L61.571 10.4357Z"
      fill="#D4E1F4"
    />
    <path d="M50.8945 28.6582V19.5069H19.1055V28.6582V29.2201H50.8945V28.6582Z" fill="#4BA1FE" />
    <path
      d="M50.8946 28.899H19.1056H14.6904V37.1673H21.0321C21.0321 37.1673 23.3601 48.8072 35 48.8072C46.6398 48.8072 48.9678 37.1673 48.9678 37.1673H55.3095V28.899H50.8946Z"
      fill="#097EFF"
    />
    <path
      d="M55.3095 36.6857H48.9679C48.9679 36.6857 46.6399 48.3256 35 48.3256C23.3601 48.3256 21.0321 36.6857 21.0321 36.6857H14.6903H0V59.9655C0 61.3302 1.0436 62.3737 2.40818 62.3737H67.5917C68.9564 62.3737 69.9999 61.3301 69.9999 59.9655V36.6857H55.3095Z"
      fill="#4BA1FE"
    />
  </svg>
);

export default EmptyInbox;
