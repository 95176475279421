import * as Yup from 'yup';

export const EnterEmailSchema = Yup.object().shape({
  username: Yup.string()
    .email('Email address is not valid')
    .required('Email address is required'),
});

export const EnterCredentialsSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
});

export const Confirm2FACodeSchema = Yup.object().shape({
  verificationCode: Yup.string().required('Verification code is required'),
});

export const ConfirmVerificationCodeSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .length(6, 'Confirmation code must be exactly 6 characters')
    .required('Please provide confirmation code'),
});

export const VerifyPhoneNumberSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Please enter phone number')
    .matches(/^\+\d{3,}/, 'Please enter your phone number in full international format, e.g. +447895689563.'),
});
