import React from 'react';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import { QuestionType } from 'stores/TemplateDetails/types';

import { useStore } from 'utils/hooks/store';

import BooleanAnswer from './components/FormTab/BooleanAnswer';
import TextAnswer from './components/FormTab/TextAnswer';
import MultiChoiceAnswer from './components/FormTab/MultiChoiceAnswer';
import SignatureAnswer from './components/FormTab/SignatureAnswer';
import { EmptyAnswerText } from './components/FormTab/styles';
import DateAnswer from './components/FormTab/DateAnswer/DateAnswer';
import LocationAnswer from './components/FormTab/LocationAnswer';

export function getAnswerComponent(
  type: string,
  answer: AnswerDetails,
  questionProperties: QuestionType,
  isSubmitted: boolean
): React.ReactNode {
  const {
    formDetails: { details },
  } = useStore();

  if (!details) {
    return null;
  }

  if (isSubmitted && answer.answer === undefined) {
    return <EmptyAnswerText>Not answered</EmptyAnswerText>;
  }

  switch (type) {
    case 'boolean':
      return (
        <BooleanAnswer
          answer={answer}
          isSubmitted={isSubmitted}
          updateAnswer={details.updateAnswer}
          updating={details.updating}
        />
      );
    case 'string':
      return (
        <TextAnswer
          answer={answer}
          isSubmitted={isSubmitted}
          updateAnswer={details.updateAnswer}
          updating={details.updating}
        />
      );
    case 'date':
      return (
        <DateAnswer
          answer={answer}
          isSubmitted={isSubmitted}
          updateAnswer={details.updateAnswer}
          updating={details.updating}
        />
      );
    case 'location':
      return (
        <LocationAnswer
          answer={answer}
          isSubmitted={isSubmitted}
          updateAnswer={details.updateAnswer}
          updating={details.updating}
        />
      );
    case 'multi':
    case 'array':
      return (
        <MultiChoiceAnswer
          answer={answer}
          isSubmitted={isSubmitted}
          questionProperties={questionProperties}
          updateAnswer={details.updateAnswer}
          updating={details.updating}
        />
      );
    case 'signature':
      return (
        <SignatureAnswer
          answer={answer}
          isSubmitted={isSubmitted}
          updateAnswer={details.updateAnswer}
          updating={details.updating}
        />
      );
    case 'object':
    case 'section':
    case 'sectionZero':
      return <EmptyAnswerText>Not answered</EmptyAnswerText>;
    default:
      return <EmptyAnswerText>Not answered</EmptyAnswerText>;
  }
}
