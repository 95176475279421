import React from 'react';
import { observer } from 'mobx-react';

import Feed from 'vatix-ui/lib/components/Feed/Feed';

import { USER_TAG_BASE } from 'core/constants';

import { SessionStoreWithUser } from 'stores/Session/types';
import API from 'utils/api';
import { useStore } from 'utils/hooks/store';

import EntitiesActivities from 'stores/EntitiesActivities';
import { ActivityMapping } from 'utils/api/types';

const EntityDetailsActivities: React.FunctionComponent<{
  activities: EntitiesActivities | undefined;
  activityTypeToComponent: ActivityMapping;
}> = ({ activities, activityTypeToComponent }): React.ReactElement => {
  const { session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  if (!activities) {
    return <></>;
  }
  return (
    <Feed
      mapping={activityTypeToComponent}
      originalActivities={activities}
      usersSearch={API.searchUsers}
      onDeleteComment={activities.deleteMessage}
      onDeleteFile={activities.deleteFile}
      sessionUser={session.user}
      downloadFile={API.downloadFile}
      userTagBaseUrl={USER_TAG_BASE}
    />
  );
};

export default observer(EntityDetailsActivities);
