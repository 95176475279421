import dayjs from 'dayjs';
import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { reverse } from 'named-urls';

import routes from 'core/routes';
import { DeviceResponse, LocationResponse, PendingRedAlertResponse, UserResponse } from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import {
  ActionEvent,
  ReverseGeocodedLocationPayload,
  UpdatedProfilePayload,
  UpdatedUserPayload,
} from 'utils/events/types';
import { isNotFound } from 'utils/api/errors';

import { postMessage } from 'utils/events/broadcast';

import API from '../../utils/api';
import Logger from '../../utils/logger';
import RootStore from '../Root';

export default class PendingRedAlert {
  store: RootStore;

  api: typeof API;

  uuid: string;

  @observable user: UserResponse;

  @observable location: LocationResponse;

  activated: dayjs.Dayjs;

  @observable ignored = false;

  device: DeviceResponse | null;

  @observable isUpdating = false;

  constructor(rootStore: RootStore, api: typeof API, data: PendingRedAlertResponse) {
    this.store = rootStore;
    this.api = api;
    this.uuid = data.uuid;

    makeObservable(this);

    this.user = data.user;
    this.location = data.location;
    this.activated = dayjs(data.activated);
    this.ignored = data.ignored;
    this.device = data.device;

    eventBus.register(this);
  }

  @action.bound
  async ignore(): Promise<void> {
    try {
      this.isUpdating = true;
      await this.api.ignoreRedAlert(this.uuid)();
      runInAction(() => {
        this.ignored = true;
      });
    } catch (e) {
      Logger.error(`Invalid ignore red alert API response. Alert id: ${this.uuid}`, e);
      // @ts-ignore
      if (isNotFound(e)) {
        this.store.notification.enqueueErrorSnackbar('Alert is no longer pending. Please refresh the page.');
      } else {
        this.store.notification.enqueueErrorSnackbar('Alert cannot be ignored right now.');
      }
    } finally {
      runInAction(() => {
        this.isUpdating = false;
      });
    }
  }

  @action.bound
  async accept(): Promise<void> {
    try {
      this.isUpdating = true;
      const { data } = await this.api.acceptRedAlert(this.uuid)();
      postMessage(EventType.AcceptedRedAlert, data);
      this.store.routing.push(reverse(routes.dashboard.alarms.details, { alarmId: this.uuid }));
    } catch (e) {
      Logger.error(`Invalid accept red alert API response. Alert id: ${this.uuid}`, e);
      this.store.notification.enqueueErrorSnackbar('Alert cannot be accepted right now.');
    } finally {
      runInAction(() => {
        this.isUpdating = false;
      });
    }
  }

  @subscribe(EventType.ReverseGeocodedLocation)
  @action
  reverseGeocodedLocation({ payload }: ActionEvent<ReverseGeocodedLocationPayload>): void {
    if (payload.uuid !== this.location.uuid) {
      return;
    }

    this.location.address = payload.address;
  }

  @subscribe(EventType.UpdatedUser)
  @subscribe(EventType.UpdatedProfile)
  @action
  updatedUser({ payload }: ActionEvent<UpdatedUserPayload | UpdatedProfilePayload>): void {
    if (payload.uuid !== this.user.uuid) {
      return;
    }

    this.user.name = payload.name;
  }

  goToProfile = (): void => {
    this.store.pendingRedAlerts.toggleAlerts();

    this.store.routing.push(reverse(routes.dashboard.admin.users.details, { userId: this.user.uuid }));
  };
}
