/* eslint-disable max-len */
import React from 'react';

export function LicensesIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14515_197641)">
        <path
          d="M9.75 1.5V6.75C9.75 7.575 10.425 8.25 11.25 8.25H16.5C17.325 8.25 18 7.575 18 6.75V1.5C18 0.675 17.325 0 16.5 0H11.25C10.425 0 9.75 0.675 9.75 1.5ZM15.75 6.75H12C11.58 6.75 11.25 6.42 11.25 6V2.25C11.25 1.83 11.58 1.5 12 1.5H15.75C16.17 1.5 16.5 1.83 16.5 2.25V6C16.5 6.42 16.17 6.75 15.75 6.75Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M0 1.5V6.75C0 7.575 0.675 8.25 1.5 8.25H6.75C7.575 8.25 8.25 7.575 8.25 6.75V1.5C8.25 0.675 7.575 0 6.75 0H1.5C0.675 0 0 0.675 0 1.5ZM6 6.75H2.25C1.83 6.75 1.5 6.42 1.5 6V2.25C1.5 1.83 1.83 1.5 2.25 1.5H6C6.42 1.5 6.75 1.83 6.75 2.25V6C6.75 6.42 6.42 6.75 6 6.75Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M9.75 11.25V16.5C9.75 17.325 10.425 18 11.25 18H16.5C17.325 18 18 17.325 18 16.5V11.25C18 10.425 17.325 9.75 16.5 9.75H11.25C10.425 9.75 9.75 10.425 9.75 11.25ZM15.75 16.5H12C11.58 16.5 11.25 16.17 11.25 15.75V12C11.25 11.58 11.58 11.25 12 11.25H15.75C16.17 11.25 16.5 11.58 16.5 12V15.75C16.5 16.17 16.17 16.5 15.75 16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M0 11.25V16.5C0 17.325 0.675 18 1.5 18H6.75C7.575 18 8.25 17.325 8.25 16.5V11.25C8.25 10.425 7.575 9.75 6.75 9.75H1.5C0.675 9.75 0 10.425 0 11.25ZM6 16.5H2.25C1.83 16.5 1.5 16.17 1.5 15.75V12C1.5 11.58 1.83 11.25 2.25 11.25H6C6.42 11.25 6.75 11.58 6.75 12V15.75C6.75 16.17 6.42 16.5 6 16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14515_197641">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveLicensesIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14515_197621)">
        <path
          d="M9.75 1.5V6.75C9.75 7.575 10.425 8.25 11.25 8.25H16.5C17.325 8.25 18 7.575 18 6.75V1.5C18 0.675 17.325 0 16.5 0H11.25C10.425 0 9.75 0.675 9.75 1.5ZM15.75 6.75H12C11.58 6.75 11.25 6.42 11.25 6V2.25C11.25 1.83 11.58 1.5 12 1.5H15.75C16.17 1.5 16.5 1.83 16.5 2.25V6C16.5 6.42 16.17 6.75 15.75 6.75Z"
          fill="white"
        />
        <path
          d="M0 1.5V6.75C0 7.575 0.675 8.25 1.5 8.25H6.75C7.575 8.25 8.25 7.575 8.25 6.75V1.5C8.25 0.675 7.575 0 6.75 0H1.5C0.675 0 0 0.675 0 1.5ZM6 6.75H2.25C1.83 6.75 1.5 6.42 1.5 6V2.25C1.5 1.83 1.83 1.5 2.25 1.5H6C6.42 1.5 6.75 1.83 6.75 2.25V6C6.75 6.42 6.42 6.75 6 6.75Z"
          fill="white"
        />
        <path
          d="M9.75 11.25V16.5C9.75 17.325 10.425 18 11.25 18H16.5C17.325 18 18 17.325 18 16.5V11.25C18 10.425 17.325 9.75 16.5 9.75H11.25C10.425 9.75 9.75 10.425 9.75 11.25ZM15.75 16.5H12C11.58 16.5 11.25 16.17 11.25 15.75V12C11.25 11.58 11.58 11.25 12 11.25H15.75C16.17 11.25 16.5 11.58 16.5 12V15.75C16.5 16.17 16.17 16.5 15.75 16.5Z"
          fill="white"
        />
        <path
          d="M0 11.25V16.5C0 17.325 0.675 18 1.5 18H6.75C7.575 18 8.25 17.325 8.25 16.5V11.25C8.25 10.425 7.575 9.75 6.75 9.75H1.5C0.675 9.75 0 10.425 0 11.25ZM6 16.5H2.25C1.83 16.5 1.5 16.17 1.5 15.75V12C1.5 11.58 1.83 11.25 2.25 11.25H6C6.42 11.25 6.75 11.58 6.75 12V15.75C6.75 16.17 6.42 16.5 6 16.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14515_197621">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
