import { observer } from 'mobx-react';
import React from 'react';

import { Grid } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

import { Form, Formik, FormikHelpers, getIn } from 'formik';

import Logger from 'vatix-ui/lib/utils/logger/Logger';

import UserDetailsData from 'stores/UserDetails/UserDetailsData';

import { StyledTextField } from 'components/Input/styles';

import API from 'utils/api';

import { UpdatedUserPayload } from 'utils/events/types';

import { postMessage } from 'utils/events/broadcast';
import { EventType } from 'utils/events/constants';

import { UserRole } from 'core/constants';

import { EditUserResponse, PersonalDetailsResponse } from 'utils/api/types';

import { formErrors } from 'utils/api/errors';

import { useStore } from '../../../../utils/hooks/store';
import { StyledSectionGrid, StyledRowTitle, ButtonWrapper } from './styles';
import { EditMedicalInformationSchema } from './types';

const EditRow: React.FunctionComponent<{
  name: string;
  disabled?: boolean;
  children: React.ReactNode;
  id?: string,
}> = ({
  name,
  disabled = false,
  children,
  id,
}): React.ReactElement => (
  <>
    <StyledSectionGrid item container xs={12} alignContent="flex-start" id={id} marginTop="16px">
      <StyledRowTitle $disabled={disabled}>{name}</StyledRowTitle>
    </StyledSectionGrid>
    <StyledSectionGrid item container xs={12}>
      {children}
    </StyledSectionGrid>
  </>
);

export const MedicalInformation: React.FunctionComponent<{ setEditing: (val: boolean) => void }> = ({
  setEditing,
}) => {
  const {
    userDetails: { details: detailsRaw },
    notification,
    session,
  } = useStore();
  const details = detailsRaw as UserDetailsData;

  const onUpdate = async (
    data: PersonalDetailsResponse,
    helpers: FormikHelpers<PersonalDetailsResponse>,
  ): Promise<void> => {
    try {
      helpers.setSubmitting(true);
      let formattedData: Partial<EditUserResponse> = {
        personalDetails: data,
      };

      if (session.user?.role === UserRole.User) {
        formattedData = {
          personalDetails: data
        };
      }
      const response = await API.updateUserData(details.uuid, formattedData)();
      const payload: UpdatedUserPayload = response.data;
      postMessage(EventType.UpdatedUser, payload);
      notification.enqueueSuccessSnackbar('User details updated successfully!');

      helpers.setStatus();
      helpers.setErrors({});
      setEditing(false);
    } catch (e) {
      notification.enqueueErrorSnackbar('Could not update user details');
      // @ts-ignore
      const { nonFieldErrors, fieldErrors } = formErrors(e);
      fieldErrors && helpers.setErrors(fieldErrors);
      nonFieldErrors && helpers.setStatus(nonFieldErrors);
      // @ts-ignore
      if (!isBadRequest(e)) {
        Logger.error('Invalid update user details API response', e);
      }
    } finally {
      helpers.setSubmitting(false);
    }
  };

  const fields = [
    ["medicalConditions", "Medical Conditions"],
    ["allergies", "Allergies"],
    ["currentMedication", "Current Medication"],
    ["bloodType", "Blood Type"],
    ["weight", "Weight"],
    ["height", "Height"],
  ];

  return (
    <Formik
      initialValues={details.personalDetails as PersonalDetailsResponse}
      onSubmit={(values, helpers) => onUpdate(values, helpers)}
      validationSchema={EditMedicalInformationSchema}
    >
      {({ values, handleChange, touched, errors, isSubmitting}) => (
        <Form>
          <Grid container style={{ padding: '20px' }}>
            {fields.map(([property, name]) => (
              <EditRow key={property} name={name}>
                <StyledTextField
                  id={property}
                  name={property}
                  value={getIn(values, property)}
                  helperText={getIn(touched, property) ? getIn(errors, property) : ''}
                  error={Boolean(getIn(touched, property)) && getIn(errors, property) !== undefined}
                  onChange={handleChange}
                  fullWidth
                />
              </EditRow>
            ))}

            <ButtonWrapper>
              <Button variant="outlined" onClick={() => setEditing(false)} size="large">
                Cancel
              </Button>

              <Button variant="contained" type="submit" size="large" disabled={isSubmitting}>
                Save
              </Button>
            </ButtonWrapper>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default observer(MedicalInformation);
