/* eslint-disable no-nested-ternary */
import React from 'react';

import { Grid } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

import { EditCompanyLogo } from './styles';
import UploadFile from '../UploadFile';
import { EditingViewType } from './types';

const EditingView: React.FunctionComponent<EditingViewType> = ({
  newLogo,
  hasOrganisationLogo,
  setNewLogo,
  handleReplaceClick,
  onDelete,
  onSave,
  onCancel,
}): React.ReactElement => (
  <>
    {newLogo || hasOrganisationLogo ? (
      <EditCompanyLogo>
        {newLogo ? (
          <img alt="new-company-logo" style={{ maxHeight: '65px' }} src={URL.createObjectURL(newLogo)} />
        ) : null}
        {newLogo === undefined && hasOrganisationLogo ? (
          <img alt="company-logo-settings-page" id="company-logo-settings-page" style={{ maxHeight: '65px' }} />
        ) : null}

        <div style={{ display: 'flex' }}>
          <Button variant="text" color="error" onClick={onDelete}>
            Delete
          </Button>
          <Button variant="text" onClick={handleReplaceClick}>
            Replace
          </Button>
        </div>
      </EditCompanyLogo>
    ) : (
      <UploadFile data-testid="upload-file-component" setNewLogo={setNewLogo} uploadFile={handleReplaceClick} />
    )}

    <Grid container justifyContent="end" marginTop="16px">
      <Button variant="outlined" style={{ marginRight: '8px' }} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="contained" onClick={onSave}>
        Save
      </Button>
    </Grid>
  </>
);

export default EditingView;
