import React from 'react';

import { DeviceTypeContent, DeviceTypeIDText } from 'containers/Devices/components/DeviceTypeCell/styles';

import Delete from 'components/Icons/Delete';

import { IconWrap, ListItemWrapper, UnassignContainer } from '../styles';

const Unassign: React.FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
  <UnassignContainer>
    <ListItemWrapper key="-1" onClick={onClick} disableHover>
      <IconWrap>
        <Delete />
      </IconWrap>
      <DeviceTypeContent>
        <DeviceTypeIDText>Unassign</DeviceTypeIDText>
      </DeviceTypeContent>
    </ListItemWrapper>
  </UnassignContainer>
);

export default Unassign;
