import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';

import routes from 'core/routes';

import { InjectedHomeRedirectProps, HomeRedirectProps } from './types';

@inject('session')
@observer
export default class HomeRedirect extends React.Component<HomeRedirectProps> {
  get injected(): InjectedHomeRedirectProps {
    return this.props as InjectedHomeRedirectProps;
  }

  render(): React.ReactNode {
    const { session } = this.injected;
    const { from } = this.props;
    const redirectTo = session.isAuthenticated ? String(routes.dashboard.home) : routes.login;

    return (
      <Redirect
        {...this.props}
        to={{
          pathname: redirectTo,
          state: { from },
        }}
      />
    );
  }
}
