import React from 'react';
import MapControl from 'vatix-ui/lib/components/Map/MapControl';
import { ControlPosition } from 'vatix-ui/lib/components/Map/MapControl/types';
import MapTypeControl from 'vatix-ui/lib/components/Map/MapTypeControl';
import FullScreenControl from 'vatix-ui/lib/components/Map/FullScreenControl';
import LocateControl from 'vatix-ui/lib/components/Map/LocateControl';

import ZoomControl from 'components/Map/ZoomControl';

import { PositionProps } from '../../types';

const arePropsEqual = (prevProp: PositionProps, nextProp: PositionProps): boolean =>
  prevProp.position.lng === nextProp.position.lng && prevProp.position.lat === nextProp.position.lat;

const MapControls: React.FunctionComponent<PositionProps> = ({ position }) => (
  <>
    <MapControl position={ControlPosition.TOP_LEFT}>
      <MapTypeControl />
    </MapControl>
    <MapControl position={ControlPosition.TOP_RIGHT}>
      <FullScreenControl />
    </MapControl>
    <MapControl position={ControlPosition.RIGHT_BOTTOM}>
      <LocateControl location={position} />
      <ZoomControl />
    </MapControl>
  </>
);

export default React.memo(MapControls, arePropsEqual);
