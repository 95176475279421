import React from 'react';
import { inject, observer } from 'mobx-react';

import MapTypeControl from 'vatix-ui/lib/components/Map/MapTypeControl';

import MapControl from 'components/Map/MapControl';

import { UserRole } from 'core/constants';

import UserSearchControl from '../UserSearchControl';
import ZoomControl from '../ZoomControl';
import { SessionStoreWithUser } from '../../../stores/Session/types';
import { InjectedUserControlsProps } from './types';

const UserControls = ({ session, searchStore }: InjectedUserControlsProps): React.ReactElement => (
  <>
    <MapControl position={window.google.maps.ControlPosition.TOP_LEFT}>
      <ZoomControl />
    </MapControl>
    <MapControl position={window.google.maps.ControlPosition.TOP_RIGHT}>
      <MapTypeControl />
    </MapControl>
    {session.user.role === UserRole.ArcOperator && (
      <MapControl position={window.google.maps.ControlPosition.TOP_LEFT}>
        <UserSearchControl searchStore={searchStore} />
      </MapControl>
    )}
  </>
);

UserControls.defaultProps = {
  session: (null as unknown) as SessionStoreWithUser,
};

export default inject('session')(observer(UserControls));
