import React from 'react';

import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { Modal, CustomModalActions, CustomModalTitle, StyledModalTitle, CustomModalDescription } from './styles';
import { CustomModalProps } from './types';

const CustomModal: React.FunctionComponent<CustomModalProps> = ({
  onClose,
  openModal,
  modalTitle,
  modalDescription,
  children,
  cancelButton,
  submitButton,
  modalWidth,
  buttonsSpacing,
}): React.ReactElement => (
  <Modal
    aria-labelledby={`${modalTitle}-modal`}
    onClose={onClose}
    open={openModal}
    maxWidth={modalWidth || 'sm'}
    fullWidth
    PaperProps={{
      square: true,
    }}
  >
    <CustomModalTitle>
      <StyledModalTitle>{modalTitle}</StyledModalTitle>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </CustomModalTitle>
    {modalDescription ? <CustomModalDescription>{modalDescription}</CustomModalDescription> : null}
    {children}
    {cancelButton || submitButton ? (
      <CustomModalActions $buttonsSpacing={buttonsSpacing}>
        {cancelButton}
        {submitButton}
      </CustomModalActions>
    ) : null}
  </Modal>
);

export default CustomModal;
