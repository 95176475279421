export enum BooleanAnswerTypes {
  True = 'true',
  False = 'false',
  Null = 'null',
}

export const getAnswerText = (answer?: BooleanAnswerTypes): string => {
  switch (answer) {
    case BooleanAnswerTypes.True:
      return 'YES';
    case BooleanAnswerTypes.False:
      return 'NO';
    default:
      return 'N/A';
  }
};

export const getAnswerStyle = (answer?: BooleanAnswerTypes): string => {
  switch (answer) {
    case BooleanAnswerTypes.True:
      return '#4CAF50';
    case BooleanAnswerTypes.False:
      return '#EF5350';
    default:
      return '#9E9E9E';
  }
}
