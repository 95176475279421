import { AxiosError } from 'axios';
import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import RootStore from 'stores/Root';
import API from 'utils/api';
import { AlarmDetailsResponse } from 'utils/api/types';
import { postMessage } from 'utils/events/broadcast';
import { EventType } from 'utils/events/constants';
import Logger from 'utils/logger';

import { ActionEvent } from 'utils/events/types';

import AlarmActivities from './AlarmActivities';

export default class AlarmDetailsStore {
  store: RootStore;

  api: typeof API;

  @observable activities?: AlarmActivities;

  @observable isLoaded = false;

  @observable error?: AxiosError;

  @observable details?: AlarmDetailsResponse;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(alarmId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadAlarmDetails(alarmId)();
      runInAction(() => {
        this.error = undefined;
        this.details = data;
        this.activities = new AlarmActivities(this.store, this.api, alarmId);
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @action.bound
  async accept(alarmUuid: string): Promise<void> {
    try {
      const { data } = await this.api.acceptRedAlert(alarmUuid)();
      postMessage(EventType.AcceptedRedAlert, data);
      this.store.notification.enqueueSuccessSnackbar('Alarm successfully accepted.');
    } catch (e) {
      Logger.error(`Invalid accept red alert API response. Alert id: ${alarmUuid}`, e);
      this.store.notification.enqueueErrorSnackbar('Alarm cannot be accepted right now.');
    }
  }

  @subscribe(EventType.AcceptedRedAlert)
  @subscribe(EventType.CompletedRedAlert)
  @subscribe(EventType.UpdatedUser)
  updatedAlarm({ payload }: ActionEvent<AlarmDetailsResponse>): void {
    if (payload.uuid === this.details?.uuid) {
      this.details = payload;
    }
  }

  @subscribe(EventType.LoggedOut)
  @action.bound
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }
}
