import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from 'utils/hooks/store';

import ActivityMapMarker from '../ActivityMapMarker';
import AlarmMapMarker from '../AlarmMapMarker';

const ActivityMapMarkers = (): React.ReactElement => {
  const { activityMap } = useStore();
  const alarms = activityMap.alarms.filter((i) => i.location && i.location.valid);

  return (
    <>
      {activityMap.users.map((user) => (
        <ActivityMapMarker key={user.uuid} user={user} />
      ))}
      <div style={{ zIndex: 200 }}>
        {alarms && alarms.map((alarm) => <AlarmMapMarker key={alarm.uuid} alarm={alarm} activityMap={activityMap} />)}
      </div>
    </>
  );
};

export default observer(ActivityMapMarkers);
