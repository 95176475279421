import React from 'react';

import { StyledRowTitle } from '../../styles';
import { StyledSectionGrid } from './styles';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <>
    <StyledSectionGrid item container xs={3} id={id}>
      <StyledRowTitle>{name}</StyledRowTitle>
    </StyledSectionGrid>
    <StyledSectionGrid item container xs={9} paddingLeft='8px'>
      {children}
    </StyledSectionGrid>
  </>
);
export default Row;
