import {
  AnswerType,
  JSONSchemaAnyOfItemType,
  JSONSchemaType,
  QuestionProperties,
  SectionProperties,
} from 'utils/api/types';

type AnyOfRule = {
  keys: {
    section: null | string;
    question: string;
  };
  values: string[];
  dependents: string[];
  elses: string[];
};
export default class SchemaBuilder {
  private hasSections = false;

  private originalSchema: JSONSchemaType;

  private content: AnswerType;

  constructor(originalSchema: JSONSchemaType, content: AnswerType, hasSections?: boolean) {
    this.hasSections = Boolean(hasSections);
    this.originalSchema = originalSchema;
    this.content = content;
  }

  private getAnyOfRulesForBlock(anyOf: JSONSchemaAnyOfItemType[], appendSection?: boolean): AnyOfRule[] {
    if (appendSection) {
      // schema with sections
      return anyOf.flatMap((anyOfItem) => {
        const ifKeys = Object.keys(anyOfItem.if.properties as SectionProperties);
        return ifKeys.map((key) => ({
          keys: {
            section: key,
            question: Object.keys((anyOfItem.if.properties as SectionProperties)[key])[0],
          },
          values: Object.values((anyOfItem.if.properties as SectionProperties)[key])[0].constArray,
          dependents: Object.keys(anyOfItem.then.dependentRequired),
          elses: Object.keys(anyOfItem.else),
        }));
      });
    }
    // schema without sections
    return anyOf.flatMap((anyOfItem) => {
      const ifKeys = Object.keys(anyOfItem.if.properties as QuestionProperties);
      return ifKeys.map((key) => ({
        keys: {
          section: null,
          question: key,
        },
        values: (anyOfItem.if.properties as QuestionProperties)[key].constArray,
        dependents: Object.keys(anyOfItem.then.dependentRequired),
        elses: Object.keys(anyOfItem.else),
      }));
    });
  }

  private getRemoveProtectForBlock(
    schema: JSONSchemaType,
    config?: {
      appendSections?: boolean;
      currentSection?: string;
    }
  ): { remove: string[]; protect: string[] } {
    const { anyOf } = schema;
    const remove: string[] = [];
    const protect: string[] = [];
    const processRule = (removeCondition: boolean, dependents: string[], elses: string[]): void => {
      if (removeCondition) {
        remove.push(...dependents);
      } else {
        protect.push(...elses);
      }
    };
    if (config?.appendSections) {
      // global sections
      this.getAnyOfRulesForBlock(anyOf || [], true).forEach(({ keys, values, dependents, elses }) => {
        const { section, question } = keys;
        if (section && this.content[section]) {
          let content: AnswerType;
          if (section === 'incidentType') {
            content = this.content[section] as AnswerType;
          } else {
            content = (this.content[section] as AnswerType)[question] as AnswerType;
          }
          processRule(
            // @ts-ignore
            !Array.isArray(content) || !values.some((item) => content.includes(item)),
            dependents,
            elses
          );
        } else {
          remove.push(...dependents);
        }
      });
    } else {
      this.getAnyOfRulesForBlock(anyOf || [], false).forEach(({ keys, values, dependents, elses }) => {
        if (config?.currentSection) {
          // local section
          if (this.content[config.currentSection]) {
            const content = (this.content[config.currentSection] as AnswerType)[keys.question];
            processRule(
              !(keys.question in (this.content[config.currentSection] as AnswerType)) ||
                !Array.isArray(content) ||
                !values.some((item) => content.includes(item)),
              dependents,
              elses
            );
          } else {
            remove.push(...elses);
          }
        } else {
          // global for schemas with no sections
          processRule(
            !(keys.question in this.content) ||
              !Array.isArray(this.content[keys.question]) ||
              !values.some((item) =>
                // @ts-ignore
                this.content[keys.question].includes(item)
              ),
            dependents,
            elses
          );
        }
      });
    }
    return {
      remove,
      protect,
    };
  }

  public checkOrder(): SchemaBuilder {
    const { order } = this.originalSchema;
    if (this.hasSections) {
      // global order
      const { remove: removeSections, protect: protectSections } = this.getRemoveProtectForBlock(this.originalSchema, {
        appendSections: true,
      });
      const updatedSectionOrder = order?.filter(
        (item) => !removeSections.filter((itemIn) => !protectSections.includes(itemIn)).includes(item)
      );
      this.originalSchema = {
        ...this.originalSchema,
        order: updatedSectionOrder,
      };
      // local orders
      order?.forEach((o) => {
        const { remove: removeFields, protect: protectFields } = this.getRemoveProtectForBlock(
          (this.originalSchema.properties || {})[o],
          { appendSections: false, currentSection: o }
        );

        const groupedItems: { [key: string]: string[] } = {};

        for (const item of removeFields) {
          const parts = item.split('::');
          if (parts.length >= 2) {
            const section = parts[0];
            if (!groupedItems[section]) {
              groupedItems[section] = [];
            }
            groupedItems[section].push(parts[1]);
          }
        }
        if (Object.keys(groupedItems).length > 0) {
          Object.keys(groupedItems).forEach((i) => {
            const updatedOrder = (this.originalSchema.properties?.[i]?.order || []).filter(
              (item) => !groupedItems[i].includes(item)
            );
            this.originalSchema = {
              ...this.originalSchema,
              properties: {
                ...this.originalSchema.properties,
                [i]: {
                  ...(this.originalSchema.properties?.[i] || {}),
                  order: updatedOrder,
                },
              },
            };
          });
        }
        const updatedOrder = (this.originalSchema.properties || {})[o].order?.filter(
          (item) => !removeFields.filter((itemIn) => !protectFields.includes(itemIn)).includes(item)
        );

        this.originalSchema = {
          ...this.originalSchema,
          properties: {
            ...this.originalSchema.properties,
            [o]: {
              ...(this.originalSchema.properties || {})[o],
              order: updatedOrder,
            },
          },
        };
      });
    } else {
      // global order for schema with no sections
      const { remove: removeFields, protect: protectFields } = this.getRemoveProtectForBlock(this.originalSchema);
      const updatedOrder = order?.filter(
        (item) => !removeFields.filter((itemIn) => !protectFields.includes(itemIn)).includes(item)
      );
      this.originalSchema = {
        ...this.originalSchema,
        order: updatedOrder,
      };
    }
    return this;
  }

  public checkRequired(): SchemaBuilder {
    const { required, order } = this.originalSchema;
    if (this.hasSections) {
      // global required
      const { remove: removeSections, protect: protectSections } = this.getRemoveProtectForBlock(this.originalSchema, {
        appendSections: true,
      });
      const updatedSectionRequired = required?.filter(
        (item) => !removeSections.filter((itemIn) => !protectSections.includes(itemIn)).includes(item)
      );
      this.originalSchema = {
        ...this.originalSchema,
        required: updatedSectionRequired,
      };
      // local requires
      order?.forEach((o) => {
        const { remove: removeFields, protect: protectFields } = this.getRemoveProtectForBlock(
          (this.originalSchema.properties || {})[o],
          { appendSections: false, currentSection: o }
        );
        const groupedItems: { [key: string]: string[] } = {};

        for (const item of removeFields) {
          const parts = item.split('::');
          if (parts.length >= 2) {
            const section = parts[0];
            if (!groupedItems[section]) {
              groupedItems[section] = [];
            }
            groupedItems[section].push(parts[1]);
          }
        }
        if (Object.keys(groupedItems).length > 0) {
          Object.keys(groupedItems).forEach((i) => {
            const updatedRequired = (this.originalSchema.properties?.[i]?.required || []).filter(
              (item) => !groupedItems[i].includes(item)
            );
            this.originalSchema = {
              ...this.originalSchema,
              properties: {
                ...this.originalSchema.properties,
                [i]: {
                  ...(this.originalSchema.properties?.[i] || {}),
                  required: updatedRequired,
                },
              },
            };
          });
        }

        const updatedRequired = (this.originalSchema.properties || {})[o].required?.filter(
          (item) => !removeFields.filter((itemIn) => !protectFields.includes(itemIn)).includes(item)
        );
        this.originalSchema = {
          ...this.originalSchema,
          properties: {
            ...this.originalSchema.properties,
            [o]: {
              ...(this.originalSchema.properties || {})[o],
              required: updatedRequired,
            },
          },
        };
      });
    } else {
      // global required for schema with no sections
      const { remove: removeFields, protect: protectFields } = this.getRemoveProtectForBlock(this.originalSchema);
      const updatedRequired = required?.filter(
        (item) => !removeFields.filter((itemIn) => !protectFields.includes(itemIn)).includes(item)
      );
      this.originalSchema = {
        ...this.originalSchema,
        required: updatedRequired,
      };
    }
    return this;
  }

  public produce(): JSONSchemaType {
    return this.originalSchema;
  }
}
