import { BroadcastChannel } from 'broadcast-channel';
import { eventBus } from 'mobx-event-bus2';

import { BROADCAST_CHANNEL_NAME, EventType } from './constants';
import { BroadcastMessage } from './types';

export const channel = new BroadcastChannel<BroadcastMessage>(
  BROADCAST_CHANNEL_NAME,
  {
    webWorkerSupport: false,
  }
);

export function postMessage<T>(type: EventType, payload: T): void {
  eventBus.post(type, payload);
  channel.postMessage({
    type,
    payload,
  });
}
