import React from 'react';
import { observer } from 'mobx-react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { QuestionRowProps } from 'containers/FormsDetails/types';

import { QuestionType } from 'stores/TemplateDetails/types';

import CustomDropdownField from 'containers/IncidentDetails/components/CustomDropdownField';

import { ProtectorType } from 'utils/api/types';

import { QuestionAnswer, Container } from './styles';

import { EmptyAnswerText } from '../styles';

const MultiChoiceAnswer: React.FunctionComponent<QuestionRowProps & { questionProperties: QuestionType }> = ({
  answer,
  isSubmitted,
  questionProperties,
  updateAnswer,
}): React.ReactElement => {
  const answers = (answer.answer || []) as string[];
  const items = questionProperties.items?.enum;
  const singleChoice = questionProperties.maxItems === 1;

  const update = async (newAnswer: string[] | null): Promise<void> => {
    if (newAnswer !== null) {
      if (newAnswer.some((ans) => !items?.includes(ans))) {
        return;
      }
    }
    if (answers !== null && answers !== undefined && newAnswer !== null && answers.length === newAnswer.length) {
      if (answers.every((ans, index) => ans === newAnswer[index])) {
        return;
      }
    }

    if (singleChoice) {
      await updateAnswer(answer.questionId, newAnswer);
    } else {
      await updateAnswer(answer.questionId, newAnswer);
    }
  };

  if (!isSubmitted && items !== undefined && items !== null && items.length) {
    return (
      <Container onClick={(e) => e.stopPropagation()}>
        <CustomDropdownField
          onChange={(value) => {
            if (value === null) {
              update(null);
              return;
            }
            update(value as string[]);
          }}
          value={answers}
          items={{ enum: items }}
          description=""
          type={singleChoice ? ProtectorType.SingleChoice : ProtectorType.MultiChoice}
        />
      </Container>
    );
  }

  if (answers !== undefined && answers !== null && answers.length) {
    return (
      <Container>
        {answers.map((ans, index) => (
          <QuestionAnswer key={`${ans}_${index}`}>
            <CheckCircleIcon htmlColor="rgba(7, 114, 229, 1)" />
            <p>{ans}</p>
          </QuestionAnswer>
        ))}
      </Container>
    );
  }

  return <EmptyAnswerText>Not answered</EmptyAnswerText>;
};

export default observer(MultiChoiceAnswer);
