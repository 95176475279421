import React from 'react';

import { RowContainer, StyledRowTitle, StyledDivider } from './styles';

const DividerRow: React.FC<{ name: string }> = ({ name }): React.ReactElement => (
  <RowContainer>
    <StyledRowTitle>{name}</StyledRowTitle>
    <StyledDivider />
  </RowContainer>
);
export default DividerRow;
