/* eslint-disable max-len */
import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { Button } from 'vatix-ui/lib/components/Button';

import { useNavBarHeight } from 'utils/hooks/navbar';

import SolutionsPane from 'containers/Home/panes/SolutionsPane';

import { Container, Header, SubText, ContactContainer } from './styles';

const MoreApps: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={() => (
        <Container>
          <Header>Our Solutions</Header>
          <SubText>
            At Vatix, we are committed to enhancing workplace safety and efficiency through innovative technology. Our
            platform offers three distinct solutions tailored to meet your diverse needs. Whether you&apos;re looking to
            protect lone workers, streamline incident reporting, or conduct thorough audits and inspections, Vatix has
            the tools you need.
          </SubText>
          <SolutionsPane displayHeader={false} />
          <ContactContainer>
            <h1>Enhance your Vatix Experience</h1>
            <p>
              If you&apos;re intrigued by the potential of our other products and how they can benefit your
              organisation, we&apos;re here to help. Upgrading your Vatix platform to include additional solutions is
              straightforward.
            </p>
            <Button
              onClick={() => {
                window.open('https://www.vatix.com/contact-us/', '_blank');
              }}
              variant="contained"
            >
              Contact Us
            </Button>
          </ContactContainer>
        </Container>
      )}
      Header={<MainHeader title="More apps" breadcrumbs={[]} />}
    />
  );
};

export default MoreApps;
