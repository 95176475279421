import React from 'react';

import IncidentChip from 'components/Chips/IncidentStatusChip';

import { IncidentsStatus } from 'core/constants';

import { StyledMenuItem, StyledSelect } from '../styles';
import { IncidentStatusSelectProps } from './types';

const IncidentStatusSelect: React.FunctionComponent<IncidentStatusSelectProps> = ({ onChange, schema, ...props }) => (
  <StyledSelect {...props} fullWidth onChange={(event) => onChange(event.target.value as IncidentsStatus)}>
    {schema?.enum?.map((status) => (
      <StyledMenuItem key={status} value={status}>
        <IncidentChip label={status} schema={schema} />
      </StyledMenuItem>
    ))}
  </StyledSelect>
);

export default IncidentStatusSelect;
