import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { ErrorMessage, FieldProps } from 'formik';
import { TextFieldProps } from '@mui/material/TextField';

import { hasError } from 'utils/form/errors';

import { ErrorIcon, ErrorText } from '../styles';
import { StyledTextField, TextFieldWrapper } from './styles';

type SecondaryTextFieldProps = Omit<
  Omit<TextFieldProps, 'variant'> & FieldProps & { variant?: 'standard' | 'filled' | 'outlined' },
  'ref'
>;

const SecondaryTextField: React.FunctionComponent<SecondaryTextFieldProps> = React.forwardRef(
  ({ field, form, ...props }, ref): React.ReactElement => {
    const error = hasError(form.errors, form.touched, field.name);

    return (
      <TextFieldWrapper>
        <StyledTextField
          error={error}
          InputProps={{
            classes: {
              root: 'input-root',
              underline: 'input-underline',
              disabled: 'input-disabled',
              multiline: 'input-multiline',
              error: 'input-error',
            },
            endAdornment: error && (
              <InputAdornment position="end">
                <ErrorIcon color="error" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: {
              error: 'input-label-error',
            },
            shrink: true,
            required: false,
          }}
          SelectProps={{
            classes: {
              select: 'select-select',
            },
          }}
          {...field}
          {...props}
          ref={ref as React.RefObject<HTMLDivElement>}
        />

        <ErrorMessage name={field.name} component={ErrorText} />
      </TextFieldWrapper>
    );
  }
);

export default SecondaryTextField;
