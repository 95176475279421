import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import DataMixin from 'utils/stores/DataMixin';
import { LicensesStatusResponse } from 'utils/api/types';
import { isNotFound } from 'utils/api/errors';

import Logger from 'utils/logger';

import { EventType } from 'utils/events/constants';

import API from 'utils/api';

import RootStore from '../Root';

class Licenses extends DataMixin {
  @observable error?: AxiosError;

  @observable details?: LicensesStatusResponse;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);
    makeObservable(this);
    eventBus.register(this, { fireImmediately: true });
  }

  @action.bound
  async loadData(): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadLicensesStatus()();
      runInAction(() => {
        this.error = undefined;
        this.details = data;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load licenses status API response`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @subscribe(EventType.UpdatedUser)
  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }
}

export default Licenses;
