import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { IncidentActivityProps } from './types';

const IncidentActivity: React.FunctionComponent<IncidentActivityProps> = ({ activity, sessionUser }) => {
  if (activity.previousStatus === null && activity.status) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id={`activity-comment-log-${activity.status}`}>
        created the incident
      </EventItem>
    );
  }

  if (activity.status === null || activity.previousStatus === null) {
    return null;
  }

  return (
    <EventItem activity={activity} sessionUser={sessionUser} id={`activity-comment-log-${activity.status}`}>
      {` has changed the status from ${activity.previousStatus} to ${activity.status}`}
    </EventItem>
  );
};

export default observer(IncidentActivity);
