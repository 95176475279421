import React from 'react';
import { FormControl } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { StyledMobileTimePicker, StyledTimePicker } from 'components/Input/styles';

import { ProtectorTypeBaseProps } from '../types';
import CustomActionBar from '../CustomPickerActionBar/CustomPickerActionBar';

export const CustomTimePicker = ({
  value,
  onChange,
  requiredNotFilled,
}: ProtectorTypeBaseProps): React.ReactElement => {
  const [val, setValue] = React.useState<Dayjs | null>(value ? dayjs(value as string) : null);
  const [error, setError] = React.useState('');

  const handleChange = (newValue: Dayjs | null): void => {
    if (newValue && Object.prototype.toString.call(newValue) === '[object Object]') {
      //  @ts-ignore
      if (isNaN(newValue)) {
        onChange('Invalid time');
        setError('Invalid time');
      } else {
        setValue(newValue);
        onChange(newValue ? new Date(newValue.format()).getTime() : null);
        setError('');
      }
    } else {
      setValue(null);
      onChange(null);
      setError('');
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleToggleOpen = (): void => {
    setOpen((prev) => !prev);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const commonTimeProps = {
    value: val,
    format: 'HH:mm',
    slots: {
      actionBar: CustomActionBar,
    },
    slotProps: {
      textField: {
        helperText: error,
        placeholder: 'hh:mm',
        error: requiredNotFilled,
      },
    },
    desktopModeMediaQuery: '@media (min-width: 320px)',
    sx: {
      '& + .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    onChange: (date: Dayjs | null) => handleChange(date),
    ampm: false,
  };

  return (
    <FormControl fullWidth>
      {window.innerWidth < 768 ? (
        <StyledMobileTimePicker
          {...commonTimeProps}
          ampmInClock={false}
          slotProps={{
            ...commonTimeProps.slotProps,
            textField: {
              ...commonTimeProps.slotProps.textField,
              InputProps: {
                endAdornment: <AccessTimeIcon htmlColor="rgba(0, 0, 0, 0.54)" />,
              },
            },
          }}
        />
      ) : (
        <StyledTimePicker
          {...commonTimeProps}
          open={open}
          onClose={handleClose}
          slotProps={{
            textField: {
              ...commonTimeProps.slotProps.textField,
              onClick: handleToggleOpen,
            },
            field: { clearable: true },
          }}
        />
      )}
    </FormControl>
  );
};

export default CustomTimePicker;
