import React from 'react';

import { Container, Text } from './styles';

const NoRowsOverlay = ({
  entity = 'item',
  customText,
}: {
  entity?: string;
  customText?: string;
}): React.ReactElement => (
  <Container>{customText ? <Text>{customText}</Text> : <Text>No {entity}s have been created yet</Text>}</Container>
);

export default NoRowsOverlay;
