import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';
import { escapeRegExp } from 'lodash';
import { GridFilterOperator } from '@mui/x-data-grid';

import dayjs from 'dayjs';

import { DatePickerRange, dateRangePickerValue } from './DateRangePicker';
import { DatePicker } from './DatePicker';
import EmptyNotEmpty from '../EmptyNotEmpty';

const BasicDateOperators = (): GridFilterOperator[] =>
  ([
    {
      label: 'is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: DatePicker,
      InputComponentProps: { value: null },
      getValueAsString: (value: number) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      label: 'range',
      value: 'range',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }

        const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: DatePickerRange,
      InputComponentProps: { value: null },
      getValueAsString: (value: [number, number]) => dateRangePickerValue(value),
    },
  ] as unknown) as GridFilterOperator[];

export default BasicDateOperators;

export const ExtendedDateOperators = (): GridFilterOperator[] =>
  ([...BasicDateOperators(), ...EmptyNotEmpty()] as unknown) as GridFilterOperator[];
