import React from 'react';

import UserRow from 'components/UserRow';

import { IncidentResponse } from 'utils/api/types';

import DetailsCreated from '../../../DetailsCreated';
import Row from '../Row';
import { Value } from '../../../EditableTextInput/styles';
import { RowContainer, RowWrapper, StyledDivider } from '../../styles';

const NoneEditableValues: React.FunctionComponent<{ details: IncidentResponse }> = ({
  details,
}): React.ReactElement => (
  <>
    <RowWrapper>
      <RowContainer>
        <Row name="Incident ID" id="incident-id-field">
          <Value id="incident-id">{`#INC${details.incidentUid}`}</Value>
        </Row>
      </RowContainer>
      <StyledDivider />
    </RowWrapper>

    <RowWrapper>
      <RowContainer>
        <Row name="Created" id="incident-created-field">
          <DetailsCreated />
        </Row>
      </RowContainer>
      <StyledDivider />
    </RowWrapper>

    <RowWrapper>
      <RowContainer>
        <Row name="Reporter" id="incident-reporter-field">
          <>{details.creator ? <UserRow user={details.creator} /> : '-'}</>
        </Row>
      </RowContainer>
      <StyledDivider />
    </RowWrapper>
  </>
);

export default NoneEditableValues;
