import React from 'react';

export function useNavBarHeight(): number {
  const [navBarHeight, setNavBarHeight] = React.useState(0);

  setTimeout(() => {
    const navBar = document.getElementById('nav-bar');
    if (navBar) {
      setNavBarHeight(navBar.offsetHeight);
    } else {
      setNavBarHeight(0);
    }
  }, 1);

  return navBarHeight;
}
