import dayjs from 'dayjs';
import { action, observable, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { RedAlertStatus } from 'core/constants';
import API from 'utils/api';
import { DeviceResponse, UserDetailsRedAlertResponse } from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import { AcceptedRedAlertPayload, ActionEvent } from 'utils/events/types';

import RootStore from '../Root';
import UserDetailsData from './UserDetailsData';

export default class UserDetailsRedAlert {
  store: RootStore;

  api: typeof API;

  uuid: string;

  @observable status: RedAlertStatus.Pending | RedAlertStatus.Accepted;

  activated: dayjs.Dayjs;

  details: UserDetailsData;

  device: DeviceResponse | null;

  constructor(rootStore: RootStore, api: typeof API, data: UserDetailsRedAlertResponse, details: UserDetailsData) {
    this.store = rootStore;
    this.api = api;
    this.uuid = data.uuid;

    makeObservable(this);

    this.activated = dayjs(data.activated);
    this.status = data.alertStatus;
    this.details = details;
    this.device = data.device;

    eventBus.register(this);
  }

  @subscribe(EventType.AcceptedRedAlert)
  @action
  acceptedRedAlert({ payload }: ActionEvent<AcceptedRedAlertPayload>): void {
    if (payload.user.uuid !== this.details.uuid) {
      return;
    }

    this.status = RedAlertStatus.Accepted;
  }
}
