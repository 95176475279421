import styled from 'styled-components';
import { Button, FormControlLabel, ListItem, IconButton } from '@mui/material';
import { TextInput } from 'vatix-ui/lib/components/TextInput';

import VatixTheme from 'theme/vatix';

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledTextInput = styled(TextInput)`
  width: 60%;
  margin-left: 16px;
`;

export const StyledControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: 1.4rem;
    color: #000;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const MultipleContainer = styled(Container)`
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #d3d6d9;
  margin-top: 8px;
`;

export const Chip = styled.div`
  border: 1px solid ${VatixTheme.palette.primary.main};
  color: ${VatixTheme.palette.primary.main};
  border-radius: 99px;
  min-width: 52px;
  margin-right: 4px;
  padding: 2px;
  font-weight: 500;
  font-size: 12px;
  background-color: ${VatixTheme.palette.common.white};
`;

export const ChipContainer = styled(ListItem)<{ $selected?: boolean }>`
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: ${(props) => (props.$selected ? '#e5f1fd' : '')};

  :hover {
    cursor: pointer;
    ${(props) => (props.$selected ? '' : 'background-color: rgba(0,0,0,0.04);')}
  }
`;

export const EditContainer = styled(MultipleContainer)`
  padding: 0 16px;
`;

export const EditChipContainer = styled(ChipContainer)`
  padding: 0;

  :hover {
    background-color: ${VatixTheme.palette.common.white};
  }
`;

export const AddNewButton = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 16px;
  margin: 0;
`;

export const AddOptionButton = styled(AddNewButton)`
  margin-left: 0;
  padding: 8px;
`;

export const Option = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 6px;
  text-align: left;
`;

export const OptionLeft = styled(Option)`
  width: 65%;
  margin-left: 4px;
`;

export const OptionRight = styled(Option)`
  width: 25%;
`;

export const OptionHeader = styled(Option)`
  line-height: 16px;
  color: #101d31;
  opacity: 0.5;
  margin: 0;
`;

export const Row = styled(Container)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 8px;
`;

export const ErrorMessage = styled.p`
  margin: 0;
  margin-left: 8px;
  color: ${VatixTheme.palette.error.main};
  font-size: 13px;
`;
