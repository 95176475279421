import InputAdornment from '@mui/material/InputAdornment';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

const SearchAdornment: React.FunctionComponent<SvgIconProps> = (
  props
): React.ReactElement => (
  <InputAdornment position="start">
    <SearchIcon {...props} />
  </InputAdornment>
);

export default SearchAdornment;
