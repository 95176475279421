/* eslint-disable max-len */
import * as React from 'react';

export function UploadIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_18050_173102)">
        <path
          d="M16 16L12 12L8 16"
          stroke="black"
          strokeOpacity="0.54"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V21"
          stroke="black"
          strokeOpacity="0.54"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3914 18.39C21.3668 17.8583 22.1373 17.0169 22.5813 15.9986C23.0253 14.9804 23.1176 13.8432 22.8436 12.7667C22.5696 11.6901 21.9449 10.7355 21.0681 10.0534C20.1913 9.37137 19.1123 9.00072 18.0014 8.99998H16.7414C16.4387 7.82923 15.8746 6.74232 15.0914 5.82098C14.3082 4.89964 13.3263 4.16783 12.2195 3.68059C11.1128 3.19335 9.90998 2.96334 8.70154 3.00787C7.49311 3.05239 6.31049 3.37028 5.2426 3.93765C4.17471 4.50501 3.24934 5.30709 2.53605 6.28357C1.82276 7.26004 1.34012 8.38552 1.1244 9.57538C0.908695 10.7652 0.96553 11.9885 1.29064 13.1532C1.61575 14.318 2.20067 15.3938 3.00143 16.3"
          stroke="black"
          strokeOpacity="0.54"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 16L12 12L8 16"
          stroke="black"
          strokeOpacity="0.54"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18050_173102">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
