import { observer } from 'mobx-react';
import React from 'react';

import { QuestionRowProps } from 'containers/FormsDetails/types';

import { QuestionAnswer, AnswersContainer, AnswerBtn } from './styles';

import { BooleanAnswerTypes, getAnswerStyle, getAnswerText } from './types';
import { EmptyAnswerText } from '../styles';

const BooleanAnswer: React.FunctionComponent<QuestionRowProps> = ({
  answer,
  isSubmitted,
  updateAnswer,
}): React.ReactElement => {
  const ANSWERS = {
    [String(true)]: <QuestionAnswer id="question-answer-yes">Yes</QuestionAnswer>,
    [String(false)]: <QuestionAnswer id="question-answer-no">No</QuestionAnswer>,
    [String(null)]: <QuestionAnswer id="question-answer-na">N/A</QuestionAnswer>,
    [String(undefined)]: <EmptyAnswerText id="question-answer-undefined">Not answered</EmptyAnswerText>,
  };

  const update = async (newAnswer: BooleanAnswerTypes): Promise<void> => {
    let sendAnswer: BooleanAnswerTypes | undefined = newAnswer;
    if (newAnswer === answer?.answer) {
      sendAnswer = undefined;
    }
    await updateAnswer(answer!.questionId, sendAnswer);
  };

  return isSubmitted ? (
    ANSWERS[String(answer?.answer)]
  ) : (
    <AnswersContainer>
      {Object.values(BooleanAnswerTypes).map((ans, index) => (
        <AnswerBtn
          id={`question-answer-${ans}-not-submitted`}
          key={index}
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            update(ans);
          }}
          $picked={String(answer?.answer) === ans}
          $backgroundColor={getAnswerStyle(ans)}
        >
          {getAnswerText(ans)}
        </AnswerBtn>
      ))}
    </AnswersContainer>
  );
};

export default observer(BooleanAnswer);
