import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouterStore } from '@superwf/mobx-react-router';
import { reverse } from 'named-urls';

import routes from 'core/routes';

import HoveredMarker from 'components/Marker/HoveredMarker';

import { AlertType } from 'utils/api/types';
import AlarmsPin from 'components/Icons/AlarmsFill';

import { InjectedActivityMapMarkerProps } from './types';

const AlarmMapMarker = ({ alarm, routing, activityMap }: InjectedActivityMapMarkerProps): React.ReactElement => {
  const onMouseOverHandler = React.useCallback(() => {
    activityMap.locationDetails.openAlarmDetails(alarm);
  }, [activityMap.locationDetails, alarm]);

  const onClickHandler = React.useCallback(() => {
    switch (alarm.alertType) {
      case AlertType.RedAlert:
        routing.push(reverse(routes.dashboard.alarms.details, { alarmId: alarm.uuid }));
        break;
      case AlertType.AmberAlert:
        routing.push(reverse(routes.dashboard.admin.users.details, { userId: alarm.user.uuid }));
        break;
      default:
        routing.push(reverse(routes.dashboard.alarms.details, { alarmId: alarm.uuid }));
    }
  }, [routing, alarm.uuid, alarm.user.uuid, alarm.alertType]);

  const mapPinColor = React.useMemo(() => {
    switch (alarm.alertType) {
      case AlertType.RedAlert:
        return '#E20101';
      case AlertType.AmberAlert:
        return '#FC9B00';
      default:
        return '#0772E5';
    }
  }, [alarm.alertType]);

  const mapPinBorderColor = React.useMemo(() => {
    switch (alarm.alertType) {
      case AlertType.RedAlert:
        return '#a80000';
      case AlertType.AmberAlert:
        return '#c27802';
      default:
        return '#223B65';
    }
  }, [alarm.alertType]);

  const mapPinZIndex = React.useMemo(() => {
    switch (alarm.alertType) {
      case AlertType.RedAlert:
        return 100;
      case AlertType.AmberAlert:
        return 10;
      default:
        return 0;
    }
  }, [alarm.alertType]);

  return (
    <>
      {alarm.location && (
        <HoveredMarker
          background={mapPinColor}
          border={mapPinBorderColor}
          lat={+alarm.location.lat}
          lng={+alarm.location.lng}
          icons={<AlarmsPin />}
          onMouseOver={onMouseOverHandler}
          onMouseOut={activityMap.locationDetails.closeAlarmDetails}
          onClick={onClickHandler}
          zIndex={mapPinZIndex}
        />
      )}
    </>
  );
};

AlarmMapMarker.defaultProps = {
  routing: (null as unknown) as RouterStore,
};

export default inject('routing')(observer(AlarmMapMarker));
