import React from 'react';

import { Grid } from '@mui/material';

import UserRow from 'components/UserRow';

import { IncidentResponse } from 'utils/api/types';

import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import { StyledRowTitle } from './styles';
import { Value } from '../../../EditableTextInput/styles';
import DetailsCreated from '../../../DetailsCreated';

const NoneEditableValuesOriginalView: React.FunctionComponent<{ details: IncidentResponse }> = ({
  details,
}): React.ReactElement => (
  <Grid container sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px', padding: '30px' }}>
    <Grid item xs={4}>
      <StyledRowTitle>Incident ID</StyledRowTitle>
    </Grid>
    <Grid item xs={4}>
      <StyledRowTitle>Submitted</StyledRowTitle>
    </Grid>
    <Grid item xs={4}>
      <StyledRowTitle>Reporter</StyledRowTitle>
    </Grid>
    <Grid item xs={4}>
      <Value>{`#INC${details.incidentUid}`}</Value>
    </Grid>
    <Grid item xs={4}>
      <DetailsCreated />
    </Grid>
    <Grid item xs={4}>
      {details.creator ? <UserRow user={details.creator} /> : <EmptyValue>-</EmptyValue>}
    </Grid>
  </Grid>
);

export default NoneEditableValuesOriginalView;
