import React from 'react';
import { Polyline } from '@react-google-maps/api';

import { AlertStatus } from 'core/constants';

import { BreadCrumbsProps } from '../../types';

import { getStrokeColor, calculateInclination } from '../../utils';
import CircleMarker from '../CircleMarker';

const lineIcon: google.maps.IconSequence = {
  icon: {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 3,
  },
  offset: '0',
  repeat: '10px',
};

const BreadCrumbs: React.FunctionComponent<BreadCrumbsProps> = ({ path }) => (
  <>
    {path.map((el, index) => {
      const nextEl = path[index + 1];
      const strokeColor = getStrokeColor(
        el.alertsStatus || AlertStatus.NoAlert,
        nextEl ? nextEl.alertsStatus : AlertStatus.NoAlert
      );

      const basicOptions = {
        strokeColor,
        strokeWeight: 1,
        strokeOpacity: 0,
        icons: [lineIcon],
      };

      return (
        <>
          {index === 1 && (
            <Polyline
              key={`${el.uuid}_${el.lat}_${index}`}
              path={[path[0], path[1]]}
              options={{
                ...basicOptions,
                icons: [
                  ...basicOptions.icons,
                  {
                    icon: {
                      strokeOpacity: 1,
                      scale: 3,
                      strokeWeight: 3,
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      rotation: calculateInclination(path[0], path[1] || null),
                    },
                    offset: '50%',
                    fixedRotation: true,
                  },
                ],
              }}
            />
          )}
          {index > 0 && <Polyline key={`${el.uuid}_${el.lat}_${index}`} path={[el, nextEl]} options={basicOptions} />}
          <CircleMarker
            el={el}
            key={`${el.uuid}_${el.lat}_${index}`}
            lastElement={index === path.length - 1}
            checkIfNextAlertIsAlsoRed={
              el.alertsStatus === AlertStatus.RedAlert &&
              (nextEl ? nextEl.alertsStatus === AlertStatus.RedAlert : false)
            }
          />
        </>
      );
    })}
  </>
);

export default BreadCrumbs;
