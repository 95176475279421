import styled from 'styled-components';

export const FormErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px 6px 16px;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), #d32f2f;
  transition: all 0.2s;
`;

export const InnerFormErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
`;

export const FormInfoContainer = styled(FormErrorContainer)`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), #0288d1;
`;
