import React from 'react';

import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { Button } from 'vatix-ui/lib/components/Button';

import { FormControl, FormControlLabel, Menu, MenuItem, Radio, RadioGroup } from '@mui/material';

import api from 'utils/api';

import { useStore } from 'utils/hooks/store';

import CustomModal from 'components/CustomModal';

import { exportToCSV } from './utils';
import { ToolbarExportProps } from './types';

const ToolbarExport: React.FunctionComponent<ToolbarExportProps> = ({
  url,
  columnsState,
  columnsDefinition,
  onlyBasicExport,
}) => {
  const { notification, session } = useStore();

  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openSelect = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (onlyBasicExport) {
      setAnchorEl(event.currentTarget);
    } else {
      setOpenModal(true);
    }
  };

  const handleClose = (): void => {
    if (onlyBasicExport) {
      setAnchorEl(null);
    } else {
      setOpenModal(false);
    }
  };

  const [exportType, setExportType] = React.useState('current');
  const handleChangeOfExportType = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setExportType((event.target as HTMLInputElement).value);
  };

  const handleExport = async (): Promise<void> => {
    const { baseUrl, params } = await exportToCSV(url, columnsState, columnsDefinition);
    if (exportType === 'all') {
      // 1. all records exportType === 'all'
      try {
        await api.sendExportEmail(`${baseUrl}export/`)();
        notification.enqueueSuccessSnackbar(
          `We will send a download link to ${session.user?.email} as soon as it is ready.`
        );
      } catch (e) {
        notification.enqueueErrorSnackbar('Failed to send export email');
      }
    } else {
      // 2. current view exportType === 'current' AND immediately download
      // 3. basic export exportType === 'current' AND immediately download
      try {
        await api.downloadAllRowsToCSV(`${baseUrl}csv/`, params)();
      } catch (e) {
        notification.enqueueErrorSnackbar('Failed to download CSV');
      }
    }

    handleClose();
  };

  return (
    <>
      <Button
        id="grid-export-button"
        aria-controls={openModal || openSelect ? 'export-grid-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openModal || openSelect ? 'true' : undefined}
        onClick={handleClick}
        sx={{ padding: '4px 5px' }}
      >
        <SaveAltIcon style={{ marginRight: '8px' }} htmlColor="rgb(75, 160, 253)" />
        <p style={{ color: 'rgb(75, 160, 253)', margin: 0 }}>Export</p>
      </Button>

      <Menu id="export-menu" anchorEl={anchorEl} open={openSelect} onClose={handleClose}>
        <MenuItem onClick={handleExport}>Download current page as CSV</MenuItem>
      </Menu>

      <CustomModal
        modalWidth="sm"
        openModal={openModal}
        onClose={handleClose}
        modalTitle="Export Data"
        modalDescription="Choose how you would like to export your data:"
        cancelButton={
          <Button id="cancel-action-button" onClick={handleClose} variant="outlined" size="large">
            Cancel
          </Button>
        }
        submitButton={
          <Button id="add-action-button" onClick={handleExport} variant="contained" size="large">
            Export
          </Button>
        }
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="export-type-radio-buttons"
            defaultValue="current"
            value={exportType}
            onChange={handleChangeOfExportType}
            name="export-type-radio-buttons"
          >
            <FormControlLabel
              slotProps={{ typography: { sx: { color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px' } } }}
              value="current"
              control={<Radio />}
              label="Export current view"
            />
            <FormControlLabel
              slotProps={{ typography: { sx: { color: 'rgba(0, 0, 0, 0.87)', fontSize: '16px' } } }}
              value="all"
              control={<Radio />}
              label="Export all records"
            />
          </RadioGroup>
        </FormControl>
      </CustomModal>
    </>
  );
};

export default ToolbarExport;
