import React from 'react';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';

import { QuestionRowProps } from 'containers/FormsDetails/types';

import { StyledTextField } from './styles';

const TextAnswer: React.FunctionComponent<QuestionRowProps> = ({
  answer,
  isSubmitted,
  updateAnswer,
}): React.ReactElement => {
  const [value, setValue] = React.useState(answer?.answer ? (answer.answer as string) : '');

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== answer?.answer && value !== '') {
        updateAnswer(answer?.questionId as string, value);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [value]);

  if (!isSubmitted) {
    return (
      <CustomTextInputField
        value={value}
        description="Write your answer here"
        onChange={(val) => {
          setValue(val as string);
        }}
      />
    );
  }

  return <StyledTextField id="question-answer">{answer?.answer as string}</StyledTextField>;
};

export default TextAnswer;
