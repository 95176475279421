import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as CustomIncidentComponent } from 'assets/icons/Custom Incident.svg';

const CustomIncident = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon component={CustomIncidentComponent} viewBox="0 0 24 24" {...props} />
);

export default CustomIncident;
