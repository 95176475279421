import React from 'react';
import { createPortal } from 'react-dom';
import { useGoogleMap } from '@react-google-maps/api';

import { MapControlProps } from './types';

const MapControl: React.FunctionComponent<MapControlProps> = ({
  position,
  children,
}): React.ReactElement | null => {
  const controlDiv = document.createElement('div');
  const map = useGoogleMap();
  React.useEffect(() => {
    if (!map) {
      return undefined;
    }

    const controls = map.controls[position];
    controls.push(controlDiv);

    return () => {
      const index = controls.getArray().indexOf(controlDiv);
      if (index > -1) {
        controls.removeAt(index);
      }
    };
  }, [position, map, controlDiv]);

  if (!map) return null;

  return createPortal(<div>{children}</div>, controlDiv);
};

export default MapControl;
