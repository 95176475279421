import dayjs, { ConfigType } from 'dayjs';

import prettyMilliseconds from 'pretty-ms';
import formatDuration from 'format-duration';

import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATETIME_FORMAT_WITHOUT_YEAR,
  DEFAULT_DATE_FORMAT,
  DEFAULT_FULL_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DOTS_FULL_DATE_FORMAT,
  FULL_DATETIME_FORMAT,
} from 'core/constants';

export const msToTimeVerbose = (time: number): string => prettyMilliseconds(time, { secondsDecimalDigits: 0 });

export const msToTime = (time: number): string => formatDuration(time);

export function formatDate(date: ConfigType): string {
  return dayjs(date).format(DEFAULT_DATE_FORMAT);
}

export function formatFullDateWithDots(date: ConfigType): string {
  return dayjs(date).format(DOTS_FULL_DATE_FORMAT);
}

export function formatFullDate(date: ConfigType): string {
  return dayjs(date).format(DEFAULT_FULL_DATE_FORMAT);
}

export function formatTime(date: ConfigType): string {
  return dayjs(date).format(DEFAULT_TIME_FORMAT);
}

export function formatFullDateTime(date: ConfigType): string {
  return dayjs(date).format(FULL_DATETIME_FORMAT);
}

export function formatDateTime(date: ConfigType): string {
  const dayjsDate = dayjs(date);
  return dayjsDate.year() === dayjs().year()
    ? dayjsDate.format(DEFAULT_DATETIME_FORMAT_WITHOUT_YEAR)
    : dayjsDate.format(DEFAULT_DATETIME_FORMAT);
}

export function formatDateForDisplay(date: ConfigType): string {
  return dayjs(date)
    .utc()
    .local()
    .format('DD MMM YYYY, HH:mm');
}

export function useTimeZone(): string {
  const offSet = new Date().getTimezoneOffset() / 60;
  if (offSet < 0) {
    return `Etc/GMT${offSet}`;
  }
  if (offSet > 0) {
    return `Etc/GMT%2B${offSet}`;
  }
  return 'Etc/GMT';
}
