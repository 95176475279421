import React, { useEffect } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ObjectSchema } from 'yup';

import { AxiosError } from 'axios';

import { apiErrors, formErrors, isBadRequest, isUnauthorized } from 'utils/api/errors';
import { useStore } from 'utils/hooks/store';
import API from 'utils/api';
import { LoginStatus, LoginType } from 'utils/api/types';
import routes from 'core/routes';
import Logger from 'utils/logger';
import { basicAuthHeader } from 'utils/api/auth';

import ConfirmVerificationCode from './components/ConfirmVerificationCode';

import { LoginFormValues, LoginStep } from './types';
import { EnterCredentialsSchema, EnterEmailSchema, Confirm2FACodeSchema, VerifyPhoneNumberSchema } from './schema';

import EnterCredentials from './components/EnterCredentials';
import EnterEmail from './components/EnterEmail';
import VerifyPhoneNumber from './components/VerifyPhoneNumber';

import { FormContainer, LoginPageContainer, LoginPageWrapper } from './styles';

const Login: React.FunctionComponent = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#FFF';
  }, []);

  const { session, routing, notification } = useStore();
  const [step, setStep] = React.useState(LoginStep.EnterEmail);
  const [loginCodeInvalidToken, setLoginCodeInvalidToken] = React.useState(false);

  const initialValues: LoginFormValues = {
    username: '',
    password: '',
    phoneNumber: '',
    verificationCode: '',
  };

  const login = async (): Promise<void> => {
    try {
      const response = await API.profile()();
      session.login(response.data);
      routing.push(String(routes.dashboard.home));
    } catch (e) {
      if (!isUnauthorized(e as AxiosError)) {
        Logger.error('Invalid profile API response', e);
      }
    }
  };

  const getFusionAuthClientId = async (username: string): Promise<string | undefined> => {
    const resp = await API.getFusionAuthApplicationData({ username })();
    const { applicationClientId } = resp.data;
    return applicationClientId;
  };

  const submitCredentials = async ({ username, password }: LoginFormValues): Promise<void> => {
    try {
      if (password) {
        const {
          data: { status },
        } = await API.login({
          username,
          password,
        })();
        if (status === LoginStatus.LoggedIn) {
          login();
        } else if (status === LoginStatus.Enable2FA) {
          setStep(LoginStep.VerifyPhoneNumber);
        } else if (status === LoginStatus.Confirm2FA) {
          setStep(LoginStep.ConfirmLoginCode);
        } else {
          Logger.error(`Unknown status: ${status}`);
        }
      } else {
        const fusionAuthApplicationId = await getFusionAuthClientId(username);

        if (fusionAuthApplicationId) {
          window.location.assign(`${process.env.REACT_APP_ADMIN_OIDC_URL}?username=${encodeURIComponent(username)}`);
          return;
        }

        const {
          data: { type, redirect },
        } = await API.login({ username })();
        switch (type) {
          case LoginType.Traditional:
            setStep(LoginStep.EnterCredentials);
            break;
          case LoginType.OAuth:
            window.location.assign(redirect as string);
            break;
        }
      }
    } catch (e) {
      const errors = apiErrors(e as AxiosError, 'Incorrect Username or Password');
      errors.forEach((message) => notification.enqueueErrorSnackbar(message));
    }
  };

  const verifyPhoneNumber = async ({ username, password, phoneNumber }: LoginFormValues): Promise<void> => {
    await API.verifyPhoneNumber({ phoneNumber }, basicAuthHeader(username, password))();
    setStep(LoginStep.ConfirmVerificationCode);
  };

  const confirmVerificationCode = async ({
    username,
    password,
    phoneNumber,
    verificationCode,
  }: LoginFormValues): Promise<void> => {
    try {
      await API.confirmVerificationCode({ phoneNumber, verificationCode }, basicAuthHeader(username, password))();
      login();
    } catch (e) {
      const errors = apiErrors(e as AxiosError, 'Invalid token');
      if (errors) {
        setLoginCodeInvalidToken(true);
      }
    }
  };

  const confirmLoginCode = async ({ username, password, verificationCode: token }: LoginFormValues): Promise<void> => {
    try {
      await API.confirmLoginCode({ token }, basicAuthHeader(username, password))();
      login();
    } catch (e) {
      const errors = apiErrors(e as AxiosError, 'Invalid token');
      if (errors) {
        setLoginCodeInvalidToken(true);
      }
    }
  };

  const onSubmit = async (values: LoginFormValues, helpers: FormikHelpers<LoginFormValues>): Promise<void> => {
    try {
      helpers.setSubmitting(true);
      if ([LoginStep.EnterEmail, LoginStep.EnterCredentials].includes(step)) {
        await submitCredentials(values);
      } else if (step === LoginStep.ConfirmVerificationCode) {
        await confirmVerificationCode(values);
      } else if (step === LoginStep.ConfirmLoginCode) {
        await confirmLoginCode(values);
      } else {
        Logger.error(`Unknown step: ${step}`);
      }
    } catch (e) {
      if (!isBadRequest(e as AxiosError)) {
        Logger.error('Unable to log in', e);
      }
      const { nonFieldErrors, fieldErrors } = formErrors(e as AxiosError);
      helpers.setSubmitting(false);
      if (fieldErrors) {
        helpers.setErrors(fieldErrors);
      }
      if (nonFieldErrors) {
        helpers.setStatus(nonFieldErrors);
      }
    }
  };

  const onResendCodeClick = async ({ username, password, phoneNumber }: LoginFormValues): Promise<void> => {
    try {
      await API.verifyPhoneNumber({ phoneNumber }, basicAuthHeader(username, password))();
      notification.enqueueSuccessSnackbar('Confirmation code has been sent');
    } catch (e) {
      Logger.error('Invalid verify phone number API response', e);
      const errors = apiErrors(e as AxiosError, 'Confirmation code cannot be sent right now');
      errors.forEach((message) => notification.enqueueErrorSnackbar(message));
    }
  };

  const onResendLoginCodeClick = async ({ username, password }: LoginFormValues): Promise<void> => {
    try {
      await API.resendLoginCode(basicAuthHeader(username, password))();
      notification.enqueueSuccessSnackbar('Login code has been sent');
    } catch (e) {
      Logger.error('Invalid resend login code API response', e);
      const errors = apiErrors(e as AxiosError, 'Login code cannot be sent right now');
      errors.forEach((message) => notification.enqueueErrorSnackbar(message));
    }
  };

  // @ts-ignore
  const getValidationSchema = (): ObjectSchema => {
    if (step === LoginStep.EnterEmail) {
      return EnterEmailSchema;
    }
    if (step === LoginStep.EnterCredentials) {
      return EnterCredentialsSchema;
    }
    if (step === LoginStep.VerifyPhoneNumber) {
      return VerifyPhoneNumberSchema;
    }
    if (step === LoginStep.ConfirmVerificationCode) {
      return Confirm2FACodeSchema;
    }
    if (step === LoginStep.ConfirmLoginCode) {
      return Confirm2FACodeSchema;
    }
    throw new Error(`Unknown step: ${step}`);
  };

  return (
    <LoginPageWrapper>
      <LoginPageContainer>
        <FormContainer>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getValidationSchema}>
            {({ errors, touched }) => (
              <Form>
                {step === LoginStep.EnterEmail && (
                  <EnterEmail
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    setStep={setStep}
                  />
                )}

                {step === LoginStep.EnterCredentials && (
                  <EnterCredentials
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    setStep={setStep}
                  />
                )}

                {step === LoginStep.VerifyPhoneNumber && (
                  <VerifyPhoneNumber
                    twoFactorAuthCallback={verifyPhoneNumber}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    setStep={setStep}
                  />
                )}

                {step === LoginStep.ConfirmVerificationCode && (
                  <ConfirmVerificationCode
                    twoFactorAuthCallback={onResendCodeClick}
                    error={loginCodeInvalidToken}
                    digits={6}
                    setStep={setStep}
                    withBackButton
                  />
                )}

                {step === LoginStep.ConfirmLoginCode && (
                  <ConfirmVerificationCode
                    twoFactorAuthCallback={onResendLoginCodeClick}
                    error={loginCodeInvalidToken}
                    digits={7}
                  />
                )}
              </Form>
            )}
          </Formik>
        </FormContainer>
      </LoginPageContainer>
    </LoginPageWrapper>
  );
};

export default Login;
