import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid #e2e4e6;
  background: #fff;
`;

export const Header = styled.h1`
  color: var(--light-text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: 0.15px;
  margin: 16px 20px 16px 20px;
  padding: 0;
`;

export const GridContainer = styled.div`
  margin: 16px 20px 20px 20px;
`;
