import styled from 'styled-components';
import TextField from '@mui/material/TextField';

import VatixTheme from 'theme/vatix';

export const StyledTextField = styled(TextField)`
  background-color: #fff;
  margin-top: 0;

  label {
    color: #d3d6d9;
    font-weight: bold;
    transform: initial;
    transform-origin: initial;
    font-size: 1rem;
    line-height: 2rem;
    opacity: ${(props) => (props.disabled ? '0.38' : '1')};

    &.input-label-error {
      color: #d3d6d9;
    }
  }
  &:focus-within label {
    color: #0772e5;
  }

  input {
    padding: 8px 0;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${VatixTheme.palette.text.secondary};
    height: 2rem;
  }

  textarea {
    padding: 8px 0;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${VatixTheme.palette.text.secondary};
  }

  .select-select {
    padding: 8px 0;
    line-height: 2rem;
    font-size: 1.4rem;
    color: ${VatixTheme.palette.text.secondary};
  }

  .select-select.multiple {
    min-width: 180px;
    padding-right: 12px;
  }

  .input-disabled {
    opacity: 0.38;
  }

  .input-root {
    &:before {
      border-bottom: 1px solid #d3d6d9;
    }

    &.input-underline:after {
      border-bottom-width: 3px;
      border-bottom-color: #0772e5;
    }

    &.input-underline.input-error:after {
      border-bottom-color: #ff0000;
    }

    input::placeholder {
      opacity: 0.8;
      color: ${VatixTheme.palette.text.secondary};
    }
  }

  .input-multiline {
    padding: 0;
  }
`;

export const TextFieldWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
`;
