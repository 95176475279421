import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import Logger from 'utils/logger';
import { AlarmResponse, AmberAlarmResponse, LocationDetailsResponse } from 'utils/api/types';
import RootStore from 'stores/Root';
import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import {
  ActionEvent,
  ReverseGeocodedLocationPayload,
  UpdatedProfilePayload,
  UpdatedUserPayload,
} from 'utils/events/types';

export default class LocationDetails {
  store: RootStore;

  api: typeof API;

  selectedLocation?: string;

  @observable details?: LocationDetailsResponse;

  @observable openedAlarmDetails: AlarmResponse | AmberAlarmResponse | null = null;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    eventBus.register(this);
  }

  @action.bound
  async openDetails(locationId: string): Promise<void> {
    if (this.selectedLocation === locationId) {
      return;
    }

    this.closeDetails();
    this.selectedLocation = locationId;

    try {
      const response = await this.api.loadLocationDetails(locationId)();
      runInAction(() => {
        if (this.selectedLocation === response.data.uuid) {
          this.details = response.data;
        }
      });
    } catch (e) {
      this.selectedLocation = undefined;
      this.store.notification.enqueueErrorSnackbar('Location details cannot be displayed right now.');
      Logger.error(`Invalid load location details API response. Location Id: ${locationId}`, e);
    }
  }

  @action.bound
  async openAlarmDetails(alarm: AlarmResponse | AmberAlarmResponse): Promise<void> {
    this.openedAlarmDetails = alarm;
  }

  @action.bound
  closeAlarmDetails(): void {
    this.openedAlarmDetails = null;
  }

  @action.bound
  closeDetails(): void {
    this.selectedLocation = undefined;
    this.details = undefined;
  }

  @subscribe(EventType.ReverseGeocodedLocation)
  @action
  reverseGeocodedLocation({ payload }: ActionEvent<ReverseGeocodedLocationPayload>): void {
    if (!this.details || payload.uuid !== this.details.uuid) {
      return;
    }

    this.details.address = payload.address;
  }

  @subscribe(EventType.UpdatedUser)
  @subscribe(EventType.UpdatedProfile)
  @action
  updatedUser({ payload }: ActionEvent<UpdatedUserPayload | UpdatedProfilePayload>): void {
    if (!this.details || payload.uuid !== this.details.user.uuid) {
      return;
    }

    this.details.user.name = payload.name;
  }
}
