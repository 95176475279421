import { action, observable, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { AlertStatus } from 'core/constants';
import API from 'utils/api';
import { UserDetailsResponse, TagResponse, SharedDeviceResponse, AmberAlert } from 'utils/api/types';
import { EventType } from 'utils/events/constants';

import {
  ActionEvent,
  CancelledAmberAlertPayload,
  CompletedRedAlertPayload,
  CreatedAmberAlertPayload,
  CreatedRedAlertPayload,
} from 'utils/events/types';
import UserDetailsRedAlert from 'stores/UserDetails/UserDetailsRedAlert';
import UserPersonalDetails from 'stores/UserDetails/UserPersonalDetails';

import EditUserData from '../EditUser/EditUserData';
import RootStore from '../Root';

export default class UserDetailsData extends EditUserData {
  store: RootStore;

  api: typeof API;

  @observable activityStatus: AlertStatus;

  @observable redAlert: UserDetailsRedAlert | null = null;

  @observable showCompletedRedAlertNotification = false;

  @observable tags: TagResponse[] = [];

  @observable devices: SharedDeviceResponse[];

  customId: string;

  @observable showSafety = false;

  @observable amberAlert: AmberAlert | null;

  constructor(rootStore: RootStore, api: typeof API, data: UserDetailsResponse) {
    super(data);

    makeObservable(this);

    this.store = rootStore;
    this.api = api;
    this.alarmResponseProfile = data.alarmResponseProfile;
    this.activityStatus = data.activityStatus;
    this.contactDetails = data.contactDetails;
    this.customId = data.customId;
    this.tags = data.tags;
    this.devices = data.devices;
    this.showSafety = data.showSafety;
    this.amberAlert = data.amberAlert;
    this.groups = data.groups;
    this.licenses = data.licenses;
    this.dateJoined = data.dateJoined;

    if (data.redAlert) {
      this.redAlert = new UserDetailsRedAlert(rootStore, api, data.redAlert, this);
    }
  }

  @subscribe(EventType.UpdatedUser)
  @action
  updatedUser({ payload }: ActionEvent<UserDetailsResponse>): void {
    if (payload.uuid !== this.uuid) {
      return;
    }

    this.name = payload.name;
    this.phoneNumber = payload.phoneNumber;
    this.role = payload.role;
    this.organisation = payload.organisation;
    this.personalDetails = new UserPersonalDetails(payload.personalDetails);
    this.vehicleDetails = payload.vehicleDetails;
    this.contactDetails = payload.contactDetails;
    this.tags = payload.tags;
    this.alarmResponseProfile = payload.alarmResponseProfile;
    this.showSafety = payload.showSafety;
    this.devices = payload.devices;
    this.licenses = payload.licenses;
    this.groups = payload.groups;
    this.email = payload.email;
  }

  @subscribe(EventType.CreatedRedAlert)
  @action
  createdRedAlert({ payload }: ActionEvent<CreatedRedAlertPayload>): void {
    if (payload.user.uuid !== this.uuid) {
      return;
    }

    this.activityStatus = AlertStatus.RedAlert;
    this.showCompletedRedAlertNotification = false;
    this.redAlert && eventBus.unregister(this.redAlert);
    this.redAlert = new UserDetailsRedAlert(this.store, this.api, payload, this);
  }

  @subscribe(EventType.CompletedRedAlert)
  @action
  completedRedAlert({ payload }: ActionEvent<CompletedRedAlertPayload>): void {
    if (payload.user.uuid !== this.uuid) {
      return;
    }

    this.activityStatus = AlertStatus.NoAlert;
    this.redAlert && eventBus.unregister(this.redAlert);
    this.redAlert = null;
    this.showCompletedRedAlertNotification = true;
  }

  @subscribe(EventType.CancelledAmberAlert)
  @action
  cancelledAmberAlert({ payload }: ActionEvent<CancelledAmberAlertPayload>): void {
    if (payload.user.uuid !== this.uuid) {
      return;
    }

    this.activityStatus = AlertStatus.NoAlert;
  }

  @subscribe(EventType.CreatedAmberAlert)
  @action
  createdAmberAlert({ payload }: ActionEvent<CreatedAmberAlertPayload>): void {
    if (payload.user.uuid !== this.uuid) {
      return;
    }

    this.activityStatus = AlertStatus.AmberAlert;
  }

  @action.bound
  hideCompletedRedAlertNotification(): void {
    this.showCompletedRedAlertNotification = false;
  }

  @action.bound
  reset(): void {
    if (this.redAlert) {
      eventBus.unregister(this.redAlert);
    }
  }
}
