import React from 'react';

import { Grid } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

import EditIcon from '@mui/icons-material/Edit';

import Logger from 'vatix-ui/lib/utils/logger/Logger';

import Axios, { AxiosResponse } from 'axios';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import api from 'utils/api';

import { useStore } from 'utils/hooks/store';

import { MAX_LOGO_SIZE } from 'core/constants';

import { TaskRecordContainer, Title, HeaderGrid } from './styles';
import EditingView from './components/EditingView';

const CompanyLogo: React.FunctionComponent = (): React.ReactElement => {
  const { notification, session } = useStore();
  const fileRef = React.useRef(null);

  const [editing, setEditing] = React.useState<boolean>(false);
  const [newLogo, setNewLogo] = React.useState<File | undefined>(undefined);
  const [hasOrganisationLogo, setHasOrganisationLogo] = React.useState(true);
  const [isVisible, setIsVisible] = React.useState(true);

  const handleReplaceClick = (): void => {
    (fileRef.current || { click: () => undefined }).click();
  };

  const onDelete = (): void => {
    setNewLogo(undefined);
    setHasOrganisationLogo(false);

    setIsVisible(false);
    setTimeout(() => setIsVisible(true), 0);
  };

  const onSave = async (): Promise<void> => {
    if (newLogo === undefined) {
      try {
        await api.deleteLogoFile()();
        session.loadOrganisationLogo();
        setEditing(false);
        notification.enqueueSuccessSnackbar('Logo removed successfully');
      } catch (e) {
        Logger.error(`Invalid delete logo file API result.`, e);
        notification.enqueueErrorSnackbar('Failed to remove logo. Please check your file and try again.');
      }
    } else {
      const cancelTokenSource = Axios.CancelToken.source();

      try {
        await api.uploadLogoFile(newLogo as File, cancelTokenSource)();
        session.loadOrganisationLogo();
        setEditing(false);
        notification.enqueueSuccessSnackbar('Logo uploaded successfully. Your company logo is now updated.');
      } catch (e) {
        Logger.error(`Invalid add logo file API result.`, e);
        notification.enqueueErrorSnackbar('Failed to upload logo. Please check your file and try again.');
      }
    }
  };

  const onFileUpload = async ({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (target.files && target.files[0]) {
      const file = target.files[0];
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        if (file.size > MAX_LOGO_SIZE) {
          notification.enqueueErrorSnackbar('File size exceeds the limit of 2 MB.');
          return;
        }
        setNewLogo(file);
      } else {
        notification.enqueueErrorSnackbar('Unsupported file format. Please select a PNG or JPEG file.');
      }
    }
  };

  React.useEffect(() => {
    const loadOrganisationLogo = (): void => {
      setHasOrganisationLogo(true);
      api
        .loadOrganisationLogo()()
        .then((response: AxiosResponse<Blob>) => {
          const objectURL = URL.createObjectURL(response.data);
          if (response.status === 204) {
            setHasOrganisationLogo(false);
          } else {
            setHasOrganisationLogo(true);
          }
          const imageElement = document.querySelector('#company-logo-settings-page') as HTMLImageElement;
          if (imageElement) {
            imageElement.src = objectURL;
          }
        })
        .catch((error: Error) => {
          Logger.error('Invalid update user details API response', error);
        });
    };

    loadOrganisationLogo();
  }, [editing]);

  return (
    <>
      {isVisible && <input hidden onChange={onFileUpload} type="file" ref={fileRef} />}
      <HeaderGrid container alignItems="center">
        <Grid item container xs={10} alignContent="flex-start">
          <Title>Company Logo</Title>
        </Grid>

        {hasOrganisationLogo ? (
          <Grid item container xs={2} justifyContent="end">
            {!editing && (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setEditing((prevState) => !prevState);
                  setNewLogo(undefined);
                }}
                style={{ padding: 0, fontSize: '14px' }}
              >
                <EditIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
                Edit
              </Button>
            )}
          </Grid>
        ) : null}
      </HeaderGrid>
      <TaskRecordContainer>
        {editing ? (
          <EditingView
            onSave={onSave}
            newLogo={newLogo}
            onDelete={onDelete}
            setNewLogo={setNewLogo}
            onCancel={() => setEditing(false)}
            handleReplaceClick={handleReplaceClick}
            hasOrganisationLogo={hasOrganisationLogo}
          />
        ) : (
          <>
            {hasOrganisationLogo ? (
              <img alt="company-logo-settings-page" id="company-logo-settings-page" style={{ maxHeight: '65px' }} />
            ) : (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setEditing((prevState) => !prevState);
                  setNewLogo(undefined);
                }}
                style={{ padding: 0, fontSize: '14px' }}
              >
                <UploadFileIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
                Upload Logo
              </Button>
            )}
          </>
        )}
      </TaskRecordContainer>
    </>
  );
};

export default CompanyLogo;
