import TableStore from 'vatix-ui/lib/utils/stores/TableStore';

import { observable, makeObservable } from 'mobx';

import API from 'utils/api';

import { DeviceAssignmentResponse } from 'utils/api/types';

import RootStore from '../Root';

export default class DeviceHistoryStore extends TableStore<
  RootStore,
  typeof API,
  DeviceAssignmentResponse,
  DeviceAssignmentResponse
> {
  @observable deviceId: string;

  constructor(rootStore: RootStore, api: typeof API, deviceId: string) {
    super(rootStore, api, (params) => api.loadDeviceHistory(this.deviceId, params));
    makeObservable(this);
    this.sortingColumn = 'created';
    this.sortingOrder = 'desc';
    this.deviceId = deviceId;
  }
}
