import React from 'react';

export function useLocalStorage<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
  const [value, setValue] = React.useState<T>(() => {
    const result = localStorage.getItem(key);
    if (result) {
      return JSON.parse(result);
    }
    return defaultValue;
  });

  const removeValue = (): void => localStorage.removeItem(key);

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [
    value,
    (newValue) => {
      setValue(newValue);
    },
    removeValue,
  ];
}
