import React from 'react';
import { FormControl } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import { DateTimePickerProps } from '@mui/x-date-pickers';

import { StyledDateTimePicker, StyledMobileDateTimePicker } from 'components/Input/styles';

import { ProtectorTypeBaseProps } from '../types';
import CustomActionBar from '../CustomPickerActionBar/CustomPickerActionBar';

export const CustomDateTimePicker = ({
  value,
  onChange,
  requiredNotFilled,
}: ProtectorTypeBaseProps): React.ReactElement => {
  const [val, setValue] = React.useState<Dayjs | null>(value ? dayjs(value as string) : null);
  const [error, setError] = React.useState('');

  const handleChange = (newValue: Dayjs | null): void => {
    if (newValue && Object.prototype.toString.call(newValue) === '[object Object]') {
      //  @ts-ignore
      if (isNaN(newValue)) {
        onChange('Invalid date or time');
        setError('Invalid date or time');
      } else {
        setValue(newValue);
        onChange(newValue ? new Date(newValue.format()).getTime() : null);
        setError('');
      }
    } else {
      setValue(null);
      onChange(null);
      setError('');
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleToggleOpen = (): void => {
    setOpen((prev) => !prev);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const commonDateTimeProps: Partial<DateTimePickerProps<Dayjs>> = {
    open,
    onClose: handleClose,
    ampmInClock: false,
    ampm: false,
    value: val,
    slots: {
      actionBar: CustomActionBar,
      toolbar: () => null,
    },
    format: 'DD/MM/YYYY HH:mm',
    slotProps: {
      field: { clearable: true },
      textField: {
        helperText: error,
        onClick: handleToggleOpen,
        InputProps: {
          ...(window.innerWidth < 768 && {
            endAdornment: <InsertInvitationIcon htmlColor="rgba(0, 0, 0, 0.54)" />,
          }),
          defaultValue: '',
        },
        placeholder: 'DD/MM/YYYY hh:mm',
        error: requiredNotFilled,
      },
    },
    desktopModeMediaQuery: '@media (min-width: 320px)',
    sx: {
      '& + .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  };

  return (
    <FormControl fullWidth>
      {window.innerWidth < 768 ? (
        <StyledMobileDateTimePicker
          {...commonDateTimeProps}
          ampmInClock={false}
          ampm={false}
          // @ts-ignore
          onChange={(date: Dayjs) => handleChange(date)}
        />
      ) : (
        <StyledDateTimePicker {...commonDateTimeProps} onChange={(date: Dayjs | null) => handleChange(date)} />
      )}
    </FormControl>
  );
};

export default CustomDateTimePicker;
