import React from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import AlarmResponseProfileChip from 'components/AlarmResponseProfileChip';

import { ResponseProfileCellProps } from './types';

const ResponseProfileCell: React.FunctionComponent<ResponseProfileCellProps> = (props) => {
  const { rowData, actions } = props;
  const { session } = useStore();

  return <AlarmResponseProfileChip session={session} user={rowData} actions={actions} trimmable />;
};

export default observer(ResponseProfileCell);
