import React from 'react';
import { Skeleton } from '@mui/material';

import { HomeSectionHeader, HomeSectionHeaderContainer } from 'containers/Home/styles';

import {
  EmptyTaskCellLeftSkeletonsContainer,
  EmptyTaskCellRightSkeletonsContainer,
  TaskListCell,
  TaskSummaryCell,
  TaskSummaryRow,
  TasksContainer,
  TasksListContainer,
  TasksSummaryContainer,
} from './styles';

const TasksPaneSkeleton: React.FunctionComponent = () => (
  <>
    <HomeSectionHeaderContainer>
      <HomeSectionHeader>My Actions</HomeSectionHeader>
    </HomeSectionHeaderContainer>
    <TasksContainer aria-label="tasks pane loading skeletons">
      <TasksSummaryContainer>
        <TaskSummaryRow>
          <TaskSummaryCell style={{ minHeight: 137 }}>
            <Skeleton sx={{ bgcolor: '#F0F0F0' }} variant="rounded" height={21} width={128} />
            <Skeleton sx={{ bgcolor: '#E7E6E6' }} variant="rounded" height={54} width={223} />
          </TaskSummaryCell>
          <TaskSummaryCell style={{ minHeight: 137 }}>
            <Skeleton sx={{ bgcolor: '#F0F0F0' }} variant="rounded" height={21} width={128} />
            <Skeleton sx={{ bgcolor: '#E7E6E6' }} variant="rounded" height={54} width={223} />
          </TaskSummaryCell>
        </TaskSummaryRow>
        <TaskSummaryRow>
          <TaskSummaryCell style={{ minHeight: 137 }}>
            <Skeleton sx={{ bgcolor: '#F0F0F0' }} variant="rounded" height={21} width={128} />
            <Skeleton sx={{ bgcolor: '#E7E6E6' }} variant="rounded" height={54} width={223} />
          </TaskSummaryCell>
          <TaskSummaryCell style={{ minHeight: 137 }}>
            <Skeleton sx={{ bgcolor: '#F0F0F0' }} variant="rounded" height={21} width={128} />
            <Skeleton sx={{ bgcolor: '#E7E6E6' }} variant="rounded" height={54} width={223} />
          </TaskSummaryCell>
        </TaskSummaryRow>
      </TasksSummaryContainer>
      <TasksListContainer>
        {[...new Array(4)].map((skeleton, index) => (
          <TaskListCell key={`task_list_skeleton_${index}`} style={{ minHeight: 66 }}>
            <EmptyTaskCellLeftSkeletonsContainer>
              <Skeleton sx={{ bgcolor: '#E7E6E6' }} variant="rounded" height={24} width={55} />
              <Skeleton sx={{ bgcolor: '#F0F0F0' }} variant="rounded" height={20} width={177} />
            </EmptyTaskCellLeftSkeletonsContainer>
            <EmptyTaskCellRightSkeletonsContainer>
              <Skeleton sx={{ bgcolor: '#F0F0F0' }} variant="rounded" height={17} width={128} />
              <Skeleton sx={{ bgcolor: '#F0F0F0' }} variant="rounded" height={24} width={24} />
            </EmptyTaskCellRightSkeletonsContainer>
          </TaskListCell>
        ))}
      </TasksListContainer>
    </TasksContainer>
  </>
);

export default TasksPaneSkeleton;
