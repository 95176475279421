import { inject, observer } from 'mobx-react';
import React from 'react';
import { PoseGroup } from 'react-pose';

import { UserRole } from 'core/constants';

import PendingRedAlertsStore from '../../stores/PendingRedAlerts';
import { SessionStoreWithUser } from '../../stores/Session/types';
import { PopUpRedAlertsContainer } from './styles';
import { InjectedPopUpRedAlertsProps } from './types';
import PopUpRedAlert from './components/PopUpRedAlert';

const PopUpRedAlerts = ({ pendingRedAlerts, session }: InjectedPopUpRedAlertsProps): React.ReactElement | null => {
  if (session.user.role !== UserRole.ArcOperator || !pendingRedAlerts.popUpAlerts.length) {
    return null;
  }

  return (
    <PopUpRedAlertsContainer>
      <PoseGroup animateOnMount preEnterPose="preEnter">
        {pendingRedAlerts.popUpAlerts.map((alert, index) => (
          <PopUpRedAlert
            key={alert.uuid}
            alert={alert}
            isMostRecent={index + 1 === pendingRedAlerts.popUpAlerts.length}
          />
        ))}
      </PoseGroup>
    </PopUpRedAlertsContainer>
  );
};

PopUpRedAlerts.defaultProps = {
  pendingRedAlerts: (null as unknown) as PendingRedAlertsStore,
  session: (null as unknown) as SessionStoreWithUser,
};

export default inject('pendingRedAlerts', 'session')(observer(PopUpRedAlerts));
