import styled from 'styled-components';

export const RowValue = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const StyledRowTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;

export const TabTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;
