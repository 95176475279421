import { action, observable, makeObservable } from 'mobx';
import { subscribe } from 'mobx-event-bus2';

import { AlertStatus } from 'core/constants';

import { RedAlertResponse } from 'utils/api/types';

import { EventType } from '../events/constants';
import {
  CancelledAmberAlertPayload,
  CompletedRedAlertPayload,
  CreatedAmberAlertPayload,
  CreatedRedAlertPayload,
  ActionEvent,
} from '../events/types';

export default class ActivityStatusMixin {
  uuid: string;

  @observable activityStatus: AlertStatus;

  @observable redAlert: RedAlertResponse | null;

  constructor(data: { uuid: string; activityStatus: AlertStatus; redAlert: RedAlertResponse | null }) {
    makeObservable(this);
    this.uuid = data.uuid;
    this.activityStatus = data.activityStatus;
    this.redAlert = data.redAlert;
  }

  @subscribe(EventType.CreatedRedAlert)
  @action
  createdRedAlert({ payload }: ActionEvent<CreatedRedAlertPayload>): void {
    if (payload.user.uuid !== this.uuid) {
      return;
    }

    this.activityStatus = AlertStatus.RedAlert;
    this.redAlert = payload;
  }

  @subscribe(EventType.CompletedRedAlert)
  @subscribe(EventType.CancelledAmberAlert)
  @action
  endedAlert({ payload }: ActionEvent<CompletedRedAlertPayload | CancelledAmberAlertPayload>): void {
    if (payload.user.uuid !== this.uuid) {
      return;
    }

    this.activityStatus = AlertStatus.NoAlert;
    this.redAlert = null;
  }

  @subscribe(EventType.CreatedAmberAlert)
  @action
  createdAmberAlert({ payload }: ActionEvent<CreatedAmberAlertPayload>): void {
    if (payload.user.uuid !== this.uuid) {
      return;
    }

    this.activityStatus = AlertStatus.AmberAlert;
    this.redAlert = null;
  }
}
