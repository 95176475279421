/* eslint-disable max-len */
import React from 'react';

export function SitesIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M16.6158 2.16667C17.8324 2.16667 18.8324 3.16667 18.8324 4.38333C18.8324 6.36667 16.6158 8.83333 16.6158 8.83333C16.6158 8.83333 14.3991 6.38333 14.3991 4.38333C14.3991 3.16667 15.3991 2.16667 16.6158 2.16667ZM16.6158 0.5C14.4658 0.5 12.7324 2.25 12.7324 4.38333C12.7324 6.88333 14.9491 9.45 15.3824 9.95C15.6991 10.3 16.1491 10.5 16.6158 10.5C17.0824 10.5 17.5324 10.3 17.8491 9.95C18.2991 9.46667 20.4991 6.88333 20.4991 4.38333C20.4991 2.23333 18.7491 0.5 16.6158 0.5Z"
        fill="#C0C7D3"
      />
      <path
        d="M16.6165 5.29985C17.0768 5.29985 17.4499 4.92675 17.4499 4.46651C17.4499 4.00627 17.0768 3.63318 16.6165 3.63318C16.1563 3.63318 15.7832 4.00627 15.7832 4.46651C15.7832 4.92675 16.1563 5.29985 16.6165 5.29985Z"
        fill="#C0C7D3"
      />
      <path
        d="M20.0833 18.8334H0.916667C0.686548 18.8334 0.5 19.0199 0.5 19.25V20.0834C0.5 20.3135 0.686548 20.5 0.916667 20.5H20.0833C20.3135 20.5 20.5 20.3135 20.5 20.0834V19.25C20.5 19.0199 20.3135 18.8334 20.0833 18.8334Z"
        fill="#C0C7D3"
      />
      <path
        d="M7.68359 8.28333H6.85026C6.62014 8.28333 6.43359 8.46987 6.43359 8.69999V9.53333C6.43359 9.76344 6.62014 9.94999 6.85026 9.94999H7.68359C7.91371 9.94999 8.10026 9.76344 8.10026 9.53333V8.69999C8.10026 8.46987 7.91371 8.28333 7.68359 8.28333Z"
        fill="#C0C7D3"
      />
      <path
        d="M7.68359 12.3666H6.85026C6.62014 12.3666 6.43359 12.5531 6.43359 12.7832V13.6166C6.43359 13.8467 6.62014 14.0332 6.85026 14.0332H7.68359C7.91371 14.0332 8.10026 13.8467 8.10026 13.6166V12.7832C8.10026 12.5531 7.91371 12.3666 7.68359 12.3666Z"
        fill="#C0C7D3"
      />
      <path
        d="M5.81641 6.23328H4.98307C4.75295 6.23328 4.56641 6.41982 4.56641 6.64994V7.48328C4.56641 7.71339 4.75295 7.89994 4.98307 7.89994H5.81641C6.04652 7.89994 6.23307 7.71339 6.23307 7.48328V6.64994C6.23307 6.41982 6.04652 6.23328 5.81641 6.23328Z"
        fill="#C0C7D3"
      />
      <path
        d="M5.81641 10.3333H4.98307C4.75295 10.3333 4.56641 10.5198 4.56641 10.7499V11.5833C4.56641 11.8134 4.75295 11.9999 4.98307 11.9999H5.81641C6.04652 11.9999 6.23307 11.8134 6.23307 11.5833V10.7499C6.23307 10.5198 6.04652 10.3333 5.81641 10.3333Z"
        fill="#C0C7D3"
      />
      <path
        d="M5.81641 14.4165H4.98307C4.75295 14.4165 4.56641 14.6031 4.56641 14.8332V15.6665C4.56641 15.8966 4.75295 16.0832 4.98307 16.0832H5.81641C6.04652 16.0832 6.23307 15.8966 6.23307 15.6665V14.8332C6.23307 14.6031 6.04652 14.4165 5.81641 14.4165Z"
        fill="#C0C7D3"
      />
      <path
        d="M8.83268 18.8334H3.83268V6.33337C3.83268 5.86671 4.19935 5.50004 4.66602 5.50004H7.99935C8.46602 5.50004 8.83268 5.86671 8.83268 6.33337V18.8334ZM2.16602 5.50004V20.5H10.4993V5.50004C10.4993 4.58337 9.74935 3.83337 8.83268 3.83337H3.83268C2.91602 3.83337 2.16602 4.58337 2.16602 5.50004Z"
        fill="#C0C7D3"
      />
      <path
        d="M17.1673 14.75V18.8333H10.5007V14.75C10.5007 14.5833 10.6007 14.4333 10.7673 14.3667L13.684 13.2C13.784 13.1667 13.9007 13.1667 14.0007 13.2L16.9173 14.3667C17.0673 14.4333 17.184 14.5833 17.184 14.75H17.1673ZM8.83398 13.9V20.5H18.834V13.9C18.834 13.5667 18.634 13.25 18.3173 13.1333L14.1507 11.4667C13.9507 11.3833 13.734 11.3833 13.534 11.4667L9.36732 13.1333C9.05065 13.2667 8.85065 13.5667 8.85065 13.9H8.83398Z"
        fill="#C0C7D3"
      />
      <path
        d="M14.666 15.5H12.9993C12.5327 15.5 12.166 15.8667 12.166 16.3333V18.8333H15.4993V16.3333C15.4993 15.8667 15.1327 15.5 14.666 15.5Z"
        fill="#C0C7D3"
      />
    </svg>
  );
}

export function ActiveSitesIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M17.184 0.5C15.334 0.5 13.834 2 13.834 3.83333C13.834 5.98333 15.5507 8.46667 16.534 9.71667C16.8673 10.1333 17.5007 10.1333 17.834 9.71667C18.8007 8.46667 20.5007 5.96667 20.5007 3.83333C20.5007 2 19.0007 0.5 17.184 0.5ZM17.1673 5C16.6007 5 16.134 4.53333 16.134 3.95C16.134 3.36667 16.6007 2.91667 17.1673 2.91667C17.734 2.91667 18.2173 3.36667 18.2173 3.95C18.2173 4.53333 17.7507 5 17.1673 5Z"
        fill="white"
      />
      <path
        d="M20.0833 18.8333H18.8333V13.9C18.8333 13.5666 18.6333 13.2666 18.3167 13.1333L14.15 11.4666C13.95 11.3833 13.7167 11.3833 13.5167 11.4666L10.5 12.6666V5.49998C10.5 4.58331 9.75 3.83331 8.83333 3.83331H3.83333C2.91667 3.83331 2.16667 4.58331 2.16667 5.49998V18.8333H0.916667C0.683333 18.8333 0.5 19.0166 0.5 19.25V20.0833C0.5 20.3166 0.683333 20.5 0.916667 20.5H20.0833C20.3167 20.5 20.5 20.3166 20.5 20.0833V19.25C20.5 19.0166 20.3167 18.8333 20.0833 18.8333ZM5.91667 15.6666C5.91667 15.9 5.73333 16.0833 5.5 16.0833H4.66667C4.43333 16.0833 4.25 15.9 4.25 15.6666V14.8333C4.25 14.6 4.43333 14.4166 4.66667 14.4166H5.5C5.73333 14.4166 5.91667 14.6 5.91667 14.8333V15.6666ZM5.91667 11.5833C5.91667 11.8 5.73333 12 5.5 12H4.66667C4.43333 12 4.25 11.8 4.25 11.5833V10.75C4.25 10.5166 4.43333 10.3333 4.66667 10.3333H5.5C5.73333 10.3333 5.91667 10.5166 5.91667 10.75V11.5833ZM5.91667 7.48331C5.91667 7.71665 5.73333 7.89998 5.5 7.89998H4.66667C4.43333 7.89998 4.25 7.71665 4.25 7.48331V6.64998C4.25 6.41665 4.43333 6.23331 4.66667 6.23331H5.5C5.73333 6.23331 5.91667 6.41665 5.91667 6.64998V7.48331ZM8.41667 13.6166C8.41667 13.85 8.23333 14.0333 8 14.0333H7.16667C6.93333 14.0333 6.75 13.85 6.75 13.6166V12.7833C6.75 12.55 6.93333 12.3666 7.16667 12.3666H8C8.23333 12.3666 8.41667 12.55 8.41667 12.7833V13.6166ZM8.41667 9.53331C8.41667 9.76665 8.23333 9.94998 8 9.94998H7.16667C6.93333 9.94998 6.75 9.76665 6.75 9.53331V8.69998C6.75 8.46665 6.93333 8.28331 7.16667 8.28331H8C8.23333 8.28331 8.41667 8.46665 8.41667 8.69998V9.53331ZM15.5 18.8333H12.1667V16.3333C12.1667 15.8666 12.5333 15.5 13 15.5H14.6667C15.1333 15.5 15.5 15.8666 15.5 16.3333V18.8333Z"
        fill="white"
      />
    </svg>
  );
}
