import { AxiosError } from 'axios';

import { FormErrors } from './types';

function isResponseStatus(error: AxiosError, status: number): boolean {
  try {
    return !!error.response && error.response.status === status;
  } catch (e) {
    return false;
  }
}

export function isForbidden(error: AxiosError): boolean {
  return isResponseStatus(error, 403);
}

export function isUnauthorized(error: AxiosError): boolean {
  return isResponseStatus(error, 401);
}

export function isBadRequest(error: AxiosError): boolean {
  return isResponseStatus(error, 400);
}

export function isNotFound(error: AxiosError): boolean {
  return isResponseStatus(error, 404);
}

export function formErrors(error: AxiosError): FormErrors {
  const processErrorArray = (array: Record<string, string[]>[]): string[] => [
    ...new Set(array.flatMap((err) => Object.values(err).toString())),
  ];

  if (!error.response || !isBadRequest(error)) {
    return { nonFieldErrors: ['Something went wrong, please try later.'] };
  }

  const {
    response: { data },
  } = error;

  if (Array.isArray(data)) {
    let values: string[];
    if (data.every((e) => typeof e === 'object')) values = processErrorArray(data);
    else values = data;

    return { nonFieldErrors: values };
  }
  const { nonFieldErrors } = data;
  delete data.nonFieldErrors;
  return { nonFieldErrors, fieldErrors: data };
}

export function apiErrors(error: AxiosError, defaultMessage: string): string[] {
  if (!error.response || !isBadRequest(error)) {
    return [defaultMessage];
  }

  return Object.values(error.response.data as Record<string, string>).flat();
}

export function apiErrorsWithKeys(error: AxiosError, defaultMessage: string): string {
  if (!error.response || !isBadRequest(error)) {
    return defaultMessage;
  }
  const errors = error.response.data as Record<string, string>;
  const formattedStrings: string[] = [];

  Object.keys(errors).forEach((key) => {
    formattedStrings.push(`${key}: ${errors[key][0]}`);
  });
  return formattedStrings.join(' | ');
}

export function fabricateFormErrorResponse(errorData: {
  fieldErrors?: string[];
  nonFieldErrors?: string[];
}): AxiosError<unknown> {
  return ({
    response: {
      status: 400,
      data: errorData,
    },
  } as unknown) as AxiosError;
}
