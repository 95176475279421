import { useHistory } from 'react-router-dom';

import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import routes from 'core/routes';

import Grid from 'components/Grid';

import { useStore } from 'utils/hooks/store';

import { TaskStatus } from 'core/constants';

import { ColumnDefinition } from 'components/Grid/types';

import { MyTasksTableProps } from './types';
import { tasksTableBasicColumns as allBasicColumns } from '../../../TasksTable/TasksTable';

const basicColumns = (allBasicColumns.map((column) =>
  column.field === 'assigned' ? { ...column, nativeFilter: false } : column
) as unknown) as ColumnDefinition[];

const MyTasksTable = ({ onError, onRefreshCallback }: MyTasksTableProps): React.ReactElement => {
  const { session } = useStore();
  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    history.push(reverse(routes.dashboard.tasks.details, { taskId: task.row.uuid }));
  };

  return (
    <Grid
      basicColumns={basicColumns}
      entity="action"
      filters={[{ field: 'status', operator: 'is not', value: `-${TaskStatus.Completed}` }]}
      onError={onError}
      onRefreshCallback={onRefreshCallback}
      onRowClick={onRowClick}
      dataURL={`/api/tasks/?assigned=${session?.user?.uuid}`}
      sortBy={{ field: 'dueDate', sort: 'asc' }}
      customNoRowsText="No tasks currently assigned to you. Please check back later."
    />
  );
};

export default observer(MyTasksTable);
