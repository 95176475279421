import React from 'react';
import { Skeleton, Switch } from '@mui/material';
import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { NotificationSettingsSwitchProps } from './types';

const NotificationSettingsSwitch: React.FunctionComponent<NotificationSettingsSwitchProps> = ({ value, onChange }) => {
  const {
    notificationSettings: { notificationsSettings, isUpdating, isLoading },
  } = useStore();

  if (isLoading) {
    return (
      <div style={{ padding: 12 }} data-testid="notification-settings-switch-loader">
        <Skeleton variant="rounded" width={34} height={14} />
      </div>
    );
  }
  return (
    <Switch
      checked={value}
      value={value}
      onChange={(_, checked) => {
        onChange(checked);
      }}
      disabled={notificationsSettings === undefined || isUpdating}
      data-testid="notification-settings-switch"
    />
  );
};

export default observer(NotificationSettingsSwitch);
