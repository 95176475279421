import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';
import { Button } from 'vatix-ui/lib/components/Button';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { observer } from 'mobx-react';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { useHistory, useRouteMatch } from 'react-router-dom';

import { reverse } from 'named-urls';

import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { useStore } from 'utils/hooks/store';

import NavigationBlocker from 'components/NavigationBlocker';

import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';

import MultipleContainers from './components/Layout/MultipleContainer';

import { LastPublished } from './styles';

const LayoutEditor: React.FunctionComponent = (): React.ReactElement => {
  const { entityLayout, entityFields } = useStore();

  const navBarHeight = useNavBarHeight();
  const history = useHistory();
  const [saving, setSaving] = React.useState(false);

  const match = useRouteMatch(routes.dashboard.objectManager.details.layoutEditor);

  const { moduleName } = match?.params as { moduleName: string };

  const onSave = async (): Promise<void> => {
    setSaving(true);
    await entityLayout.saveLayout(moduleName);
    setSaving(false);
  };

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await entityFields.loadFields(moduleName);
      await entityLayout.loadLayout(moduleName);
    };

    fetchData();
  }, [moduleName]);

  if (
    entityFields.isLoaded === false ||
    entityLayout.isLoaded === false ||
    (entityFields.data.length === 0 && entityLayout.data === undefined)
  ) {
    return <CenteredCircularProgress aria-label="layout-editor-loading" />;
  }

  if (entityLayout.error) {
    return <NotFound />;
  }

  return (
    <>
      <NavigationBlocker
        when={entityLayout.contentOfLayoutWasEdited}
        navigate={(path: string) => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          if (entityLayout.contentOfLayoutWasEdited) {
            return true;
          }
          return false;
        }}
        // eslint-disable-next-line max-len
        navigateMessage="You have made updates in the layout, but you haven’t saved the changes. Do you want to discard these changes?"
      />

      <Record
        key={`layout-editor-${moduleName}`}
        disableMaxContentHeight
        Header={
          <MainHeader
            title="Layout Editor"
            breadcrumbs={[
              {
                label: 'Object Manager',
                link: reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName }),
              },
              {
                label: getEntityTranslation[moduleName as EntityModules].plural,
                link: reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName }),
              },
              {
                label: 'Layout Editor',
                link: reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName }),
              },
            ]}
            action={
              <>
                <LastPublished>{`Last published: ${formatDateTime(entityLayout.lastPublished)}`}</LastPublished>
                <Button
                  onClick={onSave}
                  variant="contained"
                  size="large"
                  style={{ marginLeft: '4px' }}
                  // disable button if there is no changes
                  disabled={!entityLayout.contentOfLayoutWasEdited || saving}
                >
                  Publish
                </Button>
              </>
            }
          />
        }
        Content={() => <MultipleContainers />}
        navBarHeight={navBarHeight}
      />
    </>
  );
};

export default observer(LayoutEditor);
