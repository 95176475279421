/* eslint-disable max-len */
import React from 'react';

export function TeamIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <g clipPath="url(#clip0_14025_73722)">
        <path
          d="M9 6C10.65 6 12 4.65 12 3C12 1.35 10.65 0 9 0C7.35 0 6 1.35 6 3C6 4.65 7.35 6 9 6ZM9 1.5C9.84 1.5 10.5 2.19 10.5 3C10.5 3.81 9.84 4.5 9 4.5C8.16 4.5 7.5 3.84 7.5 3C7.5 2.16 8.16 1.5 9 1.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M14.8496 7.5C16.0796 7.5 17.0996 6.48 17.0996 5.25C17.0996 4.02 16.0796 3 14.8496 3C13.6196 3 12.5996 4.02 12.5996 5.25C12.5996 6.48 13.6196 7.5 14.8496 7.5ZM14.8496 4.5C15.2696 4.5 15.5996 4.83 15.5996 5.25C15.5996 5.67 15.2696 6 14.8496 6C14.4296 6 14.0996 5.67 14.0996 5.25C14.0996 4.83 14.4296 4.5 14.8496 4.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M3.15039 7.5C4.38039 7.5 5.40039 6.48 5.40039 5.25C5.40039 4.02 4.38039 3 3.15039 3C1.92039 3 0.900391 4.02 0.900391 5.25C0.900391 6.48 1.92039 7.5 3.15039 7.5ZM3.15039 4.5C3.57039 4.5 3.90039 4.83 3.90039 5.25C3.90039 5.67 3.57039 6 3.15039 6C2.73039 6 2.40039 5.67 2.40039 5.25C2.40039 4.83 2.73039 4.5 3.15039 4.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M15.75 8.63994H13.17C13.02 7.55994 12.09 6.68994 10.95 6.68994H7.05C5.91 6.68994 4.98 7.55994 4.83 8.63994H2.25C1.02 8.63994 0 9.65994 0 10.8899V14.6399C0 15.4799 0.66 16.1399 1.5 16.1399H16.5C17.34 16.1399 18 15.4799 18 14.6399V10.8899C18 9.65994 16.98 8.63994 15.75 8.63994ZM13.2 10.1399H15.75C16.17 10.1399 16.5 10.4999 16.5 10.8899V13.8899C16.5 14.3099 16.17 14.6399 15.75 14.6399H13.95C13.53 14.6399 13.2 14.3099 13.2 13.8899V10.1399ZM6.3 8.93994C6.3 8.54994 6.63 8.18994 7.05 8.18994H10.95C11.37 8.18994 11.7 8.54994 11.7 8.93994V13.8899C11.7 14.3099 11.37 14.6399 10.95 14.6399H7.05C6.63 14.6399 6.3 14.3099 6.3 13.8899V8.93994ZM4.05 14.6399H2.25C1.83 14.6399 1.5 14.3099 1.5 13.8899V10.8899C1.5 10.4999 1.83 10.1399 2.25 10.1399H4.8V13.8899C4.8 14.3099 4.47 14.6399 4.05 14.6399Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73722">
          <rect width="18" height="16.14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveTeamIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <g clipPath="url(#clip0_14025_73698)">
        <path
          d="M3.6 9.98998V15.42C3.6 15.84 3.27 16.17 2.85 16.17H0.75C0.33 16.17 0 15.84 0 15.42V11.25C0 10.38 0.72 9.59998 1.65 9.59998H3.24C3.45 9.59998 3.6 9.77998 3.6 9.95998V9.98998Z"
          fill="white"
        />
        <path
          d="M18.0004 11.25V15.42C18.0004 15.84 17.6704 16.17 17.2504 16.17H15.1504C14.7304 16.17 14.4004 15.84 14.4004 15.42V9.99C14.4004 9.78 14.5804 9.63 14.7604 9.63H16.3504C17.2804 9.63 18.0004 10.38 18.0004 11.28V11.25Z"
          fill="white"
        />
        <path
          d="M12.75 9.11997V15.42C12.75 15.84 12.42 16.17 12 16.17H6C5.58 16.17 5.25 15.84 5.25 15.42V9.11997C5.25 8.24997 5.97 7.46997 6.9 7.46997H11.16C12.09 7.46997 12.81 8.21997 12.81 9.11997H12.75Z"
          fill="white"
        />
        <path
          d="M9 6C10.6569 6 12 4.65685 12 3C12 1.34315 10.6569 0 9 0C7.34315 0 6 1.34315 6 3C6 4.65685 7.34315 6 9 6Z"
          fill="white"
        />
        <path
          d="M14.8496 7.5C16.0923 7.5 17.0996 6.49264 17.0996 5.25C17.0996 4.00736 16.0923 3 14.8496 3C13.607 3 12.5996 4.00736 12.5996 5.25C12.5996 6.49264 13.607 7.5 14.8496 7.5Z"
          fill="white"
        />
        <path
          d="M3.15039 7.5C4.39303 7.5 5.40039 6.49264 5.40039 5.25C5.40039 4.00736 4.39303 3 3.15039 3C1.90775 3 0.900391 4.00736 0.900391 5.25C0.900391 6.49264 1.90775 7.5 3.15039 7.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73698">
          <rect width="18" height="16.14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
