import styled from 'styled-components';
import Button from '@mui/material/Button';

import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

import VatixTheme from 'theme/vatix';

export const getItemContainerStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): object => ({
  margin: `8px auto`,
  backgroundColor: 'rgba(109, 120, 131,0.05)',
  borderRadius: '6px',
  paddingTop: '10px',
  width: 'calc(100% - 48px)',
  boxShadow: isDragging ? '-4px 0px 0px #0772E5' : '',
  ...draggableStyle,
});

export const getSectionContainerStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): object => ({
  margin: `8px auto`,
  paddingTop: '10px',
  backgroundColor: isDragging ? '#E1F5FE' : '',
  paddingBottom: '10px',
  width: '100%',
  ...draggableStyle,
});

export const QuestionRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: flex-start;
  margin-left: 24px;
  width: calc(100% - 48px);
`;

export const NonQuestionRow = styled(QuestionRow)`
  margin-left: 24px;
  width: calc(100% - 48px);
`;

export const AddQuestionButton = styled(Button).attrs({
  fullWidth: true,
})`
  color: ${VatixTheme.palette.primary.main};
  border-radius: 8px;
  margin-top: 10px;
  min-height: 50px;
  font-size: 12px;
  font-weight: bold;
  text-transform: initial;
  background-color: rgba(7, 114, 229, 0.05);

  :hover {
    background-color: ${VatixTheme.palette.primary.main};
    color: ${VatixTheme.palette.common.white};
  }
`;
