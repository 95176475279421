import React from 'react';

import InfoIcon from '@mui/icons-material/Info';

import { Tooltip } from '@mui/material';

import { InfoContainer, InfoHeader, InfoDescription } from './styles';

const RoleInfo: React.FC = (): React.ReactElement => (
  <Tooltip
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'white',
          boxShadow:
            // eslint-disable-next-line max-len
            '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
          borderRadius: '4px',
        },
      },
    }}
    title={
      <InfoContainer>
        <InfoHeader>User</InfoHeader>
        <InfoDescription>Can only view their own profile and settings.</InfoDescription>
        <InfoHeader>Manager</InfoHeader>
        <InfoDescription>Can view, manage and edit users in teams that they administrate.</InfoDescription>
        <InfoHeader>Account Owner</InfoHeader>
        <InfoDescription>Can add, view, manage and edit all users and configure account settings.</InfoDescription>
      </InfoContainer>
    }
    placement="right"
  >
    <InfoIcon
      fontSize="small"
      style={{ marginLeft: '10px', marginTop: '12px', marginBottom: '5px', color: 'rgba(0, 0, 0, 0.54)' }}
    />
  </Tooltip>
);

export default RoleInfo;
