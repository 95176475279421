import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { DraggableProvided } from 'react-beautiful-dnd';
import { TextInput } from 'vatix-ui/lib/components/TextInput';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IconButton, Tooltip } from '@mui/material';

import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { useStore } from 'utils/hooks/store';

import { QuestionRow } from '../QuestionsEditor/styles';

import DeleteSectionDialog from '../DeleteSectionDialog';

type QuestionEditorHeaderType = {
  provided: DraggableProvided;
  section: string;
  handleExpandClick: () => void;
  expanded: boolean;
};

const QuestionsEditorHeader: React.FunctionComponent<QuestionEditorHeaderType> = ({
  provided,
  section,
  handleExpandClick,
  expanded,
}): React.ReactElement => {
  const {
    templateDetails: { details },
  } = useStore();

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (details?.content.properties[section].order.length === 0) {
      details?.removeSection(section, true);
    } else {
      setOpenModal(true);
      event.stopPropagation();
    }
  };

  const handleCloseModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setOpenModal(false);
    event.stopPropagation();
  };

  const [value, setValue] = React.useState(details?.content.properties[section].description);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    details?.changeSectionName(section, e.target.value);
  };

  return (
    <QuestionRow {...provided.dragHandleProps} style={{ alignItems: 'center' }}>
      <DragIndicatorIcon color="disabled" />

      {expanded ? (
        <ExpandLess onClick={handleExpandClick} aria-label="show less" color="disabled" />
      ) : (
        <ExpandMore onClick={handleExpandClick} aria-label="show more" color="disabled" />
      )}

      <TextInput
        placeholder="Type section name here..."
        value={value}
        id="section-description-text-input"
        onChange={onChange}
      />

      <Tooltip title="Duplicate Section" arrow>
        <IconButton
          style={{ marginLeft: '16px' }}
          onClick={() => {
            details?.duplicateSections(section);
          }}
          disabled={value === ''}
        >
          <ContentCopyOutlinedIcon />
        </IconButton>
      </Tooltip>

      <IconButton onClick={handleOpenModal} id="delete-button">
        <DeleteOutlineIcon />
      </IconButton>

      <DeleteSectionDialog section={section} openModal={openModal} handleCloseModal={handleCloseModal} />
    </QuestionRow>
  );
};

export default QuestionsEditorHeader;
