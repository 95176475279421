import React from 'react';

import { ErrorBoundaryState, ErrorBoundaryProps } from './types';
import Logger from '../../utils/logger';

export default class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(): ErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Logger.error('component error', error, errorInfo);
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children, FallbackComponent } = this.props;

    if (hasError) {
      return <FallbackComponent />;
    }
    return children;
  }
}
