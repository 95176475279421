import React from 'react';

import { Formik, Form, Field, FormikHelpers, FieldProps, ErrorMessage } from 'formik';

import { inject, observer } from 'mobx-react';

import { RouterStore } from '@superwf/mobx-react-router';

import BlockError from 'vatix-ui/lib/components/BlockError';

import { Button } from 'vatix-ui/lib/components/Button';

import { FormControl } from '@mui/material';

import { useStore } from 'utils/hooks/store';

import ConfigStore from 'stores/Config';
import NotificationStore from 'stores/Notification';
import UIStore from 'stores/UI';

import { StyledTextField } from 'components/Input/styles';

import CustomModal from 'components/CustomModal';

import { AlarmDetailsResponse, ResolutionReasonResponse } from '../../utils/api/types';
import { EventType } from '../../utils/events/constants';
import { postMessage } from '../../utils/events/broadcast';
import API from '../../utils/api';
import Logger from '../../utils/logger';
import { formErrors, isBadRequest } from '../../utils/api/errors';
import { InjectedResolutionDialogProps, ResolutionReasonFormValues } from './types';
import { Radio, RadioButton, RadioGroup, Title } from './styles';
import { ResolutionReasonValidationSchema } from './schema';

const initialValues: ResolutionReasonFormValues = {
  resolutionReason: '',
  resolutionComment: '',
  commentRequired: false,
};

const ResolutionDialog = (props: InjectedResolutionDialogProps): React.ReactElement | null => {
  const {
    ui: { closeResolutionDialog, alarmToComplete },
    config: { resolutionReasons },
    notification,
  } = props;

  const {
    alarmDetails: { details: detailsRaw },
  } = useStore();

  const details = detailsRaw as AlarmDetailsResponse;

  const [showCommentTextField, setShowCommentTextField] = React.useState(false);

  const complete = React.useCallback(
    async (values: ResolutionReasonFormValues, helpers: FormikHelpers<ResolutionReasonFormValues>): Promise<void> => {
      if (!details) {
        return;
      }

      try {
        helpers.setSubmitting(true);
        const { data } = await API.completeRedAlert(details.uuid, values)();
        postMessage(EventType.CompletedRedAlert, data);
        notification.enqueueSuccessSnackbar('The Alert is completed.');
        helpers.resetForm();
        closeResolutionDialog();
      } catch (e) {
        // @ts-ignore
        const { nonFieldErrors, fieldErrors } = formErrors(e);
        fieldErrors && helpers.setErrors(fieldErrors);
        nonFieldErrors && helpers.setStatus(nonFieldErrors);
        // @ts-ignore
        if (!isBadRequest(e)) {
          Logger.error(`Invalid complete red alert API response. Alert id: ${details.uuid}`, e);
        }
      } finally {
        helpers.setSubmitting(false);
        helpers.resetForm();
      }
    },
    [details, closeResolutionDialog, notification]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={complete} validationSchema={ResolutionReasonValidationSchema}>
      {({ isSubmitting, submitForm, values, setFieldValue, status, resetForm }) => (
        <CustomModal
          openModal={!!alarmToComplete}
          onClose={() => {
            resetForm();
            closeResolutionDialog();
          }}
          modalTitle="Set Resolution Reason"
          modalDescription="In order to complete Alert you need to give a resolution reason"
          cancelButton={
            <Button
              id="cancel-action-button"
              onClick={() => {
                resetForm();
                closeResolutionDialog();
              }}
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          }
          submitButton={
            <Button
              id="add-action-button"
              onClick={() => {
                submitForm();
              }}
              disabled={!values.resolutionReason || isSubmitting}
              variant="contained"
              size="large"
            >
              Complete
            </Button>
          }
        >
          <Form>
            {status && <BlockError>{status}</BlockError>}

            <Field id="resolutionReason" name="resolutionReason" required>
              {({ field: { onChange, name } }: FieldProps) => (
                <>
                  <RadioGroup
                    name={name}
                    onChange={(evt: React.ChangeEvent<{}>, val: string) => {
                      onChange(evt);
                      const reasonObject = resolutionReasons.find(
                        (reason: ResolutionReasonResponse) => reason.uuid === val
                      );

                      !!reasonObject && setFieldValue('commentRequired', reasonObject.requireComment);

                      if (!values.commentRequired) {
                        setFieldValue('resolutionComment', '');
                      }
                      reasonObject?.requireComment ? setShowCommentTextField(true) : setShowCommentTextField(false);
                    }}
                  >
                    {resolutionReasons.map((reason: ResolutionReasonResponse) => (
                      <RadioButton key={reason.uuid} value={reason.uuid} label={reason.name} control={<Radio />} />
                    ))}
                  </RadioGroup>
                  <ErrorMessage name={name}>{(message) => <BlockError>{message}</BlockError>}</ErrorMessage>
                </>
              )}
            </Field>
            {showCommentTextField && (
              <FormControl
                style={{
                  width: '100%',
                  paddingLeft: '32px',
                }}
              >
                <Title>Description</Title>
                <StyledTextField
                  placeholder="Enter description"
                  value={values.resolutionComment}
                  onChange={(event) => {
                    setFieldValue('resolutionComment', event.target.value);
                  }}
                  variant="outlined"
                  multiline
                  rows="5"
                />
              </FormControl>
            )}
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

ResolutionDialog.defaultProps = {
  config: (null as unknown) as ConfigStore,
  ui: (null as unknown) as UIStore,
  notification: (null as unknown) as NotificationStore,
  routing: (null as unknown) as RouterStore,
};

export default inject('config', 'ui', 'notification', 'routing')(observer(ResolutionDialog));
