import { inject, observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { reverse } from 'named-urls';

import routes from 'core/routes';

import HoveredMarker from 'components/Marker/HoveredMarker';

import MapPinUser from 'components/Icons/MapPinUser';
import AlarmsPin from 'components/Icons/AlarmsFill';

import { AlertStatus } from 'core/constants';

import { useStore } from 'utils/hooks/store';

import { InjectedActivityMapMarkerProps } from './types';

const ActivityMapMarker = ({ user }: InjectedActivityMapMarkerProps): React.ReactElement => {
  const { routing } = useStore();

  const onMouseOverHandler = React.useCallback(() => {
    user.activityMap.locationDetails.openDetails(user.locationId);
  }, [user.activityMap.locationDetails, user.locationId]);

  const onClickHandler = React.useCallback(() => {
    switch (user.activityStatus) {
      case AlertStatus.RedAlert:
        routing.push(reverse(routes.dashboard.alarms.details, { alarmId: user.redAlert?.uuid as string }));
        break;
      default:
        routing.push(reverse(routes.dashboard.admin.users.details, { userId: user.uuid }));
    }
  }, [routing, user.uuid, user.activityStatus, user.redAlert?.uuid]);

  const mapPinColor = useMemo(() => {
    switch (user.activityStatus) {
      case AlertStatus.RedAlert:
        return '#E20101';
      case AlertStatus.AmberAlert:
        return '#FC9B00';
      default:
        return '#0772E5';
    }
  }, [user.activityStatus]);

  const mapPinBorderColor = React.useMemo(() => {
    switch (user.activityStatus) {
      case AlertStatus.RedAlert:
        return '#a80000';
      case AlertStatus.AmberAlert:
        return '#c27802';
      default:
        return '#223B65';
    }
  }, [user.activityStatus]);

  const MapIcon = useMemo(() => (user.activityStatus === AlertStatus.RedAlert ? AlarmsPin : MapPinUser), [
    user.activityStatus,
  ]);

  const mapPinZIndex = React.useMemo(() => {
    switch (user.activityStatus) {
      case AlertStatus.RedAlert:
        return 100;
      case AlertStatus.AmberAlert:
        return 10;
      default:
        return 0;
    }
  }, [user.activityStatus]);

  return (
    <HoveredMarker
      {...user.position}
      background={mapPinColor}
      border={mapPinBorderColor}
      icons={<MapIcon />}
      onMouseOver={onMouseOverHandler}
      onMouseOut={user.activityMap.locationDetails.closeDetails}
      onClick={onClickHandler}
      zIndex={mapPinZIndex}
    />
  );
};

export default inject('routing')(observer(ActivityMapMarker));
