import { Popover } from '@mui/material';
import styled from 'styled-components';

export const MainInformation = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  display: flex;
  padding: 20px;
  padding-right: 40px;
  flex-direction: row;
  margin-top: 20px;
  font-size: 16px;
  height: 90px;
  align-items: center;
`;

export const MainInformationColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  margin-right: 30px;
  flex: 1;
  p {
    margin-top: 0;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
  }
`;

export const MainInformationTitle = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    margin-left: 1rem;
  }
`;

export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 8px;
`;
