import { JSONSchemaType, AnswerType, ProtectorType } from 'utils/api/types';

const prependArray = (values: string[], array: string[]): string[] => {
  const updatedArray = array;
  updatedArray.unshift(...values);
  return updatedArray;
};

export const getIncidentOverviewPropertiesKeys = (order: string[], prependedKeys?: string[]): string[] =>
  prependArray(
    prependedKeys || [],
    order.filter((key) => !['subject', 'title', 'name'].includes(key))
  );

type ValidatorFunction = (schema: JSONSchemaType, newData: AnswerType, sectioned: boolean) => boolean;

const isDataValid = (schema: JSONSchemaType, type: ProtectorType, value: string, newData: AnswerType): string[] => {
  const validDates: string[] = [];

  schema.order.forEach((o) => {
    const properties = schema.properties[o].properties || {};

    const filteredKeys = Object.keys(properties).filter((i) => {
      if (newData[o]) {
        const newDataValue = (newData[o] as AnswerType)[i];
        return properties[i].protectorType === type && newDataValue === value;
      }
      return false;
    });

    validDates.push(...filteredKeys);
  });

  return validDates;
};

const isValid = (
  schema: JSONSchemaType,
  newData: AnswerType,
  sectioned: boolean,
  type: ProtectorType,
  value: string
): boolean => {
  if (sectioned) {
    const valid = isDataValid(schema, type, value, newData);
    return !valid.length;
  }
  return !Object.entries(schema.properties).some(
    ([key, property]) => property.protectorType === type && newData[key] === value
  );
};

export const validTimeValue: ValidatorFunction = (schema, newData, sectioned) =>
  isValid(schema, newData, sectioned, ProtectorType.Time, 'Invalid time');

export const validDateValue: ValidatorFunction = (schema, newData, sectioned) =>
  isValid(schema, newData, sectioned, ProtectorType.Date, 'Invalid date');

export const validDateTimeValue: ValidatorFunction = (schema, newData, sectioned) =>
  isValid(schema, newData, sectioned, ProtectorType.DateTime, 'Invalid date or time');

export const isNumberValid: ValidatorFunction = (schema, newData, sectioned) =>
  isValid(schema, newData, sectioned, ProtectorType.Number, 'Invalid value');
