import { AlertStatus } from 'core/constants';

import { UuidableLocation } from './types';

export const getPixelPositionOffset = (width: number, height: number): { x: number; y: number } => ({
  x: -(width / 2),
  y: -(height / 2),
});

export const getStrokeColor = (current: AlertStatus, next: AlertStatus): string => {
  if (current === AlertStatus.RedAlert && next === AlertStatus.RedAlert) {
    return getColorByAlertStatusType(current);
  }
  if (current === AlertStatus.RedAlert || next === AlertStatus.NoAlert) {
    return getColorByAlertStatusType(next);
  }
  return getColorByAlertStatusType(current);
};

export const getColorByAlertStatusType = (alertStatusType: AlertStatus): string => {
  switch (alertStatusType) {
    case AlertStatus.RedAlert:
      return '#FF0000';
    case AlertStatus.AmberAlert:
      return 'rgba(237, 108, 2)';
    case AlertStatus.NoAlert:
      return 'rgba(7, 114, 229)';
    default:
      return '';
  }
};

export function calculateInclination(p1: UuidableLocation, p2: UuidableLocation | null): number {
  if (!p2) return 0;
  const averageLat = (p1.lat + p2.lat) / 2;
  const deltaLng = (p2.lng - p1.lng) * Math.cos(averageLat * (Math.PI / 180));
  const deltaLat = p2.lat - p1.lat;
  let angleDegrees = Math.atan2(deltaLng, deltaLat) * (180 / Math.PI);
  angleDegrees = (angleDegrees + 360) % 360;
  return angleDegrees;
}

export const formatCoordinates = (coordinate: number): string => {
  const degrees = Math.floor(coordinate);
  const minutes = Math.floor((coordinate - degrees) * 60);
  const seconds = ((coordinate - degrees - minutes / 60) * 3600).toFixed(1);
  const direction = coordinate >= 0 ? 'N' : 'S';
  return `${degrees}°${minutes}'${seconds}"${direction}`;
};
