import React from 'react';

import { Button } from 'vatix-ui/lib/components/Button';

import { observer } from 'mobx-react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { EscalationInstruction, EscalationActionType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { MonitoringMethod } from 'core/constants';

import SortableList from '../SortableList';
import EscalationInstructionRow from '../EscalationInstructionRow';

const EscalationProfileDetailsArc: React.FC<{
  details: EscalationInstruction[];
}> = ({ details }): React.ReactElement => {
  const {
    escalationProfileDetails: {
      updateData,
      updateDataItem: updateInstruction,
      removeDataItem: removeInstruction,
      duplicateDataItem: duplicateInstruction,
    },
    notification,
  } = useStore();

  const data = (details || []).map((obj, index) => ({
    ...obj,
    id: index,
  }));

  return (
    <>
      <SortableList
        items={data}
        onChange={(items) => {
          updateData(items, MonitoringMethod.ArcMonitored);
        }}
        renderItem={(item, SortableItemContext) => (
          <EscalationInstructionRow
            key={item.uuid}
            instruction={data.find((i) => i.id === item.id) as EscalationInstruction}
            SortableItemContext={SortableItemContext}
            index={data.findIndex((o) => o.uuid === item.uuid) + 1}
            removeInstruction={(uuid) => removeInstruction(data, uuid, MonitoringMethod.ArcMonitored)}
            updateInstruction={(id, newData) => updateInstruction(data, id, newData, MonitoringMethod.ArcMonitored)}
            duplicateInstruction={(uuid) => duplicateInstruction(data, uuid, MonitoringMethod.ArcMonitored)}
            errors={
              details &&
              details[data.findIndex((o) => o.uuid === item.uuid)] &&
              details[data.findIndex((o) => o.uuid === item.uuid)].errors
                ? details[data.findIndex((o) => o.uuid === item.uuid)].errors
                : undefined
            }
          />
        )}
      />
      <Button
        variant="text"
        id="add-label-self"
        onClick={() => {
          if (data.length >= 10) {
            notification.enqueueErrorSnackbar('You have exceeded the limit of 10 alarm responders.');
            return;
          }
          const newItem = {
            id: data.length + 1,
            uuid: Date.now().toString(),
            contactName: '',
            escalationActionType: EscalationActionType.CALL,
            phoneNumber: '',
          };
          updateData([...data, newItem], MonitoringMethod.ArcMonitored);
        }}
      >
        <AddCircleIcon style={{ marginRight: '8px' }} />
        Add Contact
      </Button>
    </>
  );
};

export default observer(EscalationProfileDetailsArc);
