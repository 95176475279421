/* eslint-disable max-len */
import React from 'react';

const UserIcon = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
    <g clipPath="url(#clip0_14863_67101)">
      <path
        d="M2.83956 5.04477C2.76873 4.78505 2.74512 4.52533 2.74512 4.2656C2.74512 3.2031 3.17012 2.25866 3.87845 1.55033C4.56317 0.841992 5.53123 0.416992 6.59373 0.416992H6.68817C7.18401 0.416992 7.65623 0.558659 8.10484 0.747548C8.76595 1.03088 9.33262 1.52671 9.75762 2.11699C9.75762 2.11699 9.75762 2.1406 9.75762 2.16421C9.87567 2.37671 9.99373 2.58921 10.0882 2.82533C10.1826 3.06144 10.2771 3.29755 10.3243 3.55727C10.3715 3.79338 10.3951 4.02949 10.3951 4.2656V4.43088C10.3951 4.43088 10.3951 4.59616 10.3479 4.66699C10.3479 4.85588 10.3479 5.02116 10.2771 5.18644C10.2298 5.37533 10.1826 5.58783 10.0882 5.7531C9.99373 5.98921 9.89928 6.20171 9.73401 6.3906V6.41421C9.66317 6.50866 9.59234 6.62671 9.4979 6.72116C9.40345 6.86283 9.2854 6.95727 9.16734 7.07533C8.48262 7.71282 7.5854 8.0906 6.57012 8.0906C5.55484 8.0906 4.68123 7.71282 3.99651 7.09894C3.99651 7.09894 3.99651 7.09894 3.99651 7.07533C3.87845 6.98088 3.7604 6.86283 3.66595 6.72116C3.57151 6.62671 3.50067 6.50866 3.42984 6.41421V6.3906C3.28817 6.20171 3.19373 5.98921 3.09928 5.7531C3.00484 5.5406 2.91039 5.28088 2.86317 5.04477H2.83956Z"
        fill="#0772E5"
      />
      <path
        d="M12.3552 12.6711V13.4031C12.3552 14.0642 11.8122 14.5836 11.1747 14.5836H2.01356C1.37606 14.5836 0.833008 14.0642 0.833008 13.4031V12.8836C0.833008 11.0656 1.61217 9.41278 2.88717 8.30306C3.09967 8.11417 3.40662 8.13778 3.61912 8.30306C4.44551 8.89333 5.4844 9.27111 6.57051 9.27111C7.65662 9.27111 8.71912 8.89333 9.56912 8.27944C9.78162 8.11417 10.0886 8.09056 10.3011 8.27944C11.5525 9.31833 12.3316 10.9003 12.3316 12.6711H12.3552Z"
        fill="#0772E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_14863_67101">
        <rect width="11.5222" height="14.1667" fill="white" transform="translate(0.833008 0.416992)" />
      </clipPath>
    </defs>
  </svg>
);

export default UserIcon;
