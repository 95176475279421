/* eslint-disable max-len */
import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';
import { useRouteMatch } from 'react-router-dom';
import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { isNotFound } from 'utils/api/errors';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import EntityDetailsContent from 'components/Entities/EntityDetailsContent';

import EntityDetailsHeader from 'components/Entities/EntityDetailsHeader';
import EntityDetailsActivities from 'components/Entities/EntityDetailsActivities';
import EntityDetailsActivitiesFooter from 'components/Entities/EntityDetailsActivities/EntityDetailsActivitiesFooter';

import { ActivityMapping } from 'utils/api/types';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
} as unknown) as ActivityMapping;

const SuppliersDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch<{ entityId: string }>();

  const { supplierDetails } = useStore();

  return (
    <DetailsLoader
      match={match}
      routeParam="entityId"
      // @ts-ignore
      store={supplierDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }
        if (supplierDetails.details === undefined) {
          return <NotFound />;
        }
        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        const {
          prefix,
          details: { entity },
        } = supplierDetails;
        const supplierName = entity[`${prefix}supplierName`];

        return (
          <Record
            navBarHeight={navBarHeight}
            Header={
              <EntityDetailsHeader
                store={supplierDetails}
                title={supplierName.value as string}
                columns={[
                  { name: 'Name', value: <p>{supplierName.value as string}</p> },
                  {
                    name: 'Created',
                    value: <p>{formatDateTime(supplierDetails.details.createdAt)}</p>,
                  },
                  {
                    name: '',
                    value: <></>,
                  },
                ]}
              />
            }
            Content={(onError) => <EntityDetailsContent onError={onError} store={supplierDetails} />}
            Activities={{
              Content: (
                <EntityDetailsActivities
                  activities={supplierDetails.activities}
                  activityTypeToComponent={activityTypeToComponent}
                />
              ),
              Footer: <EntityDetailsActivitiesFooter activities={supplierDetails.activities} />,
            }}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default SuppliersDetails;
