import styled from 'styled-components';

export const AnswerImage = styled.img`
  max-width: fit-content;
  width: 120px;
`;

export const SignatureContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  margin-bottom: 4px;
  max-height: 120px;
  width: 100%;
`;
