import React from 'react';

import { AnswerTypes, LocationProperties } from 'utils/api/types';

import CustomLocationTextInput from 'containers/IncidentDetails/components/CustomLocationTextInput';

import { QuestionRowProps } from 'containers/FormsDetails/types';

import { AnswerText } from '../styles';

const LocationAnswer: React.FunctionComponent<QuestionRowProps> = ({
  answer,
  isSubmitted,
  updateAnswer,
}): React.ReactElement => {
  const [value, setValue] = React.useState(
    answer.answer ? ((answer.answer as LocationProperties).address as string) : ''
  );

  const onChange = (val: AnswerTypes): void => {
    setValue(val as string);
    updateAnswer(answer?.questionId as string, val as string);
  };

  if (!isSubmitted) {
    return <CustomLocationTextInput location={value} onChange={onChange} requiredNotFilled={false} />;
  }

  return <AnswerText>{(answer.answer as LocationProperties).address as string}</AnswerText>;
};

export default LocationAnswer;
