import React, { Dispatch, SetStateAction } from 'react';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { UuidableName, SearchResponse } from 'vatix-ui/lib/utils/api/types';

import { Box } from '@mui/material';

import { StyledAutocomplete, StyledTextField } from 'components/Input/styles';

import {
  InspectionQuestionsSearchResponse,
  InspectionTaskLinkType,
  LinksType,
  TaskListResponse,
} from 'utils/api/types';

import API from 'utils/api';

import { StyledMenuItem, EditTaskRowName } from '../../EditTaskProperties/styles';

type UseDelayedSearch = (
  searchMethod: (phrase: string) => Promise<void>
) => {
  delayedSearch: (phrase: string) => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;
const nullOption = { uuid: 'null', name: '' } as SearchResponse;

const TIMEOUT = 400; // ms

const useDelayedSearch: UseDelayedSearch = (searchMethod) => {
  const [timeId, setTimeId] = React.useState<number | null>(null);
  const [search, setSearch] = React.useState('');

  const delayedSearch = (phrase: string): void => {
    setSearch(phrase);

    if (timeId) {
      window.clearTimeout(timeId);
    }
    setTimeId(
      window.setTimeout(() => {
        searchMethod(phrase);
      }, TIMEOUT)
    );
  };

  React.useEffect(
    () => () => {
      if (timeId) {
        window.clearTimeout(timeId);
      }
    },
    [timeId]
  );

  return {
    delayedSearch,
    search,
    setSearch,
  };
};

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <div key={id}>
    <EditTaskRowName>{name}</EditTaskRowName>
    <div>{children}</div>
  </div>
);

const QuestionField: React.FunctionComponent<{
  updateField: (field: string, value: LinksType) => void;
  taskDetailsData: TaskListResponse;
}> = ({ updateField, taskDetailsData }): React.ReactElement => {
  const inspectionLink = taskDetailsData.links.find((link) => link.entity === 'inspection');
  if (taskDetailsData.links === undefined || inspectionLink === undefined || inspectionLink.instance === undefined) {
    return <></>;
  }

  const [selectedQuestion, setSelectedQuestion] = React.useState<UuidableName | null | undefined>(undefined);

  const [questions, setQuestions] = React.useState<UuidableName[]>([]);

  const mapToAutocompleteFormat = (apiResponseData: InspectionQuestionsSearchResponse[]): UuidableName[] =>
    apiResponseData.map((item) => ({
      uuid: item.id,
      name: item.description,
    }));

  const searchQuestions = React.useCallback(async (phrase: string): Promise<void> => {
    const edited = phrase.replace('\n', '');
    if (inspectionLink.instance?.uuid) {
      const { data } = await API.searchInspectionQuestion(inspectionLink.instance?.uuid as string, {
        query: edited,
      })();
      const formattedData = mapToAutocompleteFormat(data);
      setQuestions(formattedData);
    }
  }, []);
  const { delayedSearch } = useDelayedSearch(searchQuestions);

  React.useEffect(() => {
    searchQuestions('');
    setSelectedQuestion({
      name: inspectionLink.extraData ? (inspectionLink.extraData as InspectionTaskLinkType).question : '',
      uuid: inspectionLink.extraData ? (inspectionLink.extraData as InspectionTaskLinkType).questionId : '',
    });
  }, []);

  const [open, setOpen] = React.useState(false);

  return (
    <Row name="Question" id="field-3">
      <StyledAutocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        id="question-dropdown-field"
        options={questions}
        value={selectedQuestion === undefined ? nullOption : selectedQuestion}
        renderOption={(props, option: UuidableName) => {
          const { ...restProps } = props;
          return option.uuid === loadingOption.uuid ? (
            <CenteredCircularProgress />
          ) : (
            <Box sx={{ alignItems: 'center' }}>
              <StyledMenuItem
                key={`${option.uuid}`}
                {...restProps}
                value={option.uuid}
                onClick={() => {
                  setOpen(false);
                  setSelectedQuestion({
                    name: option.name,
                    uuid: option.uuid,
                  });

                  updateField('links', {
                    ...inspectionLink,
                    // @ts-ignore
                    extraData: {
                      question: option.name,
                      questionId: option.uuid,
                    },
                  });
                }}
              >
                {' '}
                {option.name}{' '}
              </StyledMenuItem>
            </Box>
          );
        }}
        getOptionLabel={(option: UuidableName) => option?.name as string}
        getOptionDisabled={(option: SearchResponse) =>
          option.uuid === loadingOption.uuid || option.uuid === selectedQuestion?.uuid
        }
        renderInput={(params) => (
          <StyledTextField
            {...params}
            onChange={(e) => {
              delayedSearch(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            placeholder="Search"
          />
        )}
        fullWidth
        disableClearable={!selectedQuestion?.uuid}
        onChange={() => {
          setSelectedQuestion(undefined);
          delayedSearch('');
        }}
        onInputChange={(_, __, reason) => {
          if (reason === 'clear') {
            updateField('links', {
              ...inspectionLink,
              // @ts-ignore
              extraData: {},
            });
          }
        }}
      />
    </Row>
  );
};

export default QuestionField;
