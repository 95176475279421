/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Scope, User } from '@sentry/types';

import { LogLevel, SentryLogLevel } from '../constants';
import { Handler } from './types';

export default class SentryHandler implements Handler {
  user: User | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(level: LogLevel, message: string, error: any): void {
    if (error && error instanceof Error) {
      window.Sentry.withScope((scope: Scope) => {
        scope.setExtra('message', message);

        window.Sentry.captureException(error);
      });
    } else {
      window.Sentry.withScope((scope: Scope) => {
        scope.setExtra('error', error);

        window.Sentry.captureMessage(message, SentryLogLevel[level]);
      });
    }
  }

  setUser(user: User | null): void {
    this.user = user;

    this.configureScope();
  }

  configureScope(): void {
    window.Sentry.configureScope((scope: Scope) => {
      scope.setUser(this.user);
    });
  }
}
