import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { IncidentActivityProps } from './types';

const IncidentAssignment: React.FunctionComponent<IncidentActivityProps> = ({ activity, sessionUser }) => {
  if (activity.previousUser && activity.user) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-reassigned">
        has changed the incident owner from {activity.previousUser.name} to {activity.user.name}
      </EventItem>
    );
  }
  if (activity.user) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-assigned">
        has assigned {activity.user.name} as the owner of the incident
      </EventItem>
    );
  }
  if (activity.previousUser && activity.user === null) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-unassigned">
        unassigned incident from {activity.previousUser.name}
      </EventItem>
    );
  }
  return <></>;
};

export default observer(IncidentAssignment);
