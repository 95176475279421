import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button } from 'vatix-ui/lib/components/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { UserRole } from 'core/constants';

import { SessionStoreWithUser } from '../../../stores/Session/types';
import UIStore from '../../../stores/UI';
import { NotificationBarContainer, NotificationBarMessage } from '../styles';
import { InjectedAutoplayAudioProps } from './types';

const AutoplayAudio = ({ ui, session: { user } }: InjectedAutoplayAudioProps): React.ReactElement | null => {
  if (ui.canAutoplayAudio || user.role !== UserRole.ArcOperator) {
    return null;
  }

  return (
    <NotificationBarContainer>
      <ErrorOutlineIcon htmlColor="#d32f2f" />
      <NotificationBarMessage>Allow me to autoplay audio notifications.</NotificationBarMessage>

      <Button onClick={ui.acceptAutoplayAudio} variant="contained">
        Accept
      </Button>
    </NotificationBarContainer>
  );
};

AutoplayAudio.defaultProps = {
  session: (null as unknown) as SessionStoreWithUser,
  ui: (null as unknown) as UIStore,
};

export default inject('ui', 'session')(observer(AutoplayAudio));
