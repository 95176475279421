export interface LoginFormValues {
  username: string;
  password: string;
  verificationCode: string;
  phoneNumber: string;
}

export enum LoginStep {
  EnterEmail,
  EnterCredentials,
  VerifyPhoneNumber,
  ConfirmVerificationCode,
  ConfirmLoginCode,
}

export interface LoginTextFieldProps {
  error: boolean | undefined;
  helperText?: string | boolean | undefined;
  setStep?: Function;
  twoFactorAuthCallback?: Function;
  digits?: number;
  withBackButton?: boolean;
}
