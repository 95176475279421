import styled from 'styled-components';

import DueDate from 'components/DueDate';
import TaskStatusChip from 'containers/TaskDetails/components/TaskStatusChip';

export const StyledTaskDueDate = styled(DueDate)`
  font-size: 14px;
`;

export const StyledTaskChip = styled(TaskStatusChip)`
  font-size: 13px;
`;
