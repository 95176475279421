/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { DisableableSvgIconProps } from './types';

const DeviceIcon = (props: DisableableSvgIconProps): React.ReactElement => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M9 21C7.9 21 6.95833 20.6083 6.175 19.825C5.39167 19.0417 5 18.1 5 17V8C5 6.61667 5.4875 5.4375 6.4625 4.4625C7.4375 3.4875 8.61667 3 10 3H14C15.3833 3 16.5625 3.4875 17.5375 4.4625C18.5125 5.4375 19 6.61667 19 8V17C19 18.1 18.6083 19.0417 17.825 19.825C17.0417 20.6083 16.1 21 15 21H9ZM9 19.5H15C15.7 19.5 16.2917 19.2583 16.775 18.775C17.2583 18.2917 17.5 17.7 17.5 17V8C17.5 7.03333 17.1583 6.20833 16.475 5.525C15.7917 4.84167 14.9667 4.5 14 4.5H10C9.03333 4.5 8.20833 4.84167 7.525 5.525C6.84167 6.20833 6.5 7.03333 6.5 8V17C6.5 17.7 6.74167 18.2917 7.225 18.775C7.70833 19.2583 8.3 19.5 9 19.5ZM9 12.75C9.21667 12.75 9.39583 12.675 9.5375 12.525C9.67917 12.375 9.75 12.2 9.75 12C9.75 11.7833 9.67917 11.6042 9.5375 11.4625C9.39583 11.3208 9.21667 11.25 9 11.25C8.8 11.25 8.625 11.3208 8.475 11.4625C8.325 11.6042 8.25 11.7833 8.25 12C8.25 12.2 8.325 12.375 8.475 12.525C8.625 12.675 8.8 12.75 9 12.75ZM12 12.75C12.2167 12.75 12.3958 12.675 12.5375 12.525C12.6792 12.375 12.75 12.2 12.75 12C12.75 11.7833 12.6792 11.6042 12.5375 11.4625C12.3958 11.3208 12.2167 11.25 12 11.25C11.8 11.25 11.625 11.3208 11.475 11.4625C11.325 11.6042 11.25 11.7833 11.25 12C11.25 12.2 11.325 12.375 11.475 12.525C11.625 12.675 11.8 12.75 12 12.75ZM15 12.75C15.2167 12.75 15.3958 12.675 15.5375 12.525C15.6792 12.375 15.75 12.2 15.75 12C15.75 11.7833 15.6792 11.6042 15.5375 11.4625C15.3958 11.3208 15.2167 11.25 15 11.25C14.8 11.25 14.625 11.3208 14.475 11.4625C14.325 11.6042 14.25 11.7833 14.25 12C14.25 12.2 14.325 12.375 14.475 12.525C14.625 12.675 14.8 12.75 15 12.75Z"
      // eslint-disable-next-line react/destructuring-assignment
      fill={props.disabled ? '##00000099' : '#4BA0FD'}
    />
  </SvgIcon>
);

export default DeviceIcon;
