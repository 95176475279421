/* eslint-disable max-len */
import React from 'react';

export function FormTemplateIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73405)">
        <path
          d="M17.25 9H12C11.5858 9 11.25 9.33579 11.25 9.75C11.25 10.1642 11.5858 10.5 12 10.5H17.25C17.6642 10.5 18 10.1642 18 9.75C18 9.33579 17.6642 9 17.25 9Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.25 12.75H12C11.5858 12.75 11.25 13.0858 11.25 13.5C11.25 13.9142 11.5858 14.25 12 14.25H17.25C17.6642 14.25 18 13.9142 18 13.5C18 13.0858 17.6642 12.75 17.25 12.75Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.25 16.5H12C11.5858 16.5 11.25 16.8358 11.25 17.25C11.25 17.6642 11.5858 18 12 18H17.25C17.6642 18 18 17.6642 18 17.25C18 16.8358 17.6642 16.5 17.25 16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M6.75 16.5H2.25C1.83 16.5 1.5 16.17 1.5 15.75V11.25C1.5 10.83 1.83 10.5 2.25 10.5H6.75C7.17 10.5 7.5 10.83 7.5 11.25V15.75C7.5 16.17 7.17 16.5 6.75 16.5ZM0 10.5V16.5C0 17.34 0.66 18 1.5 18H7.5C8.34 18 9 17.34 9 16.5V10.5C9 9.66 8.34 9 7.5 9H1.5C0.66 9 0 9.66 0 10.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M15.75 5.25H2.25C1.83 5.25 1.5 4.92 1.5 4.5V2.25C1.5 1.83 1.83 1.5 2.25 1.5H15.75C16.17 1.5 16.5 1.83 16.5 2.25V4.5C16.5 4.92 16.17 5.25 15.75 5.25ZM0 1.5V5.25C0 6.09 0.66 6.75 1.5 6.75H16.5C17.34 6.75 18 6.09 18 5.25V1.5C18 0.66 17.34 0 16.5 0H1.5C0.66 0 0 0.66 0 1.5Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73405">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveFormTemplateIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_14025_73360)">
        <path
          d="M19.1667 10H13.3333C12.8731 10 12.5 10.3731 12.5 10.8333C12.5 11.2936 12.8731 11.6667 13.3333 11.6667H19.1667C19.6269 11.6667 20 11.2936 20 10.8333C20 10.3731 19.6269 10 19.1667 10Z"
          fill="white"
        />
        <path
          d="M19.1667 14.1666H13.3333C12.8731 14.1666 12.5 14.5397 12.5 15C12.5 15.4602 12.8731 15.8333 13.3333 15.8333H19.1667C19.6269 15.8333 20 15.4602 20 15C20 14.5397 19.6269 14.1666 19.1667 14.1666Z"
          fill="white"
        />
        <path
          d="M19.1667 18.3334H13.3333C12.8731 18.3334 12.5 18.7065 12.5 19.1667C12.5 19.6269 12.8731 20 13.3333 20H19.1667C19.6269 20 20 19.6269 20 19.1667C20 18.7065 19.6269 18.3334 19.1667 18.3334Z"
          fill="white"
        />
        <path
          d="M10 11.6667V18.3333C10 19.2667 9.26667 20 8.33333 20H1.66667C0.733333 20 0 19.2667 0 18.3333V11.6667C0 10.7667 0.733333 10 1.66667 10H8.33333C9.26667 10 10 10.7667 10 11.6667Z"
          fill="white"
        />
        <path
          d="M20 1.66667V5.83333C20 6.76667 19.2667 7.5 18.3333 7.5H1.66667C0.733333 7.5 0 6.76667 0 5.83333V1.66667C0 0.766667 0.733333 0 1.66667 0H18.3333C19.2667 0 20 0.766667 20 1.66667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73360">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
