import React from 'react';

import Chip from 'components/Chip';

import { Container } from './styles';
import { GridChipProps } from './types';

const GridChipRenderer: React.FunctionComponent<GridChipProps> = ({
  value: {
    value: { name },
  },
}) => (
  <Container>
    <Chip label={name} />
  </Container>
);

export default GridChipRenderer;
