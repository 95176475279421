import styled from 'styled-components';

import Grid from '@mui/material/Grid';

export const BackgroundInformationSections = styled.div`
  position: relative;
  overflow-y: scroll;

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    margin-bottom: 30px;
  }
`;

export const BackgroundInformationContainer = styled.div`
  margin: 40px 0 40px 20px;
  min-width: 60%;
  max-width: 60%;

  display: flex;
  flex-direction: column;
`;

export const UserIncidentOverview = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  margin: 0 20px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const SectionGrid = styled(Grid).attrs({
  classes: {
    item: 'grid-item',
  },
})`
  &.grid-item {
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
