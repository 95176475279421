import styled from 'styled-components';

export const NotLinked = styled.p`
  color: #939ba3;
`;

export const Linked = styled.a`
  cursor: pointer;
  color: #0772e5;
  overflow: hidden;
  font-size: 14px;
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 0;
  text-overflow: ellipsis;
`;
