import React from 'react';
import { FormControl } from '@mui/material';

import { StyledTextField } from 'components/Input/styles';

import { ProtectorTypeNumberProps } from '../types';

import { ErrorMessage } from './styles';

export const CustomNumberInputField = ({
  value,
  description,
  onChange,
  maximum,
  minimum,
  requiredNotFilled,
}: ProtectorTypeNumberProps): React.ReactElement => {
  const [input, setInput] = React.useState(value);
  const [error, setError] = React.useState(false);

  return (
    <FormControl fullWidth>
      {error && (
        <ErrorMessage>{`Invalid number. Please enter a number between ${minimum} and ${maximum}`}</ErrorMessage>
      )}
      <StyledTextField
        error={requiredNotFilled}
        placeholder={description}
        value={input}
        onChange={(event) => {
          const val = event.target.value ? Number(event.target.value) : null;

          setInput(val);
          onChange(val);

          if ((maximum && val !== null && val > maximum) || (minimum && val !== null && val < minimum)) {
            setError(true);
            onChange('Invalid value');
          } else {
            setError(false);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'e' || event.key === '+' || (!event.key.match(/^[0-9]+$/) && event.key !== 'Backspace')) {
            event.preventDefault();
          }
          event.stopPropagation();
        }}
        variant="outlined"
        type="number"
        InputProps={{ inputProps: { min: minimum || undefined, max: maximum || undefined } }}
      />
    </FormControl>
  );
};

export default CustomNumberInputField;
