import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { reverse } from 'named-urls';

import { AxiosError } from 'axios';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { UserRole } from 'core/constants';

import { EscalationProfileResponse } from 'utils/api/types';

import { ColumnActions } from 'components/Grid/types';

import api from 'utils/api';

import { isBadRequest } from 'utils/api/errors';

import { MenuItem } from './styles';
import RemoveARPModal from '../RemoveARPModal';

const ActionCell: React.FunctionComponent<{ rowData: EscalationProfileResponse; actions: ColumnActions }> = ({
  rowData,
  actions,
}) => {
  const { session, routing, notification } = useStore();

  const deleteAlarmResponseProfile = async (uuid: string): Promise<void> => {
    try {
      await api.deleteAlarmResponseProfile(uuid)();
      notification.enqueueSuccessSnackbar('Alarm response profile removed successfully');
      await actions.refreshData();
    } catch (e) {
      if (isBadRequest(e as AxiosError)) {
        notification.enqueueErrorSnackbar(
          'You cannot remove this alarm response profile because it is currently assigned to at least one user.'
        );
      } else {
        notification.enqueueErrorSnackbar('There was an error removing the alarm response profile');
      }
    }
  };

  const userRole = session.user?.role as UserRole;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    routing.push(reverse(routes.dashboard.alarms.escalationProfiles.details, { escalationProfileId: rowData.uuid }));
  };

  const handleRemoveClick = async (): Promise<void> => {
    setOpenModal(true);
    setAnchorEl(null);
  };

  const closeModal = (): void => {
    setOpenModal(false);
  };

  const handleRemoveARP = async (): Promise<void> => {
    deleteAlarmResponseProfile(rowData.uuid);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <GridActionsCellItem icon={<MoreVert />} label="" color="inherit" onClick={handleOpen} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          id="edit-button"
          onClick={async (e) => {
            handleEditClick(e);
          }}
        >
          {!session?.user?.readOnly ? 'Edit' : 'View'}
        </MenuItem>
        {[UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(userRole) && !session?.user?.readOnly && (
          <MenuItem id="remove-button" onClick={handleRemoveClick}>
            Remove
          </MenuItem>
        )}
      </Popover>
      {openModal && <RemoveARPModal onClose={closeModal} handleRemoveARP={handleRemoveARP} />}
    </>
  );
};

export default observer(ActionCell);
