import React from 'react';
import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';

import { useStore } from 'utils/hooks/store';
import DeviceActivitiesStore from 'stores/DeviceDetails/DeviceActivities';
import API from 'utils/api';

const DeviceActivitiesFooter: React.FunctionComponent = () => {
  const { deviceDetails } = useStore();
  const activities = deviceDetails.activities as DeviceActivitiesStore;
  return (
    <AddCommentActivities
      aria-label="device-activities-input"
      placeholder="Post an update"
      uploadFile={activities.uploadFile}
      sendMessage={activities.addMessage}
      usersSearch={API.searchUsers}
    />
  );
};

export default DeviceActivitiesFooter;
