import { prepareIcon } from './Icon';
import batteryFull from '../../assets/icons/Battery - Full.png';
import batteryFull2x from '../../assets/icons/Battery - Full@2x.png';
import batteryFull3x from '../../assets/icons/Battery - Full@3x.png';

const BatteryFull = prepareIcon({
  alt: 'Battery Recharged',
  src: batteryFull,
  srcSet: `
    ${batteryFull2x} 2x,
    ${batteryFull3x} 3x
  `,
});

export default BatteryFull;
