import styled from 'styled-components';
import { FormHelperText } from '@mui/material';

export const RedFormHelperText = styled(FormHelperText)`
  color: rgba(211, 47, 47, 1);
  font-size: 12px;
  font-weight: 400;
`;

export const StyledOption = styled.li`
  padding: 2px 16px;
  min-height: 36px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const StyledModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const OptionalText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const InputLabel = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const ProductLicencesGroup = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;
