import React from 'react';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { useNavBarHeight } from 'utils/hooks/navbar';

import routes from 'core/routes';

import NotificationsList from './components/NotificationsList';

const Notifications: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={() => <NotificationsList />}
      Header={
        <MainHeader
          title="Inbox"
          breadcrumbs={[
            { label: 'Inbox', link: String(routes.dashboard.notifications) },
            { label: 'All', link: String(routes.dashboard.notifications) },
          ]}
        />
      }
    />
  );
};

export default Notifications;
