import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { TaskListResponse } from 'utils/api/types';
import { isNotFound } from 'utils/api/errors';
import { postMessage } from 'utils/events/broadcast';

import RootStore from '../Root';
import TaskActivities from './TaskActivities';

export default class TaskDetailsStore extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable details?: TaskListResponse;

  @observable activities?: TaskActivities;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(taskId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadTaskDetails(taskId)();
      runInAction(() => {
        this.activities = new TaskActivities(this.store, this.api, taskId);
        this.error = undefined;
        this.details = data;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load task details API response. Task id: ${taskId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @action
  async deleteTask(taskId: string): Promise<boolean> {
    try {
      await this.api.deleteTask(taskId)();
      postMessage(EventType.DeletedTask, { taskId });
      this.store.notification.enqueueSuccessSnackbar('Task deleted successfully');
      return true;
    } catch (ex) {
      Logger.error('Invalid delete task API response.', ex);
      this.store.notification.enqueueErrorSnackbar('Task could not be deleted');
      return false;
    }
  }

  @action
  async updateTask(taskData: Partial<TaskListResponse>): Promise<boolean> {
    try {
      const { data } = await this.api.updateTask(this.details?.uuid as string, taskData)();
      this.store.notification.enqueueSuccessSnackbar('Task updated successfully');
      postMessage(EventType.UpdatedTask, data);
      runInAction(() => {
        this.details = data;
      });
      return true;
    } catch (ex) {
      Logger.error('Invalid update task API response.', ex);
      this.store.notification.enqueueErrorSnackbar('Task could not be updated');
      throw ex;
    }
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }
}
