import { MenuItem } from '@mui/material';

import styled from 'styled-components';

import VatixTheme from 'theme/vatix';

export const StyledMenuItem = styled(MenuItem)`
  color: ${VatixTheme.palette.grey[200]};
  font-size: 1.3rem;
  line-height: 2rem;
`;
