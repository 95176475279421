import React from 'react';
import { observer } from 'mobx-react';

import { EntitiesStores } from '../types';

import { BackgroundInformationSections } from './styles';

import EntitySection from './EntitySection';

const EntityDetailsTab: React.FunctionComponent<{ store: EntitiesStores }> = ({ store }): React.ReactElement => {
  const { details } = store;
  if (!details) {
    return <></>;
  }
  return (
    <BackgroundInformationSections>
      {details.layout.order.map((key) => {
        const section = details.layout.properties[key];
        if (!section) return null;
        return <EntitySection title={section.title} fieldOrder={section.order} store={store} />;
      })}
    </BackgroundInformationSections>
  );
};

export default observer(EntityDetailsTab);
