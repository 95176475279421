/* eslint-disable max-len */
import React from 'react';

export function FormIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
      <g clipPath="url(#clip0_14037_74332)">
        <path
          d="M14.1667 20H1.66667C0.733333 20 0 19.2667 0 18.3333V1.66667C0 0.733333 0.733333 0 1.66667 0H14.1667C15.1 0 15.8333 0.733333 15.8333 1.66667V18.3333C15.8333 19.2667 15.1 20 14.1667 20ZM2.5 18.3333H13.3333C13.8 18.3333 14.1667 17.9667 14.1667 17.5V2.5C14.1667 2.03333 13.8 1.66667 13.3333 1.66667H2.5C2.03333 1.66667 1.66667 2.03333 1.66667 2.5V17.5C1.66667 17.9667 2.03333 18.3333 2.5 18.3333Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M5.83398 3.33337H4.16732C3.70708 3.33337 3.33398 3.70647 3.33398 4.16671V5.83337C3.33398 6.29361 3.70708 6.66671 4.16732 6.66671H5.83398C6.29422 6.66671 6.66732 6.29361 6.66732 5.83337V4.16671C6.66732 3.70647 6.29422 3.33337 5.83398 3.33337Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M11.6673 4.16675H9.16732C8.70708 4.16675 8.33398 4.53984 8.33398 5.00008C8.33398 5.46032 8.70708 5.83341 9.16732 5.83341H11.6673C12.1276 5.83341 12.5007 5.46032 12.5007 5.00008C12.5007 4.53984 12.1276 4.16675 11.6673 4.16675Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M5.83398 8.33337H4.16732C3.70708 8.33337 3.33398 8.70647 3.33398 9.16671V10.8334C3.33398 11.2936 3.70708 11.6667 4.16732 11.6667H5.83398C6.29422 11.6667 6.66732 11.2936 6.66732 10.8334V9.16671C6.66732 8.70647 6.29422 8.33337 5.83398 8.33337Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M11.6673 9.16675H9.16732C8.70708 9.16675 8.33398 9.53984 8.33398 10.0001C8.33398 10.4603 8.70708 10.8334 9.16732 10.8334H11.6673C12.1276 10.8334 12.5007 10.4603 12.5007 10.0001C12.5007 9.53984 12.1276 9.16675 11.6673 9.16675Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M5.83398 13.3334H4.16732C3.70708 13.3334 3.33398 13.7065 3.33398 14.1667V15.8334C3.33398 16.2936 3.70708 16.6667 4.16732 16.6667H5.83398C6.29422 16.6667 6.66732 16.2936 6.66732 15.8334V14.1667C6.66732 13.7065 6.29422 13.3334 5.83398 13.3334Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M11.6673 14.1667H9.16732C8.70708 14.1667 8.33398 14.5398 8.33398 15.0001C8.33398 15.4603 8.70708 15.8334 9.16732 15.8334H11.6673C12.1276 15.8334 12.5007 15.4603 12.5007 15.0001C12.5007 14.5398 12.1276 14.1667 11.6673 14.1667Z"
          fill="white"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_14037_74332">
          <rect width="15.8333" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveFormIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
      <g clipPath="url(#clip0_14037_74332)">
        <path
          d="M14.1667 20H1.66667C0.733333 20 0 19.2667 0 18.3333V1.66667C0 0.733333 0.733333 0 1.66667 0H14.1667C15.1 0 15.8333 0.733333 15.8333 1.66667V18.3333C15.8333 19.2667 15.1 20 14.1667 20ZM2.5 18.3333H13.3333C13.8 18.3333 14.1667 17.9667 14.1667 17.5V2.5C14.1667 2.03333 13.8 1.66667 13.3333 1.66667H2.5C2.03333 1.66667 1.66667 2.03333 1.66667 2.5V17.5C1.66667 17.9667 2.03333 18.3333 2.5 18.3333Z"
          fill="white"
        />
        <path
          d="M5.83398 3.33337H4.16732C3.70708 3.33337 3.33398 3.70647 3.33398 4.16671V5.83337C3.33398 6.29361 3.70708 6.66671 4.16732 6.66671H5.83398C6.29422 6.66671 6.66732 6.29361 6.66732 5.83337V4.16671C6.66732 3.70647 6.29422 3.33337 5.83398 3.33337Z"
          fill="white"
        />
        <path
          d="M11.6673 4.16675H9.16732C8.70708 4.16675 8.33398 4.53984 8.33398 5.00008C8.33398 5.46032 8.70708 5.83341 9.16732 5.83341H11.6673C12.1276 5.83341 12.5007 5.46032 12.5007 5.00008C12.5007 4.53984 12.1276 4.16675 11.6673 4.16675Z"
          fill="white"
        />
        <path
          d="M5.83398 8.33337H4.16732C3.70708 8.33337 3.33398 8.70647 3.33398 9.16671V10.8334C3.33398 11.2936 3.70708 11.6667 4.16732 11.6667H5.83398C6.29422 11.6667 6.66732 11.2936 6.66732 10.8334V9.16671C6.66732 8.70647 6.29422 8.33337 5.83398 8.33337Z"
          fill="white"
        />
        <path
          d="M11.6673 9.16675H9.16732C8.70708 9.16675 8.33398 9.53984 8.33398 10.0001C8.33398 10.4603 8.70708 10.8334 9.16732 10.8334H11.6673C12.1276 10.8334 12.5007 10.4603 12.5007 10.0001C12.5007 9.53984 12.1276 9.16675 11.6673 9.16675Z"
          fill="white"
        />
        <path
          d="M5.83398 13.3334H4.16732C3.70708 13.3334 3.33398 13.7065 3.33398 14.1667V15.8334C3.33398 16.2936 3.70708 16.6667 4.16732 16.6667H5.83398C6.29422 16.6667 6.66732 16.2936 6.66732 15.8334V14.1667C6.66732 13.7065 6.29422 13.3334 5.83398 13.3334Z"
          fill="white"
        />
        <path
          d="M11.6673 14.1667H9.16732C8.70708 14.1667 8.33398 14.5398 8.33398 15.0001C8.33398 15.4603 8.70708 15.8334 9.16732 15.8334H11.6673C12.1276 15.8334 12.5007 15.4603 12.5007 15.0001C12.5007 14.5398 12.1276 14.1667 11.6673 14.1667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14037_74332">
          <rect width="15.8333" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
