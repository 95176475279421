import React from 'react';

import { ProtectorImageTypeProps } from '../types';
import { StyledImage } from './styles';

export const CustomImage = ({ contentEncoding, description }: ProtectorImageTypeProps): React.ReactElement => (
  <StyledImage src={contentEncoding} alt={`img-${description}`} />
);

export default CustomImage;
