import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';

import { LinearProgress } from '@mui/material';

const StyledModal = styled(Dialog)`
  .MuiPaper-root {
    padding: 16px 24px;
    border-radius: 8px;
    border: 0px solid #eee;
    background: #fff;
    box-shadow: 0px 8px 50px 0px rgba(7, 5, 26, 0.07);
  }
`;

export const StyledLinearProgress = styled(LinearProgress)<{ $completed: boolean }>`
  border-radius: 4px;
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ $completed }) => ($completed ? 'rgba(76, 175, 80, 1)' : '')};
  }
`;

export const FileSize = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
`;

export const FileName = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
`;

export const FileUploadContainer = styled.div`
  display: flex;
  padding: 9px 16px;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 4px 0;
`;

export const SimpleModal = styled(StyledModal)`
  .MuiPaper-root {
    max-width: 360px;
    align-items: center;
  }
`;

export const Modal = styled(StyledModal)`
  .MuiPaper-root {
    max-width: 660px;
    border-radius: 8px;
  }
`;

export const StyledModalTitle = styled.h2`
  font-size: 20px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 12px 0 0;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
`;

export const GreenHeader = styled.h2`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: #4caf50;
  margin: 0;
`;

export const InfoText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin: 0;
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
`;
