/* eslint-disable max-len */
import React from 'react';

export function ManageIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73508)">
        <path
          d="M0.75 3.75H9.12C9.42 5.07 10.62 6 12 6C13.38 6 14.58 5.07 14.88 3.75H17.25C17.67 3.75 18 3.42 18 3C18 2.61 17.67 2.25 17.25 2.25H14.88C14.58 0.96 13.38 0 12 0C10.62 0 9.42 0.96 9.12 2.25H0.75C0.33 2.25 0 2.61 0 3C0 3.42 0.33 3.75 0.75 3.75ZM10.5 3C10.5 2.73 10.56 2.46 10.71 2.25C10.95 1.8 11.43 1.5 12 1.5C12.57 1.5 13.05 1.8 13.29 2.25C13.44 2.46 13.5 2.73 13.5 3C13.5 3.27 13.44 3.54 13.32 3.75C13.05 4.2 12.57 4.5 12 4.5C11.43 4.5 10.95 4.2 10.71 3.75C10.56 3.54 10.5 3.27 10.5 3Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.25 8.25H8.88C8.58 6.96 7.38 6 6 6C4.62 6 3.42 6.96 3.12 8.25H0.75C0.33 8.25 0 8.61 0 9C0 9.42 0.33 9.75 0.75 9.75H3.12C3.42 11.07 4.62 12 6 12C7.38 12 8.58 11.07 8.88 9.75H17.25C17.67 9.75 18 9.42 18 9C18 8.61 17.67 8.25 17.25 8.25ZM7.5 9C7.5 9.27 7.44 9.54 7.32 9.75C7.05 10.2 6.57 10.5 6 10.5C5.43 10.5 4.95 10.2 4.71 9.75C4.56 9.54 4.5 9.27 4.5 9C4.5 8.73 4.56 8.46 4.71 8.25C4.95 7.8 5.43 7.5 6 7.5C6.57 7.5 7.05 7.8 7.29 8.25C7.44 8.46 7.5 8.73 7.5 9Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.25 14.25H14.88C14.58 12.96 13.38 12 12 12C10.62 12 9.42 12.96 9.12 14.25H0.75C0.33 14.25 0 14.61 0 15C0 15.42 0.33 15.75 0.75 15.75H9.12C9.42 17.07 10.62 18 12 18C13.38 18 14.58 17.07 14.88 15.75H17.25C17.67 15.75 18 15.42 18 15C18 14.61 17.67 14.25 17.25 14.25ZM13.5 15C13.5 15.27 13.44 15.54 13.32 15.75C13.05 16.2 12.57 16.5 12 16.5C11.43 16.5 10.95 16.2 10.71 15.75C10.56 15.54 10.5 15.27 10.5 15C10.5 14.73 10.56 14.46 10.71 14.25C10.95 13.8 11.43 13.5 12 13.5C12.57 13.5 13.05 13.8 13.29 14.25C13.44 14.46 13.5 14.73 13.5 15Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73508">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveManageIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73498)">
        <path
          d="M0.75 3.75H9.15C9.48 5.04 10.62 6 12 6C13.38 6 14.52 5.04 14.85 3.75H17.25C17.67 3.75 18 3.42 18 3C18 2.58 17.67 2.25 17.25 2.25H14.85C14.52 0.96 13.38 0 12 0C10.62 0 9.48 0.96 9.15 2.25H0.75C0.33 2.25 0 2.58 0 3C0 3.42 0.33 3.75 0.75 3.75Z"
          fill="white"
        />
        <path
          d="M17.25 8.25H8.85C8.52 6.96 7.38 6 6 6C4.62 6 3.48 6.96 3.15 8.25H0.75C0.33 8.25 0 8.58 0 9C0 9.42 0.33 9.75 0.75 9.75H3.15C3.48 11.04 4.62 12 6 12C7.38 12 8.52 11.04 8.85 9.75H17.25C17.67 9.75 18 9.42 18 9C18 8.58 17.67 8.25 17.25 8.25Z"
          fill="white"
        />
        <path
          d="M17.25 14.25H14.85C14.52 12.96 13.38 12 12 12C10.62 12 9.48 12.96 9.15 14.25H0.75C0.33 14.25 0 14.58 0 15C0 15.42 0.33 15.75 0.75 15.75H9.15C9.48 17.04 10.62 18 12 18C13.38 18 14.52 17.04 14.85 15.75H17.25C17.67 15.75 18 15.42 18 15C18 14.58 17.67 14.25 17.25 14.25Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73498">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
