/* eslint-disable max-len */
import * as React from 'react';

function VatixMonitoring(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_14341_24547)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3333 8.33333L18.3333 8.3667C19.25 8.3667 20 9.1167 20 10.0334V13.3667C20 14.2834 19.25 15.0334 18.3333 15.0334V17.5C18.3333 18.4167 17.5833 19.1667 16.6667 19.1667H12.5C12.5 19.6269 12.1269 20 11.6667 20H8.33333C7.8731 20 7.5 19.6269 7.5 19.1667V17.5C7.5 17.0398 7.8731 16.6667 8.33333 16.6667H11.6667C12.1269 16.6667 12.5 17.0398 12.5 17.5V17.5H15.8333C16.3 17.5 16.6667 17.1334 16.6667 16.6667V15.0334C15.75 15.0334 15 14.2834 15 13.3667V10.0334C15 9.1167 15.75 8.3667 16.6667 8.3667V8.33333C16.6667 4.65 13.6833 1.66667 10 1.66667C6.31667 1.66667 3.33333 4.65 3.33333 8.33333V8.3667C4.25 8.3667 5 9.1167 5 10.0334V13.3667C5 14.2834 4.25 15.0334 3.33333 15.0334H1.66667C0.75 15.0334 0 14.2834 0 13.3667V10.0334C0 9.1167 0.75 8.3667 1.66667 8.3667V8.33333C1.66667 3.73333 5.4 0 10 0C14.6 0 18.3333 3.73333 18.3333 8.33333ZM2.91667 13.3667H2.08333C1.85 13.3667 1.66667 13.1834 1.66667 12.95V10.45C1.66667 10.2167 1.85 10.0334 2.08333 10.0334H2.91667C3.15 10.0334 3.33333 10.2167 3.33333 10.45V12.95C3.33333 13.1834 3.15 13.3667 2.91667 13.3667ZM17.9167 13.3667H17.0833C16.85 13.3667 16.6667 13.1834 16.6667 12.95V10.45C16.6667 10.2167 16.85 10.0334 17.0833 10.0334H17.9167C18.15 10.0334 18.3333 10.2167 18.3333 10.45V12.95C18.3333 13.1834 18.15 13.3667 17.9167 13.3667ZM11.9006 7.4167L10.0006 6.7667L8.10059 7.4167V8.93337C8.10059 10.25 9.33392 11.1334 10.0006 11.5334C10.6506 11.1667 11.9006 10.2667 11.9006 8.93337V7.4167ZM9.85059 5.05003C9.90059 5.03337 10.0006 5.03337 10.0006 5.03337C10.0506 5.03337 10.1506 5.05003 10.1506 5.05003L13.2673 6.1167C13.4506 6.1667 13.5673 6.35003 13.5673 6.53337V8.93337C13.5673 11.85 10.3173 13.2667 10.1673 13.3167C10.1173 13.35 10.0673 13.35 10.0006 13.35C9.93392 13.35 9.88392 13.35 9.83392 13.3167C9.68392 13.2667 6.43392 11.85 6.43392 8.93337V6.53337C6.43392 6.35003 6.55059 6.1667 6.73392 6.1167L9.85059 5.05003Z"
          fill="#0772E5"
        />
      </g>
      <defs>
        <clipPath id="clip0_14341_24547">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VatixMonitoring;
