import styled from 'styled-components';

export const SectionContainerHeader = styled.div<{ $expanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) => (props.$expanded ? `1px solid #e0e0e0` : null)};
  padding: 0 20px;
  background: #f5f5f5;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 11px 12px;
`;

export const Section = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  align-items: flex-start;
`;

export const EditFieldDescription = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  margin: 16px 0 5px;
`;
