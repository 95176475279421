import React from 'react';

import { FormControl } from '@mui/material';

import { useStore } from 'utils/hooks/store';
import { IncidentResponse } from 'utils/api/types';

import IncidentStatusChip from 'components/Chips/IncidentStatusChip/IncidentStatusChip';

import { StyledChipSelect } from 'components/Input/styles';

import { StyledMenuItem } from './styles';
import { ProtectorTypeStatusProps } from '../types';

const CustomStatus: React.FunctionComponent<ProtectorTypeStatusProps> = ({
  editable,
  onChange,
  originalValue,
  path,
  schema,
  value,
  requiredNotFilled,
}): React.ReactElement => {
  const {
    incidentDetails,
    incidentDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as IncidentResponse;
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const onScroll = (): void => setIsOpen(false);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleStatusChange = (status: string): void => {
    if (onChange) {
      onChange(status);
    } else {
      incidentDetails.updateIncidentData({
        details: {
          schema: details.details.schema,
          content: {
            ...details.details.content,
            [path?.at(-1) as string]: status,
          },
        },
      });
    }
  };

  const isTransitionDisabled = (item: string): boolean => {
    const transitions = schema?.protectorSettings?.transitions?.[(originalValue ?? value) as string];

    if (!transitions) {
      return false;
    }
    return !transitions.some(([transitionValue]) => transitionValue === item);
  };

  return (
    <FormControl fullWidth>
      {editable && (
        <StyledChipSelect
          onClick={() => {
            setIsOpen((prevState) => !prevState);
          }}
          open={isOpen}
          value={value}
          MenuProps={{
            variant: 'menu',
            disableScrollLock: true,
          }}
          error={requiredNotFilled}
        >
          {schema?.enum?.map((item) => (
            <StyledMenuItem
              key={item}
              value={item}
              disabled={isTransitionDisabled(item)}
              onClick={() => {
                handleStatusChange(item);
              }}
            >
              <IncidentStatusChip schema={schema} label={item} />
            </StyledMenuItem>
          ))}
        </StyledChipSelect>
      )}
    </FormControl>
  );
};

export default CustomStatus;
