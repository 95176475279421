import { observer } from 'mobx-react';
import React from 'react';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import { useStore } from 'utils/hooks/store';

import { StyledTabs, StyledTab, TabsContainer } from './styles';
import OverviewTab from '../OverviewTab';
import RelatedTab from '../RelatedTab';
import FormTab from '../FormTab';

export const FormDetailsContent: React.FunctionComponent = () => {
  const { formDetails } = useStore();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  if (formDetails.details?.submitted === null) {
    return <FormTab />;
  }

  return (
    <TabsContainer>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab label="Form" id="form-tab" value={0} />
        <StyledTab label="Overview" id="overview-tab" value={1} />
        <StyledTab label="Related" id="related-tab" value={2} />
      </StyledTabs>

      <TabPanel value={value} index={0}>
        <FormTab />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <OverviewTab />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <RelatedTab />
      </TabPanel>
    </TabsContainer>
  );
};

export default observer(FormDetailsContent);
