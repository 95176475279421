import { UuidableName } from 'vatix-ui/lib/utils/api/types';
import * as Yup from 'yup';

import { UsersCreationParams } from 'utils/api/types';
import { UserRole } from 'core/constants';

export interface FormValues {
  name: string;
  email: string;
  role: string;
  productLicenses: {
    alarms: boolean;
    incidents: boolean;
    audits: boolean;
  };
  teams: UuidableName[];
  organisation: UuidableName;
}

type SchemaObject = {
  [key in keyof FormValues]: Yup.Schema;
};

const UserSchema = Yup.object().shape<SchemaObject>({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  role: Yup.string().required('Role is required'),
  productLicenses: Yup.object().shape({
    alarms: Yup.boolean(),
    incidents: Yup.boolean(),
    audits: Yup.boolean(),
  }),
  teams: Yup.array(),
  organisation: Yup.object().shape({ uuid: Yup.string(), name: Yup.string() }),
});

export const getValidationSchema = (userRole: UserRole): typeof UserSchema => {
  if (userRole === UserRole.Admin) {
    return UserSchema.shape({
      organisation: Yup.object().shape({
        uuid: Yup.string().required('Organisation is required'),
        name: Yup.string().required('Organisation is required'),
      }),
    });
  }
  return UserSchema;
};

export const initialValues: FormValues = {
  name: '',
  email: '',
  role: '',
  productLicenses: {
    alarms: false,
    incidents: false,
    audits: false,
  },
  teams: [],
  organisation: { uuid: '', name: '' },
};

export const getUserValues = (values: FormValues): UsersCreationParams => {
  const { role, email, name, productLicenses, teams, organisation } = values;

  const licenses = {
    workflows: productLicenses.audits,
    protectorNotify: false,
    protectorAlarms: productLicenses.alarms,
    protectorIncidents: productLicenses.incidents,
  };
  return {
    email,
    name,
    teams: teams.map(({ uuid }) => ({ uuid })),
    licenses,
    role,
    organisation: organisation.uuid !== '' ? organisation.uuid : undefined,
  };
};
