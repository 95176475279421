import { ArrayHelpers } from 'formik';
import { action, observable, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { AlarmDetailsResponse } from 'utils/api/types';

import API from '../../utils/api';
import { EventType } from '../../utils/events/constants';
import RootStore from '../Root';

export default class UIStore {
  store: RootStore;

  api: typeof API;

  @observable loginMessage = '';

  @observable navBarHeight = 0;

  @observable canAutoplayAudio = false;

  @observable isAddCustomFieldDialogOpen = false;

  @observable alarmToComplete: AlarmDetailsResponse | null = null;

  customFieldArrayHelpers: ArrayHelpers | null = null;

  @observable subSideBarOpen = true;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    eventBus.register(this);
  }

  @action.bound
  setSubSideBarOpen(): void {
    this.subSideBarOpen = !this.subSideBarOpen;
  }

  @action.bound
  setLoginMessage(message: string): void {
    this.loginMessage = message;
  }

  @action.bound
  removeLoginMessage(): void {
    this.loginMessage = '';
  }

  @action.bound
  setNavBarHeight(height: number): void {
    this.navBarHeight = height;
  }

  @action.bound
  acceptAutoplayAudio(): void {
    this.canAutoplayAudio = true;
  }

  @action.bound
  openAddCustomFieldDialog(helper: ArrayHelpers): void {
    this.isAddCustomFieldDialogOpen = true;
    this.customFieldArrayHelpers = helper;
  }

  @action.bound
  closeAddCustomFieldDialog(): void {
    this.isAddCustomFieldDialogOpen = false;
    this.customFieldArrayHelpers = null;
  }

  @action.bound
  openResolutionDialog(alert: AlarmDetailsResponse): void {
    this.alarmToComplete = alert;
  }

  @action.bound
  closeResolutionDialog(): void {
    this.alarmToComplete = null;
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.canAutoplayAudio = false;
    this.alarmToComplete = null;

    this.closeAddCustomFieldDialog();
  }
}
