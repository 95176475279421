import { AnswerType, JSONSchemaType, ProtectorType } from 'utils/api/types';

export const getMissingElements = (required: string[], content: AnswerType): string[] =>
  required && content
    ? required.filter(
        (item) =>
          !Object.keys(content).includes(item) ||
          content[item] === null ||
          (typeof content[item] === 'string' &&
            // @ts-ignore
            content[item].trim() === '')
      )
    : [];

export const getMissingElementsWithSections = (incidentContent: AnswerType, schema: JSONSchemaType): string[] =>
  schema.order.flatMap((s) => {
    if (!incidentContent[s]) {
      return schema.properties[s].required || [];
    }
    return getMissingElements(schema.properties[s].required || [], incidentContent[s] as AnswerType);
  });

export const schemaContainsSection = (schema: JSONSchemaType): boolean =>
  Object.keys(schema.properties).some((k) => schema.properties[k].protectorType === ProtectorType.Section);
