import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as UnassignedGroupComponent } from 'assets/icons/Group Unassigned.svg';

const UnassignedGroup = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    component={UnassignedGroupComponent}
    viewBox="0 0 32 32"
    {...props}
  />
);

export default UnassignedGroup;
