import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ContainerLink = styled(Link)`
  text-decoration: none;
`;

export const NotificationItemContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 7px 50px 0px rgba(7, 5, 26, 0.08);
  margin-bottom: 15px;
  div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

export const TimeText = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
`;

export const NotificationStatusDot = styled.div<{
  dismissed: boolean;
}>`
  width: 11px;
  height: 11px;
  background-color: ${({ dismissed }) => (dismissed ? '#9E9E9E' : '#0772E5')};
  border-radius: 100%;
  align-self: flex-start;
  margin: auto 0px;
`;
