import { prepareIcon } from './Icon';
import accepted from '../../assets/icons/Red Alert - Accepted.png';
import accepted2x from '../../assets/icons/Red Alert - Accepted@2x.png';
import accepted3x from '../../assets/icons/Red Alert - Accepted@3x.png';

const AcceptedRedAlert = prepareIcon({
  alt: 'Accepted Alert',
  src: accepted,
  srcSet: `
    ${accepted2x} 2x,
    ${accepted3x} 3x
  `,
});

export default AcceptedRedAlert;
