import React from 'react';

import { RowContainer, StyledRowTitle, StyledSectionGrid } from './styles';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <RowContainer>
    <StyledSectionGrid item container xs={3} id={id}>
      <StyledRowTitle>{name}</StyledRowTitle>
    </StyledSectionGrid>
    <StyledSectionGrid item container xs={9} paddingLeft="8px">
      {children}
    </StyledSectionGrid>
  </RowContainer>
);
export default Row;
