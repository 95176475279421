import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import omit from 'lodash/omit';

import routes from 'core/routes';

import { Products } from 'core/constants';

import { SecureRouteProps, InjectedSecureRouteProps } from './types';

@inject('session')
@observer
export default class SecureRoute extends React.Component<SecureRouteProps> {
  get injected(): InjectedSecureRouteProps {
    return this.props as InjectedSecureRouteProps;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRoute = (props: RouteComponentProps<any>): React.ReactNode => {
    const { session } = this.injected;
    const {
      component: AuthorizedComponent,
      isAuthorized,
      redirectComponent: UnauthorizedComponent,
      onUnauthorizedRedirectProducts,
      redirectTo,
    } = this.props;

    const hasAccess = isAuthorized(session);

    if (hasAccess) {
      return AuthorizedComponent && <AuthorizedComponent {...props} />;
    }
    if (redirectTo) {
      return (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: props.location },
          }}
        />
      );
    }

    if (onUnauthorizedRedirectProducts) {
      const redirect = (pathname: string | null): React.ReactElement | null => {
        if (pathname === null) {
          window.location.assign(Products.admin);
          return null;
        }
        return (
          <Redirect
            to={{
              pathname,
              state: { from: props.location },
            }}
          />
        );
      };
      if (session.user?.licenses.protectorIncidents) {
        return redirect(routes.dashboard.incidents.toString());
      }
      if (session.user?.licenses.protectorAlarms) {
        return redirect(routes.dashboard.alarms.activityMap);
      }
      return redirect(null);
    }
    return UnauthorizedComponent && <UnauthorizedComponent {...props} />;
  };

  render(): React.ReactNode {
    const props = omit(this.props, ['component', 'isAuthorized', 'redirectComponent', 'redirectTo']);

    return <Route {...props} render={this.renderRoute} />;
  }
}
