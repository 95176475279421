import styled from 'styled-components';
import { MenuItem as MuiMenuItem, Popover } from '@mui/material';

export const MainInformation = styled.div<{ $withHeader: boolean }>`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  display: flex;
  padding: 20px;
  padding-right: 40px;
  flex-direction: row;
  margin-top: ${(props) => (props.$withHeader ? '20px' : '0px')};
  font-size: 16px;
  height: 90px;
  align-items: center;
`;

export const UserName = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

export const MainInformationColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  margin-right: 30px;
  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
  }
`;

export const MainInformationTitle = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    margin-left: 1rem;
  }
`;
export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 0.5rem;
`;

export const MenuItem = styled(MuiMenuItem)`
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 0px 8px 50px 0px rgba(7, 5, 26, 0.07);
  height: 50px;
  padding: 6px 16px;
  background: #fff;
  display: flex;
`;
