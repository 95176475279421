import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as AssignedDeviceComponent } from 'assets/icons/Device Assigned.svg';

const AssignedDevice = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon component={AssignedDeviceComponent} viewBox="0 0 32 32" {...props} />
);

export default AssignedDevice;
