import React from 'react';
import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { EscalationActionType, EscalationAction } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { MonitoringMethod } from 'core/constants';

import SortableList from '../SortableList';
import EscalationActionRow from '../EscalationActionRow';

const EscalationProfileDetailsSelf: React.FC<{ details: EscalationAction[] }> = ({ details }): React.ReactElement => {
  const {
    escalationProfileDetails: {
      updateData,
      removeDataItem: removeAction,
      updateDataItem: updateAction,
      duplicateDataItem: duplicateAction,
    },
    notification,
  } = useStore();
  const data = (details || []).map((obj, index) => ({
    ...obj,
    id: index,
  }));

  return (
    <>
      <SortableList
        items={data}
        onChange={(items) => {
          updateData(items, MonitoringMethod.SelfMonitored);
        }}
        renderItem={(item, SortableItemContext) => (
          <EscalationActionRow
            key={item.uuid}
            action={data.find((i) => i.id === item.id) as EscalationAction}
            SortableItemContext={SortableItemContext}
            index={data.findIndex((o) => o.uuid === item.uuid) + 1}
            removeAction={(uuid) => removeAction(data, uuid, MonitoringMethod.SelfMonitored)}
            updateAction={(id, newData) => updateAction(data, id, newData, MonitoringMethod.SelfMonitored)}
            duplicateAction={(uuid) => duplicateAction(data, uuid, MonitoringMethod.SelfMonitored)}
            errors={
              details &&
              details[data.findIndex((o) => o.uuid === item.uuid)] &&
              details[data.findIndex((o) => o.uuid === item.uuid)].errors
                ? details[data.findIndex((o) => o.uuid === item.uuid)].errors
                : undefined
            }
          />
        )}
      />
      <Button
        variant="text"
        id="add-label-self"
        onClick={() => {
          if (data.length >= 10) {
            notification.enqueueErrorSnackbar('You have exceeded the limit of 10 alarm responders.');
            return;
          }

          const newItem = {
            id: data.length + 1,
            uuid: Date.now().toString(),
            contactName: '',
            escalationActionType: EscalationActionType.CALL,
            phoneNumber: '',
          };
          updateData([...data, newItem], MonitoringMethod.SelfMonitored);
        }}
      >
        <AddCircleIcon style={{ marginRight: '8px' }} />
        Add Action
      </Button>
    </>
  );
};

export default observer(EscalationProfileDetailsSelf);
