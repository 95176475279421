import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ObjectSchema } from 'yup';
import Logger from 'vatix-ui/lib/utils/logger/Logger';
import { FusionAuthClient, TwoFactorSendRequest } from '@fusionauth/typescript-client';

import { AxiosError } from 'axios';

import { useStore } from 'utils/hooks/store';
import { ConfirmVerificationCodeSchema, VerifyPhoneNumberSchema } from 'containers/Login/schema';
import { SecondaryTextField } from 'components/TextField';
import { apiErrors, formErrors, isBadRequest } from 'utils/api/errors';

import { TwoFactorAuthSetupFormProps, TwoFactorAuthSetupFormValues, TwoFactorAuthSetupStep } from './types';
import {
  ConfirmVerificationCodeContainer,
  DigitsContainer,
  FormRow,
  FormError,
  RowLabel,
  StyledField,
  SubmitButton,
  FormInfo,
  FormStatusAnchor,
} from './styles';
import DigitField from './components/DigitField';
import FormStatus from './components/FormStatus';

const TwoFactorAuthSetupForm: React.FunctionComponent<TwoFactorAuthSetupFormProps> = ({
  onClose,
  onInitialVerificationCodeSent,
}) => {
  const initialValues: TwoFactorAuthSetupFormValues = {
    phoneNumber: '',
    verificationCode: '',
  };
  const { session, notification } = useStore();

  const [elapsedTime, setElapsedTime] = React.useState(30);

  const [step, setStep] = React.useState(TwoFactorAuthSetupStep.VerifyPhoneNumber);
  const [hasConfirmationCodeBeenSubmitted, setHasConfirmationCodeBeenSubmitted] = React.useState(false);
  const [fusionAuthUser2FAMethodId, setFusionAuthUser2FAMethodId] = React.useState<string | undefined>('');

  const confirmVerificationCodesInputRefs = React.useRef<Array<HTMLInputElement | null>>(
    Array.from({ length: 6 }, () => null)
  );

  const fusionAuthClient = new FusionAuthClient('', process.env.REACT_APP_FUSION_AUTH_URL as string);

  const verifyPhoneNumber = async (
    { phoneNumber }: TwoFactorAuthSetupFormValues,
    onPhoneNumberVerified: () => void
  ): Promise<void> => {
    const data = {
      method: 'sms',
      mobilePhone: phoneNumber,
      userId: session.user?.uuid,
    } as TwoFactorSendRequest;
    if (session.user?.twoFactorAuthEnabled) {
      const fusionAuthUser = await (await fusionAuthClient.retrieveUser(session.user.uuid)).response.user;
      const fusionAuthUser2FAMethod =
        fusionAuthUser && fusionAuthUser.twoFactor && fusionAuthUser.twoFactor.methods
          ? fusionAuthUser.twoFactor.methods.find((method) => method.mobilePhone === phoneNumber)
          : '';

      if (fusionAuthUser2FAMethod) {
        data.methodId = fusionAuthUser2FAMethod.id;
        setFusionAuthUser2FAMethodId(fusionAuthUser2FAMethod.id);
      }
    }
    await fusionAuthClient.sendTwoFactorCodeForEnableDisable(data);

    onPhoneNumberVerified();
    setStep(TwoFactorAuthSetupStep.ConfirmVerificationCode);
  };

  const confirmVerificationCode = async ({
    phoneNumber,
    verificationCode,
  }: TwoFactorAuthSetupFormValues): Promise<void> => {
    if (session.user?.twoFactorAuthEnabled) {
      await fusionAuthClient.disableTwoFactor(
        session.user ? session.user?.uuid : '',
        fusionAuthUser2FAMethodId || '',
        verificationCode
      );
      session.user?.disableTwoFactorAuth();
      notification.enqueueSuccessSnackbar('Two-Factor Authentication disabled successfully');
    } else {
      await fusionAuthClient.enableTwoFactor(session.user ? session.user?.uuid : '', {
        method: 'sms',
        code: verificationCode,
        mobilePhone: phoneNumber,
      });
      session.user?.enableTwoFactorAuth();
      notification.enqueueSuccessSnackbar('Two-Factor Authentication enabled successfully');
    }
    onClose();
  };

  const informationMessage = (
    helpers: FormikHelpers<TwoFactorAuthSetupFormValues>,
    values: TwoFactorAuthSetupFormValues
  ): void => {
    const interval = setInterval(() => {
      setElapsedTime((prevElapsedTime) => {
        const newElapsedTime = prevElapsedTime - 1;

        helpers.setStatus({
          info: <FormInfo>{`Didn’t receive a code? Try again in ${newElapsedTime} seconds`}</FormInfo>,
        });

        if (newElapsedTime <= 0) {
          clearInterval(interval);
          helpers.setStatus({
            info: (
              <FormInfo>
                Didn’t receive the code?{' '}
                <FormStatusAnchor
                  onClick={() => {
                    onResendCodeClick(values, helpers);
                  }}
                >
                  Click here
                </FormStatusAnchor>{' '}
                to resend
              </FormInfo>
            ),
          });
          return 30;
        }

        return newElapsedTime;
      });
    }, 1000);
  };

  const onSubmit = async (
    values: TwoFactorAuthSetupFormValues,
    helpers: FormikHelpers<TwoFactorAuthSetupFormValues>
  ): Promise<void> => {
    try {
      helpers.setSubmitting(true);

      if (step === TwoFactorAuthSetupStep.VerifyPhoneNumber) {
        await verifyPhoneNumber(values, () => {
          helpers.setStatus({
            info: <FormInfo>{`Didn’t receive a code? Try again in ${elapsedTime} seconds`}</FormInfo>,
          });
          informationMessage(helpers, values);
          onInitialVerificationCodeSent();
        });
      } else if (step === TwoFactorAuthSetupStep.ConfirmVerificationCode) {
        await confirmVerificationCode(values);
      } else {
        Logger.error(`Unknown step: ${step}`);
      }
    } catch (e) {
      const err = e as AxiosError;
      if (hasConfirmationCodeBeenSubmitted === false) {
        setHasConfirmationCodeBeenSubmitted(true);
      }

      if (!isBadRequest(err)) {
        Logger.error('Unable to setup 2fa', e);
      }
      const { nonFieldErrors, fieldErrors } = formErrors(err);
      helpers.setSubmitting(false);
      if (fieldErrors) {
        helpers.setErrors(fieldErrors);
        if (fieldErrors.verificationCode) {
          helpers.setStatus({
            errors: (
              <FormError>
                Invalid code entered. Please check and try again. If you’re having issues,{' '}
                <FormStatusAnchor
                  onClick={() => {
                    onResendCodeClick(values);
                  }}
                >
                  request a new code
                </FormStatusAnchor>
              </FormError>
            ),
          });
        }
      }
      nonFieldErrors &&
        helpers.setStatus({
          errors: (
            <>
              {nonFieldErrors.map((error) => (
                <FormError>{error}</FormError>
              ))}
            </>
          ),
          info: undefined,
        });
    }
  };

  const getValidationSchema = (): ObjectSchema<{ phoneNumber?: string } | { verificationCode?: string }> => {
    if (step === TwoFactorAuthSetupStep.VerifyPhoneNumber) {
      return VerifyPhoneNumberSchema;
    }
    if (step === TwoFactorAuthSetupStep.ConfirmVerificationCode) {
      return ConfirmVerificationCodeSchema;
    }
    throw new Error(`Unknown step: ${step}`);
  };

  const onResendCodeClick = async (
    values: TwoFactorAuthSetupFormValues,
    helpers?: FormikHelpers<TwoFactorAuthSetupFormValues>
  ): Promise<void> => {
    const { phoneNumber } = values;

    try {
      await fusionAuthClient.sendTwoFactorCodeForEnableDisable({
        method: 'sms',
        mobilePhone: phoneNumber,
        userId: session.user?.uuid,
      });
      notification.enqueueSuccessSnackbar('Confirmation code has been sent');
      if (helpers) {
        informationMessage(helpers, values);
      }

      setStep(TwoFactorAuthSetupStep.ConfirmVerificationCode);
    } catch (e) {
      const err = e as AxiosError;
      Logger.error('Invalid verify phone number API response', err);
      const errors = apiErrors(err, 'Confirmation code cannot be sent right now');
      errors.forEach((message: string) => notification.enqueueErrorSnackbar(message));
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getValidationSchema()}>
      {({ isSubmitting, status, errors, touched, submitForm, values }) => (
        <Form id="change-password-form">
          {step === TwoFactorAuthSetupStep.VerifyPhoneNumber && (
            <>
              <FormRow hasError={Object.keys(errors).includes('phoneNumber') && Boolean(touched.phoneNumber)}>
                <RowLabel>Phone Number</RowLabel>
                <StyledField
                  id="phoneNumber"
                  name="phoneNumber"
                  autoComplete="phone"
                  component={SecondaryTextField}
                  required
                  placeholder="E.g. +44 1234567890"
                  fullWidth
                />
                <FormStatus status={status} />
                <SubmitButton
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={submitForm}
                >
                  Send Code
                </SubmitButton>
              </FormRow>
            </>
          )}
          {step === TwoFactorAuthSetupStep.ConfirmVerificationCode && (
            <ConfirmVerificationCodeContainer>
              <DigitsContainer>
                {Array.from({ length: 6 }, (_, index) => (
                  <DigitField
                    index={index}
                    inputRef={(el) => {
                      confirmVerificationCodesInputRefs.current[index] = el;
                    }}
                    onValueEntered={(digitIndex) => {
                      confirmVerificationCodesInputRefs.current[digitIndex + 1]?.focus();
                    }}
                    onBackSpacePressed={(digitIndex) => {
                      confirmVerificationCodesInputRefs.current[digitIndex - 1]?.focus();
                    }}
                    confirmationCodeSubmitted={hasConfirmationCodeBeenSubmitted}
                    data-testid={`digit-${index}`}
                  />
                ))}
              </DigitsContainer>
              <FormStatus status={status} />
              <SubmitButton
                disabled={isSubmitting || Object.keys(errors).length > 0 || values.verificationCode.length !== 6}
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                onClick={() => {
                  submitForm();
                }}
              >
                Continue
              </SubmitButton>
            </ConfirmVerificationCodeContainer>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default TwoFactorAuthSetupForm;
