import React from 'react';

import { GridActionsCellItem, GridValidRowModel } from '@mui/x-data-grid';

import { Popover, MenuItem } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

const ActionCell: React.FunctionComponent<{ rowData: GridValidRowModel }> = ({ rowData }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleRemoveClick = async (): Promise<void> => {
    rowData.removeMember();
  };

  return (
    <>
      <GridActionsCellItem icon={<MoreVert />} label="" color="inherit" onClick={handleOpen} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem id="remove-button" onClick={handleRemoveClick}>
          Remove
        </MenuItem>
      </Popover>
    </>
  );
};

export default ActionCell;
