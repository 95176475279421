import styled from 'styled-components';

import { DeviceTypeWrapper } from 'containers/Devices/components/DeviceTypeCell/styles';

export const UnassignContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;

  hover: none;
`;

export const ListItemWrapper = styled(DeviceTypeWrapper)<{
  disableHover?: boolean;
}>`
  height: 32px;
  cursor: default;
  padding-left: 16px;
  margin: 4px 0px 4px 0px;

  ${(props) =>
    !props.disableHover
      ? `:hover {
        background-color: rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
      :hover > * {
        background-color: transparent;
        border: none;
      }`
      : ''};
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: rgba(238, 238, 238, 1);
  border-radius: 64px;
`;
