import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as AssignedGroupComponent } from 'assets/icons/Group Assigned.svg';

const AssignedGroup = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon component={AssignedGroupComponent} viewBox="0 0 32 32" {...props} />
);

export default AssignedGroup;
