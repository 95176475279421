import React from 'react';

import Row from 'containers/IncidentDetails/components/IncidentInformation/components/Row/Row';

import { StyledRowWrapper, RowContainer, StyledDivider } from './styles';

const EntityRowWrapper: React.FC<{ rowName: string; withDivider: boolean; children: React.ReactNode }> = ({
  rowName,
  withDivider,
  children,
}) => (
  <StyledRowWrapper>
    <RowContainer>
      <Row name={rowName} id={`entities-${rowName.toLowerCase()}-field`}>
        {children}
      </Row>
    </RowContainer>
    {withDivider && <StyledDivider />}
  </StyledRowWrapper>
);

export default EntityRowWrapper;
