import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { TaskAssignmentProps } from './types';

const TaskAssignment: React.FunctionComponent<TaskAssignmentProps> = ({ activity, sessionUser }) => {
  if (activity.user) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-assigned">
        assigned task to {activity.user.name}
      </EventItem>
    );
  }
  if (activity.previousUser && activity.user === null) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id="activity-comment-log-unassigned">
        unassigned task from {activity.previousUser.name}
      </EventItem>
    );
  }
  return <></>;
};

export default observer(TaskAssignment);
