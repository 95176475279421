import { formatFullDate } from 'utils/formatters/time';
import { DeviceAnalyticsType, ChartType } from 'utils/api/types';

export type FilterSelected = {
  filter: string;
  value: string;
  labelFilter: string;
  labelValue: string;
  hide?: boolean;
};

export const OPTIONS = {
  maintainAspectRatio: false,
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    drawTicks: false,
    x: {
      offset: true,
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
        offset: true,
      },
      ticks: {
        autoSkip: true,
        maxRotation: 90,
        minRotation: 90,
        labelOffset: -5,
      },
    },
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      },
      grid: {
        borderDash: [2, 3],
        drawBorder: false,
        zeroLineColor: 'transparent',
      },
    },
  },
  layout: {
    padding: {
      top: 0,
      right: 24,
      bottom: 0,
      left: 24,
    },
  },
  events: ['click'],
};

export const COMMON = {
  barPercentage: 0.7,
  hoverBorderColor: '#fff',
  hoverBorderWidth: 1,
  borderRadius: 1,
};

export const CHART_COLORS = {
  active: '#4BA0FD',
  inactive: '#E0E0E0',
};

const past30Days = [...Array(30).keys()]
  .map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return formatFullDate(date);
  })
  .reverse();

export const useAnalyticsData = (dataList: DeviceAnalyticsType[]): ChartType => {
  const test: ChartType = { date: [], active: [], inactive: [] };

  // eslint-disable-next-line array-callback-return
  past30Days.map((day) => {
    const res = dataList.find((o) => o.date === day);
    test.date.push(day);
    if (res) {
      test.active.push(res.active);
      test.inactive.push(res.inactive);
    } else {
      test.active.push(0);
      test.inactive.push(0);
    }
  });

  return test;
};
