import * as React from 'react';

function ConfirmCheck(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg width="84" height="83" viewBox="0 0 84 83" fill="none" {...props}>
      <circle opacity="0.2" cx="42" cy="41.5" r="41.5" fill="url(#paint0_linear_5513_290273)" />
      <circle opacity="0.3" cx="42" cy="41.5" r="26.5" fill="#4CAF50" />
      <circle cx="42" cy="41.5" r="15.5" fill="#4CAF50" />
      <path
        d="M50.5 35.5L39.5 46.5L34.5 41.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5513_290273"
          x1="65.7562"
          y1="6.30565"
          x2="42"
          y2="83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CAF50" stopOpacity="0" />
          <stop offset="1" stopColor="#4CAF50" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ConfirmCheck;
