import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import React from 'react';

import { GOOGLE_MAPS_STYLES } from 'core/constants';

import { EnchantedMapContainer } from './styles';

const EnchantedMap: React.FunctionComponent<GoogleMapProps> = ({
  children,
  options,
  ...props
}): React.ReactElement => (
  <EnchantedMapContainer>
    <GoogleMap
      mapContainerClassName="google-maps-container"
      options={{
        styles: GOOGLE_MAPS_STYLES,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
        ...options,
      }}
      {...props}
    >
      {children}
    </GoogleMap>
  </EnchantedMapContainer>
);

export default EnchantedMap;
