import { prepareIcon } from './Icon';
import redAlertCancelled from '../../assets/icons/Red Alert - Cancelled.png';
import redAlertCancelled2x from '../../assets/icons/Red Alert - Cancelled@2x.png';
import redAlertCancelled3x from '../../assets/icons/Red Alert - Cancelled@3x.png';

const CancelledRedAlert = prepareIcon({
  alt: 'Cancelled Alert',
  src: redAlertCancelled,
  srcSet: `
    ${redAlertCancelled2x} 2x,
    ${redAlertCancelled3x} 3x
  `,
});

export default CancelledRedAlert;
