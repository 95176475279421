import React, { useState } from 'react';

import { Typography } from '@mui/material';

import { msToTimeVerbose } from 'utils/formatters/time';
import { AmberAlertStatus } from 'core/constants';

import { TimeCellProps } from './types';

const getRemainingTime = (endTime: string): number => new Date(endTime).getTime() - Date.now();

const TimeCell: React.FunctionComponent<TimeCellProps> = ({ row: { endTime, alertStatus } }): React.ReactElement => {
  const [time, setTime] = useState(getRemainingTime(endTime));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(getRemainingTime(endTime));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [endTime]);

  return endTime && time / 1000 > 0.9 && alertStatus === AmberAlertStatus.Pending ? (
    <Typography>{msToTimeVerbose(time)}</Typography>
  ) : (
    <Typography> N/A</Typography>
  );
};

export default TimeCell;
