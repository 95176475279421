import React from 'react';
import { FormControl } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import { StyledDatePicker } from 'components/Input/styles';

import { CustomDatePickerProps } from './types';
import CustomActionBar from '../CustomPickerActionBar/CustomPickerActionBar';

export const CustomDatePicker = ({
  value,
  onChange,
  requiredNotFilled,
  ...props
}: CustomDatePickerProps): React.ReactElement => {
  const [val, setValue] = React.useState<Dayjs | null>(value ? dayjs(value as string) : null);
  const [error, setError] = React.useState('');

  const handleChange = (newValue: Dayjs | null): void => {
    if (newValue && Object.prototype.toString.call(newValue) === '[object Object]') {
      //  @ts-ignore
      if (isNaN(newValue)) {
        onChange('Invalid date');
        setError('Invalid date');
      } else {
        setValue(newValue);
        onChange(newValue ? new Date(newValue.format()).getTime() : null);
        setError('');
      }
    } else {
      setValue(null);
      onChange(null);
      setError('');
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleToggleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    setOpen((prev) => !prev);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <FormControl fullWidth>
      <StyledDatePicker
        {...props}
        utc
        timezone="UTC"
        id="date-picker"
        value={val}
        // @ts-ignore
        onChange={(date: Dayjs) => {
          handleChange(date);
        }}
        open={open}
        onClose={handleClose}
        slotProps={{
          field: { clearable: true },
          textField: {
            onClick: handleToggleOpen,
            helperText: error,
            InputProps: {
              ...(window.innerWidth < 768 && {
                endAdornment: <InsertInvitationIcon htmlColor="rgba(0, 0, 0, 0.54)" />,
              }),
            },
            error: requiredNotFilled,
          },
          clearButton: {
            style: { padding: 0 },
          },
        }}
        slots={{
          actionBar: CustomActionBar,
          toolbar: () => null,
        }}
        format="DD/MM/YYYY"
      />
    </FormControl>
  );
};

export default CustomDatePicker;
