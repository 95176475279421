/* eslint-disable max-len */
import React from 'react';

export function UsageIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73492)">
        <path
          d="M15.81 2.19C14.46 0.84 12.57 0 10.5 0C10.08 0 9.75 0.36 9.75 0.75V7.5C9.75 7.92 10.08 8.25 10.5 8.25H17.25C17.67 8.25 18 7.92 18 7.5C18 5.43 17.16 3.54 15.81 2.19ZM11.67 1.62C13.08 1.89 14.28 2.67 15.12 3.75H11.25V2.01C11.25 1.77 11.46 1.59 11.67 1.65V1.62ZM11.25 6.36V5.22H16.02C16.17 5.55 16.29 5.88 16.38 6.27C16.44 6.51 16.26 6.72 16.02 6.72H11.64C11.43 6.72 11.25 6.54 11.25 6.36Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M14.2509 9.75002H9.00085C8.58085 9.75002 8.25085 9.42002 8.25085 9.00002V3.75002C8.25085 3.36002 7.92085 3.00002 7.53085 3.00002C2.64085 2.97002 -1.16915 7.65002 0.330852 12.75C1.02085 15.09 2.91085 16.98 5.25085 17.67C10.3509 19.17 15.0309 15.36 15.0009 10.47C15.0009 10.08 14.6409 9.75002 14.2509 9.75002ZM7.50085 16.5C4.20085 16.5 1.50085 13.83 1.50085 10.5C1.50085 7.80002 3.33085 5.46002 5.82085 4.74002C6.30085 4.59002 6.75085 4.98002 6.75085 5.46002V10.47C6.75085 10.89 7.08085 11.22 7.50085 11.22H12.5109C12.9909 11.22 13.3809 11.67 13.2309 12.15C12.5109 14.64 10.2009 16.47 7.47085 16.47L7.50085 16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73492">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveUsageIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73485)">
        <path
          d="M15.81 2.19C14.46 0.84 12.57 0 10.5 0C10.08 0 9.75 0.36 9.75 0.75V7.5C9.75 7.92 10.08 8.25 10.5 8.25H17.25C17.67 8.25 18 7.92 18 7.5C18 5.43 17.16 3.54 15.81 2.19ZM11.67 1.62C13.08 1.89 14.28 2.67 15.12 3.75H11.25V2.01C11.25 1.77 11.46 1.59 11.67 1.65V1.62ZM11.25 6.36V5.22H16.02C16.17 5.55 16.29 5.88 16.38 6.27C16.44 6.51 16.26 6.72 16.02 6.72H11.64C11.43 6.72 11.25 6.54 11.25 6.36Z"
          fill="white"
        />
        <path
          d="M15.0009 10.47C15.0309 15.36 10.3509 19.17 5.25085 17.67C2.91085 17.01 1.02085 15.09 0.330852 12.75C-1.16915 7.65 2.64085 3 7.53085 3C7.92085 3 8.25085 3.36 8.25085 3.75V9C8.25085 9.42 8.58085 9.75 9.00085 9.75H14.2509C14.6409 9.75 15.0009 10.08 15.0009 10.47Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73485">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
