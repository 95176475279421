/* eslint-disable @typescript-eslint/no-explicit-any */

import { LogLevel, LogLevelWeight } from './constants';
import { handlerFactory } from './handler/factory';
import { Handler } from './handler/types';

class Logger {
  handler: Handler;

  constructor(handler: Handler) {
    this.handler = handler;
  }

  error(message: string, error?: any, ...params: any[]): void {
    this.handleLog(LogLevel.error, message, error, params);
  }

  info(message: string, error?: any, ...params: any[]): void {
    this.handleLog(LogLevel.info, message, error, params);
  }

  warn(message: string, error?: any, ...params: any[]): void {
    this.handleLog(LogLevel.warning, message, error, params);
  }

  debug(message: string, error?: any, ...params: any[]): void {
    this.handleLog(LogLevel.debug, message, error, params);
  }

  critical(message: string, error?: any, ...params: any[]): void {
    this.handleLog(LogLevel.critical, message, error, params);
  }

  handleLog(level: LogLevel, message: string, error: any, params: any[]): void {
    let loggerLevel = process.env.REACT_APP_LOGGER_LEVEL;
    if (!loggerLevel) {
      loggerLevel = LogLevel.error;
      // eslint-disable-next-line no-console
      console.warn('"REACT_APP_LOGGER_LEVEL" not set. Default to error');
    }
    if (LogLevelWeight[level] >= LogLevelWeight[loggerLevel]) {
      this.handler.log(level, message, error, params);
    }
  }
}

export default new Logger(
  handlerFactory(process.env.REACT_APP_LOGGER_HANDLER)
);
