import { observable, makeObservable } from 'mobx';

import RootStore from '../../stores/Root';
import API from '../api';

export default class DataMixin {
  store: RootStore;

  api: typeof API;

  @observable isLoaded = false;

  @observable isFailure = false;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;
  }

  reset(): void {
    this.isFailure = false;
    this.isLoaded = false;
  }
}
