import { action } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import TableStore from 'vatix-ui/lib/utils/stores/TableStore';

import { EventType } from 'utils/events/constants';

import Logger from 'utils/logger';

import { isBadRequest } from 'utils/api/errors';

import API from '../../utils/api';
import { EscalationProfileResponse } from '../../utils/api/types';
import RootStore from '../Root';

export default class EscalationProfileList extends TableStore<
  RootStore,
  typeof API,
  EscalationProfileResponse,
  EscalationProfileResponse
> {
  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, api.loadEscalationProfiles);
    this.sortingColumn = 'name';

    eventBus.register(this);
  }

  @subscribe(EventType.UpdatedEscalationProfile)
  @action
  updateEscalationProfile(): void {
    this.reloadData();
  }

  @action
  async deleteAlarmResponseProfile(uuid: string): Promise<void> {
    try {
      await this.api.deleteAlarmResponseProfile(uuid)();
      this.isLoaded = false;
      this.store.notification.enqueueSuccessSnackbar('Alarm response profile removed successfully');
    } catch (e) {
      Logger.error(`Invalid Alarm Response Profile deletion action API response`, e);
      // @ts-ignore
      if (isBadRequest(e)) {
        this.store.notification.enqueueErrorSnackbar(
          'You cannot remove this alarm response profile because it is currently assigned to at least one user.'
        );
      } else {
        this.store.notification.enqueueErrorSnackbar('There was an error removing the alarm response profile');
      }
    }
  }
}
