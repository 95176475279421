import styled from 'styled-components';
import Paper from '@mui/material/Paper';

import VatixTheme from 'theme/vatix';

import { ellipsis } from 'core/styles';

export const StyledPaper = styled(Paper).attrs({
  elevation: 5,
})`
  color: #000;
  padding: 8px;
  min-width: 260px;
`;

export const LocationAddress = styled.p`
  font-size: 1.3rem;
  line-height: 1.7rem;
  margin: 0;
  padding-top: 2px;
  ${ellipsis};
`;

export const LocationPosition = styled.p`
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 0;
  padding-top: 2px;
`;

export const TimeToEnd = styled.div`
  text-align: left;
  font: normal normal normal 14px/16px Roboto;
  color: ${VatixTheme.palette.text.secondary};
  margin: 6px 0;
`;
