// eslint-disable-next-line no-restricted-imports
import { DefaultTheme as StyledTheme } from 'styled-components';

import { Theme as MuiTheme } from './mui';

export const Theme: StyledTheme = {
  ...MuiTheme,
  ui: {
    snackbar: {
      success: '#78C925',
      warning: '#F5A623',
      info: '#4285F4',
      error: '#FF0000',
    },
    profileAndSettings: {
      passwordChanged: '#7ED321',
    },
    select: {
      arrowDropDown: 'rgba(0, 0, 0, 0.54)',
    },
    avatar: {
      background: '#B616E4',
    },
    chip: {
      success: '#4BBD00',
      error: '#F30000',
      border: '#D3D6D9',
      grayText: '#6D7883',
      text: '#0772E5',
    },
    alarm: {
      main: '#E20101',
      mainHover: '#a80000',
      amber: '#FC9B00',
      amberHover: '#c27802',
    },
    icon: {
      background: {
        secondary: '#E2E4E6',
        primary: '#0772E51A',
      },
    },
  },
};
