import { prepareIcon } from './Icon';
import redAlertCompleted from '../../assets/icons/Red Alert - Completed.png';
import redAlertCompleted2x from '../../assets/icons/Red Alert - Completed@2x.png';
import redAlertCompleted3x from '../../assets/icons/Red Alert - Completed@3x.png';

const CompletedRedAlert = prepareIcon({
  alt: 'Completed Alert',
  src: redAlertCompleted,
  srcSet: `
    ${redAlertCompleted2x} 2x,
    ${redAlertCompleted3x} 3x
  `,
});

export default CompletedRedAlert;
