import { action, computed, observable, toJS, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { UserRole } from 'core/constants';

import {
  EditUserResponse,
  VehicleDetailsResponse,
  ContactDetailsResponse,
  OrganisationResponse,
  AlarmResponseProfileResponse,
  UserLicensesResponse,
} from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import { ActionEvent, UpdatedProfilePayload } from 'utils/events/types';

import { EditUserFormValues } from '../../containers/UserDetails/types';
import UserPersonalDetails from '../UserDetails/UserPersonalDetails';

export default class EditUserData {
  uuid: string;

  @observable name: string;

  @observable role: UserRole;

  organisation: OrganisationResponse;

  email: string;

  @observable phoneNumber: string | null;

  @observable personalDetails: UserPersonalDetails;

  @observable alarmResponseProfile: AlarmResponseProfileResponse | null;

  @observable vehicleDetails: VehicleDetailsResponse[];

  @observable contactDetails: ContactDetailsResponse[];

  @observable groups: UuidableName[];

  @observable licenses: UserLicensesResponse;

  @observable dateJoined: string;

  constructor(data: EditUserResponse) {
    makeObservable(this);
    this.uuid = data.uuid;
    this.name = data.name;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.role = data.role;
    this.organisation = data.organisation;
    this.personalDetails = new UserPersonalDetails(data.personalDetails);
    this.alarmResponseProfile = data.alarmResponseProfile;
    this.vehicleDetails = data.vehicleDetails;
    this.contactDetails = data.contactDetails;
    this.groups = data.groups;
    this.licenses = data.licenses;
    this.dateJoined = data.dateJoined;

    eventBus.register(this);
  }

  @subscribe(EventType.UpdatedProfile)
  @action
  updatedProfile({ payload }: ActionEvent<UpdatedProfilePayload>): void {
    if (payload.uuid !== this.uuid) {
      return;
    }

    this.name = payload.name;
    this.role = payload.role;
    this.phoneNumber = payload.phoneNumber;
  }

  @computed get initialValues(): EditUserFormValues {
    return toJS({
      name: this.name,
      role: this.role,
      phoneNumber: this.phoneNumber || '',
      email: this.email,
      personalDetails: this.personalDetails.initialValues,
      vehicleDetails: this.vehicleDetails.map((vehicle) => ({
        ...vehicle,
        registeredDate: vehicle.registeredDate || '',
      })),
      contactDetails: this.contactDetails.map((contact) => ({
        ...contact,
        phoneNumber: contact.phoneNumber || '',
      })),
    });
  }
}
