import { observer } from 'mobx-react';
import React from 'react';

import { Grid } from '@mui/material';

import dayjs from 'dayjs';

import GroupsIcon from '@mui/icons-material/Groups';

import { Button } from 'vatix-ui/lib/components/Button';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import EditIcon from '@mui/icons-material/Edit';

import UserDetailsData from 'stores/UserDetails/UserDetailsData';

import GridListRenderer from 'components/Grid/renderers/GridListRenderer';

import { SectionGrid } from 'components/BackgroundInformation/styles';

import { useStore } from '../../../../utils/hooks/store';
import { StyledRowTitle, RowValue, TabTitle } from './styles';
import EditPersonalInformation from '../EditPersonalInformation';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <>
    <SectionGrid item container xs={2} alignContent="flex-start" id={id} margin="8px 0">
      <StyledRowTitle>{name}</StyledRowTitle>
    </SectionGrid>
    <SectionGrid item container xs={10}>
      {children}
    </SectionGrid>
  </>
);

export const PersonalInformation: React.FunctionComponent = () => {
  const {
    userDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as UserDetailsData;
  const [editing, setEditing] = React.useState(false);

  return (
    <>
      <Grid
        container
        style={{ padding: '16px 20px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', minHeight: '68px' }}
        alignItems="center"
      >
        <SectionGrid item container xs={10} alignContent="flex-start">
          <TabTitle>Personal Information</TabTitle>
        </SectionGrid>
        <SectionGrid item container xs={2} justifyContent="end">
          {!editing && (
            <Button size="medium" variant="text" onClick={() => setEditing(true)}>
              <EditIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
              Edit
            </Button>
          )}
        </SectionGrid>
      </Grid>
      {editing ? (
        <EditPersonalInformation setEditing={setEditing} />
      ) : (
        <Grid container marginTop="16px" style={{ padding: '0px 20px 20px 20px' }} alignItems="center">
          <Row name="Name">
            <RowValue>{details.name || 'N/A'}</RowValue>
          </Row>

          <Row name="Email">
            <RowValue>{details.email || 'N/A'}</RowValue>
          </Row>
          <Row name="Account">
            <RowValue>{details.organisation.name || 'N/A'}</RowValue>
          </Row>
          <Row name="Date of birth">
            <RowValue>
              {details.personalDetails.birthDate
                ? dayjs(details.personalDetails.birthDate).format('MMM D, YYYY')
                : 'N/A'}
            </RowValue>
          </Row>
          <Row name="Phone number">
            <RowValue>{details.phoneNumber || 'N/A'}</RowValue>
          </Row>
          <Row name="Created">
            <RowValue>{details.dateJoined ? dayjs(details.dateJoined).format('D MMM YYYY, hh:mm') : 'N/A'}</RowValue>
          </Row>
          <Row name="Teams">
            {details.groups.length > 0 ? (
              <GridListRenderer
                maxItems={3}
                value={details.groups}
                rendererItem={({ value }) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <GroupsIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    <RowValue style={{ margin: '0 16px 0 6px' }}>{(value.value as UuidableName).name}</RowValue>
                  </div>
                )}
              />
            ) : (
              <RowValue>N/A</RowValue>
            )}
          </Row>
          <Row name="Memorable word">
            <RowValue>{details.personalDetails.verificationWord || 'N/A'}</RowValue>
          </Row>
        </Grid>
      )}
    </>
  );
};

export default observer(PersonalInformation);
