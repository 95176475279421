import React from 'react';
import { observer } from 'mobx-react';

import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';

import API from 'utils/api';

import { useStore } from 'utils/hooks/store';

import AlarmActivitiesStore from 'stores/AlarmDetails/AlarmActivities';

const AlarmActivities: React.FunctionComponent = (): React.ReactElement => {
  const { alarmDetails } = useStore();
  const activities = alarmDetails.activities as AlarmActivitiesStore;

  return (
    <AddCommentActivities
      placeholder="Add a comment or drag and drop files"
      uploadFile={activities.uploadFile}
      sendMessage={activities.addMessage}
      usersSearch={API.searchUsers}
    />
  );
};

export default observer(AlarmActivities);
