import { LoadScriptNext } from '@react-google-maps/api';
import React from 'react';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { getDisplayName } from 'utils/hocs/name';

const withGoogleMaps = <T extends {}>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> =>
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component<T> {
    static displayName = `withGoogleMaps(${getDisplayName(WrappedComponent)}`;

    render(): React.ReactNode {
      return (
        <LoadScriptNext
          id="google-maps-script-loader"
          googleMapsApiKey={(process.env.REACT_APP_GOOGLE_MAPS_API_KEY as unknown) as string}
          loadingElement={<CenteredCircularProgress />}
        >
          <WrappedComponent {...this.props} />
        </LoadScriptNext>
      );
    }
  };
export default withGoogleMaps;
