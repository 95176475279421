type LngLat = {
  lat: number | string;
  lng: number | string;
};

type LngLatPosition = {
  position: LngLat;
};

const isLngLatPosition = (obj: unknown): obj is LngLatPosition =>
  Object.prototype.hasOwnProperty.call(obj, 'position');

const isLngLat = (obj: unknown): obj is LngLat =>
  Object.prototype.hasOwnProperty.call(obj, 'lat') &&
  Object.prototype.hasOwnProperty.call(obj, 'lng');

export function fitBounds(
  map: google.maps.Map | undefined,
  markers: LngLat[] | LngLatPosition[]
): void {
  if (!map || !markers.length) {
    return;
  }

  const bounds = new window.google.maps.LatLngBounds();
  markers.forEach((marker: unknown) => {
    if (isLngLat(marker)) {
      bounds.extend(
        new window.google.maps.LatLng(Number(marker.lat), Number(marker.lng))
      );
    }
    if (isLngLatPosition(marker)) {
      bounds.extend(
        new window.google.maps.LatLng(
          Number(marker.position.lat),
          Number(marker.position.lng)
        )
      );
    }
  });
  // Don't zoom in too far on only one marker
  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
    const extendStartPoint = new window.google.maps.LatLng(
      bounds.getNorthEast().lat() + 0.01,
      bounds.getNorthEast().lng() + 0.01
    );
    const extendEndPoint = new window.google.maps.LatLng(
      bounds.getNorthEast().lat() - 0.01,
      bounds.getNorthEast().lng() - 0.01
    );
    bounds.extend(extendStartPoint);
    bounds.extend(extendEndPoint);
  }
  map.fitBounds(bounds);
}
