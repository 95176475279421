import React from 'react';
import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import UserRow from 'components/UserRow';
import { Value } from 'containers/IncidentDetails/components/EditableTextInput/styles';

import { useStore } from 'utils/hooks/store';

const UserField: React.FunctionComponent<{ userId?: string }> = ({ userId }) => {
  const { userDetails } = useStore();

  const [name, setName] = React.useState('');

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (userId === undefined) {
        return;
      }
      await userDetails.loadDetails(userId);
      if (userDetails.isLoaded && userDetails.details) {
        setName(userDetails.details.name as string);
      }
    };

    fetchData();
  }, [userId, userDetails]);

  if (userId === undefined) {
    return <UserRow user={{ name: 'N/A' } as UuidableName} />;
  }

  return !userDetails.isLoaded || name === '' ? <Value>Loading...</Value> : <UserRow user={{ name } as UuidableName} />;
};

export default UserField;
