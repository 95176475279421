/* eslint-disable max-len */
import * as React from 'react';

export function WarningToast(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M12 6.49L19.53 19.5H4.47L12 6.49ZM12 2.5L1 21.5H23L12 2.5Z" fill="#ED6C02" />
      <path d="M13 16.5H11V18.5H13V16.5Z" fill="#ED6C02" />
      <path d="M13 10.5H11V15.5H13V10.5Z" fill="#ED6C02" />
    </svg>
  );
}

export function CloseWarningToast(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M15.8327 5.34169L14.6577 4.16669L9.99935 8.82502L5.34102 4.16669L4.16602 5.34169L8.82435 10L4.16602 14.6584L5.34102 15.8334L9.99935 11.175L14.6577 15.8334L15.8327 14.6584L11.1743 10L15.8327 5.34169Z"
        fill="#ED6C02"
      />
      <path
        d="M15.8327 5.34169L14.6577 4.16669L9.99935 8.82502L5.34102 4.16669L4.16602 5.34169L8.82435 10L4.16602 14.6584L5.34102 15.8334L9.99935 11.175L14.6577 15.8334L15.8327 14.6584L11.1743 10L15.8327 5.34169Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
}
