import React from 'react';
import { observer } from 'mobx-react';
import InputAdornment from '@mui/material/InputAdornment';

import { useFormikContext } from 'formik';

import ArrowLeft from 'components/Icons/ArrowLeft';
import Lock from 'components/Icons/Lock';
import routes from 'core/routes';

import { LoginTextFieldProps, LoginStep, LoginFormValues } from 'containers/Login/types';

import { StyledTextField } from 'components/Input/styles';

import { HelpLink, TitleWrapper, EmailTitle, InputSubtitle, BackButton, StyledButton } from '../../styles';

const EnterCredentials: React.FunctionComponent<LoginTextFieldProps> = ({ error, helperText, setStep }) => {
  const { values, isSubmitting, handleChange, setFieldValue } = useFormikContext<LoginFormValues>();

  const handleEnterEmailSetStep = (): void => {
    setFieldValue('username', '');
    setFieldValue('password', '');
    setStep && setStep(LoginStep.EnterEmail);
  };

  return (
    <>
      <BackButton startIcon={<ArrowLeft />} onClick={handleEnterEmailSetStep}>
        Back
      </BackButton>
      <TitleWrapper>
        <EmailTitle>{values.username}</EmailTitle>
        <InputSubtitle>Please enter your password</InputSubtitle>
      </TitleWrapper>
      <StyledTextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock sx={{ fill: '#4BA0FD' }} />
            </InputAdornment>
          ),
        }}
        fullWidth
        autoFocus
        id="password"
        name="password"
        type="password"
        variant="outlined"
        placeholder="Enter your password"
        value={values.password}
        onChange={handleChange}
        error={error}
        helperText={helperText}
      />
      <HelpLink to={String(routes.passwordReset)} onMouseDown={(event) => event.preventDefault()}>
        Forgot password?
      </HelpLink>
      <StyledButton type="submit" variant="contained" color="primary" disabled={isSubmitting || !values.password}>
        {!isSubmitting ? 'Login' : 'Processing'}
      </StyledButton>
    </>
  );
};

export default observer(EnterCredentials);
