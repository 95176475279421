import React from 'react';
import { FormControl } from '@mui/material';

import { StyledTextField } from 'components/Input/styles';

import { ProtectorTypeTextProps } from '../types';

export const CustomTextInputField = ({
  value,
  description,
  onChange,
  multiline,
  requiredNotFilled,
}: ProtectorTypeTextProps): React.ReactElement => {
  const [input, setInput] = React.useState(value);
  return (
    <FormControl fullWidth>
      <StyledTextField
        id={`${multiline ? 'long' : 'short'}-text-field`}
        placeholder={description}
        value={input}
        onChange={(event) => {
          setInput(event.target.value);
          onChange(event.target.value);
        }}
        variant="outlined"
        multiline={multiline}
        rows={description === 'Description' || multiline ? 5 : 1}
        onFocus={(event) => {
          const { length } = input as string;
          event.target.setSelectionRange(length, length);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        error={requiredNotFilled}
      />
    </FormControl>
  );
};

export default CustomTextInputField;
