import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Button } from 'vatix-ui/lib/components/Button';

import { CircularProgress } from '@mui/material';

import Grid from '@mui/material/Grid';

import { ProtectorType } from 'utils/api/types';
import IncidentFormField from 'containers/IncidentDetails/components/IncidentFormField/IncidentFormField';

import EditEntityRow from 'components/EditEntityRow';

import { transformInitialValues, transformValues } from './utils';
import { EditEntitySectionProps, InitialValues } from './types';

const EditEntitySection: React.FunctionComponent<EditEntitySectionProps> = ({
  setEditing,
  fieldOrder,
  entity,
  store: { edit },
}): React.ReactElement => {
  const initialValues = transformInitialValues(entity);

  const onSubmit = async (values: InitialValues, actions: FormikHelpers<InitialValues>): Promise<void> => {
    const val = transformValues(values, initialValues, entity);
    const updated = await edit(val);

    if (updated) {
      actions.setSubmitting(false);
      setEditing(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit}>
          {fieldOrder.map((outerOrderKey) => {
            const { schema, value } = entity[outerOrderKey];
            return (
              <EditEntityRow key={`edit-${outerOrderKey}`} id={`edit-${outerOrderKey}`} name={schema.name || 'N/A'}>
                <IncidentFormField
                  editable
                  type={schema.protectorType as ProtectorType}
                  schema={schema}
                  originalValue={value || ''}
                  value={values[outerOrderKey] || ''}
                  onChange={(val) => setFieldValue(outerOrderKey, val)}
                  description={schema.name || 'N/A'}
                  items={{ enum: schema.enum as string[] }}
                  maximum={schema.maximum}
                  minimum={schema.minimum}
                  requiredNotFilled={false}
                  lookupType={schema.lookupType}
                />
              </EditEntityRow>
            );
          })}

          <Grid item container xs={12} marginTop={2} marginBottom={2} justifyContent="end">
            <Button
              disabled={isSubmitting}
              onClick={() => setEditing(false)}
              style={{
                marginRight: '16px',
              }}
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
            {isSubmitting ? (
              <Button
                disabled
                startIcon={<CircularProgress size={24} color="inherit" />}
                variant="contained"
                size="large"
              >
                Saving...
              </Button>
            ) : (
              <Button type="submit" disabled={isSubmitting} variant="contained" size="large">
                Save
              </Button>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};
export default EditEntitySection;
