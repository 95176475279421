/* eslint-disable max-len */
import React from 'react';

export function AssetsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M19.6793 10.6497C19.6776 10.6489 19.6761 10.6478 19.6744 10.647L15.2734 8.46469V2.92969C15.2734 2.71168 15.1509 2.50934 14.9555 2.40887C14.9534 2.40781 14.9516 2.40664 14.9495 2.40562L10.262 0.061875C10.0971 -0.020625 9.90293 -0.020625 9.73797 0.061875L5.05047 2.40562C5.04844 2.40664 5.04656 2.40781 5.04457 2.40883C4.84422 2.51184 4.72656 2.71754 4.72656 2.92969V8.46465L0.325625 10.647C0.323281 10.6481 0.321055 10.6495 0.31875 10.6507C0.311836 10.6542 0.305039 10.6579 0.298281 10.6617C0.112344 10.7665 0 10.9635 0 11.1719V17.0312C0 17.2525 0.124609 17.4548 0.322187 17.5545L5.04875 19.9373C5.05047 19.9381 5.05227 19.9387 5.05398 19.9395C5.21488 20.0187 5.40551 20.0218 5.57301 19.9386C5.57465 19.9377 5.57637 19.9372 5.57801 19.9364L10 17.6886L14.422 19.9364C14.4235 19.9372 14.4251 19.9377 14.4266 19.9384C14.5855 20.0175 14.7764 20.0231 14.9462 19.9395C14.9479 19.9387 14.9496 19.9382 14.9512 19.9373L19.6778 17.5545C19.8754 17.4549 20 17.2526 20 17.0313V11.1719C20 10.958 19.8818 10.7526 19.6793 10.6497ZM4.72656 18.4625L1.17188 16.6705V12.1164L4.72656 13.8791V18.4625ZM5.31141 12.8611L1.90488 11.1719L5.31141 9.4827L8.68981 11.1719L5.31141 12.8611ZM9.41406 16.6718L5.89844 18.4589V13.8778L9.41406 12.12V16.6718ZM9.41406 10.2238L5.89844 8.46602V3.87777L9.41406 5.63559V10.2238ZM6.6227 2.92969L10 1.24105L13.3773 2.92969L10 4.61836L6.6227 2.92969ZM14.1016 3.87777V8.46602L10.5859 10.2238V5.63559L14.1016 3.87777ZM14.1016 18.4589L10.5859 16.6718V12.12L14.1016 13.8778V18.4589ZM14.6886 12.8611L11.3102 11.1719L14.6886 9.4827L18.0951 11.1719L14.6886 12.8611ZM18.8281 16.6705L15.2734 18.4625V13.8791L18.8281 12.1164V16.6705Z"
        fill="#C0C7D3"
      />
    </svg>
  );
}

export function ActiveAssetsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10.5859 5.67469V10.2237L15.2734 7.87984V3.33082L10.5859 5.67469Z" fill="white" />
      <path d="M5.89844 13.8778V20L9.41406 17.9794V12.12L5.89844 13.8778Z" fill="white" />
      <path d="M4.72656 3.33094V7.88001L9.41406 10.2238V5.6747L4.72656 3.33094Z" fill="white" />
      <path d="M4.00215 8.82813L0.625 10.5167L5.3125 12.8605L8.68965 11.1719L4.00215 8.82813Z" fill="white" />
      <path d="M10.5859 12.12V17.9794L14.1016 20V13.8778L10.5859 12.12Z" fill="white" />
      <path d="M15.9998 8.82806L11.3125 11.1719L14.6896 12.8605L19.377 10.5167L15.9998 8.82806Z" fill="white" />
      <path
        d="M0 11.5341V17.0313C0 17.2533 0.125313 17.4558 0.323867 17.5554L4.6875 20V13.8778L0 11.5341Z"
        fill="white"
      />
      <path
        d="M15.3125 13.8778V20L19.6761 17.5554C19.8747 17.4558 20 17.2533 20 17.0313V11.5339L15.3125 13.8778Z"
        fill="white"
      />
      <path
        d="M10.2621 0.0618C10.0973 -0.0205828 9.90274 -0.0205828 9.73793 0.0618L5.3125 2.3136L10 4.65735L14.6873 2.31352L10.2621 0.0618Z"
        fill="white"
      />
    </svg>
  );
}
