/* eslint-disable max-len */
import React from 'react';

const RedAlertIcon = (): React.ReactElement => (
  <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.16152 2.99412C8.81141 1.93068 10.3559 1.93068 11.0058 2.99412L17.2007 13.1312C17.8794 14.2418 17.0801 15.667 15.7786 15.667H3.38876C2.08721 15.667 1.28792 14.2418 1.96662 13.1312L8.16152 2.99412Z"
      fill="white"
    />
    <path
      d="M9.58308 12.207C9.11714 12.207 8.72656 12.5976 8.72656 13.0636C8.72656 13.5295 9.11714 13.9201 9.58308 13.9201C10.0319 13.9201 10.4396 13.5295 10.4191 13.0841C10.4396 12.5942 10.0525 12.207 9.58308 12.207Z"
      fill="#D32F2F"
    />
    <path
      d="M9.3712 6.19435C8.96349 6.31083 8.70996 6.68085 8.70996 7.12967C8.73052 7.40033 8.74765 7.67442 8.7682 7.94508C8.82645 8.97633 8.88469 9.98703 8.94293 11.0183C8.96349 11.3677 9.23415 11.6213 9.58361 11.6213C9.93308 11.6213 10.2072 11.3506 10.2243 10.9977C10.2243 10.7853 10.2243 10.59 10.2448 10.3742C10.2825 9.71294 10.3236 9.05171 10.3613 8.39047C10.3819 7.96221 10.4196 7.53395 10.4401 7.10569C10.4401 6.95151 10.4196 6.81447 10.3613 6.67743C10.1866 6.2937 9.7789 6.09842 9.3712 6.19435Z"
      fill="#D32F2F"
    />
    <path
      d="M17.5111 15.2425C18.049 14.3141 18.0524 13.2074 17.5179 12.2824L12.1527 2.99084C11.6216 2.05551 10.6623 1.50049 9.58655 1.50049C8.51076 1.50049 7.55145 2.05894 7.02041 2.98741L1.6483 12.2892C1.11383 13.2246 1.11726 14.338 1.65858 15.2665C2.19305 16.1847 3.14893 16.7363 4.21787 16.7363H14.9347C16.007 16.7363 16.9698 16.1779 17.5111 15.2425ZM16.3462 14.571C16.0482 15.0849 15.5205 15.3899 14.9312 15.3899H4.21444C3.63201 15.3899 3.10781 15.0918 2.8166 14.5882C2.52195 14.0777 2.51853 13.4678 2.81317 12.9539L8.18528 3.6555C8.4765 3.14501 8.99726 2.84352 9.58655 2.84352C10.1724 2.84352 10.6966 3.14844 10.9878 3.65893L16.3565 12.9573C16.6443 13.4575 16.6409 14.0605 16.3462 14.571Z"
      fill="white"
    />
  </svg>
);

export default RedAlertIcon;
