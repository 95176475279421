/* eslint-disable max-len */
import React from 'react';

import { DeviceType } from 'utils/api/types';

import { AlarmSourceContainer, AlarmSourceIcon, AlarmSourceId, AlarmSourceModel, StyledPhone } from './styles';
import { AlarmSourceProps } from './types';

const AlarmSource: React.FunctionComponent<AlarmSourceProps> = ({ device }): React.ReactElement => {
  const alarmSourceIcon =
    device.deviceType === DeviceType.SharedDevice ? (
      <AlarmSourceIcon src={device.type?.image} />
    ) : (
      <StyledPhone>Mobile app</StyledPhone>
    );
  return (
    <AlarmSourceContainer>
      {alarmSourceIcon}
      <AlarmSourceModel id="device-type">
        {device.deviceType === DeviceType.SharedDevice
          ? `${device.type?.brand} ${device.type?.model}`
          : device.phoneNumber}
      </AlarmSourceModel>
      {device.deviceType === DeviceType.SharedDevice && (
        <AlarmSourceId id="device-name">({device.customId})</AlarmSourceId>
      )}
    </AlarmSourceContainer>
  );
};

export default AlarmSource;
