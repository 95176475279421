import React from 'react';
import { observer } from 'mobx-react';
import InputAdornment from '@mui/material/InputAdornment';

import { useFormikContext } from 'formik';

import PhoneIphone from 'components/Icons/PhoneIphone';
import ArrowLeft from 'components/Icons/ArrowLeft';

import { LoginTextFieldProps, LoginStep, LoginFormValues } from 'containers/Login/types';

import { StyledTextField } from 'components/Input/styles';

import { TitleWrapper, InputTitle, InputSubtitle, StyledButton, BackButton } from '../../styles';

const VerifyPhoneNumber: React.FunctionComponent<LoginTextFieldProps> = ({
  error,
  helperText,
  setStep,
  twoFactorAuthCallback,
}) => {
  const { values, handleChange, isSubmitting, setFieldValue } = useFormikContext<LoginFormValues>();

  const handleEnterEmailSetStep = (): void => {
    setFieldValue('username', '');
    setFieldValue('password', '');
    setFieldValue('phoneNumber', '');
    setStep && setStep(LoginStep.EnterEmail);
  };

  const handleSubmit = (): void => {
    twoFactorAuthCallback && twoFactorAuthCallback(values);
    setStep && setStep(LoginStep.ConfirmVerificationCode);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <BackButton startIcon={<ArrowLeft />} onClick={handleEnterEmailSetStep}>
        Back to login
      </BackButton>
      <TitleWrapper>
        <InputTitle>Two-Factor Authentication</InputTitle>
        <InputSubtitle>
          You need to setup two-factor authentication in order to continue using our platform. Provide your phone number
          to receive your confirmation code.
        </InputSubtitle>
      </TitleWrapper>
      <StyledTextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIphone fill="#4BA0FD" />
            </InputAdornment>
          ),
        }}
        fullWidth
        autoFocus
        id="phoneNumber"
        name="phoneNumber"
        variant="outlined"
        placeholder="e.g. +44 0123456789"
        value={values.phoneNumber}
        onChange={handleChange}
        error={error}
        helperText={helperText}
        onKeyDown={(event) => {
          // @ts-ignore
          handleKeyDown(event);
        }}
      />
      <StyledButton
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitting || !values.phoneNumber || error}
      >
        {!isSubmitting ? 'Send code' : 'Processing'}
      </StyledButton>
    </>
  );
};

export default observer(VerifyPhoneNumber);
