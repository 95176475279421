import React from 'react';

const CustomTabPanel: React.FC<{
  children?: React.ReactNode;
  index: number;
  value: number;
  customStyle?: React.CSSProperties;
}> = ({ children, value, index, customStyle }): React.ReactElement => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    style={{ width: '100%', ...customStyle }}
  >
    {value === index && <>{children}</>}
  </div>
);
export default CustomTabPanel;
