import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React from 'react';
import { useGoogleMap } from '@react-google-maps/api';

import { ZoomControlButton, ZoomControlContainer } from './styles';

const ZoomControl: React.FunctionComponent = (): React.ReactElement | null => {
  const map = useGoogleMap();
  const [zoom, setZoom] = React.useState(map ? map.getZoom() : 0);

  const increaseZoom = React.useCallback(() => {
    map && zoom && map.setZoom(zoom + 1);
  }, [map, zoom]);
  const decreaseZoom = React.useCallback(() => {
    map && zoom && map.setZoom(zoom - 1);
  }, [map, zoom]);
  React.useEffect(() => {
    if (!map) {
      return undefined;
    }

    const zoomChangedListener = map.addListener('zoom_changed', () => {
      setZoom(map.getZoom());
    });

    return () => zoomChangedListener.remove();
  }, [map]);

  if (!map) {
    return null;
  }

  return (
    <ZoomControlContainer>
      <ZoomControlButton onClick={increaseZoom}>
        <AddIcon />
      </ZoomControlButton>
      <ZoomControlButton onClick={decreaseZoom}>
        <RemoveIcon />
      </ZoomControlButton>
    </ZoomControlContainer>
  );
};

export default ZoomControl;
