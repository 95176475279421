import React from 'react';

import { TimePassedProps } from './types';

const TimePassed: React.FunctionComponent<TimePassedProps> = ({
  since,
  formatter,
  ...props
}): React.ReactElement => {
  const sinceTime = since.getTime();
  const [time, setTime] = React.useState(
    Math.abs(new Date().getTime() - sinceTime)
  );
  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(Math.abs(new Date().getTime() - sinceTime));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [sinceTime]);

  return <span {...props}>{formatter(time)}</span>;
};

export default TimePassed;
