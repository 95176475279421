import { UserRole } from 'core/constants';
import SessionUser from 'stores/Session/SessionUser';
import { UserLicensesResponse } from 'utils/api/types';

export enum SideBarPermissions {
  HOME_PAGE_VISIBLE = 'home_page_visible',
  INBOX_VISIBLE = 'inbox_visible',
  ACTIONS_VISIBLE = 'actions_visible',
  INCIDENTS_VISIBLE = 'incidents_visible',
  LONE_WORKING_VISIBLE = 'lone_working_visible',
  LONE_WORKING_MANAGE_VISIBLE = 'lone_working_manage_visible',
  LONE_WORKING_DEVICES_VISIBLE = 'lone_working_devices_visible',
  LONE_WORKING_USAGE_VISIBLE = 'lone_working_usage_visible',
  LONE_WORKING_ARPS_VISIBLE = 'lone_working_arps_visible',
  LONE_WORKING_MAP_VISIBLE = 'lone_working_map_visible',
  AUDITS_VISIBLE = 'audits_visible',
  ANALYTICS_VISIBLE = 'analytics_visible',
  ADMIN_VISIBLE = 'admin_visible',
  PROFILE_SETTINGS_NOTIFICATIONS_VISIBLE = 'profile_settings_notifications_visible',
  MORE_APPS_VISIBLE = 'more_apps_visible',
}

export type PermissionRules = {
  [key in SideBarPermissions]:
    | boolean
    | ((user?: SessionUser) => boolean)
    | ((licenses: UserLicensesResponse) => boolean)
    | ((data: { licenses: UserLicensesResponse; user: SessionUser | null }) => boolean);
};

export type UserRoleRules = {
  [key in UserRole]: PermissionRules;
};
