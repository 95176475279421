import { action, observable, makeObservable } from 'mobx';
import { AxiosError } from 'axios';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import API from 'utils/api';
import RootStore from 'stores/Root';

import { EntityFieldType } from 'utils/api/types';

import { FieldType } from './types';

export default class FieldsStore extends DataMixin<typeof API, RootStore> {
  @observable error?: AxiosError;

  @observable data: FieldType[] = [];

  @observable filteredData: FieldType[] = [];

  @observable searchQuery = '';

  @observable activeField: EntityFieldType | null = null;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);
  }

  @action.bound
  async loadFields(entityType: string): Promise<void> {
    this.isLoaded = false;
    try {
      const {
        data: { results },
      } = await this.api.loadEntityFields(entityType, {limit: 10000, offset: 0})();
      // modify data to match the FieldType type
      this.data = results.map((field) => ({ id: field.key, name: field.name }));
      this.error = undefined;
    } catch (err) {
      const e = err as AxiosError;
      this.error = e;
    } finally {
      this.isLoaded = true;
    }
  }

  @action.bound
  setActiveField(field: EntityFieldType | null): void {
    this.activeField = field;
  }

  // field manager actions
  createField = async (entityType: string, fieldValues: Partial<EntityFieldType>): Promise<void> => {
    await this.api.createEntityField(entityType, fieldValues)();
  };

  editField = async (entityType: string, fieldId: string, fieldValues: Partial<EntityFieldType>): Promise<void> => {
    await this.api.editEntityField(entityType, fieldId, fieldValues)();
  };

  deleteField = async (entityType: string, fieldId: string): Promise<void> => {
    await this.api.deleteEntityField(entityType, fieldId)();
  };

  getNameOfField(id: string): string {
    if (!this.data) {
      return '';
    }

    const field = this.data.find((f) => f.id === id);
    const fieldDisabled = this.data.find((f) => f.id === `${id}-disabled`);
    const res = field || fieldDisabled;

    return res ? res.name : `${id} not found`;
  }

  @action.bound
  changeFieldsToDisabled(id: string[]): void {
    if (!this.data) {
      return;
    }
    this.data = this.data.map((field) => {
      if (id.includes(field.id)) {
        return { id: `${field.id}-disabled`, name: field.name };
      }
      return field;
    });
    this.filteredData = this.data;
  }

  @action.bound
  searchFields(query: string): void {
    this.searchQuery = query;
    this.filteredData = this.data?.filter((field) => field.name.toLowerCase().includes(query.toLowerCase()));
  }

  private updateField(id: string, newId: string): void {
    if (!this.data) {
      return;
    }
    const index = this.data.findIndex((field) => field.id === id);
    if (index !== -1) {
      const newData = [...this.data];
      newData[index] = { id: newId, name: this.getNameOfField(id) };
      this.data = newData;
    }
    this.filteredData = this.data;
  }

  @action.bound
  addField(id: string): void {
    if (!this.data || this.data.some((field) => field.id === id)) {
      return;
    }
    this.updateField(`${id}-disabled`, id);
  }

  @action.bound
  removeField(id: string): void {
    if (!this.data) {
      return;
    }
    this.updateField(id, `${id}-disabled`);
  }

  @action.bound
  addAllFieldsFromRemovedContainer(itemsFromContainer: string[]): void {
    if (!this.data) {
      return;
    }
    itemsFromContainer.forEach((item) => {
      this.removeField(item);
      this.addField(item);
    });
  }
}
