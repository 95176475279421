import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import Grid from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';

import { listRenderer } from 'components/Grid/renderers/GridListRenderer';
import { UserLicensesResponse } from 'utils/api/types';

import UserCell from '../UserCell';
import UserRoleCell from '../UserRoleCell';
import UserProductsCell from '../UserProductsCell';

import UserRoleOperators from './operators/UserRolesOperator';
import ProductLicencesOperator from './operators/ProductLicencesOperator';

const basicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    valueGetter: (params: GridValueGetterParams) => ({ name: params.row.name, uuid: params.row.uuid }),
    renderCell: ({ value }: { value: { name: string; uuid: string } }) => (
      <UserCell name={value.name} uuid={value.uuid} />
    ),
    nativeFilter: true,
    minWidth: 250,
    flex: 2,
  },
  {
    field: 'role',
    headerName: 'Role',
    renderCell: ({ value }: { value: string }) => <UserRoleCell role={value} />,
    filterOperators: UserRoleOperators,
    nativeFilter: true,
    flex: 2,
  },
  {
    field: 'groups',
    headerName: 'Teams',
    renderCell: listRenderer(
      ({ value }) => <p>{(value.value as UuidableName).name}</p>,
      3,
      (values: unknown[]) => values.map((value) => (value as UuidableName).name).join(', ')
    ),
    nativeFilter: true,
    minWidth: 150,
    sortable: false,
  },
  {
    field: 'licenses',
    headerName: 'Product licences',
    renderCell: ({ value }: { value: UserLicensesResponse }) => <UserProductsCell licenses={value} />,
    filterOperators: ProductLicencesOperator,
    nativeFilter: true,
    minWidth: 300,
    flex: 1,
    sortable: false,
  },
] as unknown) as ColumnDefinition[];

const UserList: React.FunctionComponent<{
  onRefreshCallback: (callback: () => void) => void;
  onError: (error: VatixError) => void;
}> = ({ onError, onRefreshCallback }) => {
  const { routing } = useStore();
  const onRowClick: GridEventListener<'rowClick'> = (user: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.admin.users.details, { userId: user.row.uuid }));
  };

  return (
    <Grid
      basicColumns={basicColumns}
      onError={onError}
      onRowClick={onRowClick}
      dataURL={`${process.env.REACT_APP_ADMIN_API_URL}users/`}
      onRefreshCallback={onRefreshCallback}
      sortBy={{ field: 'name', sort: 'asc' }}
      disableColumnSelector
    />
  );
};

export default observer(UserList);
