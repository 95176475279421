/* eslint-disable max-len */

import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LocationOnFilled(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9 1.5C6.0975 1.5 3.75 3.8475 3.75 6.75C3.75 10.6875 9 16.5 9 16.5C9 16.5 14.25 10.6875 14.25 6.75C14.25 3.8475 11.9025 1.5 9 1.5ZM9 8.625C7.965 8.625 7.125 7.785 7.125 6.75C7.125 5.715 7.965 4.875 9 4.875C10.035 4.875 10.875 5.715 10.875 6.75C10.875 7.785 10.035 8.625 9 8.625Z"
        fill="black"
        fillOpacity="0.54"
      />
    </SvgIcon>
  );
}

export default LocationOnFilled;
