import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import React from 'react';

import { LOCATION_API_REFRESH_INTERVAL } from 'core/constants';
import { ActivityMapTab } from 'containers/ActivityMap/constants';
import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { ActionEvent, DeletedUsersPayload } from 'utils/events/types';
import Logger from 'utils/logger';
import { setInstantInterval } from 'utils/time';
import LocationDetails from 'utils/stores/LocationDetails';

import { AlarmResponse, AmberAlarmResponse, IncidentResponse, IncidentStatus, IncidentType } from 'utils/api/types';

import RootStore from '../Root';
import ActiveUser from './ActiveUser';
import { ActivityMapQueryParams } from './types';

export default class ActivityMapStore {
  store: RootStore;

  api: typeof API;

  @observable isReady = false;

  @observable bounds = '';

  @observable users: ActiveUser[] = [];

  @observable incidents: IncidentResponse[] = [];

  @observable alarms: (AlarmResponse | AmberAlarmResponse)[] = [];

  @observable selectedTab: ActivityMapTab = ActivityMapTab.None;

  @observable searchQuery = '';

  refreshMapInterval?: number;

  locationDetails: LocationDetails;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;
    this.locationDetails = new LocationDetails(rootStore, api);

    eventBus.register(this);
  }

  getDefaultTab(): ActivityMapTab {
    let defaultTab = ActivityMapTab.None;
    if (this.store.session.user?.licenses.protectorIncidents) {
      defaultTab = ActivityMapTab.Incidents;
    }
    if (this.store.session.user?.licenses.protectorAlarms) {
      defaultTab = ActivityMapTab.People;
    }
    return defaultTab;
  }

  @action.bound
  async refreshActiveUsers(): Promise<void> {
    try {
      switch (this.selectedTab) {
        case ActivityMapTab.People: {
          const response = await this.api.loadActiveUsers(this.userQueryParams, LOCATION_API_REFRESH_INTERVAL)();
          runInAction(() => {
            this.removeData();
            this.users = response.data.map((activity) => new ActiveUser(this, activity));
          });
          break;
        }
        case ActivityMapTab.Alarms: {
          const [amberAlertsResponse, redAlertsResponse] = await Promise.all([
            this.api.loadAmberAlarms({})(),
            this.api.loadAlarms({})(),
          ]);
          runInAction(() => {
            this.removeData();
            this.alarms = [...amberAlertsResponse.data.results, ...redAlertsResponse.data.results];
          });
          break;
        }
        case ActivityMapTab.Incidents: {
          const incidentsResponse = await this.api.loadIncidents(
            this.incidentQueryParams([IncidentType.CustomIncident])
          )();
          runInAction(() => {
            this.removeData();
            this.incidents = incidentsResponse.data.results;
          });
          break;
        }
        case ActivityMapTab.None:
          runInAction(() => {
            this.removeData();
          });
          break;
      }

      runInAction(() => {
        this.isReady = true;
      });
    } catch (e) {
      Logger.error('Invalid load Activity Map API response', e);
    }
  }

  @action.bound
  removeUsers(): void {
    this.users.forEach((user) => eventBus.unregister(user));
    this.users = [];
  }

  @action.bound
  removeIncidents(): void {
    this.incidents.forEach((incident) => eventBus.unregister(incident));
    this.incidents = [];
  }

  @action.bound
  removeAlarms(): void {
    this.alarms.forEach((alarm) => eventBus.unregister(alarm));
    this.alarms = [];
  }

  @action.bound
  changeSearchQuery(query: unknown): void {
    this.searchQuery = query as string;
    this.isReady = false;
    this.bounds = '';
    this.locationDetails.closeDetails();

    this.startRefreshingMap();
  }

  @action.bound
  changeTab(event: React.ChangeEvent<{}>, value: ActivityMapTab): void {
    this.selectedTab = value;
    this.isReady = false;
    this.bounds = '';
    this.locationDetails.closeDetails();

    this.startRefreshingMap();
  }

  @action.bound
  changeBounds(bounds: string): void {
    this.bounds = bounds;

    this.startRefreshingMap();
  }

  @subscribe(EventType.DeletedUsers)
  @action
  deletedUsers({ payload }: ActionEvent<DeletedUsersPayload>): void {
    if (!this.isReady) {
      return;
    }

    this.users = this.users.filter((user) => {
      if (payload.users.includes(user.uuid)) {
        eventBus.unregister(user);
        return false;
      }
      return true;
    });
  }

  @action.bound
  startRefreshingMap(): void {
    this.stopRefreshingMap();
    this.refreshMapInterval = setInstantInterval(this.refreshActiveUsers, LOCATION_API_REFRESH_INTERVAL)();
  }

  @action.bound
  stopRefreshingMap(): void {
    clearInterval(this.refreshMapInterval);
  }

  removeData(): void {
    this.removeUsers();
    this.removeIncidents();
    this.removeAlarms();
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.removeData();
    this.locationDetails.closeDetails();
    this.isReady = false;
    this.selectedTab = ActivityMapTab.None;
    this.searchQuery = '';
    this.bounds = '';
  }

  @subscribe(EventType.LoggedIn)
  @action
  updateSelectedTab(): void {
    this.selectedTab = this.getDefaultTab();
  }

  @computed get userQueryParams(): ActivityMapQueryParams {
    return {
      query: this.searchQuery,
      bounds: this.bounds,
    };
  }

  incidentQueryParams(type: IncidentType[]): ActivityMapQueryParams {
    return {
      query: this.searchQuery,
      type: type.join(','),
      status: IncidentStatus.Open,
      bounds: this.bounds,
    };
  }
}
