import React from 'react';
import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import { CircularProgress } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useStore } from 'utils/hooks/store';

import { ADD_MULTIPLE_USERS_HELP_URL } from 'core/constants';

import { StyledModalActions } from './styles';
import ClickAndDragUpload from '../ClickAndDragUpload';
import ErrorAfterUpload from '../ErrorAfterUpload';

type UploadCSVStepProps = {
  onClose: () => void;
  isSubmitting: boolean;
  onSumUp: () => void;
  tryAgain: () => void;
};

const UploadCSVStep: React.FC<UploadCSVStepProps> = ({
  onClose,
  isSubmitting,
  onSumUp,
  tryAgain,
}): React.ReactElement => {
  const { userList } = useStore();
  const errorView = userList.uploadErrors.length > 0;

  const redirectToHelpCenter = (): void => {
    window.open(ADD_MULTIPLE_USERS_HELP_URL);
  };

  return (
    <>
      {!isSubmitting && errorView ? (
        <ErrorAfterUpload
          uploadSuccess={userList.usersUploadedSuccesfully}
          uploadErrors={userList.uploadErrors.length}
        />
      ) : (
        <ClickAndDragUpload />
      )}
      <StyledModalActions $justify="space-between">
        <Button
          onClick={redirectToHelpCenter}
          startIcon={<HelpOutlineIcon style={{ color: 'rgba(7, 114, 229, 1)', width: '24px', height: '24px' }} />}
        >
          Help
        </Button>
        <div>
          <Button
            type="button"
            variant="outlined"
            id="cancel-button"
            onClick={onClose}
            size="large"
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          {errorView ? (
            <Button
              type="submit"
              id="try-again-button"
              variant="contained"
              size="large"
              style={{ marginLeft: '8px' }}
              onClick={tryAgain}
            >
              Try Again
            </Button>
          ) : (
            <Button
              type="submit"
              id="next-button"
              variant="contained"
              size="large"
              style={{ marginLeft: '8px' }}
              onClick={onSumUp}
              disabled={!userList.usersLoaded}
            >
              {isSubmitting && <CircularProgress color="inherit" size="16px" style={{ marginRight: '8px' }} />}
              Next
            </Button>
          )}
        </div>
      </StyledModalActions>
    </>
  );
};

export default observer(UploadCSVStep);
