/* eslint-disable max-len */
import React from 'react';

import LockedPage from 'vatix-ui/lib/components/LockedPage/LockedPage';

import { useStore } from 'utils/hooks/store';

import AuditImage from './images/auditsLockImage.png';
import ProtectorLockImage from './images/protectorLockImage.png';
import IncidentsImage from './images/incidentsLockImage.png';

import { FeaturesInformationType } from './types';

const FeaturesInformation: FeaturesInformationType = {
  incidents: {
    name: 'Incidents',
    infoTitle: 'Incident Reporting & Management',
    infoText:
      'Optimise your incident reporting and management processes with our customisable mobile and web-based solution. You can use this module for incident reporting, investigation, and tracking follow-up actions, all in one place.',
    image: IncidentsImage,
  },
  audits: {
    name: 'Audits',
    infoTitle: 'audits & inspections',
    infoText:
      'Users of the Audits module can create custom audit and inspection forms, conduct inspections through our handy mobile app and track corrective actions.',
    image: AuditImage,
  },
  protector: {
    name: 'Lone Working',
    infoTitle: 'Lone Working Safety',
    infoText:
      'Users of the Lone Working module gain access to our lone working safety solutions. This module is designed to safeguard your staff and boost H&S compliance through our 24/7 monitored safety app or lone worker devices through our Lone Working Device and Lone Working Mobile App',
    image: ProtectorLockImage,
  },
} as const;

const DisableFeatures: React.FunctionComponent = (): React.ReactElement => {
  const { routing } = useStore();
  const currentPage = routing.location.pathname.slice(17, -1);

  if (!FeaturesInformation[currentPage]) {
    return <LockedPage pageName="Unknown" infoTitle="Unknown" infoText="" image={ProtectorLockImage} />;
  }

  const { name, infoText, infoTitle, image } = FeaturesInformation[currentPage];

  return <LockedPage pageName={name} infoTitle={infoTitle} infoText={infoText} image={image} />;
};

export default DisableFeatures;
