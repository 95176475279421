import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import { Title, StyledIconButton, StyledInfoIcon, MandatoryQuestion } from './styles';

const FieldTitle: React.FunctionComponent<{
  description: string;
  required: boolean;
  infoText?: string;
}> = ({ description, required, infoText }): React.ReactElement => (
  <Title id="filed-description">
    {required ? (
      <>
        {description}
        <MandatoryQuestion>*</MandatoryQuestion>
      </>
    ) : (
      description
    )}
    {infoText ? (
      <Tooltip title={infoText} arrow placement="right">
        <StyledIconButton>
          <StyledInfoIcon />
        </StyledIconButton>
      </Tooltip>
    ) : null}
  </Title>
);

export default FieldTitle;
