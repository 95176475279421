/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { observer } from 'mobx-react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import { useNavBarHeight } from 'utils/hooks/navbar';

import routes from 'core/routes';

import PhoneIphone from 'components/Icons/PhoneIphone';

import DeviceIcon from 'components/Icons/DeviceIcon';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import { StyledTabs, StyledTab, TabsContainer } from './styles';

import MobileTab from '../MobileTab';
import DeviceTab from '../DeviceTab';

const UsageReport = (): React.ReactElement => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue);
  };

  const navBarHeight = useNavBarHeight();

  return (
    <>
      <Record
        navBarHeight={navBarHeight}
        Header={
          <MainHeader
            title="Usage"
            breadcrumbs={[
              { label: 'Lone Working', link: routes.dashboard.alarms.red.toString() },
              { label: 'Usage', link: routes.dashboard.alarms.reports.toString() },
            ]}
          />
        }
        Content={(onError) => (
          <TabsContainer>
            <StyledTabs value={tabValue} onChange={handleChange}>
              <StyledTab label="Device" id="device-tab" icon={<DeviceIcon disabled />} iconPosition="start" />
              <StyledTab label="App" id="app-tab" icon={<PhoneIphone disabled />} iconPosition="start" />
            </StyledTabs>
            <TabPanel value={tabValue} index={0}>
              <DeviceTab onError={onError} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <MobileTab onError={onError} />
            </TabPanel>
          </TabsContainer>
        )}
        disableMaxContentHeight
      />
    </>
  );
};

export default observer(UsageReport);
