import styled from 'styled-components';

export const ErrorMessage = styled.p`
  background-color: #fff;
  margin: 0;
  padding: 16px 0;
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #d32f2f;
`;
