import { action, computed, observable, makeObservable } from 'mobx';
import { ContentRect } from 'react-measure';

import RootStore from 'stores/Root';
import { FOLDED_MAP_HEIGHT } from 'core/constants';

export default class CollapsibleMap {
  store: RootStore;

  @observable headerHeight = 0;

  @observable scrollY = 0;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.store = rootStore;
  }

  @action.bound
  setHeaderHeight(contentRect: ContentRect): void {
    if (contentRect.bounds) {
      this.headerHeight = contentRect.bounds.height;
    }
  }

  @action.bound
  setScrollY(position: number): void {
    this.scrollY = position;
  }

  @computed get detailsOffset(): number {
    // space taken by components other than details
    const mapHeight = this.scrollY >= FOLDED_MAP_HEIGHT ? 0 : FOLDED_MAP_HEIGHT - this.scrollY;
    return mapHeight + this.headerHeight + this.store.ui.navBarHeight;
  }

  @computed get scrollHeight(): number {
    // extra space used to scroll and collapse map
    return FOLDED_MAP_HEIGHT - this.store.ui.navBarHeight;
  }

  reset(): void {
    this.headerHeight = 0;
    this.scrollY = 0;
  }
}
