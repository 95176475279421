import styled from 'styled-components';

import VatixTheme from 'theme/vatix';

export const StyledContainer = styled.div`
  background-color: ${VatixTheme.palette.common.white};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: none;
  border-radius: 0 0 8px 8px;
`;

export const StyledTitle = styled.p`
  padding: 24px 20px;
  margin: 0;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
  letter-spacing: 0.15px;
`;

export const StyledFooter = styled.p`
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin: 24px 20px;
  padding: 0;
  min-height: 40px;
  border-radius: 10px;
  background: rgba(7, 114, 229, 0.04);
  color: #6d7883;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
`;
