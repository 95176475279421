import React from 'react';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import ClearIcon from '@mui/icons-material/Clear';

import { AnswerTypes, ProtectorType } from 'utils/api/types';

import { StyledAutocomplete } from 'components/Input/styles';

import Chip from 'components/Chip';

import { ProtectorTypeMultiChoiceProps, ProtectorTypeSingleChoiceProps } from '../types';

export const CustomDropdownField = ({
  type,
  value,
  items,
  description,
  onChange,
  requiredNotFilled,
}: ProtectorTypeSingleChoiceProps | ProtectorTypeMultiChoiceProps): React.ReactElement => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [firstClick, setFirstClick] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const toggleInputMode = (): void => {
    if (inputRef.current) {
      setOpen(true);
      inputRef.current.setAttribute('inputmode', firstClick ? 'none' : 'text');
      inputRef.current.setAttribute('aria-expanded', 'true');
      setFirstClick(false);
    }
  };

  const getValue = (rawValue?: AnswerTypes): string | string[] => {
    if (rawValue === null || rawValue === undefined || rawValue === '') {
      return type === ProtectorType.MultiChoice ? [] : '';
    }
    return value as string[];
  };

  const handleArrowClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setOpen(!open);
    if (open && inputRef.current) {
      inputRef.current.setAttribute('inputmode', 'none');
    }
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onChange(null);
  };

  return (
    <FormControl fullWidth>
      <StyledAutocomplete
        open={open}
        onClose={() => setOpen(false)}
        role="textbox"
        id={`${type === ProtectorType.MultiChoice ? 'multi' : 'single'}-drop-down-field`}
        data-testid={description}
        disablePortal
        options={items.enum}
        disableClearable={value === ''}
        renderInput={(params) => (
          <TextField
            {...params}
            error={requiredNotFilled}
            inputRef={inputRef}
            placeholder={type === ProtectorType.MultiChoice ? 'Choose option(s)' : 'Choose an option'}
            onClick={toggleInputMode}
            InputProps={{
              ...params.InputProps,
              sx: { paddingRight: '9px !important' },
              endAdornment: (
                <InputAdornment position="end" sx={{ height: 'auto' }}>
                  {value && (
                    <IconButton onClick={handleClear} aria-label="Clear" title="Clear" sx={{ padding: '4px' }}>
                      <ClearIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  )}

                  <IconButton onClick={handleArrowClick} aria-label="toggle dropdown" sx={{ padding: '4px' }}>
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        multiple={type === ProtectorType.MultiChoice}
        value={getValue(value)}
        fullWidth
        style={{
          height: 'fit-content',
          width: '100%',
        }}
        size="medium"
        sx={{
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
            fontSize: '16px',
          },
          '& + .MuiChip-root': {
            fontSize: '16px',
          },
        }}
        onChange={(_, newValue) => {
          if (newValue) {
            onChange(typeof newValue === 'string' ? [newValue] : (newValue as string[]));
          } else {
            onChange(null);
          }
        }}
        onInputChange={(_, __, reason) => {
          if (reason === 'clear') {
            onChange(null);
          }
        }}
        getOptionLabel={(option) => `${option}`}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} label={`${option}`} fontSize={14} variant="filled" />
          ))
        }
        isOptionEqualToValue={(option, val) => val !== '' && val === option}
        disableCloseOnSelect={type === ProtectorType.MultiChoice}
        freeSolo
      />
    </FormControl>
  );
};

export default CustomDropdownField;
