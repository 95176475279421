import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { SiteStateActivityProps } from './types';

const SiteStateActivity: React.FunctionComponent<SiteStateActivityProps> = ({ activity, sessionUser }) => (
  <EventItem activity={activity} sessionUser={sessionUser} id="activity-site-created">
    created a site
  </EventItem>
);

export default observer(SiteStateActivity);
