import { BooleanKeys, ColumnDefinition, StringKeys } from 'components/Grid/types';

import { ExportParams } from './types';

const parseUrl = (url: string): ExportParams => {
  const urlObj = new URL(url);
  const params: StringKeys = {};

  urlObj.searchParams.forEach((value, key) => {
    if (key === 'custom') {
      params[key] = decodeURIComponent(value);
    } else {
      params[key] = value;
    }
  });

  return { baseUrl: urlObj.origin + urlObj.pathname, params };
};

const extractFields = (
  columnsState: BooleanKeys,
  columnsDefinition: ColumnDefinition[]
): { nativeFields: string[]; customFields: string[] } => {
  const nativeFields = columnsDefinition
    .filter((col) => !col.customColumn && columnsState[col.field] && col.field !== 'actions')
    .map((col) => col.field);

  const customFields = columnsDefinition
    .filter((col) => col.customColumn && columnsState[col.field])
    .map((col) => col.field);

  return { nativeFields, customFields };
};

export const exportToCSV = async (
  url: string,
  columnsState: BooleanKeys,
  columnsDefinition: ColumnDefinition[]
): Promise<ExportParams> => {
  const { baseUrl, params } = parseUrl(url);
  const { nativeFields, customFields } = extractFields(columnsState, columnsDefinition);

  const updatedParams = new URLSearchParams(params);

  updatedParams.set('field', nativeFields.join(','));
  updatedParams.set('custom_field', customFields.map(encodeURIComponent).join(','));

  return {
    baseUrl,
    params: Object.fromEntries(updatedParams.entries()),
  };
};
