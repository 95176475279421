/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AlarmFilled = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" fill="#C62828" />
  </SvgIcon>
);

export default AlarmFilled;
