import React from 'react';
import { observer } from 'mobx-react';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import EscalationProfileList from './components/EscalationProfileList';
import NewProfileButton from './components/NewProfileButton';

const EscalationProfiles: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      Header={
        <MainHeader
          title="Alarm Response Profiles"
          breadcrumbs={[
            { label: 'Lone Working', link: routes.dashboard.alarms.red.toString() },
            { label: 'Alarm Response Profiles', link: routes.dashboard.alarms.escalationProfiles.toString() },
          ]}
          action={<NewProfileButton />}
        />
      }
      Content={(onError) => <EscalationProfileList onError={onError} />}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(EscalationProfiles);
