/* eslint-disable max-len */
import React from 'react';

export const InboxIcon: React.FunctionComponent<{ notDismissedNotificationsCount: number }> = ({
  notDismissedNotificationsCount,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 20" fill="none">
    <g>
      <path
        d="M20.3167 6.66667L18.9667 1.26667C18.7833 0.516667 18.1167 0 17.35 0H3.65C2.88333 0 2.21667 0.516667 2.03333 1.26667L0.683333 6.66667L0.5 7.36667V15.8333C0.5 16.75 1.25 17.5 2.16667 17.5H18.8333C19.75 17.5 20.5 16.75 20.5 15.8333V7.36667L20.3167 6.66667ZM4.95 1.66667H16.05C16.8167 1.66667 17.4833 2.18333 17.6667 2.93333L18.4667 6.15C18.5333 6.41667 18.3333 6.66667 18.0667 6.66667H15.9167C15 6.66667 14.25 7.41667 14.25 8.33333V10.0333C14.25 10.5 13.8833 10.8667 13.4167 10.8667H7.58333C7.11667 10.8667 6.75 10.5 6.75 10.0333V8.33333C6.75 7.41667 6 6.66667 5.08333 6.66667H2.93333C2.66667 6.66667 2.46667 6.41667 2.53333 6.15L3.33333 2.93333C3.51667 2.18333 4.18333 1.66667 4.95 1.66667ZM17.1667 15.8333H3.83333C2.91667 15.8333 2.16667 15.0833 2.16667 14.1667V8.75C2.16667 8.51667 2.35 8.33333 2.58333 8.33333H4.66667C4.9 8.33333 5.08333 8.51667 5.08333 8.75V10.8667C5.08333 11.7833 5.83333 12.5333 6.75 12.5333H14.25C15.1667 12.5333 15.9167 11.7833 15.9167 10.8667V8.75C15.9167 8.51667 16.1 8.33333 16.3333 8.33333H18.4167C18.65 8.33333 18.8333 8.51667 18.8333 8.75V14.1667C18.8333 15.0833 18.0833 15.8333 17.1667 15.8333Z"
        fill="#C0C7D3"
      />
      <path
        d="M11.834 15.0166H9.16732C8.70065 15.0166 8.33398 14.65 8.33398 14.1833C8.33398 13.7166 8.70065 13.35 9.16732 13.35H11.834C12.3007 13.35 12.6673 13.7166 12.6673 14.1833C12.6673 14.65 12.3007 15.0166 11.834 15.0166Z"
        fill="#C0C7D3"
      />
    </g>
    <defs>
      <clipPath id="clip0_13967_169366">
        <rect width="20" height="17.5" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
    {notDismissedNotificationsCount > 0 ? (
      <g>
        <ellipse stroke="#fff" fill="#ff0000" rx="7px" ry="7px" cx="18px" cy="7px" />
        <text
          x="18.19454"
          y="4.7"
          textAnchor="middle"
          dominantBaseline="middle"
          alignmentBaseline="middle"
          stroke="#FFFFFF"
          strokeWidth="1px"
          dy=".3em"
          fontSize="9px"
          fontWeight={300}
        >
          {notDismissedNotificationsCount > 9 ? '9+' : String(notDismissedNotificationsCount)}
        </text>
      </g>
    ) : null}
  </svg>
);

export const ActiveInboxIcon: React.FunctionComponent<{ notDismissedNotificationsCount: number }> = ({
  notDismissedNotificationsCount,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 20" fill="none">
    <path
      d="M16.3333 9.33331C16.1 9.33331 15.9167 9.51665 15.9167 9.74998V11.85C15.9167 12.7833 15.1667 13.5166 14.25 13.5166H6.75C5.83333 13.5166 5.08333 12.7833 5.08333 11.85V9.74998C5.08333 9.51665 4.9 9.33331 4.66667 9.33331H0.5V16.8333C0.5 17.75 1.25 18.5 2.16667 18.5H18.8333C19.75 18.5 20.5 17.75 20.5 16.8333V9.33331H16.3333ZM11.8333 16.8333H9.16667C8.71667 16.8333 8.33333 16.4666 8.33333 16C8.33333 15.5333 8.71667 15.1666 9.16667 15.1666H11.8333C12.2833 15.1666 12.6667 15.55 12.6667 16C12.6667 16.45 12.2833 16.8333 11.8333 16.8333Z"
      fill="white"
    />
    <path
      d="M20.3169 7.66667H15.9169C15.0003 7.66667 14.2503 8.41667 14.2503 9.33333V11.0167C14.2503 11.4833 13.8836 11.85 13.4169 11.85H7.58359C7.11693 11.85 6.75026 11.4833 6.75026 11.0167V9.33333C6.75026 8.41667 6.00026 7.66667 5.08359 7.66667H0.683594L2.03359 2.26667C2.21693 1.51667 2.88359 1 3.65026 1H17.3503C18.1169 1 18.7836 1.51667 18.9669 2.26667L20.3169 7.66667Z"
      fill="white"
    />

    {notDismissedNotificationsCount > 0 ? (
      <g>
        <ellipse stroke="#fff" fill="#ff0000" rx="7px" ry="7px" cx="18px" cy="7px" />
        <text
          x="18.19454"
          y="4.7"
          textAnchor="middle"
          dominantBaseline="middle"
          alignmentBaseline="middle"
          stroke="#FFFFFF"
          strokeWidth="1px"
          dy=".3em"
          fontSize="9px"
          fontWeight={300}
        >
          {notDismissedNotificationsCount > 9 ? '9+' : String(notDismissedNotificationsCount)}
        </text>
      </g>
    ) : null}
  </svg>
);

export const AllInboxIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clipPath="url(#clip0_14025_73980)">
      <path
        d="M5.25 6H2.25C1.83 6 1.5 5.67 1.5 5.25V2.25C1.5 1.83 1.83 1.5 2.25 1.5H5.25C5.67 1.5 6 1.83 6 2.25V5.25C6 5.67 5.67 6 5.25 6ZM0 1.5V6C0 6.84 0.66 7.5 1.5 7.5H6C6.84 7.5 7.5 6.84 7.5 6V1.5C7.5 0.66 6.84 0 6 0H1.5C0.66 0 0 0.66 0 1.5Z"
        fill="white"
        fillOpacity="0.56"
      />
      <path
        d="M17.25 3.08997H9.75C9.33 3.08997 9 2.75997 9 2.33997C9 1.91997 9.33 1.58997 9.75 1.58997H17.25C17.67 1.58997 18 1.91997 18 2.33997C18 2.75997 17.67 3.08997 17.25 3.08997Z"
        fill="white"
        fillOpacity="0.56"
      />
      <path
        d="M17.25 6.08997H9.75C9.33 6.08997 9 5.75997 9 5.33997C9 4.91997 9.33 4.58997 9.75 4.58997H17.25C17.67 4.58997 18 4.91997 18 5.33997C18 5.75997 17.67 6.08997 17.25 6.08997Z"
        fill="white"
        fillOpacity="0.56"
      />
      <path
        d="M5.25 16.5H2.25C1.83 16.5 1.5 16.17 1.5 15.75V12.75C1.5 12.33 1.83 12 2.25 12H5.25C5.67 12 6 12.33 6 12.75V15.75C6 16.17 5.67 16.5 5.25 16.5ZM0 12V16.5C0 17.34 0.66 18 1.5 18H6C6.84 18 7.5 17.34 7.5 16.5V12C7.5 11.16 6.84 10.5 6 10.5H1.5C0.66 10.5 0 11.16 0 12Z"
        fill="white"
        fillOpacity="0.56"
      />
      <path
        d="M17.25 13.59H9.75C9.33 13.59 9 13.26 9 12.84C9 12.42 9.33 12.09 9.75 12.09H17.25C17.67 12.09 18 12.42 18 12.84C18 13.26 17.67 13.59 17.25 13.59Z"
        fill="white"
        fillOpacity="0.56"
      />
      <path
        d="M17.25 16.59H9.75C9.33 16.59 9 16.26 9 15.84C9 15.42 9.33 15.09 9.75 15.09H17.25C17.67 15.09 18 15.42 18 15.84C18 16.26 17.67 16.59 17.25 16.59Z"
        fill="white"
        fillOpacity="0.56"
      />
    </g>
    <defs>
      <clipPath id="clip0_14025_73980">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ActiveAllInboxIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <g clipPath="url(#clip0_14025_73950)">
      <path
        d="M7.5 1.5V6C7.5 6.84 6.81 7.5 6 7.5H1.5C0.66 7.5 0 6.84 0 6V1.5C0 0.69 0.66 0 1.5 0H6C6.81 0 7.5 0.69 7.5 1.5Z"
        fill="white"
      />
      <path
        d="M17.28 3.08997H9.75C9.33 3.08997 9 2.75997 9 2.33997C9 1.91997 9.33 1.58997 9.75 1.58997H17.28C17.7 1.58997 18.03 1.91997 18.03 2.33997C18.03 2.75997 17.7 3.08997 17.28 3.08997Z"
        fill="white"
      />
      <path
        d="M17.28 6.08997H9.75C9.33 6.08997 9 5.75997 9 5.33997C9 4.91997 9.33 4.58997 9.75 4.58997H17.28C17.7 4.58997 18.03 4.91997 18.03 5.33997C18.03 5.75997 17.7 6.08997 17.28 6.08997Z"
        fill="white"
      />
      <path
        d="M7.5 12V16.5C7.5 17.34 6.81 18 6 18H1.5C0.66 18 0 17.34 0 16.5V12C0 11.19 0.66 10.5 1.5 10.5H6C6.81 10.5 7.5 11.19 7.5 12Z"
        fill="white"
      />
      <path
        d="M17.28 13.59H9.75C9.33 13.59 9 13.26 9 12.84C9 12.42 9.33 12.09 9.75 12.09H17.28C17.7 12.09 18.03 12.42 18.03 12.84C18.03 13.26 17.7 13.59 17.28 13.59Z"
        fill="white"
      />
      <path
        d="M17.28 16.59H9.75C9.33 16.59 9 16.26 9 15.84C9 15.42 9.33 15.09 9.75 15.09H17.28C17.7 15.09 18.03 15.42 18.03 15.84C18.03 16.26 17.7 16.59 17.28 16.59Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_14025_73950">
        <rect width="18.03" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
