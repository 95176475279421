import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { useNavBarHeight } from 'utils/hooks/navbar';
import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import MasterServiceAgreement from './MasterServiceAgreement';
import CompanyLogo from './CompanyLogo';

const CompanySettings: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  const { session } = useStore();

  return (
    <Record
      Header={
        <MainHeader
          title={session.user?.organisation.name || 'Organisation Name'}
          breadcrumbs={[
            { label: 'Admin', link: routes.dashboard.admin.toString() },
            { label: 'Company Settings', link: routes.dashboard.admin.companySettings.toString() },
          ]}
        />
      }
      Content={() => (
        <>
          <CompanyLogo />
          <MasterServiceAgreement />
        </>
      )}
      navBarHeight={navBarHeight}
    />
  );
};

export default CompanySettings;
