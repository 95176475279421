import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { useHistory } from 'react-router-dom';

import RemoveEntityModal from 'components/RemoveEntityModal';

import { useStore } from 'utils/hooks/store';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import { MenuItem } from './styles';
import { EntityActionCellProps } from './types';

const EntityActionCell: React.FunctionComponent<EntityActionCellProps> = ({
  rowData: { uuid },
  actions: { refreshData },
  store: { entityType: type, detailsPath, delete: deleteEntity },
}) => {
  const history = useHistory();
  const { notification } = useStore();

  const entityTranslation = getEntityTranslation[type];

  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const handleCloseModal = (): void => {
    if (!deleting) {
      setDeletionModalOpen(false);
      setAnchorEl(null);
    }
  };

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    history.push(detailsPath(uuid));
  };

  const onDelete = async (): Promise<void> => {
    const deleted = await deleteEntity(uuid);
    if (deleted) {
      notification.enqueueSuccessSnackbar(`${entityTranslation.singular} deleted successfully`);
      await refreshData();
      setDeleting(false);
    } else {
      notification.enqueueErrorSnackbar(
        `The ${entityTranslation.singular} could not be deleted. Something went wrong.`
      );
    }
  };

  return (
    <>
      <GridActionsCellItem
        icon={<MoreVert />}
        label=""
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem id="edit-button" onClick={handleEditClick}>
          Edit
        </MenuItem>
        <MenuItem id="remove-button" onClick={() => setDeletionModalOpen(true)}>
          Delete
        </MenuItem>
        {deletionModalOpen && (
          <RemoveEntityModal
            onClose={handleCloseModal}
            handleRemoveEntity={onDelete}
            entityType={entityTranslation.singular}
          />
        )}
      </Popover>
    </>
  );
};

export default observer(EntityActionCell);
