import React from 'react';

import { observer } from 'mobx-react';

import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { AmberAlertActivityStatusName } from 'core/constants';

import { AmberAlertActivityProps } from './types';

const AmberAlertActivity: React.FunctionComponent<AmberAlertActivityProps> = ({ activity, sessionUser }) => (
  <EventItem activity={activity} sessionUser={sessionUser} id="activity-timed-alert">
    {AmberAlertActivityStatusName[activity.status]} timed alert
  </EventItem>
);

export default observer(AmberAlertActivity);
