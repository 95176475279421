import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { UserRole } from 'core/constants';

import EscalationProfileDetails from 'containers/EscalationProfileDetails';
import UserRoute from 'components/Route/UserRoute';
import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';
import { SessionStoreWithUser } from 'stores/Session/types';

import EscalationProfiles from '../EscalationProfiles';

const Routing = (): React.ReactElement => {
  const { session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;

  return (
    <Switch>
      <UserRoute
        path={String(routes.dashboard.alarms.escalationProfiles)}
        component={EscalationProfiles}
        isAuthorizedUser={(user) =>
          [UserRole.Admin, UserRole.AccountManager, UserRole.Manager, UserRole.User].includes(user.role) &&
          session.user.licenses.protectorAlarms
        }
        exact
        data-test-id="dashboard-alarms-configure-escalation-profiles"
      />

      <UserRoute
        path={String(routes.dashboard.alarms.escalationProfiles.details)}
        component={EscalationProfileDetails}
        isAuthorizedUser={(user) =>
          [UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(user.role) &&
          session.user.licenses.protectorAlarms
        }
        exact
      />

      <Redirect
        from={String(routes.dashboard.alarms.escalationProfiles)}
        to={String(routes.dashboard.alarms.escalationProfiles)}
        exact
        data-test-id="dashboard-alarms-configure-redirect-escalation-profiles"
      />
    </Switch>
  );
};

export default Routing;
