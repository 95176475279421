import React, { useState, useMemo, createContext, ReactNode } from 'react';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  Active,
  UniqueIdentifier,
} from '@dnd-kit/core';

import { SortableContext, arrayMove, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';

import { Context } from './types';

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

function SortableItem({
  children,
  id,
}: React.PropsWithChildren<{
  id: UniqueIdentifier;
}>): JSX.Element {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );
  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <div ref={setNodeRef} style={{ ...style, width: '100%' }}>
        {children}
      </div>
    </SortableItemContext.Provider>
  );
}
interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T, SortableItemContext: React.Context<Context>): ReactNode;
}

export default function SortableListMain<T extends BaseItem>({
  items,
  onChange,
  renderItem,
}: Props<T>): React.ReactElement {
  const [activeState, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => items.find((item) => item.id === activeState?.id), [activeState, items]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const itemIds = useMemo(() => items.map((item) => item.id), [items]);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={itemIds}>
        {items.map((item) => (
          <SortableItem id={item.id} key={item.id}>
            {renderItem(item, SortableItemContext)}
          </SortableItem>
        ))}
      </SortableContext>
      <DragOverlay dropAnimation={null}>
        {activeItem ? (
          <SortableItem id={activeItem.id} key={activeItem.id}>
            {renderItem(activeItem, SortableItemContext)}
          </SortableItem>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
