import React from 'react';

import { circularProgressClasses } from '@mui/material/CircularProgress';

import { StyledLinearProgress, LicenseLabel } from './styles';

const LicensesAssignedCell: React.FunctionComponent<{
  licensesAssigned: { taken: number; max: number };
}> = ({ licensesAssigned: { taken, max } }): React.ReactElement => (
  <>
    <StyledLinearProgress
      data-test-id="styled-circular-progress"
      value={max === 0 ? 100 : (taken * 100) / max}
      sx={{
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
    />
    <LicenseLabel>
      {taken} / {max}
    </LicenseLabel>
  </>
);

export default LicensesAssignedCell;
