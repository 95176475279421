import { action, makeObservable, observable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import TableStore from 'vatix-ui/lib/utils/stores/TableStore';

import { AxiosError } from 'axios';

import { apiErrorsWithKeys } from 'utils/api/errors';
import { EventType } from 'utils/events/constants';
import { ActionEvent, DeletedUsersPayload } from 'utils/events/types';
import API from 'utils/api';
import { EditUserResponse, UserResponse, UsersCreationInBulkParams, UploadUserType } from 'utils/api/types';

import RootStore from '../Root';

export default class UserListStore extends TableStore<RootStore, typeof API, UserResponse, UserResponse> {
  @observable usersToUpload: UsersCreationInBulkParams[] = [];

  @observable uploadErrors: Array<UsersCreationInBulkParams & { error: string }> = [];

  @observable usersUploadedSuccesfully = 0;

  @observable uploadCount = 0;

  @observable uploadedAllUsers = false;

  @observable usersLoaded = false;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, api.loadUsers);
    this.sortingOrder = 'asc';
    this.sortingColumn = 'name';

    makeObservable(this);
  }

  @subscribe(EventType.DeletedUsers)
  @action
  deletedUsers({ payload }: ActionEvent<DeletedUsersPayload>): void {
    if (this.isFailure || !this.isLoaded) {
      return;
    }

    this.items = this.items.filter((user) => {
      if (payload.users.indexOf(user.uuid) === -1) {
        return true;
      }
      eventBus.unregister(user);
      return false;
    });

    if (this.count !== undefined) {
      this.count -= payload.users.length;
    }
  }

  @subscribe(EventType.UpdatedUser)
  @action
  updatedUser({ payload }: ActionEvent<EditUserResponse>): void {
    if (this.isFailure || !this.isLoaded) {
      return;
    }

    const index = this.items.findIndex((u) => u.uuid === payload.uuid);
    if (index !== -1) {
      this.items[index] = {
        ...this.items[index],
        ...payload,
      };
    }
  }

  @action.bound
  resetUsers(): void {
    this.items.forEach((user) => eventBus.unregister(user));
    this.items = [];
  }

  @action.bound
  loadUsersFromCSV(results: UploadUserType[]): void {
    const userRoles: Record<string, string> = {
      user: 'user',
      manager: 'manager',
      'account owner': 'account_owner',
    };

    // license fields are string, and we need to convert them to boolean
    // user can put there any combination of 'True' like 'true', 'TRUE', 'TrUe' etc.
    // so we need to check if it's 'True' or not
    const checkLicense = (license: string | undefined): boolean => license?.toLowerCase() === 'true';

    results.forEach((result) => {
      const user: UsersCreationInBulkParams = {
        firstName: result.firstName || null,
        lastName: result.lastName || null,
        email: result.emailAddress || null,
        phoneNumber: result.phoneNumber || null,
        role: result.role ? userRoles[result.role.toLowerCase()] : null,
        dateOfBirth: result.dateOfBirth || null,
        teams: result.teams ? result.teams.split(',').map((i) => i.trim()) : [],
        memorableWord: result.memorableWord || null,
        loneWorkingLicense: checkLicense(result.loneWorkingLicence),
        auditsLicense: checkLicense(result.auditsLicence),
        incidentsLicense: checkLicense(result.incidentsLicence),
        account: result.account,
      };

      this.usersToUpload.push(user);
    });

    // change it after 2 second, because the progress bar from 'react-papaparse' has a delay
    setTimeout(() => {
      this.usersLoaded = true;
    }, 2000);
  }

  @action.bound
  async createUsersInBatches(batchSize: number = 1): Promise<void> {
    const users = [...this.usersToUpload];
    while (users.length) {
      const batch = users.splice(0, batchSize);
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        batch.map(async (user) => {
          try {
            await this.api.createUserInBulk(user)();
            this.usersUploadedSuccesfully += 1;
          } catch (e) {
            const error = e as AxiosError;
            this.uploadErrors.push({
              ...user,
              error: apiErrorsWithKeys(error, 'Error creating user'),
            });
          }
          this.uploadCount += 1;
        })
      );
    }
    if (this.usersToUpload.length === this.usersUploadedSuccesfully) {
      this.uploadedAllUsers = true;
    }
  }

  @action.bound
  clearUsersToUpload(): void {
    this.usersToUpload = [];
    this.uploadCount = 0;
    this.uploadErrors = [];
    this.usersUploadedSuccesfully = 0;
    this.uploadedAllUsers = false;
    this.usersLoaded = false;
  }
}
