/* eslint-disable max-len */
import React from 'react';

import { Grid } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import InfoIcon from '@mui/icons-material/Info';

import { AxiosResponse } from 'axios';
import Logger from 'vatix-ui/lib/utils/logger/Logger';

import api from 'utils/api';

import {
  TaskRecordContainer,
  TaskRecordRow,
  RecordRow,
  FirstColumn,
  SecondColumn,
  Title,
  HeaderGrid,
  StyledTooltip,
  RedirectButton,
} from './styles';

const DEFAULT_MASTER_SERVICE_AGREEMENT = {
  name: 'Master Service Agreement March 2024',
  url: 'https://vatix.com/legal/agreements/master-service-agreement/',
};

const MasterServiceAgreement: React.FunctionComponent = (): React.ReactElement => {
  const [masterServiceAgreement, setMasterServiceAgreement] = React.useState<{
    name: string;
    url: string;
  }>();

  React.useEffect(() => {
    const loadMasterServiceAgreement = (): void => {
      api
        .loadMasterServiceAgreement()()
        .then((response: AxiosResponse) => {
          setMasterServiceAgreement(response.data);
        })
        .catch((error: Error) => {
          Logger.error('Invalid load master service agreement API response', error);
        });
    };

    loadMasterServiceAgreement();
  }, []);

  return (
    <>
      <HeaderGrid container alignItems="center" marginTop="10px">
        <Grid item container xs={12} alignContent="flex-start">
          <Title>Master Service Agreement</Title>
        </Grid>
      </HeaderGrid>
      <TaskRecordContainer>
        <TaskRecordRow>
          <RecordRow>
            <FirstColumn>
              Master Service Agreement
              <StyledTooltip title="Contains key terms and conditions for using Vatix products. Click for details on your current agreement version.">
                <InfoIcon fontSize="small" htmlColor="rgba(158, 158, 158, 1)" />
              </StyledTooltip>
            </FirstColumn>
            <SecondColumn>
              <RedirectButton
                size="small"
                variant="text"
                onClick={() =>
                  masterServiceAgreement
                    ? window.open(masterServiceAgreement.url)
                    : window.open(DEFAULT_MASTER_SERVICE_AGREEMENT.url)
                }
              >
                {masterServiceAgreement ? masterServiceAgreement.name : DEFAULT_MASTER_SERVICE_AGREEMENT.name}
                <OpenInNewIcon style={{ width: 15, height: 15, marginLeft: '8px' }} />
              </RedirectButton>
            </SecondColumn>
          </RecordRow>
        </TaskRecordRow>
      </TaskRecordContainer>
    </>
  );
};
export default MasterServiceAgreement;
