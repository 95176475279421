import React from 'react';

import { Button } from 'vatix-ui/lib/components/Button';

import { Formik } from 'formik';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { observer } from 'mobx-react';

import { SectionGrid } from 'components/BackgroundInformation/styles';

import { useStore } from 'utils/hooks/store';
import { InspectionTaskLinkType, InspectionTaskLinkToUpdateType, TaskListResponse } from 'utils/api/types';

import { formatFullDate } from 'utils/formatters/time';

import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';

import TaskStatusSelect from 'components/TaskStatusSelect';

import CustomUserDropdownField from 'containers/IncidentDetails/components/CustomUserDropdownField';

import EntitySearchField from 'components/EntitySearchField';

import { useEntityDisplayValue } from 'components/Entities/EntityDisplayValue/utils';

import { EditTaskRowName } from './styles';
import InspectionField from '../components/InspectionsField';
import QuestionField from '../components/QuestionField/QuestionField';
import IncidentField from '../components/IncidentField/IncidentField';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <div key={id}>
    <EditTaskRowName>{name}</EditTaskRowName>
    <div>{children}</div>
  </div>
);

const EditTaskProperties: React.FunctionComponent<{ setEditing: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setEditing,
}): React.ReactElement => {
  const {
    taskDetails: { details: detailsRaw },
    taskDetails,
  } = useStore();
  const details = detailsRaw as TaskListResponse;

  const { session } = useStore();

  const onUpdate = async (
    values: TaskListResponse,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ): Promise<void> => {
    setSubmitting(true);
    const getUuidFromLink = (entity: string): string | null => {
      const link = values.links?.find((l) => l.entity === entity);
      return link ? link.instance.uuid : null;
    };

    let linksToUpdate: InspectionTaskLinkToUpdateType[] = [];
    // Get relevant UUIDs from links
    const site = getUuidFromLink('sites');
    const asset = getUuidFromLink('assets');
    const supplier = getUuidFromLink('suppliers');
    const risk = getUuidFromLink('risks');

    if (values.links && values.links.length > 0) {
      const inspectionLink = values.links.find((l) => l.entity === 'inspection');
      if (inspectionLink && inspectionLink.extraData) {
        linksToUpdate = [
          {
            entity: 'inspection',
            instance: inspectionLink.instance.uuid,
            extraData: { questionId: (inspectionLink.extraData as InspectionTaskLinkType).questionId },
          },
        ];
      } else if (inspectionLink && inspectionLink.instance === null) {
        linksToUpdate = linksToUpdate.filter((link) => link.entity !== 'inspection');
      } else if (inspectionLink) {
        linksToUpdate = [
          {
            entity: 'inspection',
            instance: (inspectionLink && inspectionLink.instance.uuid) || '',
            extraData: {},
          },
        ];
      }
    }

    const updatedData = ({
      ...values,
      links: linksToUpdate,
      dueDate: values.dueDate || null,
      customIncident: values.customIncident ? values.customIncident.uuid : null,
      assigned: values.assigned ? values.assigned.uuid : null,
      site,
      asset,
      supplier,
      risk,
    } as unknown) as Partial<TaskListResponse>;

    const res = await taskDetails.updateTask(updatedData);
    setSubmitting(false);
    setEditing(!res);
  };

  return (
    <Formik initialValues={details} onSubmit={onUpdate}>
      {({ values, setFieldValue, isSubmitting, handleSubmit }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updateField = (field: string, value: any): void => {
          if (field === 'sites' && !value) {
            const links = values.links.filter((link) => link.entity !== 'sites');
            setFieldValue('links', links);
            return;
          }
          if (field === 'links') {
            const links = values.links.map((link) => {
              if (link.entity === value.entity) {
                return value;
              }
              return link;
            });
            // if there was no link with the entity, add it
            if (links.every((link) => link.entity !== value.entity)) {
              links.push(value);
            }
            setFieldValue('links', links);
          } else {
            setFieldValue(field, value);
          }
        };

        return (
          <>
            <Row name="Name" id="field-1">
              <CustomTextInputField
                description="Name"
                value={values.name}
                onChange={(value) => setFieldValue('name', value)}
              />
            </Row>
            {session.user?.licenses.protectorIncidents && (
              <IncidentField updateField={updateField} taskDetailsData={values} />
            )}
            {session.user?.licenses.workflows && <InspectionField updateField={updateField} taskDetailsData={values} />}
            {session.user?.licenses.workflows && <QuestionField updateField={updateField} taskDetailsData={values} />}

            {session.user?.activeEntityModules.map((module) => {
              const { entityLink, singular, plural } = useEntityDisplayValue(module, details.links);

              return (
                <Row name={singular} id={`related-${singular}-edit-label`} key={`related-${singular}-edit-label`}>
                  <EntitySearchField
                    field={plural.toLowerCase()}
                    module={module}
                    data={entityLink?.instance as UuidableName}
                    updateField={(field: string, value: UuidableName | null) => {
                      if (!value) {
                        const links = values.links.filter((link) => link.entity !== field);
                        setFieldValue('links', links);
                        return;
                      }
                      const links = values.links.map((link) => {
                        if (link.entity === field) {
                          return {
                            entity: field,
                            instance: { name: value.name, uuid: value.uuid },
                          };
                        }
                        return link;
                      });
                      if (links.every((link) => link.entity !== field)) {
                        links.push({
                          entity: field,
                          instance: { name: value.name, uuid: value.uuid },
                        });
                      }
                      setFieldValue('links', links);
                    }}
                  />
                </Row>
              );
            })}

            <Row name="Description" id="field-3">
              <CustomTextInputField
                description="Description"
                value={values.description}
                onChange={(value) => setFieldValue('description', value)}
                multiline
              />
            </Row>

            <Row name="Assigned to" id="field-4">
              <CustomUserDropdownField
                description=""
                value={values.assigned ? `user:${values.assigned.uuid}` : ''}
                onChange={(value) => {
                  setFieldValue('assigned', value ? { uuid: (value as string).split(':')[1] } : null);
                }}
              />
            </Row>

            <Row id="field-5" name="Status">
              <TaskStatusSelect
                value={values.status}
                onChange={(value) => {
                  setFieldValue('status', value);
                }}
              />
            </Row>

            <Row name="Due date" id="field-6">
              <CustomDatePicker
                // @ts-ignore
                value={values.dueDate ? new Date(values.dueDate) : undefined}
                onChange={(val) => {
                  const convert = val ? formatFullDate(val as string) : '';
                  setFieldValue('dueDate', convert);
                }}
                description="Due date"
              />
            </Row>

            <SectionGrid item container xs={12} marginTop="20px" justifyContent="end" marginBottom="20px">
              <Button
                disabled={isSubmitting}
                onClick={() => setEditing(false)}
                style={{
                  marginRight: '16px',
                }}
                variant="outlined"
                size="large"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                size="large"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </SectionGrid>
          </>
        );
      }}
    </Formik>
  );
};

export default observer(EditTaskProperties);
