import styled from 'styled-components';

export const ProductAccessContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  align-items: center;

  .MuiSvgIcon-fontSizeMedium {
    font-size: 18px;
  }
`;

export const ProductAccessText = styled.p`
  display: flex;
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
`;
