import React from 'react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { Button } from 'vatix-ui/lib/components/Button';

import { GridValueGetterParams, GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import AddIcon from '@mui/icons-material/Add';

import { ColumnDefinition } from 'components/Grid/types';

import { useStore } from 'utils/hooks/store';

import Grid, { defaultFormatters, defaultRenderers } from 'components/Grid';

import { formatFullDateTime } from 'utils/formatters/time';

import routes from 'core/routes';
import { EntitiesStores } from 'components/Entities/types';

import CreateFormModal from 'containers/Forms/components/CreateFormModal';

import AbstractWrapper from './AbstractWrapper';
import { defaultProps } from './types';

const AuditsColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    nativeFilter: true,
  },
  {
    field: 'reporter',
    headerName: 'Reporter',
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'reporters',
  },
  {
    field: 'submitted',
    headerName: 'Submitted',
    renderCell: ({ value }: { value: string | null }) => (value !== null ? formatFullDateTime(value) : 'Not submitted'),
    nativeFilter: 'submitted',
  },
] as unknown) as ColumnDefinition[];

const AuditsRelatedToEntities: React.FunctionComponent<{
  onError: (error: VatixError) => void;
  store: EntitiesStores;
}> = ({ store }) => {
  const {
    routing,
    session: { user },
    formDetails: { setExtraParamsForCreatingInspection },
  } = useStore();

  const { details, entityType } = store;

  const [openCreateFormModal, setOpenCreateFormModal] = React.useState(false);

  const history = useHistory();

  const onRowClick: GridEventListener<'rowClick'> = (form: GridRowParams): void => {
    history.push(reverse(routes.dashboard.audits.forms.details, { formId: form.row.uuid }));
  };

  const redirectToAudits = (): void => {
    routing.push(reverse(routes.dashboard[entityType].relatedAudits, { entityId: details?.uuid || '' }));
  };

  const handleCreateForm = (): void => {
    setExtraParamsForCreatingInspection({ [entityType]: details?.uuid || '' });
    setOpenCreateFormModal(true);
  };

  return (
    user?.licenses.workflows && (
      <>
        <AbstractWrapper
          headerTitle="Audits"
          headerAction={
            <Button variant="text" size="medium" startIcon={<AddIcon />} onClick={handleCreateForm}>
              New Audit
            </Button>
          }
          gridElement={
            <Grid
              onRowClick={onRowClick}
              basicColumns={AuditsColumns}
              // TODO: replace query with correct one
              dataURL={`${process.env.REACT_APP_WORKFLOWS_API_URL}inspections/?site_uuid=${details?.uuid}`}
              {...defaultProps}
            />
          }
          footerAction={
            <Button variant="text" size="medium" onClick={redirectToAudits}>
              View all...
            </Button>
          }
        />
        <CreateFormModal open={openCreateFormModal} setOpen={setOpenCreateFormModal} />
      </>
    )
  );
};

export default AuditsRelatedToEntities;
