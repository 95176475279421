import { action, computed, observable, runInAction, makeObservable } from 'mobx';

import { setInstantInterval } from 'utils/time';
import { LOCATION_API_REFRESH_INTERVAL, LOCATION_HISTORY_PERIOD, LOCATION_HISTORY_PERIOD_LATEST } from 'core/constants';

import { LocationHistoryResponse } from 'utils/api/types';
import Logger from 'utils/logger';

import { LoadLocations, AlertStatusPolyline, UuidableLocation, Location } from './types';

export default class LocationHistory {
  refreshMapInterval?: number;

  @observable positions?: LocationHistoryResponse[];

  @observable period: keyof typeof LOCATION_HISTORY_PERIOD = LOCATION_HISTORY_PERIOD_LATEST;

  loadLocations: LoadLocations;

  constructor(loadLocations: LoadLocations) {
    makeObservable(this);
    this.loadLocations = loadLocations;
  }

  @computed
  get numberPositions(): UuidableLocation[] | undefined {
    return this.positions?.map((el) => ({
      lat: Number(el.lat),
      lng: Number(el.lng),
      uuid: el.uuid,
    }));
  }

  @action.bound
  startRefreshingMap(): void {
    this.stopRefreshingMap();
    this.refreshMapInterval = setInstantInterval(this.refreshLocations, LOCATION_API_REFRESH_INTERVAL)();
  }

  @action.bound
  stopRefreshingMap(): void {
    clearInterval(this.refreshMapInterval);
  }

  @action.bound
  async refreshLocations(): Promise<void> {
    const period = this.period !== LOCATION_HISTORY_PERIOD_LATEST ? this.period : undefined;
    try {
      const response = await this.loadLocations(period);
      runInAction(() => {
        this.positions = response.data;
      });
    } catch (e) {
      runInAction(() => {
        this.positions = undefined;
      });
      Logger.error('Invalid load Locations API response.', e);
    }
  }

  @action.bound
  setPeriod(period: string): void {
    this.period = period as keyof typeof LOCATION_HISTORY_PERIOD;
    this.startRefreshingMap();
  }

  @action.bound
  reset(): void {
    this.stopRefreshingMap();
  }

  @computed get currentPosition(): Location | null {
    if (this.positions && this.positions.length) {
      return {
        lng: Number(this.positions[0].lng),
        lat: Number(this.positions[0].lat),
      };
    }
    return null;
  }

  @computed get alertStatusHistory(): AlertStatusPolyline[] {
    if (!this.positions || !this.positions.length) {
      return [];
    }

    const polylinePaths: AlertStatusPolyline[] = [];

    const temporaryPolylinePaths: Location[] = [];

    this.positions.forEach((position, index) => {
      let prevMarker;

      if (this.positions && this.positions[index - 1]) {
        prevMarker = this.positions[index - 1];
      }

      if (index === 0) {
        temporaryPolylinePaths.push({
          lat: Number(position.lat),
          lng: Number(position.lng),
        });
      }

      if (index !== 0 && prevMarker && position.alertsStatus === prevMarker.alertsStatus) {
        temporaryPolylinePaths.push({
          lat: Number(position.lat),
          lng: Number(position.lng),
        });
      } else if (index !== 0 && prevMarker) {
        temporaryPolylinePaths.push({
          lat: Number(position.lat),
          lng: Number(position.lng),
        });

        polylinePaths.push({
          path: [...temporaryPolylinePaths],
          alertsStatus: prevMarker.alertsStatus,
        });
        temporaryPolylinePaths.length = 0;
        temporaryPolylinePaths.push({
          lat: Number(position.lat),
          lng: Number(position.lng),
        });
      }

      if (this.positions && index + 1 === this.positions.length) {
        polylinePaths.push({
          path: [...temporaryPolylinePaths],
          alertsStatus: position.alertsStatus,
        });
      }
    });
    return polylinePaths;
  }
}
