import { prepareIcon } from './Icon';
import deviceCharging from '../../assets/icons/Device - Charging.png';
import deviceCharging2x from '../../assets/icons/Device - Charging@2x.png';
import deviceCharging3x from '../../assets/icons/Device - Charging@3x.png';

const DeviceCharging = prepareIcon({
  alt: 'Battery Charging',
  src: deviceCharging,
  srcSet: `
    ${deviceCharging2x} 2x,
    ${deviceCharging3x} 3x
  `,
});

export default DeviceCharging;
