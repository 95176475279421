import { styled } from 'styled-components';

export const ScoreChip = styled.div<{ $bgColor: string }>`
  text-align: center;
  min-width: 52px;
  height: 24px;
  background: ${(props) => props.$bgColor};
  border-radius: 100px;
  color: white;
`;
