/* eslint-disable max-len */
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosResponse, CancelTokenSource } from 'axios';

import { action, runInAction, makeObservable } from 'mobx';

import DetailsActivities from 'vatix-ui/lib/utils/stores/DetailsActivities';

import Logger from 'vatix-ui/lib/utils/logger';

import { ActivityListResponse, ActivityType, PaginationParams, ActivityResponse } from 'vatix-ui/lib/utils/api/types';

import { TaskListResponse } from 'utils/api/types';
import API from 'utils/api';
import RootStore from 'stores/Root';

import { EventType } from 'utils/events/constants';
import { postMessage } from 'utils/events/broadcast';
import { ActionEvent } from 'utils/events/types';

export default class AnswerActivities extends DetailsActivities<RootStore, typeof API> {
  inspectionId: string;

  answerId: string;

  constructor(rootStore: RootStore, api: typeof API, inspectionId: string, answerId: string) {
    super(rootStore, api);

    makeObservable(this);

    this.inspectionId = inspectionId;

    this.answerId = answerId;

    eventBus.register(this);
  }

  async loadActivitiesData(params: PaginationParams): Promise<AxiosResponse<ActivityListResponse>> {
    return this.api.loadActivitiesAnswer(this.inspectionId, this.answerId, params)();
  }

  @action.bound
  async addMessage(message: string): Promise<void> {
    if (this.error || !this.activities || message.trim() === '') {
      return;
    }
    try {
      const { data } = await this.api.addAnswerMessage(this.inspectionId, this.answerId, message)();
      runInAction(() => {
        if (this.activities) {
          this.activities.unshift({
            ...data,
            type: ActivityType.Message,
          });
          postMessage(EventType.AddedMessage, {
            ...data,
            inspectionId: this.inspectionId,
            questionId: this.answerId,
          });
        }
      });
    } catch (e) {
      Logger.error(
        `Invalid add message API result: Inspection ID: ${this.inspectionId}. Answer ID: ${this.answerId}. Message: ${message}`,
        e
      );
    }
  }

  @action.bound
  async deleteMessage(messageId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }
    const message = this.activities.find((activity) => activity.uuid === messageId);
    if (message) {
      try {
        await this.api.removeAnswerMessage(this.inspectionId, this.answerId, messageId)();
        runInAction(() => {
          if (this.activities !== undefined) {
            this.activities = this.activities.filter((activity) => activity.uuid !== messageId);
          }
          postMessage(EventType.DeletedMessage, {
            inspectionId: this.inspectionId,
            questionId: this.answerId,
          });
        });
      } catch (e) {
        Logger.error(
          `Invalid delete message API result: Inspection ID: ${this.inspectionId}. Answer ID: ${this.answerId}. Message ID: ${messageId}`,
          e
        );
      }
    }
  }

  async doUploadFile(
    file: File,
    onUpdateProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): Promise<void> {
    try {
      const { data } = await this.api.uploadInspectionFile(
        this.inspectionId,
        this.answerId,
        file,
        onUpdateProgress,
        cancelTokenSource
      )();
      runInAction(() => {
        if (this.activities) {
          this.activities.unshift((data as unknown) as ActivityResponse);
          postMessage(EventType.AddedFile, {
            ...data,
            inspectionId: this.inspectionId,
            questionId: this.answerId,
          });
        }
      });
    } catch (e) {
      Logger.error(
        `Invalid add file API result: Inspection ID: ${this.inspectionId}. Question ID: ${this.answerId}.`,
        e
      );
      this.rootStore.notification.enqueueErrorSnackbar('Failed to upload file');
    }
  }

  @action.bound
  async deleteFile(fileId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }

    try {
      await this.api.removeInspectionFile(this.inspectionId, this.answerId, fileId)();
      runInAction(() => {
        this.removeActivity(fileId);
        postMessage(EventType.DeletedFile, {
          inspectionId: this.inspectionId,
          questionId: this.answerId,
        });
      });
    } catch (e) {
      Logger.error(
        `Invalid delete file API result: Inspection ID: ${this.inspectionId}. Question ID: ${this.answerId}. File ID: ${fileId}`,
        e
      );
    }
  }

  @subscribe(EventType.CreatedTask)
  @subscribe(EventType.CreatedTaskAnswerTask)
  @action
  createdTask({ payload }: ActionEvent<TaskListResponse>): void {
    if (this.activities !== undefined && payload.links.some((link) => link.instance.uuid === this.inspectionId)) {
      this.activities = undefined;
      this.loadActivities();
    }
  }
}
