import styled from 'styled-components';

export const EditCompanyLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed #0772e5;
  padding: 30px 0;
`;
