/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Line = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon width="17" height="1" viewBox="0 0 17 1" {...props}>
    <line y1="0.5" x2="17" y2="0.5" stroke="black" strokeOpacity="0.87" />
  </SvgIcon>
);

export default Line;
