import React, { useRef, useEffect } from 'react';

import { useFormikContext } from 'formik';

import { LoginTextFieldProps, LoginStep, LoginFormValues } from 'containers/Login/types';

import LoginError from 'components/Icons/LoginError';

import ArrowLeft from 'components/Icons/ArrowLeft';

import {
  StyledDigitTextField,
  DigitContainer,
  HelpContainer,
  VerificationCodeContainer,
  ErrorContainer,
  StyledErrorText,
} from './styles';

import { TitleWrapper, InputTitle, InputSubtitle, HelpButton, StyledButton, BackButton } from '../../styles';

const ConfirmVerificationCode: React.FunctionComponent<LoginTextFieldProps> = ({
  twoFactorAuthCallback,
  error,
  digits,
  withBackButton,
  setStep,
}) => {
  const { values, isSubmitting, setFieldValue, setSubmitting } = useFormikContext<LoginFormValues>();

  const [resendLinkVisible, setResendLinkVisible] = React.useState(false);

  const code = values.verificationCode || '';

  const onResendCodeClickCallback = async (): Promise<void> => {
    setSubmitting(true);
    twoFactorAuthCallback && (await twoFactorAuthCallback(values));
    setSubmitting(false);
  };

  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array.from({ length: digits || 0 }, () => null));

  const handleEnterEmailSetStep = (): void => {
    setFieldValue('username', '');
    setFieldValue('password', '');
    setFieldValue('phoneNumber', '');
    setStep && setStep(LoginStep.EnterEmail);
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    let newCode = code;

    const digit = event.target.value.replace(/\D/g, '');
    newCode = newCode.substring(0, index) + digit + newCode.substring(index + 1);
    setFieldValue('verificationCode', newCode);

    if (digit && index < 6 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
    if (event.key === 'Backspace') {
      if (index > 0 && !code[index]) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
    setTimeout(() => {
      setResendLinkVisible(true);
    }, 30000);
  }, []);

  return (
    <>
      {withBackButton && (
        <BackButton startIcon={<ArrowLeft />} onClick={handleEnterEmailSetStep}>
          Back to login
        </BackButton>
      )}
      <TitleWrapper>
        <InputTitle>Two-Factor Authentication</InputTitle>
        <InputSubtitle>
          We&apos;ve sent a {digits}-digit code to your phone number. Please enter it below.
        </InputSubtitle>
      </TitleWrapper>

      <VerificationCodeContainer>
        <DigitContainer>
          {digits &&
            Array.from({ length: digits }, (_, index) => (
              <StyledDigitTextField
                key={index}
                type="text"
                variant="outlined"
                size="small"
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: 'center' },
                }}
                value={code[index] || ''}
                // @ts-ignore
                onChange={(event) => handleCodeChange(event, index)}
                // @ts-ignore
                onKeyDown={(event) => handleKeyDown(event, index)}
                inputRef={(el: HTMLInputElement | null) => {
                  inputRefs.current[index] = el;
                }}
                error={error}
              />
            ))}
        </DigitContainer>
        {!error && (
          <HelpContainer>
            {resendLinkVisible ? (
              <div>
                Did&apos;t receive the code? <HelpButton onClick={onResendCodeClickCallback}>Click here</HelpButton> to
                resend.
              </div>
            ) : (
              "Didn't receive the code? Try again in 30 seconds."
            )}
          </HelpContainer>
        )}

        {error && (
          <ErrorContainer>
            <LoginError />
            <StyledErrorText>
              Invalid code. Please check and try again. If you&apos;re having issues,
              <HelpButton onClick={onResendCodeClickCallback}> request a new code</HelpButton>
            </StyledErrorText>
          </ErrorContainer>
        )}
      </VerificationCodeContainer>
      <StyledButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting || values.verificationCode.length !== digits}
      >
        {!isSubmitting ? 'Continue' : 'Processing'}
      </StyledButton>
    </>
  );
};

export default ConfirmVerificationCode;
