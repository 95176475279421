/* eslint-disable max-len */
import React from 'react';

export function AnalyticsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <g clipPath="url(#clip0_14309_69339)">
        <path
          d="M5.91667 20H0.916667C0.683333 20 0.5 19.8167 0.5 19.5834V13.3334C0.5 12.4167 1.25 11.6667 2.16667 11.6667H4.66667C5.58333 11.6667 6.33333 12.4167 6.33333 13.3334V19.5834C6.33333 19.8167 6.15 20 5.91667 20ZM2.58333 18.3334H4.25C4.48333 18.3334 4.66667 18.15 4.66667 17.9167V14.1667C4.66667 13.7 4.3 13.3334 3.83333 13.3334H3C2.53333 13.3334 2.16667 13.7 2.16667 14.1667V17.9167C2.16667 18.15 2.35 18.3334 2.58333 18.3334Z"
          fill="#C0C7D3"
        />
        <path
          d="M13.0007 20H8.00065C7.76732 20 7.58398 19.8166 7.58398 19.5833V9.99998C7.58398 9.08331 8.33398 8.33331 9.25065 8.33331H11.7507C12.6673 8.33331 13.4173 9.08331 13.4173 9.99998V19.5833C13.4173 19.8166 13.234 20 13.0007 20ZM9.66732 18.3333H11.334C11.5673 18.3333 11.7507 18.15 11.7507 17.9166V10.8333C11.7507 10.3666 11.384 9.99998 10.9173 9.99998H10.084C9.61732 9.99998 9.25065 10.3666 9.25065 10.8333V17.9166C9.25065 18.15 9.43398 18.3333 9.66732 18.3333Z"
          fill="#C0C7D3"
        />
        <path
          d="M20.0671 20H15.0671C14.8337 20 14.6504 19.8167 14.6504 19.5833V1.66667C14.6504 0.75 15.4171 0 16.3337 0H18.8337C19.7504 0 20.5004 0.75 20.5004 1.66667V19.5833C20.5004 19.8167 20.3004 20 20.0671 20ZM16.7504 18.3333H18.4171C18.6504 18.3333 18.8337 18.15 18.8337 17.9167V2.5C18.8337 2.03333 18.4671 1.66667 18.0004 1.66667H17.1671C16.7004 1.66667 16.3337 2.03333 16.3337 2.5V17.9167C16.3337 18.15 16.5171 18.3333 16.7504 18.3333Z"
          fill="#C0C7D3"
        />
        <path
          d="M6.0819 1.66667C6.59856 1.66667 7.09856 1.86667 7.4819 2.21667C8.3319 2.98333 8.39856 4.31667 7.61523 5.16667C7.21523 5.6 6.66523 5.85 6.0819 5.85C5.56523 5.85 5.06523 5.65 4.6819 5.3C3.8319 4.53333 3.76523 3.2 4.54856 2.35C4.94856 1.91667 5.49856 1.66667 6.0819 1.66667ZM6.0819 0C5.06523 0 4.04856 0.416667 3.31523 1.21667C1.91523 2.75 2.0319 5.11667 3.56523 6.51667C4.2819 7.16667 5.1819 7.5 6.09856 7.5C7.11523 7.5 8.1319 7.08333 8.86523 6.28333C10.2652 4.75 10.1486 2.38333 8.61523 0.983333C7.89856 0.333333 6.99856 0 6.0819 0Z"
          fill="#C0C7D3"
        />
        <path
          d="M1.21615 9.20003L0.632812 8.6167C0.466146 8.45003 0.466146 8.18336 0.632812 8.03336L3.78281 4.88336L4.96615 6.0667L1.81615 9.2167C1.64948 9.38336 1.38281 9.38336 1.23281 9.2167L1.21615 9.20003Z"
          fill="#C0C7D3"
        />
      </g>
      <defs>
        <clipPath id="clip0_14309_69339">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveAnalyticsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M6.33333 13.3333V19.5833C6.33333 19.8167 6.15 20 5.91667 20H0.916667C0.683333 20 0.5 19.8167 0.5 19.5833V13.3333C0.5 12.4167 1.25 11.6667 2.16667 11.6667H4.66667C5.58333 11.6667 6.33333 12.4167 6.33333 13.3333Z"
        fill="white"
      />
      <path
        d="M13.4173 9.99999V19.5833C13.4173 19.8167 13.234 20 13.0007 20H8.00065C7.76732 20 7.58398 19.8167 7.58398 19.5833V9.99999C7.58398 9.08333 8.33398 8.33333 9.25065 8.33333H11.7507C12.6673 8.33333 13.4173 9.08333 13.4173 9.99999Z"
        fill="white"
      />
      <path
        d="M20.5004 1.66667V19.5833C20.4837 19.8167 20.3004 20 20.0671 20H15.0671C14.8337 20 14.6504 19.8167 14.6504 19.5833V1.66667C14.6671 0.75 15.4171 0 16.3337 0H18.8337C19.7504 0 20.5004 0.75 20.5004 1.66667Z"
        fill="white"
      />
      <path
        d="M6.0819 1.66667C6.59856 1.66667 7.09857 1.86667 7.4819 2.21667C8.3319 2.98333 8.39856 4.31667 7.61523 5.16667C7.21523 5.6 6.66523 5.85 6.0819 5.85C5.56523 5.85 5.06523 5.65 4.6819 5.3C3.8319 4.53333 3.76523 3.2 4.54856 2.35C4.94856 1.91667 5.49856 1.66667 6.0819 1.66667ZM6.0819 0C5.06523 0 4.04856 0.416667 3.31523 1.21667C1.91523 2.75 2.0319 5.11667 3.56523 6.51667C4.2819 7.16667 5.1819 7.5 6.09856 7.5C7.11523 7.5 8.1319 7.08333 8.86523 6.28333C10.2652 4.75 10.1486 2.38333 8.61523 0.983333C7.8819 0.316667 6.9819 0 6.0819 0Z"
        fill="white"
      />
      <path
        d="M1.21615 9.19989L0.632813 8.61656C0.466146 8.44989 0.466146 8.18322 0.632813 8.03322L3.78281 4.88322L4.96615 6.06656L1.81615 9.21656C1.64948 9.38322 1.38281 9.38322 1.23281 9.21656L1.21615 9.19989Z"
        fill="white"
      />
    </svg>
  );
}
