import React from 'react';
import { observer } from 'mobx-react';

import Feed, { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { USER_TAG_BASE } from 'core/constants';
import { SessionStoreWithUser } from 'stores/Session/types';
import TaskActivities from 'stores/TaskDetails/TaskActivities';
import API from 'utils/api';
import { useStore } from 'utils/hooks/store';

import { ActivityMapping } from 'utils/api/types';

import { ActivityType } from './types';
import Assignment from '../Assignment';
import State from '../State';
import Status from '../Status';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.Assignment]: Assignment,
  [ActivityType.State]: State,
  [ActivityType.Status]: Status,
} as unknown) as ActivityMapping;

const TaskActivitiesContent: React.FunctionComponent = (): React.ReactElement => {
  const { taskDetails, session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  const activities = taskDetails.activities as TaskActivities;

  return (
    <Feed
      mapping={activityTypeToComponent}
      originalActivities={activities}
      onDeleteComment={activities.deleteMessage}
      onDeleteFile={activities.deleteFile}
      usersSearch={API.searchUsers}
      sessionUser={session.user}
      downloadFile={API.downloadFile}
      userTagBaseUrl={USER_TAG_BASE}
    />
  );
};

export default observer(TaskActivitiesContent);
