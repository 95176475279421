import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';
import { Button } from 'vatix-ui/lib/components/Button';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import Record from 'vatix-ui/lib/containers/Record/Record';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import TeamList from './components/TeamList';

const Teams: React.FunctionComponent = (): React.ReactElement => {
  const history = useHistory();
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={(onError) => <TeamList onError={onError} />}
      Header={
        <MainHeader
          title="Teams"
          breadcrumbs={[
            { label: 'Admin', link: routes.dashboard.admin.teams.toString() },
            { label: 'Teams', link: routes.dashboard.admin.teams.toString() },
          ]}
          action={
            <Button
              onClick={() =>
                history.push(
                  reverse(routes.dashboard.admin.teams.details, {
                    teamId: 'new',
                  })
                )
              }
              variant="contained"
              size="large"
            >
              New Team
            </Button>
          }
        />
      }
    />
  );
};

export default Teams;
