import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { AxiosError } from 'axios';

import CollapsibleMap from 'utils/stores/CollapsibleMap';
import { EditUserSectionTab } from 'core/constants';
import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import Logger from 'utils/logger';
import LocationHistory from 'utils/stores/LocationHistory';
import LocationDetails from 'utils/stores/LocationDetails';

import { isNotFound } from 'utils/api/errors';

import RootStore from '../Root';
import UserDetailsData from './UserDetailsData';
import { LocationAddressResponse } from '../../utils/api/types';

export default class UserDetailsStore {
  store: RootStore;

  api: typeof API;

  collapsibleMap: CollapsibleMap;

  @observable isLoaded = false;

  @observable error?: AxiosError;

  @observable details?: UserDetailsData;

  @observable showSafety?: boolean;

  @observable isEditProfileDialogOpen = false;

  @observable editProfileTab: EditUserSectionTab = EditUserSectionTab.General;

  locationHistory?: LocationHistory;

  locationDetails: LocationDetails;

  lastLocation?: LocationAddressResponse;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    this.collapsibleMap = new CollapsibleMap(this.store);
    this.locationDetails = new LocationDetails(this.store, this.api);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(userId: string, managersScope = false): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = managersScope
        ? await this.api.loadUserDetailsForManagers(userId)()
        : await this.api.loadUserDetails(userId)();

      const {
        data: { showSafety },
      } = managersScope ? await this.api.loadUserDataForManagers(userId)() : await this.api.loadUserData(userId)();

      runInAction(() => {
        this.error = undefined;
        this.details = new UserDetailsData(this.store, this.api, data);
        this.showSafety = showSafety;
        this.lastLocation = data.lastLocation;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load user details API response. User Id: ${userId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @action.bound
  openEditProfileDialog(tab: EditUserSectionTab): void {
    this.isEditProfileDialogOpen = true;
    this.editProfileTab = tab;
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.isEditProfileDialogOpen = false;
    this.editProfileTab = EditUserSectionTab.General;

    this.collapsibleMap.reset();
    if (this.details) {
      this.details.reset();
      eventBus.unregister(this.details);
      this.details = undefined;
    }
    if (this.locationHistory) {
      this.locationHistory.reset();
      this.locationHistory = undefined;
    }
  }

  @action
  async deleteUser(userId: string): Promise<boolean> {
    try {
      const data = { users: [userId] };
      await this.api.deleteUsers(data)();
      this.store.licenses.reset();
      this.store.notification.enqueueSuccessSnackbar('User deactivated successfully');
      return true;
    } catch (e) {
      Logger.error('Invalid deactivated user API response.', e);
      this.store.notification.enqueueErrorSnackbar((e as AxiosError).response?.data.users[0]);
      return false;
    }
  }
}
