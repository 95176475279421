import React from 'react';

import TaskChip from 'containers/TaskDetails/components/TaskStatusChip';

import { TaskStatus } from 'core/constants';

import { StyledSelect } from 'components/Input/styles';

import { StyledMenuItem } from './styles';
import { TaskStatusSelectProps } from './types';

const TaskStatusSelect: React.FunctionComponent<TaskStatusSelectProps> = ({ onChange, ...props }) => (
  <StyledSelect
    {...props}
    fullWidth
    onChange={(event) => onChange(event.target.value as TaskStatus)}
    $withoutBorder={props.withoutBorder}
    inputProps={props.disableIconComponent ? { IconComponent: () => null } : {}}
  >
    <StyledMenuItem key="todo" value="todo">
      <TaskChip status={TaskStatus.ToDo} />
    </StyledMenuItem>
    <StyledMenuItem key="cant_do" value="cant_do">
      <TaskChip status={TaskStatus.CantDo} />
    </StyledMenuItem>
    <StyledMenuItem key="in_progress" value="in_progress">
      <TaskChip status={TaskStatus.InProgress} />
    </StyledMenuItem>
    <StyledMenuItem key="completed" value="completed">
      <TaskChip status={TaskStatus.Completed} />
    </StyledMenuItem>
  </StyledSelect>
);

export default TaskStatusSelect;
