import * as Yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const EditPersonalInformationSchema = (showSafety: boolean) => {
  const phoneNumber = showSafety
    ? {
        phoneNumber: Yup.string().required(
          'Phone number is required when the lone working mobile app is enabled on the Manage page.'
        ),
      }
    : {};
  // @ts-ignore
  return Yup.object().shape({
    ...phoneNumber,
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
  });
};
