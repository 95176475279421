import React from 'react';

import { DeviceTypeContent, DeviceTypeNameText } from 'containers/Devices/components/DeviceTypeCell/styles';
import { MonitoringMethod } from 'core/constants';

import Chip from 'components/Chip';

import { StyledSelfMonitoringStatus, StyledVatixMonitoringStatus } from '../../styles';
import { DropDownItemProps } from './types';

const DropDownItem: React.FunctionComponent<DropDownItemProps> = ({ item, onClick }) => (
  <Chip
    key={item.uuid}
    label={
      <DeviceTypeContent>
        <DeviceTypeNameText data-test="deviceTypeNameText">{`${item.name}`}</DeviceTypeNameText>
      </DeviceTypeContent>
    }
    iconLeft={
      item.escalationType === MonitoringMethod.ArcMonitored ? (
        <StyledVatixMonitoringStatus />
      ) : (
        <StyledSelfMonitoringStatus />
      )
    }
    onClick={() => {
      onClick?.(item);
    }}
    size="small"
    colors={{
      background: '#FFF',
      iconLeftBackground: 'rgba(2, 136, 209, 0.12)',
    }}
  />
);

export default DropDownItem;
