/* eslint-disable max-len */
import React from 'react';

export function CompanySettingsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73794)">
        <path
          d="M15.1791 10.3199C15.1791 10.7399 14.8491 11.0699 14.4291 11.0699H11.0691C10.6491 11.0699 10.2891 10.7399 10.2891 10.3199C10.2891 9.89995 10.6491 9.56995 11.0691 9.56995H14.4291C14.8491 9.56995 15.1791 9.89995 15.1791 10.3199Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M15.1791 7.14001C15.1791 7.56001 14.8491 7.89001 14.4291 7.89001H11.0691C10.6491 7.89001 10.2891 7.56001 10.2891 7.14001C10.2891 6.72001 10.6491 6.39001 11.0691 6.39001H14.4291C14.8491 6.39001 15.1791 6.72001 15.1791 7.14001Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M15.1791 3.95996C15.1791 4.34996 14.8491 4.70996 14.4291 4.70996H11.0691C10.6491 4.70996 10.2891 4.34996 10.2891 3.95996C10.2891 3.53996 10.6491 3.20996 11.0691 3.20996H14.4291C14.8491 3.20996 15.1791 3.53996 15.1791 3.95996Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M8.25 0.75V17.25H17.25V0.75H8.25ZM11.79 16.5V14.64C11.79 14.43 11.97 14.25 12.18 14.25H13.29C13.5 14.25 13.68 14.43 13.68 14.64V16.5H11.79ZM16.5 16.5H15.18V13.5C15.18 13.11 14.85 12.75 14.43 12.75H11.07C10.65 12.75 10.29 13.11 10.29 13.5V16.5H9V2.25C9 1.86 9.33 1.5 9.75 1.5H15.75C16.17 1.5 16.5 1.86 16.5 2.25V16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M16.5 0H9C8.16 0 7.5 0.69 7.5 1.5V18H18V1.5C18 0.69 17.31 0 16.5 0ZM16.5 16.5H9V2.25C9 1.86 9.33 1.5 9.75 1.5H15.75C16.17 1.5 16.5 1.86 16.5 2.25V16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M0.75 9.75V17.25H8.25V9.75H0.75ZM4.08 16.5V14.25H4.89V16.5H4.08ZM7.5 16.5H6.39V13.5C6.39 13.11 6.06 12.75 5.64 12.75H3.33C2.94 12.75 2.58 13.11 2.58 13.5V16.5H1.5V11.25C1.5 10.86 1.83 10.5 2.25 10.5H7.5V16.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M1.5 9C0.66 9 0 9.69 0 10.5V18H9V9H1.5ZM1.5 16.5V11.25C1.5 10.86 1.83 10.5 2.25 10.5H7.5V16.5H1.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M15.1791 3.95996C15.1791 4.34996 14.8491 4.70996 14.4291 4.70996H11.0691C10.6491 4.70996 10.2891 4.34996 10.2891 3.95996C10.2891 3.53996 10.6491 3.20996 11.0691 3.20996H14.4291C14.8491 3.20996 15.1791 3.53996 15.1791 3.95996Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M15.1791 7.14001C15.1791 7.56001 14.8491 7.89001 14.4291 7.89001H11.0691C10.6491 7.89001 10.2891 7.56001 10.2891 7.14001C10.2891 6.72001 10.6491 6.39001 11.0691 6.39001H14.4291C14.8491 6.39001 15.1791 6.72001 15.1791 7.14001Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M15.1791 10.3199C15.1791 10.7399 14.8491 11.0699 14.4291 11.0699H11.0691C10.6491 11.0699 10.2891 10.7399 10.2891 10.3199C10.2891 9.89995 10.6491 9.56995 11.0691 9.56995H14.4291C14.8491 9.56995 15.1791 9.89995 15.1791 10.3199Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M14.4291 12.75H11.0691C10.6491 12.75 10.2891 13.11 10.2891 13.5V18H15.1791V13.5C15.1791 13.11 14.8491 12.75 14.4291 12.75ZM11.7891 16.5V14.64C11.7891 14.43 11.9691 14.25 12.1791 14.25H13.2891C13.4991 14.25 13.6791 14.43 13.6791 14.64V16.5H11.7891Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M5.64008 12.75H3.33008C2.94008 12.75 2.58008 13.11 2.58008 13.5V18H6.39008V13.5C6.39008 13.11 6.06008 12.75 5.64008 12.75ZM4.08008 16.5V14.25H4.89008V16.5H4.08008Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M3.99023 1.74H2.49023V0.39C2.49023 0.18 2.67023 0 2.85023 0H3.60023C3.81023 0 3.96023 0.18 3.96023 0.36V1.71L3.99023 1.74Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M1.37963 3.26998L0.17963 2.57998C-0.000369956 2.48998 -0.0603699 2.24998 0.0296301 2.06998L0.41963 1.40998C0.50963 1.22998 0.74963 1.16998 0.92963 1.25998L2.09963 1.94998L1.34963 3.23998L1.37963 3.26998Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M0.418927 5.51994L0.0589269 4.88994C-0.0310731 4.70994 0.0289269 4.46994 0.208927 4.37994L1.37893 3.68994L2.12893 4.97994L0.958927 5.66994C0.778927 5.75994 0.538927 5.69994 0.448927 5.51994H0.418927Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M5.09961 3.27001L4.34961 1.98001L5.51961 1.29001C5.69961 1.20001 5.93961 1.26001 6.02961 1.44001L6.41961 2.10001C6.50961 2.28001 6.44961 2.52001 6.26961 2.61001L5.09961 3.30001V3.27001Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M5.64 2.96999C5.52 2.42999 5.25 1.97999 4.89 1.64999C4.65 1.40999 4.32 1.22999 3.99 1.13999C3.75 1.01999 3.51 0.98999 3.24 0.98999C2.97 0.98999 2.73 1.01999 2.49 1.13999C2.13 1.22999 1.83 1.40999 1.56 1.64999C1.2 1.97999 0.93 2.42999 0.81 2.93999C0.78 3.14999 0.75 3.29999 0.75 3.47999C0.75 3.65999 0.78 3.83999 0.81 3.98999C0.93 4.49999 1.2 4.94999 1.56 5.30999C1.83 5.54999 2.13 5.72999 2.49 5.81999C2.73 5.93999 2.97 5.96999 3.24 5.96999C3.51 5.96999 3.75 5.93999 3.99 5.81999C4.32 5.72999 4.65 5.54999 4.89 5.30999C5.25 4.97999 5.52 4.52999 5.64 4.01999C5.67 3.86999 5.7 3.65999 5.7 3.47999C5.7 3.29999 5.67 3.14999 5.64 2.96999ZM3.24 4.46999C2.7 4.46999 2.25 4.01999 2.25 3.47999C2.25 2.93999 2.7 2.48999 3.24 2.48999C3.78 2.48999 4.2 2.93999 4.2 3.47999C4.2 4.01999 3.78 4.46999 3.24 4.46999Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M3.60023 6.92997H2.85023C2.64023 6.92997 2.49023 6.74997 2.49023 6.56997V5.21997H3.99023V6.56997C3.99023 6.77997 3.81023 6.92997 3.63023 6.92997H3.60023Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M5.54977 5.67L4.25977 4.92L5.00977 3.63L6.29977 4.38C6.47977 4.47 6.53977 4.71 6.44977 4.89L6.05977 5.55C5.96977 5.73 5.72977 5.79 5.54977 5.7V5.67Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73794">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveCompanySettingsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73746)">
        <path
          d="M16.5 0H9C8.16 0 7.5 0.69 7.5 1.5V9H1.5C0.66 9 0 9.69 0 10.5V17.64C0 17.85 0.18 18 0.36 18H2.22C2.43 18 2.58 17.82 2.58 17.64V13.5C2.58 13.08 2.91 12.75 3.33 12.75H5.64C6.06 12.75 6.39 13.11 6.39 13.5V17.64C6.39 17.85 6.57 18 6.75 18H9.9C10.11 18 10.26 17.82 10.26 17.64V13.5C10.26 13.11 10.62 12.75 11.04 12.75H14.4C14.82 12.75 15.15 13.11 15.15 13.5V17.64C15.15 17.85 15.33 18 15.51 18H17.58C17.79 18 17.94 17.82 17.94 17.64V1.5C17.94 0.69 17.25 0 16.44 0H16.5ZM14.43 4.71H11.07C10.65 4.71 10.29 4.35 10.29 3.96C10.29 3.54 10.65 3.21 11.07 3.21H14.43C14.85 3.21 15.18 3.54 15.18 3.96C15.18 4.35 14.85 4.71 14.43 4.71ZM15.18 7.14C15.18 7.56 14.85 7.89 14.43 7.89H11.07C10.65 7.89 10.29 7.56 10.29 7.14C10.29 6.72 10.65 6.39 11.07 6.39H14.43C14.85 6.39 15.18 6.72 15.18 7.14ZM14.43 11.07H11.07C10.65 11.07 10.29 10.74 10.29 10.32C10.29 9.9 10.65 9.57 11.07 9.57H14.43C14.85 9.57 15.18 9.9 15.18 10.32C15.18 10.74 14.85 11.07 14.43 11.07Z"
          fill="white"
        />
        <path
          d="M3.99023 1.74H2.49023V0.39C2.49023 0.18 2.67023 0 2.85023 0H3.60023C3.81023 0 3.96023 0.18 3.96023 0.36V1.71L3.99023 1.74Z"
          fill="white"
        />
        <path
          d="M1.37963 3.26998L0.17963 2.57998C-0.000369956 2.48998 -0.0603699 2.24998 0.0296301 2.06998L0.41963 1.40998C0.50963 1.22998 0.74963 1.16998 0.92963 1.25998L2.09963 1.94998L1.34963 3.23998L1.37963 3.26998Z"
          fill="white"
        />
        <path
          d="M0.418927 5.51994L0.0589269 4.88994C-0.0310731 4.70994 0.0289269 4.46994 0.208927 4.37994L1.37893 3.68994L2.12893 4.97994L0.958927 5.66994C0.778927 5.75994 0.538927 5.69994 0.448927 5.51994H0.418927Z"
          fill="white"
        />
        <path
          d="M5.09961 3.27001L4.34961 1.98001L5.51961 1.29001C5.69961 1.20001 5.93961 1.26001 6.02961 1.44001L6.41961 2.10001C6.50961 2.28001 6.44961 2.52001 6.26961 2.61001L5.09961 3.30001V3.27001Z"
          fill="white"
        />
        <path
          d="M3.24 5.96999C1.86 5.96999 0.75 4.85999 0.75 3.47999C0.75 2.09999 1.86 0.98999 3.24 0.98999C4.62 0.98999 5.73 2.09999 5.73 3.47999C5.73 4.85999 4.62 5.96999 3.24 5.96999ZM3.24 2.48999C2.7 2.48999 2.25 2.93999 2.25 3.47999C2.25 4.01999 2.7 4.46999 3.24 4.46999C3.78 4.46999 4.23 4.01999 4.23 3.47999C4.23 2.93999 3.78 2.48999 3.24 2.48999Z"
          fill="white"
        />
        <path
          d="M3.60023 6.92997H2.85023C2.64023 6.92997 2.49023 6.74997 2.49023 6.56997V5.21997H3.99023V6.56997C3.99023 6.77997 3.81023 6.92997 3.63023 6.92997H3.60023Z"
          fill="white"
        />
        <path
          d="M5.54977 5.67L4.25977 4.92L5.00977 3.63L6.29977 4.38C6.47977 4.47 6.53977 4.71 6.44977 4.89L6.05977 5.55C5.96977 5.73 5.72977 5.79 5.54977 5.7V5.67Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73746">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
