import React from 'react';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import API from 'utils/api';

import { Container, NotAnswered, UserCellName, UserCellNameContainer, UserCellAvatar } from './styles';
import { UserProps } from './types';

const GridUserRenderer: React.FunctionComponent<UserProps> = ({ value }) => {
  const [user, setUser] = React.useState<UuidableName | null | undefined>(undefined);

  React.useEffect(() => {
    (async () => {
      if (value && (value as UuidableName)?.uuid && (value as UuidableName)?.name) {
        setUser(value as UuidableName);
      } else if (value) {
        setUser(undefined);
        const { data } = await API.loadUserData((value as string).substring(5))();
        setUser(data);
      } else {
        setUser(null);
      }
    })();
  }, [value]);

  if (user === null) {
    return <NotAnswered>N/A</NotAnswered>;
  }

  if (user === undefined) {
    return <>Loading...</>;
  }

  return (
    <Container>
      {user.name && <UserCellAvatar name={user.name} size="small" />}
      <UserCellNameContainer>
        <UserCellName>{user.name}</UserCellName>
      </UserCellNameContainer>
    </Container>
  );
};

export default GridUserRenderer;
