import React from 'react';

import { observer } from 'mobx-react';
import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';

import API from 'utils/api';

import EntitiesActivities from 'stores/EntitiesActivities';

const EntitiesDetailsActivitiesFooter: React.FunctionComponent<{ activities: EntitiesActivities | undefined }> = ({
  activities,
}): React.ReactElement => {
  if (!activities) {
    return <></>;
  }

  return (
    <AddCommentActivities
      placeholder="Post an update"
      uploadFile={activities.uploadFile}
      usersSearch={API.searchUsers}
      sendMessage={activities.addMessage}
    />
  );
};

export default observer(EntitiesDetailsActivitiesFooter);
