import styled from 'styled-components';

import { columnFlex } from 'core/styles';

export const EnchantedMapContainer = styled.div`
  &,
  & .google-maps-container {
    ${columnFlex};
    padding: 20px;
    background: #fff;
  }
`;
