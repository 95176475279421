import React from 'react';

import { observer } from 'mobx-react';

import { Switch, FormControlLabel } from '@mui/material';

import { StyledTextField } from 'components/Input/styles';

import { DropdownOptionsProps } from './types';

const DropdownOptions: React.FunctionComponent<DropdownOptionsProps> = ({
  editView,
  options,
  setOptions,
  multipleAnswers,
  setMultipleAnswers,
}): React.ReactElement => {
  const [value, setValue] = React.useState(options.join('\n'));

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setValue(event.target.value);
    setOptions(event.target.value.split('\n').map((option) => option.trim()));
  };

  return (
    <>
      <StyledTextField
        placeholder="List options, one per line"
        value={value}
        onChange={onChange}
        variant="outlined"
        multiline
        rows={5}
        fullWidth
        style={{ margin: '8px 0' }}
      />
      <FormControlLabel
        control={
          <Switch
            size="small"
            id="multipleAnswers"
            checked={multipleAnswers}
            onChange={(e) => setMultipleAnswers(e.target.checked)}
          />
        }
        disabled={editView}
        label="Allow Multiple Answers"
        style={{ margin: 0 }}
      />
    </>
  );
};

export default observer(DropdownOptions);
