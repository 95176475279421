import TableStore from 'vatix-ui/lib/utils/stores/TableStore';

import { observable, runInAction, makeObservable, action } from 'mobx';

import { SearchResponse } from 'vatix-ui/lib/utils/api/types';

import { subscribe } from 'mobx-event-bus2';

import API from 'utils/api';

import { EventType } from 'utils/events/constants';

import { ActionEvent, UpdatedDevicePayload } from 'utils/events/types';

import RootStore from '../Root';
import ListDevice from './ListDevice';

export default class DeviceListStore extends TableStore<RootStore, typeof API, ListDevice, ListDevice> {
  @observable tags: SearchResponse[] = [];

  @observable filterUsers: SearchResponse[] = [];

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, api.loadDeviceList);
    makeObservable(this);
    this.sortingColumn = 'device';
    this.sortingOrder = 'desc';
  }

  private async loadTags(): Promise<void> {
    const { data } = await this.api.searchDeviceTags({})();
    runInAction(() => {
      this.tags = data;
    });
  }

  private async loadFilterUsers(): Promise<void> {
    const { data } = await this.api.assignedDevicesUsers()();
    runInAction(() => {
      this.filterUsers = data;
    });
  }

  async loadData(): Promise<void> {
    await super.loadData();
    await this.loadTags();
    await this.loadFilterUsers();
  }

  @subscribe(EventType.UpdatedDevice)
  @action
  updatedDevice({ payload }: ActionEvent<UpdatedDevicePayload>): void {
    if (this.isFailure || !this.isLoaded) {
      return;
    }

    const index = this.items.findIndex((u) => u.uuid === payload.uuid);
    if (index !== -1) {
      // @ts-ignore
      this.items[index] = {
        ...this.items[index],
        ...payload,
      };
    }
  }
}
