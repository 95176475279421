/* eslint-disable max-len */
import React from 'react';

export function MoreAppsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.68334 7.51667H2.5C2.03333 7.51667 1.66667 7.15001 1.66667 6.68334V2.5C1.66667 2.03333 2.03333 1.66667 2.5 1.66667H6.68334C7.15001 1.66667 7.51667 2.03333 7.51667 2.5V6.68334C7.51667 7.15001 7.15001 7.51667 6.68334 7.51667ZM0 1.66667V7.51667C0 8.43334 0.750001 9.18334 1.66667 9.18334H7.51667C8.43334 9.18334 9.18334 8.43334 9.18334 7.51667V1.66667C9.18334 0.750001 8.43334 0 7.51667 0H1.66667C0.750001 0 0 0.750001 0 1.66667Z"
        fill="#C0C7D3"
      />
      <path
        d="M6.68334 18.3333H2.5C2.03333 18.3333 1.66667 17.9667 1.66667 17.5V13.3167C1.66667 12.85 2.03333 12.4833 2.5 12.4833H6.68334C7.15001 12.4833 7.51667 12.85 7.51667 13.3167V17.5C7.51667 17.9667 7.15001 18.3333 6.68334 18.3333ZM0 12.4833V18.3333C0 19.25 0.750001 20 1.66667 20H7.51667C8.43334 20 9.18334 19.25 9.18334 18.3333V12.4833C9.18334 11.5667 8.43334 10.8167 7.51667 10.8167H1.66667C0.750001 10.8167 0 11.5667 0 12.4833Z"
        fill="#C0C7D3"
      />
      <path
        d="M17.4997 7.51667H13.3164C12.8497 7.51667 12.4831 7.15001 12.4831 6.68334V2.5C12.4831 2.03333 12.8497 1.66667 13.3164 1.66667H17.4997C17.9664 1.66667 18.3331 2.03333 18.3331 2.5V6.68334C18.3331 7.15001 17.9664 7.51667 17.4997 7.51667ZM10.8164 1.66667V7.51667C10.8164 8.43334 11.5664 9.18334 12.4831 9.18334H18.3331C19.2497 9.18334 19.9997 8.43334 19.9997 7.51667V1.66667C19.9997 0.750001 19.2497 0 18.3331 0H12.4831C11.5664 0 10.8164 0.750001 10.8164 1.66667Z"
        fill="#C0C7D3"
      />
      <path
        d="M19.9997 15.4C19.9997 15.8667 19.6331 16.2333 19.1664 16.2333H16.6497C16.4164 16.2333 16.2331 16.4167 16.2331 16.65V19.1667C16.2331 19.6333 15.8664 20 15.3997 20C14.9331 20 14.5664 19.6333 14.5664 19.1667V16.65C14.5664 16.4167 14.3831 16.2333 14.1497 16.2333H11.6497C11.1831 16.2333 10.8164 15.8667 10.8164 15.4C10.8164 14.9333 11.1831 14.5667 11.6497 14.5667H14.1497C14.3831 14.5667 14.5664 14.3833 14.5664 14.15V11.65C14.5664 11.1833 14.9497 10.8167 15.3997 10.8167C15.8497 10.8167 16.2331 11.1833 16.2331 11.65V14.15C16.2331 14.3833 16.4164 14.5667 16.6497 14.5667H19.1664C19.6331 14.5667 19.9997 14.95 19.9997 15.4Z"
        fill="#C0C7D3"
      />
    </svg>
  );
}

export function ActiveMoreAppsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_14225_78409)">
        <path
          d="M9.18333 1.66667V7.51667C9.18333 8.45 8.45 9.18333 7.51667 9.18333H1.66667C0.75 9.18333 0 8.45 0 7.51667V1.66667C0 0.75 0.75 0 1.66667 0H7.51667C8.45 0 9.18333 0.75 9.18333 1.66667Z"
          fill="white"
        />
        <path
          d="M9.18333 12.4833V18.3333C9.18333 19.25 8.45 20 7.51667 20H1.66667C0.75 20 0 19.25 0 18.3333V12.4833C0 11.55 0.75 10.8167 1.66667 10.8167H7.51667C8.45 10.8167 9.18333 11.55 9.18333 12.4833Z"
          fill="white"
        />
        <path
          d="M19.9997 1.66667V7.51667C19.9997 8.45 19.2497 9.18333 18.3331 9.18333H12.4831C11.5497 9.18333 10.8164 8.45 10.8164 7.51667V1.66667C10.8164 0.75 11.5497 0 12.4831 0H18.3331C19.2497 0 19.9997 0.75 19.9997 1.66667Z"
          fill="white"
        />
        <path
          d="M19.9997 15.4C19.9997 15.8667 19.6331 16.2333 19.1664 16.2333H16.6497C16.4164 16.2333 16.2331 16.4167 16.2331 16.65V19.1667C16.2331 19.6333 15.8664 20 15.3997 20C14.9331 20 14.5664 19.6333 14.5664 19.1667V16.65C14.5664 16.4167 14.3831 16.2333 14.1497 16.2333H11.6497C11.1831 16.2333 10.8164 15.8667 10.8164 15.4C10.8164 14.9333 11.1831 14.5667 11.6497 14.5667H14.1497C14.3831 14.5667 14.5664 14.3833 14.5664 14.15V11.65C14.5664 11.1833 14.9497 10.8167 15.3997 10.8167C15.8497 10.8167 16.2331 11.1833 16.2331 11.65V14.15C16.2331 14.3833 16.4164 14.5667 16.6497 14.5667H19.1664C19.6331 14.5667 19.9997 14.95 19.9997 15.4Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14225_78409">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
