import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@mui/material';
import { Button } from 'vatix-ui/lib/components/Button';

import { Formik, Form, FormikHelpers } from 'formik';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { StyledTextField } from 'components/Input/styles';
import { useStore } from 'utils/hooks/store';
import DetailsForm from 'stores/FormDetails/DetailsForm';
import CustomLocationTextInput from 'containers/IncidentDetails/components/CustomLocationTextInput';
import EntitySearchField from 'components/EntitySearchField';

import { EntityModules } from 'core/constants';

import { LocationAddressResponse, LocationProperties } from 'utils/api/types';

import { useEntityDisplayValue } from 'components/Entities/EntityDisplayValue/utils';

import { StyledSectionGrid, StyledRowTitle, ButtonWrapper } from './styles';

const EditRow: React.FC<{ name: string; disabled?: boolean; children: React.ReactNode; id?: string }> = ({
  name,
  disabled = false,
  children,
  id,
}): React.ReactElement => (
  <>
    <StyledSectionGrid item container xs={12} alignContent="flex-start" id={id} marginTop="16px">
      <StyledRowTitle $disabled={disabled}>{name}</StyledRowTitle>
    </StyledSectionGrid>
    <StyledSectionGrid item container xs={12}>
      {children}
    </StyledSectionGrid>
  </>
);

interface EditOverviewFormValues {
  name: string;
  location: LocationProperties;
  site: UuidableName | null;
  asset: UuidableName | null;
  supplier: UuidableName | null;
}

const EditOverviewTab: React.FC<{ setEditing: (val: boolean) => void }> = ({ setEditing }) => {
  const {
    formDetails: { details: detailsRaw },
    session,
  } = useStore();
  const details = detailsRaw as DetailsForm;

  const initialValues: EditOverviewFormValues = {
    name: details.name,
    location: {
      address: details.location?.address || '',
      lat: details.location?.lat || '0',
      lng: details.location?.lng || '0',
    },
    site: details.links.find((link) => link.entity === 'sites')?.instance || null,
    asset: details.links.find((link) => link.entity === 'assets')?.instance || null,
    supplier: details.links.find((link) => link.entity === 'suppliers')?.instance || null,
  };

  const onSubmit = async (
    values: EditOverviewFormValues,
    { setSubmitting }: FormikHelpers<EditOverviewFormValues>
  ): Promise<void> => {
    const siteId = values.site ? values.site.uuid : null;
    const assetId = values.asset ? values.asset.uuid : null;
    const supplierId = values.supplier ? values.supplier.uuid : null;
    const res = await details.updateForm(
      details.uuid,
      values.name,
      values.location as LocationAddressResponse,
      siteId,
      assetId,
      supplierId
    );
    if (res) {
      setEditing(false);
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, setFieldValue, handleChange, values }) => (
        <Form>
          <Grid container style={{ padding: '20px' }}>
            <EditRow name="Name">
              <StyledTextField id="name" name="name" value={values.name} fullWidth onChange={handleChange} />
            </EditRow>
            <EditRow name="Location" id="location">
              <CustomLocationTextInput
                location={initialValues.location.address}
                onChange={(e) => setFieldValue('location', e)}
                requiredNotFilled={false}
              />
            </EditRow>

            {session.user?.activeEntityModules
              .filter((val) => val !== EntityModules.Events && val !== EntityModules.Risks)
              .map((module) => {
                const { entityLink, singular } = useEntityDisplayValue(module, details.links);

                return (
                  <EditRow name={singular} id={`related-${singular}-edit-label`} key={singular}>
                    <EntitySearchField
                      field={singular.toLowerCase()}
                      module={module}
                      data={entityLink?.instance as UuidableName}
                      updateField={(field: string, value: UuidableName | null) => {
                        const formattedData = value || null;
                        setFieldValue(field, formattedData);
                      }}
                    />
                  </EditRow>
                );
              })}

            <ButtonWrapper>
              <Button variant="outlined" onClick={() => setEditing(false)} size="large">
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                size="large"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </ButtonWrapper>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default observer(EditOverviewTab);
