/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import { SvgIconProps } from '@mui/material';
import React from 'react';

export function AuditsIcon(props: SvgIconProps): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <g clipPath="url(#clip0_13967_176414)">
        <path
          d="M13.8333 16.6834V17.5C13.8333 17.9667 13.4667 18.3334 13 18.3334H3C2.53333 18.3334 2.16667 17.9667 2.16667 17.5V4.16669C2.16667 3.70002 2.53333 3.33335 3 3.33335H3.83333V1.66669H2.16667C1.25 1.66669 0.5 2.41669 0.5 3.33335V18.3334C0.5 19.25 1.25 20 2.16667 20H13.8333C14.75 20 15.5 19.25 15.5 18.3334V16.6834H13.8333ZM13.8333 1.66669H12.1667V3.33335H13C13.4667 3.33335 13.8333 3.70002 13.8333 4.16669V6.68335H15.5V3.33335C15.5 2.41669 14.75 1.66669 13.8333 1.66669Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
        <path
          d="M19.75 9.53335V15.85C19.75 16.3 19.3833 16.6833 18.9167 16.6833H10.5833C10.1167 16.6833 9.75 16.3 9.75 15.85V7.51668C9.75 7.05002 10.1167 6.68335 10.5833 6.68335H17.4167L15.75 8.35002H11.8333C11.6 8.35002 11.4167 8.53335 11.4167 8.76668V14.6C11.4167 14.8167 11.6 15.0167 11.8333 15.0167H17.6667C17.9 15.0167 18.0833 14.8167 18.0833 14.6V11.2L19.75 9.53335Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
        <path
          d="M20.366 7.46669L15.0493 12.8167C15.0493 12.8167 14.8493 12.95 14.7493 12.95C14.6493 12.95 14.5327 12.9 14.4493 12.8167L13.8327 12.2L12.416 10.7834C12.2493 10.6167 12.2493 10.35 12.416 10.1834L12.9993 9.60002C13.0827 9.51669 13.1827 9.48335 13.2993 9.48335C13.3993 9.48335 13.516 9.51669 13.5827 9.60002L14.5993 10.6167C14.5993 10.6167 14.6993 10.6834 14.7493 10.6834C14.7993 10.6834 14.8493 10.65 14.8993 10.6167L15.4993 10.0167L17.1493 8.35002L18.816 6.68335L19.1827 6.28335C19.266 6.21669 19.366 6.16669 19.4827 6.16669C19.5827 6.16669 19.6993 6.21669 19.7827 6.28335L20.366 6.88335C20.5327 7.05002 20.5327 7.30002 20.366 7.46669Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
        <path
          d="M11.334 0H4.66732C4.20065 0 3.83398 0.366667 3.83398 0.833333V4.16667C3.83398 4.63333 4.20065 5 4.66732 5H11.334C11.8007 5 12.1673 4.63333 12.1673 4.16667V0.833333C12.1673 0.366667 11.8007 0 11.334 0ZM10.5007 2.91667C10.5007 3.15 10.3173 3.33333 10.084 3.33333H5.91732C5.68398 3.33333 5.50065 3.15 5.50065 2.91667V2.08333C5.50065 1.85 5.68398 1.66667 5.91732 1.66667H10.084C10.3173 1.66667 10.5007 1.85 10.5007 2.08333V2.91667Z"
          fill={props.fill ? props.fill : '#C0C7D3'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13967_176414">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveAuditsIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <g clipPath="url(#clip0_13967_179725)">
        <path
          d="M10.5833 5.35H15.5V3.75C15.5 2.83333 14.75 2.08333 13.8333 2.08333H12.1667V0.833333C12.1667 0.366667 11.8 0 11.3333 0H4.66667C4.2 0 3.83333 0.366667 3.83333 0.833333V2.08333H2.16667C1.25 2.08333 0.5 2.83333 0.5 3.75V18.3333C0.5 19.25 1.25 20 2.16667 20H13.8333C14.75 20 15.5 19.25 15.5 18.3333V18.0167H10.5833C9.38333 18.0167 8.41667 17.05 8.41667 15.85V7.51667C8.41667 6.31667 9.38333 5.35 10.5833 5.35ZM5.5 1.66667C5.5 1.43333 5.68333 1.25 5.91667 1.25H10.0833C10.3167 1.25 10.5 1.43333 10.5 1.66667V2.5C10.5 2.73333 10.3167 2.91667 10.0833 2.91667H5.91667C5.68333 2.91667 5.5 2.73333 5.5 2.5V1.66667Z"
          fill="white"
        />
        <path
          d="M19.0333 10.25C19.3 9.98335 19.75 10.1667 19.75 10.55V15.8667C19.75 16.3167 19.3833 16.7 18.9167 16.7H10.5833C10.1167 16.7 9.75 16.3167 9.75 15.8667V7.51668C9.75 7.05002 10.1167 6.68335 10.5833 6.68335H15.0833C15.3167 6.68335 15.5 6.86668 15.5 7.10002V7.93335C15.5 8.16668 15.3167 8.35002 15.0833 8.35002H11.8333C11.6 8.35002 11.4167 8.53335 11.4167 8.76668V14.6C11.4167 14.8167 11.6 15.0167 11.8333 15.0167H17.6667C17.9 15.0167 18.0833 14.8167 18.0833 14.6V11.3667C18.0833 11.25 18.1333 11.15 18.2 11.0667L19.0333 10.2333V10.25Z"
          fill="white"
        />
        <path
          d="M20.4327 7.38336C20.4327 7.38336 20.3993 7.43336 20.3827 7.45002L19.766 8.06669L15.4993 12.3667L15.0493 12.8167C14.966 12.9 14.8493 12.95 14.7493 12.95C14.6493 12.95 14.5327 12.9 14.4493 12.8167L12.416 10.7834C12.2493 10.6167 12.2493 10.35 12.416 10.1834L12.9993 9.60002C13.0827 9.51669 13.1827 9.48336 13.2993 9.48336C13.3993 9.48336 13.516 9.51669 13.5827 9.60002L14.5993 10.6167C14.5993 10.6167 14.6993 10.6834 14.7493 10.6834C14.7993 10.6834 14.8493 10.65 14.8993 10.6167L15.4993 10.0167L17.1493 8.35002L18.8327 6.66669L19.1993 6.26669C19.2827 6.20002 19.3827 6.15002 19.4993 6.15002C19.5993 6.15002 19.716 6.20002 19.7993 6.26669L20.3827 6.86669C20.3827 6.86669 20.416 6.91669 20.4327 6.93336C20.5327 7.06669 20.5327 7.25002 20.4327 7.38336Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13967_179725">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
