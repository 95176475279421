import { Button } from '@mui/material';
import { Field } from 'formik';
import styled from 'styled-components';

export const FormRow = styled.div<{
  hasError: boolean;
}>`
  display: flex;
  flex-direction: column;
  .MuiFormHelperText-root {
    margin-top: 0px;
    margin-left: 12px;
    color: #d32f2f;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
  }
  .MuiFormControl-fullWidth {
    margin-bottom: 3px;
  }
  margin-bottom: ${(props) => (props.hasError ? 16 : 0)}px;
`;

export const RowLabel = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 5px;
`;

export const StyledField = styled(Field)`
  margin-bottom: 4px;
  input {
    padding: 16px 12px 16px 12px;
  }
  svg {
    display: none;
  }
`;

export const ConfirmVerificationCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DigitsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
`;

export const FormError = styled.p`
  color: rgba(211, 47, 47, 1);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-left: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const FormInfo = styled(FormError)`
  color: #0772e5;
`;

export const FormStatusAnchor = styled.a`
  color: #0872e6;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
  text-decoration: underline;
  cursor: pointer;
`;

export const SubmitButton = styled(Button).attrs({
  type: 'submit',
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  height: 42px;
  padding: 7px 21px 7px 21px;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  box-shadow: none;
  margin-top: 8px;
  align-self: flex-end;
`;
