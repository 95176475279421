import { EntityModules } from 'core/constants';

export type Translation = {
  singular: string;
  plural: string;
};

export const getEntityTranslation: Record<EntityModules, Translation> = {
  [EntityModules.Assets]: {
    plural: 'Assets',
    singular: 'Asset',
  },
  [EntityModules.Sites]: {
    plural: 'Sites',
    singular: 'Site',
  },
  [EntityModules.Suppliers]: {
    plural: 'Suppliers',
    singular: 'Supplier',
  },
  [EntityModules.Risks]: {
    plural: 'Risk Assessments',
    singular: 'Risk Assessment',
  },
  [EntityModules.Events]: {
    plural: 'Events',
    singular: 'Event',
  },
};
