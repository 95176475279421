import { action, makeObservable } from 'mobx';

import { toast, Theme, ToastOptions, Slide } from 'react-toastify';

import { SuccessToast, CloseSuccessToast } from 'components/Icons/SuccessToast';

import { WarningToast, CloseWarningToast } from 'components/Icons/WarningToast';

import { ErrorToast, CloseErrorToast } from 'components/Icons/ErrorToast';

import { InfoToast, CloseInfoToast } from 'components/Icons/InfoToast';

import RootStore from '../Root';

class NotificationStore {
  store: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.store = rootStore;
  }

  commonOptions: ToastOptions = {
    progress: 0.0,
    autoClose: 3000,
    theme: 'colored' as Theme,
    position: toast.POSITION.TOP_CENTER,
    draggable: true,
    pauseOnHover: true,
    closeOnClick: true,
    hideProgressBar: true,
    pauseOnFocusLoss: false,
    transition: Slide,
  };

  @action.bound
  enqueueInfoSnackbar(message: string): void {
    toast.info(message, { ...this.commonOptions, icon: InfoToast, closeButton: CloseInfoToast });
  }

  @action.bound
  enqueueSuccessSnackbar(message: string): void {
    toast.success(message, { ...this.commonOptions, icon: SuccessToast, closeButton: CloseSuccessToast });
  }

  @action.bound
  enqueueErrorSnackbar(message: string): void {
    toast.error(message, { ...this.commonOptions, icon: ErrorToast, closeButton: CloseErrorToast });
  }

  @action.bound
  enqueueWarningSnackbar(message: string): void {
    toast.warn(message, { ...this.commonOptions, icon: WarningToast, closeButton: CloseWarningToast });
  }
}

export default NotificationStore;
