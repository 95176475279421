import React from 'react';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { UserSection, UserNameValue, StyledAvatar } from './styles';

const UserRow: React.FunctionComponent<{ user: UuidableName }> = ({ user }): React.ReactElement => (
  <UserSection aria-label="user-field">
    {user.name && user.name !== 'N/A' && <StyledAvatar name={user.name} size="small" />}
    <UserNameValue>{user.name}</UserNameValue>
  </UserSection>
);

export default UserRow;
