import React from 'react';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import Grid, { defaultOperators, defaultFormatters } from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { formatDateForDisplay } from 'utils/formatters/time';

import EntityActionCell from 'components/Entities/EntityActionCell';

import { SuppliersTableProps } from './types';
import { StyledCreated } from './styles';

export const basicColumns = ([
  {
    field: 'suppliers__supplierName',
    headerName: 'Name',
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    renderCell: ({ value }: GridValueGetterParams) => (
      <StyledCreated>{formatDateForDisplay(value.value)}</StyledCreated>
    ),
    filterOperators: defaultOperators.date_time(),
    valueFormatter: defaultFormatters.date,
    nativeFilter: 'createdAt',
  },
] as unknown) as ColumnDefinition[];

const SuppliersTable: React.FunctionComponent<SuppliersTableProps> = ({
  onError,
  onRefreshCallback,
}): React.ReactElement => {
  const { routing, supplierDetails } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (item: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.suppliers.details, { entityId: item.id }));
  };

  return (
    <Grid
      basicColumns={
        ([
          ...basicColumns,
          {
            field: 'actions',
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            ariaLabel: 'actions_cell',
            // eslint-disable-next-line react/no-unused-prop-types
            renderCell: ({ row, colDef }: { row: { uuid: string }; colDef: ColumnDefinition }) => (
              <EntityActionCell rowData={row} actions={colDef.actions} store={supplierDetails} />
            ),
            filterOperators: null,
            sortable: false,
          },
        ] as unknown) as ColumnDefinition[]
      }
      entity="suppliers"
      onError={onError}
      dataURL="/api/entities/suppliers/"
      fieldsURL="/api/entities/suppliers/fields/"
      customNoRowsText="No suppliers have been created yet"
      onRowClick={onRowClick}
      onRefreshCallback={onRefreshCallback}
      disableSelectAllColumns
    />
  );
};

export default SuppliersTable;
