import React from 'react';

import { Formik, Form, FormikHelpers } from 'formik';

import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';

import InputAdornment from '@mui/material/InputAdornment';

import { reverse } from 'named-urls';

import {
  FormContainer,
  StyledButton,
  LoginPageContainer,
  TitleWrapper,
  BackButton,
  InputSubtitle,
  InputTitle,
  LoginPageWrapper,
} from 'containers/Login/styles';

import { StyledTextField } from 'components/Input/styles';

import ArrowLeft from 'components/Icons/ArrowLeft';

import Mail from 'components/Icons/Mail';

import routes from 'core/routes';

import { PasswordResetValues } from './types';

import API from '../../utils/api';

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address is not valid')
    .required('Email address is required'),
});

const PasswordReset: React.FunctionComponent = () => {
  const history = useHistory();
  const [completed, setCompleted] = React.useState(false);

  const initialValues = {
    email: '',
  };

  const onSubmit = async (data: PasswordResetValues, helpers: FormikHelpers<PasswordResetValues>): Promise<void> => {
    try {
      helpers.setSubmitting(true);
      await API.resetPassword(data)();
      setCompleted(true);
    } catch (e) {
      // @ts-ignore
      const { nonFieldErrors, fieldErrors } = formErrors(e);
      helpers.setSubmitting(false);
      fieldErrors && helpers.setErrors(fieldErrors);
      nonFieldErrors && helpers.setStatus(nonFieldErrors);
    }
  };
  return (
    <LoginPageWrapper>
      <LoginPageContainer>
        <FormContainer>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={FormSchema}>
            {({ isSubmitting, handleChange, values, touched, errors }) => (
              <Form>
                <BackButton startIcon={<ArrowLeft />} onClick={() => history.push(reverse(routes.login))}>
                  Back to login
                </BackButton>
                <TitleWrapper>
                  <InputTitle>{completed ? 'Password Reset Requested' : 'Forgot Password?'}</InputTitle>
                  <InputSubtitle>
                    {completed
                      ? `A password reset link has been sent to ${values.email}.
                      Please check your inbox and follow the instructions.`
                      : 'Enter your email and we will send an email with instructions to reset your password'}
                  </InputSubtitle>
                </TitleWrapper>

                {!completed && (
                  <>
                    <StyledTextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Mail fill="#4BA0FD" />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      id="email"
                      name="email"
                      type="email"
                      variant="outlined"
                      placeholder="Enter your email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <StyledButton type="submit" disabled={isSubmitting} variant="contained" color="primary">
                      Reset password
                    </StyledButton>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </FormContainer>
      </LoginPageContainer>
    </LoginPageWrapper>
  );
};
export default PasswordReset;
