import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { UserRole } from 'core/constants';
import { EventType } from 'utils/events/constants';
import { Indexable } from 'utils/types';
import { OrganisationResponse, ResolutionReasonResponse } from 'utils/api/types';

import { ConfigResponse, ConfigPromises } from './types';
import Logger from '../../utils/logger';
import API from '../../utils/api';
import DataMixin from '../../utils/stores/DataMixin';
import RootStore from '../Root';

export default class ConfigStore extends DataMixin {
  @observable organisations: OrganisationResponse[] = [];

  @observable resolutionReasons: ResolutionReasonResponse[] = [];

  roleConfigMap: Indexable<() => ConfigPromises>;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    this.roleConfigMap = {
      [UserRole.User]: this.loneWorkerConfig,
      [UserRole.Manager]: this.managerConfig,
      [UserRole.ArcOperator]: this.arcOperatorConfig,
      [UserRole.Admin]: this.adminConfig,
      [UserRole.AccountManager]: this.accountManagerConfig,
    };

    eventBus.register(this);
  }

  loneWorkerConfig = (): ConfigPromises => [Promise.resolve(null), this.api.loadResolutionReasons()()];

  managerConfig = (): ConfigPromises => [this.api.loadOrganisations()(), this.api.loadResolutionReasons()()];

  accountManagerConfig = (): ConfigPromises => [this.api.loadOrganisations()(), this.api.loadResolutionReasons()()];

  adminConfig = (): ConfigPromises => [this.api.loadOrganisations()(), this.api.loadResolutionReasons()()];

  arcOperatorConfig = (): ConfigPromises => [Promise.resolve(null), this.api.loadResolutionReasons()()];

  @action.bound
  async loadConfig(): Promise<void> {
    if (!this.store.session.user) {
      this.isLoaded = true;
      return;
    }

    this.isLoaded = false;

    try {
      const [organisationsResponse, resolutionReasonsResponse] = (await Promise.all(
        this.roleConfigMap[this.store.session.user.role]()
      )) as ConfigResponse;

      runInAction(() => {
        this.organisations = organisationsResponse ? organisationsResponse.data : [];
        this.resolutionReasons = resolutionReasonsResponse ? resolutionReasonsResponse.data : [];
        this.isFailure = false;
      });
    } catch (e) {
      runInAction(() => {
        this.isFailure = true;
      });
      Logger.error('Invalid load config API response', e);
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @computed get resolutionReasonOptions(): Indexable<string> {
    return this.resolutionReasons.reduce((accumulator: Indexable<string>, resolutionReason): Indexable<string> => {
      accumulator[resolutionReason.uuid] = resolutionReason.name;
      return accumulator;
    }, {});
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    super.reset();

    this.organisations = [];
    this.resolutionReasons = [];
  }
}
