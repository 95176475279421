import dayjs from 'dayjs';

export function formatDate(dueDate: Date): { name: string; color: string } {
  const currentDate = new Date();

  if (
    dueDate.getDate() === currentDate.getDate() &&
    dueDate.getMonth() === currentDate.getMonth() &&
    dueDate.getFullYear() === currentDate.getFullYear()
  ) {
    return { name: 'Today', color: 'rgb(76, 175, 80)' };
  }

  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  if (
    dueDate.getDate() === tomorrow.getDate() &&
    dueDate.getMonth() === tomorrow.getMonth() &&
    dueDate.getFullYear() === tomorrow.getFullYear()
  ) {
    return { name: 'Tomorrow', color: 'rgb(237, 108, 2)' };
  }

  const fiveDaysFromTomorrow = new Date(tomorrow);
  fiveDaysFromTomorrow.setDate(tomorrow.getDate() + 5);

  if (dueDate <= fiveDaysFromTomorrow && dueDate >= currentDate) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return { name: daysOfWeek[dueDate.getDay()], color: 'rgba(0, 0, 0, 0.6)' };
  }

  if (dueDate.getFullYear() === currentDate.getFullYear() && new Date(dueDate) < new Date(currentDate)) {
    return { name: dayjs(dueDate).format('D MMM'), color: '#D32F2F' };
  }

  if (dueDate.getFullYear() === currentDate.getFullYear()) {
    return { name: dayjs(dueDate).format('D MMM'), color: 'rgba(0, 0, 0, 0.6)' };
  }

  if (dueDate.getFullYear() > currentDate.getFullYear()) {
    return { name: dayjs(dueDate).format('D MMM, YYYY'), color: 'rgba(0, 0, 0, 0.6)' };
  }

  return { name: dayjs(dueDate).format('D MMM, YYYY'), color: '#D32F2F' };
}
