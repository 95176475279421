import React from 'react';

import { useSortable } from '@dnd-kit/sortable';

import { useDraggable } from '@dnd-kit/core';

import { Tooltip } from '@mui/material';

import { Handle } from '../helpers/Handle';
import { ItemProps } from './types';
import { DraggableItemStyled, ItemStyled, Wrapper } from './styles';

import { SortableItemProps } from '../Layout/types';

export const DraggableItem = ({
  id,
  name,
  disabled,
}: {
  id: string;
  name: string;
  disabled: boolean;
}): React.ReactElement => {
  const { isDragging, setNodeRef, listeners } = useDraggable({
    id,
    data: { type: 'item' },
    disabled,
  });

  return (
    <>
      {disabled ? (
        <Tooltip title="Field already used in this layout" placement="right">
          <DraggableItemStyled {...listeners} ref={setNodeRef} key={id} $disabled={disabled} $isDragging={isDragging}>
            <p id={`disabled-field-${name}`}>{name}</p>
          </DraggableItemStyled>
        </Tooltip>
      ) : (
        <DraggableItemStyled {...listeners} ref={setNodeRef} key={id} $disabled={disabled} $isDragging={isDragging}>
          <p id={`active-field-${name}`}>{name}</p>
        </DraggableItemStyled>
      )}
    </>
  );
};

export function SortableItem({ disabled, id, name, index, isLast }: SortableItemProps): React.ReactElement {
  const { setNodeRef, listeners, isDragging, transform } = useSortable({
    id,
  });
  const mountedWhileDragging = isDragging;
  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={name}
      dragging={isDragging}
      handle={false}
      index={index}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      isLast={isLast}
    />
  );
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, ItemProps>(
    (
      { dragOverlay, dragging, disabled, fadeIn, handle, index, listeners, transform, value, isLast, ...props },
      ref
    ) => (
      <Wrapper
        fadeIn={Boolean(fadeIn)}
        dragOverlay={Boolean(dragOverlay)}
        style={
          {
            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
            '--index': index,
          } as React.CSSProperties
        }
        ref={ref}
      >
        <ItemStyled
          dragging={Boolean(dragging)}
          disabled={Boolean(disabled)}
          dragOverlay={Boolean(dragOverlay)}
          {...(!handle ? listeners : undefined)}
          {...props}
          tabIndex={!handle ? 0 : undefined}
          $isLast={isLast || false}
        >
          <Handle />
          <p id={`item-${value}`}>{value}</p>
        </ItemStyled>
      </Wrapper>
    )
  )
);
