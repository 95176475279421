import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import { center } from 'core/styles';
import vatix from 'theme/vatix';

export const Wrapper = styled.div`
  ${center};
  flex-direction: column;
  height: 100vh;
  background-color: ${vatix.palette.primary.main};
`;

export const SuccessMessage = styled(Typography)`
  margin-top: 27px;
  margin-bottom: 31px;
  text-align: center;
  color: white;
`;
