/* eslint-disable max-len */
import React from 'react';

export function ActionIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M19.166 18.3334H7.49935C7.03268 18.3334 6.66602 17.9667 6.66602 17.5001C6.66602 17.0334 7.03268 16.6667 7.49935 16.6667H19.166C19.6327 16.6667 19.9993 17.0334 19.9993 17.5001C19.9993 17.9667 19.6327 18.3334 19.166 18.3334Z"
        fill="#C0C7D3"
      />
      <path
        d="M19.166 10.8334H7.49935C7.03268 10.8334 6.66602 10.4667 6.66602 10.0001C6.66602 9.53341 7.03268 9.16675 7.49935 9.16675H19.166C19.6327 9.16675 19.9993 9.53341 19.9993 10.0001C19.9993 10.4667 19.6327 10.8334 19.166 10.8334Z"
        fill="#C0C7D3"
      />
      <path
        d="M19.166 3.33341H7.49935C7.03268 3.33341 6.66602 2.96675 6.66602 2.50008C6.66602 2.03341 7.03268 1.66675 7.49935 1.66675H19.166C19.6327 1.66675 19.9993 2.03341 19.9993 2.50008C19.9993 2.96675 19.6327 3.33341 19.166 3.33341Z"
        fill="#C0C7D3"
      />
      <path
        d="M4.16667 7.5H0.833333C0.366667 7.5 0 7.88333 0 8.33333V11.6667C0 12.1333 0.366667 12.5 0.833333 12.5H4.16667C4.63333 12.5 5 12.1333 5 11.6667V8.33333C5 7.88333 4.63333 7.5 4.16667 7.5ZM3.33333 10.4167C3.33333 10.65 3.15 10.8333 2.91667 10.8333H2.08333C1.85 10.8333 1.66667 10.65 1.66667 10.4167V9.58333C1.66667 9.35 1.85 9.16667 2.08333 9.16667H2.91667C3.15 9.16667 3.33333 9.35 3.33333 9.58333V10.4167Z"
        fill="#C0C7D3"
      />
      <path
        d="M4.16667 15H0.833333C0.366667 15 0 15.3833 0 15.8333V19.1667C0 19.6333 0.366667 20 0.833333 20H4.16667C4.63333 20 5 19.6333 5 19.1667V15.8333C5 15.3833 4.63333 15 4.16667 15ZM3.33333 17.9167C3.33333 18.15 3.15 18.3333 2.91667 18.3333H2.08333C1.85 18.3333 1.66667 18.15 1.66667 17.9167V17.0833C1.66667 16.85 1.85 16.6667 2.08333 16.6667H2.91667C3.15 16.6667 3.33333 16.85 3.33333 17.0833V17.9167Z"
        fill="#C0C7D3"
      />
      <path
        d="M4.16667 0H0.833333C0.366667 0 0 0.383333 0 0.833333V4.16667C0 4.63333 0.366667 5 0.833333 5H4.16667C4.63333 5 5 4.63333 5 4.16667V0.833333C5 0.383333 4.63333 0 4.16667 0ZM3.33333 2.91667C3.33333 3.15 3.15 3.33333 2.91667 3.33333H2.08333C1.85 3.33333 1.66667 3.15 1.66667 2.91667V2.08333C1.66667 1.85 1.85 1.66667 2.08333 1.66667H2.91667C3.15 1.66667 3.33333 1.85 3.33333 2.08333V2.91667Z"
        fill="#C0C7D3"
      />
    </svg>
  );
}

export function ActiveActionIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M19.166 18.3334H7.49935C7.03268 18.3334 6.66602 17.9667 6.66602 17.5001C6.66602 17.0334 7.03268 16.6667 7.49935 16.6667H19.166C19.6327 16.6667 19.9993 17.0334 19.9993 17.5001C19.9993 17.9667 19.6327 18.3334 19.166 18.3334Z"
        fill="white"
      />
      <path
        d="M19.166 10.8334H7.49935C7.03268 10.8334 6.66602 10.4667 6.66602 10.0001C6.66602 9.53341 7.03268 9.16675 7.49935 9.16675H19.166C19.6327 9.16675 19.9993 9.53341 19.9993 10.0001C19.9993 10.4667 19.6327 10.8334 19.166 10.8334Z"
        fill="white"
      />
      <path
        d="M19.166 3.33341H7.49935C7.03268 3.33341 6.66602 2.96675 6.66602 2.50008C6.66602 2.03341 7.03268 1.66675 7.49935 1.66675H19.166C19.6327 1.66675 19.9993 2.03341 19.9993 2.50008C19.9993 2.96675 19.6327 3.33341 19.166 3.33341Z"
        fill="white"
      />
      <path
        d="M5 8.33333V11.6667C5 12.1333 4.63333 12.5 4.16667 12.5H0.833333C0.366667 12.5 0 12.1333 0 11.6667V8.33333C0 7.88333 0.366667 7.5 0.833333 7.5H4.16667C4.63333 7.5 5 7.88333 5 8.33333Z"
        fill="white"
      />
      <path
        d="M5 15.8333V19.1667C5 19.6333 4.63333 20 4.16667 20H0.833333C0.366667 20 0 19.6333 0 19.1667V15.8333C0 15.3833 0.366667 15 0.833333 15H4.16667C4.63333 15 5 15.3833 5 15.8333Z"
        fill="white"
      />
      <path
        d="M5 0.833333V4.16667C5 4.63333 4.63333 5 4.16667 5H0.833333C0.366667 5 0 4.63333 0 4.16667V0.833333C0 0.383333 0.366667 0 0.833333 0H4.16667C4.63333 0 5 0.383333 5 0.833333Z"
        fill="white"
      />
    </svg>
  );
}
