import React from 'react';

import { formatDate } from 'utils/date';

import { DueDateParagraph } from './styles';

interface TaskListCellDueDateProps {
  dueDate: string | null;
}

const TaskListCellDueDate: React.FunctionComponent<TaskListCellDueDateProps> = ({ dueDate }) => {
  if (dueDate === undefined || dueDate === null) {
    return <DueDateParagraph color="rgba(0, 0, 0, 0.6)">No Due Date</DueDateParagraph>;
  }

  const { name, color } = formatDate(new Date(dueDate));
  return <DueDateParagraph color={color}>{name}</DueDateParagraph>;
};

export default TaskListCellDueDate;
