import React from 'react';

import { Wrapper, SuccessMessage } from './styles';

const SuccessPage: React.FunctionComponent = (): React.ReactElement => (
  <Wrapper>
    <SuccessMessage variant="h1">Incident reported successfully!</SuccessMessage>
  </Wrapper>
);

export default SuccessPage;
