import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as UnassignedDeviceComponent } from 'assets/icons/Device Unassigned.svg';

const UnassignedDevice = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    component={UnassignedDeviceComponent}
    viewBox="0 0 32 32"
    {...props}
  />
);

export default UnassignedDevice;
