import styled from 'styled-components';

export const StyledOption = styled.li<{ $disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 16px;
  min-height: 36px;

  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  &:hover {
    background-color: ${(props) => (props.$disabled ? '' : 'rgba(0, 0, 0, 0.1)')};
  }
`;

export const AddMemberText = styled.p`
  color: #0772e5;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  margin: 0;
  margin-left: 8px;
`;
