import React from 'react';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { formatDateTime, formatTime } from 'vatix-ui/lib/utils/formatters/time';

import { GridFilterOperator } from '@mui/x-data-grid';

import { formatDate } from 'utils/formatters/time';

import { JSONSchemaType, JSONSchemaValue, LocationAnswerType, ProtectorType } from 'utils/api/types';

import IncidentStatusChip from 'components/Chips/IncidentStatusChip';

import { FilterType, GridProtectorType, StringKeys } from './types';

import GridUserRenderer from './renderers/GridUserRenderer';
import textOperators from './operators/Text';
import BasicDateOperators, { ExtendedDateOperators } from './operators/Date/Date';

import userOperators from './operators/User';
import { IncidentStatusOperator } from './operators/Status';
import { Text } from './styles';
import GridLookupRenderer from './renderers/GridLookupRenderer';

export const defaultRenderers: {
  [key in GridProtectorType]: (item: { value: JSONSchemaValue; colDef: { schema: JSONSchemaType } }) => React.ReactNode;
} = {
  [ProtectorType.Date]: ({ value }) => <Text>{value?.value ? formatDate(value?.value as string) : ''}</Text>,
  [ProtectorType.SingleChoice]: ({ value }) => <Text>{value?.value as string}</Text>,
  [ProtectorType.MultiChoice]: ({ value }) => <Text>{((value?.value as string[]) || []).join(', ')}</Text>,
  [ProtectorType.LongText]: ({ value }) => <Text>{value?.value as string}</Text>,
  [ProtectorType.ShortText]: ({ value }) => <Text>{value?.value as string}</Text>,
  [ProtectorType.User]: ({ value }) => <GridUserRenderer value={value?.value as string} />,
  [ProtectorType.Location]: ({ value }) => (
    <Text>{((value?.value as unknown) as LocationAnswerType)?.address || 'N/A'}</Text>
  ),
  [ProtectorType.Number]: ({ value }) => <Text>{value?.value as string}</Text>,
  [ProtectorType.DateTime]: ({ value }) => <Text>{value?.value ? formatDateTime(value?.value as string) : ''}</Text>,
  [ProtectorType.Time]: ({ value }) => <Text>{value?.value ? formatTime(value?.value as string) : ''}</Text>,
  [ProtectorType.Status]: ({ value, colDef }) => (
    <IncidentStatusChip schema={value?.schema || colDef} label={value?.value as string} />
  ),
  [ProtectorType.Lookup]: ({ value }) =>
    value?.value ? (
      <GridLookupRenderer
        // @ts-ignore
        entity={value.value.lookup}
        instance={{
          uuid: (value.value as UuidableName).uuid,
          name: (value.value as UuidableName).name,
        }}
      />
    ) : (
      <Text>N/A</Text>
    ),
};

export const defaultFormatters: {
  [key in GridProtectorType]: (item: { value: JSONSchemaValue }) => string | number;
} = {
  [ProtectorType.Date]: ({ value }) => (value?.value ? formatDate(value?.value as string) : ''),
  [ProtectorType.SingleChoice]: ({ value }) =>
    ((value?.value && Array.isArray(value?.value) ? value?.value : ([value?.value] as string[])) || []).join(', '),
  [ProtectorType.MultiChoice]: ({ value }) => ((value?.value as string[]) || []).join(', '),
  [ProtectorType.LongText]: ({ value }) => value?.value as string,
  [ProtectorType.ShortText]: ({ value }) => value?.value as string,
  [ProtectorType.User]: ({ value }) => (value?.value as UuidableName)?.name || (value?.value as string),
  [ProtectorType.Location]: ({ value }) => ((value?.value as unknown) as LocationAnswerType)?.address || 'N/A',
  [ProtectorType.Number]: ({ value }) => value?.value as string,
  [ProtectorType.DateTime]: ({ value }) => (value?.value ? formatDateTime(value?.value as string) : ''),
  [ProtectorType.Time]: ({ value }) => (value?.value ? formatTime(value?.value as string) : ''),
  [ProtectorType.Status]: ({ value }) => value?.value as string,
  [ProtectorType.Lookup]: ({ value }) => (value?.value as UuidableName)?.name || (value?.value as string),
};

export const defaultOperators: {
  [key in GridProtectorType]: (props?: { schema?: JSONSchemaType }) => GridFilterOperator[];
} = {
  [ProtectorType.Date]: ExtendedDateOperators,
  [ProtectorType.SingleChoice]: textOperators,
  [ProtectorType.MultiChoice]: textOperators,
  [ProtectorType.LongText]: textOperators,
  [ProtectorType.ShortText]: textOperators,
  [ProtectorType.User]: userOperators,
  [ProtectorType.Location]: textOperators,
  [ProtectorType.Number]: textOperators,
  [ProtectorType.DateTime]: BasicDateOperators,
  [ProtectorType.Time]: BasicDateOperators,
  [ProtectorType.Status]: IncidentStatusOperator,
  [ProtectorType.Lookup]: textOperators,
};

export const defaultColumnProps = {
  aggregable: false,
  flex: 1,
  groupable: false,
  filterOperators: defaultOperators[ProtectorType.ShortText](),
  sortable: true,
  valueFormatter: ({ value }: { value: string }) => value,
};

export const getFilters = (items: FilterType[]): StringKeys => {
  const groupedFilters = items
    .filter((item) => item.field && item.value)
    .reduce(
      (accumulator, { field, value }) => ({
        ...accumulator,
        [field]: [...(accumulator[field] || []), Array.isArray(value) ? value.join('::') : value],
      }),
      {} as { [key: string]: string[] }
    );
  return Object.fromEntries(Object.entries(groupedFilters).map(([key, value]) => [key, value.join(';;')]));
};
