import get from 'lodash/get';

export function hasError(
  errors: unknown,
  touched: unknown,
  field: string
): boolean {
  return !!(get(errors, field) && get(touched, field));
}

export function* findFields<T>(
  errors: T,
  prefix = ''
): IterableIterator<string> {
  if (typeof errors === 'object') {
    if (Array.isArray(errors)) {
      for (let i = 0; i < errors.length; i += 1) {
        yield* findFields(errors[i], `${prefix}[${i}]`);
      }
    } else if (errors) {
      for (const key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          const newPrefix = prefix ? `${prefix}.${key}` : key;
          if (typeof errors[key] === 'object') {
            yield* findFields(errors[key], newPrefix);
          } else {
            yield newPrefix;
          }
        }
      }
    }
  }
}
