import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  margin: 0;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 150px 50px 30px 50px;
`;

export const Header = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
`;
export const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;
