import AnswerDetails from 'stores/FormDetails/AnswerDetails';
import { SelectedAnswerType } from 'utils/api/types';

export interface QuestionRowProps {
  answer: AnswerDetails;
  isSubmitted: boolean;
  updateAnswer: (
    answerKey: string,
    selectedAnswer: SelectedAnswerType
  ) => Promise<void>;
  updating?: boolean;
}

export const ScoreChipColors = {
  orange: '#E65100',
  blue: '#0772E5',
  yellow: '#FBC02D',
  green: '#2e7d32',
};
