import { observer } from 'mobx-react';
import React from 'react';

import { Drawer, CircularProgress } from '@mui/material';

import { useLocation, useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import { Button } from 'vatix-ui/lib/components/Button';

import DetailsForm from 'stores/FormDetails/DetailsForm';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { QuestionsContainer, SavingContainer, SubmitContainer } from './styles';
import CollapsedSections from './CollapsedSections';
import QuestionDrawer from './QuestionDrawer';
import QuestionRow from './QuestionRow';
import { QuestionContainer } from './CollapsedSections/styles';

function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const FormTab: React.FunctionComponent = () => {
  const {
    formDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as DetailsForm;

  const query = useQuery();
  const history = useHistory();
  const questionId = query.get('questionId');
  const isSubmitted = details?.submitted !== null;

  const goToQuestion = (qId: string): void => {
    const route = reverse(routes.dashboard.audits.forms.details, {
      formId: details.uuid,
    });
    history.push(`${route}?questionId=${qId}`);
  };

  const onCloseDrawer = (): void => {
    history.push(
      reverse(routes.dashboard.audits.forms.details, {
        formId: details.uuid,
      })
    );
  };

  const onBack = (): void => {
    history.push(reverse(routes.dashboard.audits.forms.toString()));
  };

  return (
    <QuestionsContainer>
      {(details?.template.content.order || []).map((sectionId) => (
        <>
          {sectionId !== '0' ? (
            <CollapsedSections
              goToQuestion={(qId) => goToQuestion(qId)}
              sectionId={sectionId}
              questionId={questionId || ''}
              key={`${sectionId}_section`}
              isSubmitted={isSubmitted}
            />
          ) : (
            details?.template.content.properties[sectionId].order.map((questionIdItem) => (
              <div key={`${questionIdItem}_question`}>
                <QuestionContainer
                  onClick={() => goToQuestion(questionIdItem)}
                  selected={questionIdItem === questionId}
                  $withoutSection
                >
                  <QuestionRow questionId={questionIdItem} showStats sectionId={sectionId} isSubmitted={isSubmitted} />
                </QuestionContainer>
              </div>
            ))
          )}
        </>
      ))}
      <Drawer
        anchor="right"
        open={!!questionId}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: {
            minWidth: '35%',
            maxWidth: '35%',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            backgroundColor: '#fff',
          },
        }}
        key={`${questionId}_drawer`}
      >
        {questionId && (
          <QuestionDrawer
            questionId={questionId || ''}
            sectionId={questionId.split('::')[0] || ''}
            onClose={onCloseDrawer}
            isSubmitted
          />
        )}
      </Drawer>
      {isSubmitted === false && (
        <SubmitContainer>
          <SavingContainer>
            <Button variant="contained" onClick={detailsRaw?.submitForm} disabled={detailsRaw?.submitting}>
              Submit
            </Button>
            {detailsRaw?.updating && (
              <>
                <CircularProgress size="2rem" sx={{ color: '#9E9E9E' }} />
                <p>Saving changes...</p>
              </>
            )}
          </SavingContainer>
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
        </SubmitContainer>
      )}
    </QuestionsContainer>
  );
};

export default observer(FormTab);
