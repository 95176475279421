import { MonitoringMethod, UserRole } from 'core/constants';

import SessionUser from '../../stores/Session/SessionUser';

interface AlertOwner {
  monitoringMethod: MonitoringMethod;
}

export const canHandleRedAlert = (
  user: SessionUser | null,
  alertOwner: AlertOwner | null
): boolean => {
  if (!user) {
    return false;
  }
  if ([UserRole.Admin, UserRole.ArcOperator].includes(user.role)) {
    return true;
  }
  return !!(
    [UserRole.Manager, UserRole.AccountManager].includes(user.role) &&
    alertOwner?.monitoringMethod === MonitoringMethod.SelfMonitored
  );
};
