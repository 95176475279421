import React from 'react';

import DisableProduct from 'components/Icons/DisabledProduct';

import { UserLicensesResponse } from 'utils/api/types';

import ActiveProduct from 'components/Icons/ActiveProduct';

import { ProductAccessContainer, ProductAccessText } from './styles';

const UserProductsCell: React.FunctionComponent<{ licenses: UserLicensesResponse }> = ({ licenses }) => (
  <>
    <div
      style={{
        display: 'flex',
      }}
    >
      {Object.entries({
        protectorAlarms: 'Lone Working',
        protectorIncidents: 'Incidents',
        workflows: 'Audits',
      }).map(([licenseKey, labelText]) => (
        <ProductAccessContainer key={licenseKey}>
          {// @ts-ignore
          licenses[licenseKey] ? <ActiveProduct /> : <DisableProduct />}
          <ProductAccessText>{labelText}</ProductAccessText>
        </ProductAccessContainer>
      ))}
    </div>
  </>
);

export default UserProductsCell;
