import styled from 'styled-components';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 4px;
  max-width: 240px;
`;

export const InfoHeader = styled.h4`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 700;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 6px 0;
`;

export const InfoDescription = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  margin: 6px 0;
`;
