/* eslint-disable max-len */
import React from 'react';

export function RiskIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_14643_242260" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path d="M0 0H20V20H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_14643_242260)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.626464 14.8674C0.239488 14.8674 -0.0742188 14.5537 -0.0742188 14.1668C-0.0742188 11.4005 1.04053 8.89467 2.84531 7.07389C2.85487 7.06309 2.86481 7.05251 2.87514 7.04218C2.88544 7.03189 2.89597 7.02198 2.90672 7.01246C4.72571 5.20853 7.22892 4.09346 9.99257 4.09108C9.99553 4.09104 9.99849 4.09102 10.0015 4.09102C10.0044 4.09102 10.0074 4.09104 10.0104 4.09108C15.5709 4.09588 20.0771 8.60509 20.0771 14.1668C20.0771 14.5537 19.7634 14.8674 19.3765 14.8674H14.2983C13.9114 14.8674 13.5977 14.5537 13.5977 14.1668C13.5977 13.1849 13.2041 12.2949 12.5662 11.6459C12.5584 11.6387 12.5507 11.6314 12.5431 11.6238C12.5356 11.6163 12.5283 11.6087 12.5212 11.601C11.8724 10.9637 10.9828 10.5706 10.0015 10.5706C8.01536 10.5706 6.40527 12.1806 6.40527 14.1668C6.40527 14.5537 6.09157 14.8674 5.70459 14.8674H0.626464ZM13.0044 10.1717C12.3448 9.67507 11.5583 9.33805 10.7021 9.21793V5.52033C12.5706 5.66968 14.2722 6.41114 15.6191 7.55699L13.0044 10.1717ZM16.6101 8.5478C17.7566 9.89491 18.4985 11.597 18.6479 13.4661H14.9503C14.8301 12.6093 14.4927 11.8223 13.9955 11.1624L16.6101 8.5478ZM9.30078 5.52033V9.21793C8.44394 9.33815 7.6568 9.67565 6.99685 10.1729L4.38225 7.55835C5.72939 6.41173 7.43155 5.66974 9.30078 5.52033ZM3.39147 8.54941L6.00617 11.1641C5.50969 11.8236 5.17274 12.6101 5.05264 13.4661H1.35504C1.50438 11.5977 2.24576 9.89626 3.39147 8.54941Z"
          fill="#C0C7D3"
        />
      </g>
    </svg>
  );
}

export function ActiveRiskIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask x="0" y="0" width="20" height="20">
        <path d="M0 0H20V20H0V0Z" fill="white" />
      </mask>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29932 4.81757V9.8699C9.29932 9.88891 9.30008 9.90775 9.30157 9.92638C8.63577 10.0352 8.02142 10.2971 7.49581 10.6747C7.48364 10.6604 7.47082 10.6465 7.45733 10.633L3.88485 7.06055C5.36089 5.78922 7.23735 4.96992 9.29932 4.81757ZM5.70312 14.1668C5.70312 13.2323 6.00145 12.3675 6.50805 11.6623C6.49379 11.6502 6.4799 11.6374 6.46642 11.6239L2.89393 8.05145C1.47987 9.6931 0.625 11.8301 0.625 14.1668H5.70312ZM10.7007 4.81757V9.8699C10.7007 9.88891 10.6999 9.90775 10.6984 9.92638C11.3645 10.0353 11.979 10.2972 12.5047 10.675C12.517 10.6606 12.53 10.6465 12.5436 10.6329L16.1156 7.06094C14.6395 5.78938 12.7629 4.96993 10.7007 4.81757ZM17.1065 8.0519C18.5203 9.69349 19.375 11.8303 19.375 14.1668H14.2969C14.2969 13.2325 13.9987 12.3678 13.4923 11.6628C13.5067 11.6505 13.5208 11.6375 13.5345 11.6238L17.1065 8.0519Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
