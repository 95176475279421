import { observer } from 'mobx-react';
import React from 'react';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useStore } from 'utils/hooks/store';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import { isMultipleAnswerQuestionType } from 'utils/api/types';

import { QuestionType } from 'stores/TemplateDetails/types';

import ListIcon from 'components/Icons/ListIcon';

import { StatsContainer, SummaryCounter, ScoreCounter } from './styles';

import { QuestionStatsProps } from './types';

type ScoreValues = { scoredPoints: string; allPoints: string; average: string; bgColor?: string };

const QuestionRow: React.FunctionComponent<QuestionStatsProps> = ({ questionId, sectionId }): React.ReactElement => {
  const {
    formDetails: { details },
  } = useStore();

  const sectionID = details?.template.content.order.indexOf(sectionId) as number;
  const questionProperties = details?.template.content.properties[sectionId].properties[questionId] as QuestionType;
  const { stats, answer } = details?.answers[sectionID][questionId] as AnswerDetails;

  const score =
    isMultipleAnswerQuestionType(questionProperties) && details?.submitted
      ? (details?.getScore(questionProperties, answer as string[]) as ScoreValues)
      : null;

  return (
    <StatsContainer>
      {stats?.comments ? (
        <>
          <ChatBubbleOutlineIcon htmlColor="rgba(0, 0, 0, 0.6)" style={{ width: '20px', height: '20px' }} />
          <SummaryCounter id="comments-counter-summary">{stats.comments}</SummaryCounter>
        </>
      ) : null}
      {stats?.files ? (
        <>
          <UploadFileIcon htmlColor="rgba(0, 0, 0, 0.6)" style={{ width: '20px', height: '20px' }} />
          <SummaryCounter id="files-counter-summary">{stats.files}</SummaryCounter>
        </>
      ) : null}
      {stats?.tasks ? (
        <>
          <ListIcon fontSize="small" />
          <SummaryCounter id="tasks-counter-summary">{stats.tasks}</SummaryCounter>
        </>
      ) : null}
      {score && questionProperties.scoring && score.allPoints ? (
        <ScoreCounter id="task-score-summary">
          <p>{`${score.scoredPoints}/${score.allPoints} | ${score.average}`}</p>
        </ScoreCounter>
      ) : null}
    </StatsContainer>
  );
};

export default observer(QuestionRow);
