import { AxiosError } from 'axios';

import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { subscribe, eventBus } from 'mobx-event-bus2';

import RootStore from 'stores/Root/Root';
import API from 'utils/api';
import { NotificationType } from 'utils/api/types';
import Logger from 'utils/logger';
import { EventType } from 'utils/events/constants';
import { NotificationTarget } from 'core/constants';

const NOTIFICATIONS_PAGE_SIZE = 10;

type NotificationTargetValue = typeof NotificationTarget[keyof typeof NotificationTarget];

const allowedNotificationSettings: Set<NotificationTargetValue> = new Set([
  NotificationTarget.NEW_TASK_ASSIGNED_TO_ME,
  NotificationTarget.NEW_INCIDENT_MESSAGE,
  NotificationTarget.NEW_INCIDENT_SUBMISSION_MESSAGE,
  NotificationTarget.NEW_INCIDENT_REPORTED,
  NotificationTarget.NEW_INCIDENT_ASSIGNED_TO_ME,
  NotificationTarget.NEW_TASK_MESSAGE,
  NotificationTarget.NEW_FORM_SUBMITTED,
]);

export default class Inbox {
  store: RootStore;

  api: typeof API;

  @observable initialSync?: boolean = undefined;

  @observable notifications?: NotificationType[];

  @observable isLoading = false;

  @observable isDismissing = false;

  @observable hasMore = false;

  @observable error?: AxiosError;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    eventBus.register(this);
  }

  @action.bound
  async loadInitialData(): Promise<void> {
    this.initialSync = false;
    this.isLoading = true;

    try {
      const { data } = await this.api.loadNotifications({
        target: [...allowedNotificationSettings.values()].join(','),
        offset: 0,
        limit: NOTIFICATIONS_PAGE_SIZE,
      })();
      runInAction(() => {
        this.error = undefined;
        this.initialSync = true;
        this.notifications = data.results;
        this.hasMore = !!data.next;
      });
    } catch (e) {
      Logger.error('Invalid load initial notifications data API response', e);
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  async loadNotifications(): Promise<void> {
    if (this.hasMore) {
      const offset = this.notifications ? this.notifications.length : 0;
      this.isLoading = true;
      try {
        const { data } = await this.api.loadNotifications({
          target: [...allowedNotificationSettings.values()].join(','),
          limit: NOTIFICATIONS_PAGE_SIZE,
          offset,
        })();
        const { results, next } = data;
        runInAction(() => {
          this.error = undefined;
          if (this.notifications === undefined) {
            this.notifications = [];
          }
          this.notifications.push(
            ...results.filter((item2) => !this.notifications?.some((item1) => item1.uuid === item2.uuid))
          );
          this.hasMore = !!next;
        });
      } catch (e) {
        Logger.error('Invalid load notifications API response', e);
        runInAction(() => {
          // @ts-ignore
          this.error = e;
        });
      } finally {
        this.isLoading = false;
      }
    }
  }

  @action.bound
  async dismissNotifications(): Promise<void> {
    if (this.notifications === undefined) {
      return;
    }
    const originalNotifications = this.notifications;
    const uuids = this.notifications.filter(({ dismissed }) => !dismissed).map(({ uuid }) => uuid);

    this.isDismissing = true;
    try {
      await this.api.dismissNotifications(uuids)();

      const updatedNotifications = this.notifications?.map((notification) =>
        !notification.dismissed ? { ...notification, dismissed: true } : notification
      );
      runInAction(() => {
        this.notifications = updatedNotifications;
      });
    } catch (e) {
      Logger.error('Invalid dismiss notifications API response', e);
      runInAction(() => {
        this.notifications = originalNotifications;
      });
    } finally {
      runInAction(() => {
        this.isDismissing = false;
      });
    }
  }

  @computed
  get notDismissedNotificationsCount(): number {
    if (!this.notifications) {
      return 0;
    }
    return this.notifications.filter((notification) => !notification.dismissed).length;
  }

  @subscribe(EventType.LoggedOut)
  @action
  logout(): void {
    this.hasMore = false;
    this.notifications = undefined;
    this.isLoading = false;
    this.error = undefined;
    this.initialSync = undefined;
  }
}
