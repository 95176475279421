import SessionUser from './SessionUser';
import SessionStore from './Session';

export type SessionStoreWithUser = SessionStore & { user: SessionUser };

export enum PusherConnectionState {
  INITIALIZED = 'initialized',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  UNAVAILABLE = 'unavailable',
  FAILED = 'failed',
  DISCONNECTED = 'disconnected',
}
