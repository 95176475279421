import styled from 'styled-components';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

import Protector from 'vatix-ui/lib/components/Icons/Protector';

import Logo from 'components/Icons/Logo';

export const StyledLogo = styled(Logo)`
  height: 28px;
  width: 102px;
`;

export const StyledProtectorLogo = styled(Protector)`
  & path {
    fill: white;
  }
`;

export const SelectedLeaderboardIcon = styled(LeaderboardIcon)`
  color: #d0e9ff;
`;

export const ProfileSettingsSubItemsIconSX = { width: 18, height: 18, marginBottom: '3px' };
