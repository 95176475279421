import React from 'react';

import { observer } from 'mobx-react';

import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';

import Feed from 'vatix-ui/lib/components/Feed';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { useStore } from 'utils/hooks/store';

import API from 'utils/api/api';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import { ActivityType } from 'utils/stores/Activities/constants';

import { ActivityMapping } from 'utils/api/types';

import { ScrollableContainer } from '../styles';

import Assignment from '../Assignment';
import State from '../State';
import Status from '../Status';
import { DataContainer } from '../../TaskList/styles';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.Assignment]: Assignment,
  [ActivityType.State]: State,
  [ActivityType.Status]: Status,
} as unknown) as ActivityMapping;

const TaskActivity: React.FunctionComponent<{
  questionId: string;
  sectionId: string;
}> = ({ questionId, sectionId }): React.ReactElement => {
  const { formDetails, session } = useStore();

  const [keepBottom, setKeepBottom] = React.useState(true);
  const scrollableRef = React.useRef<HTMLDivElement | null>(null);

  const sectionID = formDetails.details?.template.content.order.indexOf(sectionId) as number;
  const answer = formDetails.details?.answers[sectionID][questionId] as AnswerDetails;

  const onScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    // @ts-ignore
    const bottom = e.target.clientHeight;
    // @ts-ignore
    const scrolledAt = e.target.scrollHeight - e.target.scrollTop;
    const shouldKeepBottom = scrolledAt - bottom <= 20; // 20 is the padding.

    if (shouldKeepBottom !== keepBottom) {
      setKeepBottom(shouldKeepBottom);
    }
  };

  return (
    <DataContainer $withoutPadding>
      <ScrollableContainer onScroll={onScroll} ref={scrollableRef}>
        <Feed
          originalActivities={answer?.activities}
          onDeleteComment={answer?.activities.deleteMessage}
          onDeleteFile={answer?.activities.deleteFile}
          usersSearch={API.searchUsers}
          sessionUser={session.user as UuidableName}
          downloadFile={API.downloadFile}
          mapping={activityTypeToComponent}
        />
      </ScrollableContainer>
      <AddCommentActivities
        placeholder="Post an update"
        sendMessage={answer?.activities.addMessage}
        uploadFile={answer?.activities.uploadFile}
        usersSearch={API.searchUsers}
      />
    </DataContainer>
  );
};

export default observer(TaskActivity);
