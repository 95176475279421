import React from 'react';

import { Button } from 'vatix-ui/lib/components/Button';

import { StyledTextField } from 'components/Input/styles';

import CustomModal from 'components/CustomModal';

import { InfoText, HelpText } from './styles';

const CorrectConfirmationText = 'DELETE';

const RemoveFormModal: React.FC<{
  onClose: () => void;
  handleRemoveForm: () => void;
}> = ({ onClose, handleRemoveForm }): React.ReactElement => {
  const [openModal, setOpenModal] = React.useState(true);
  const [confirmationText, setConfirmationText] = React.useState('');

  const onCloseModal = (): void => {
    setOpenModal(false);
    onClose();
  };

  const onDelete = (): void => {
    handleRemoveForm();
    onCloseModal();
  };

  return (
    <>
      <CustomModal
        openModal={openModal}
        onClose={onCloseModal}
        modalTitle="Delete Form"
        cancelButton={
          <Button id="cancel-action-button" onClick={onCloseModal} variant="outlined" size="large">
            Cancel
          </Button>
        }
        submitButton={
          <Button
            id="delete-action-button"
            onClick={onDelete}
            disabled={confirmationText !== CorrectConfirmationText}
            variant="contained"
            size="large"
            color="error"
          >
            Delete
          </Button>
        }
      >
        <InfoText>Are you sure you want to delete this record?</InfoText>
        <InfoText>Once removed, you will not be able to recover this data.</InfoText>
        <HelpText>
          Please type in <b>DELETE</b> to confirm
        </HelpText>
        <StyledTextField
          variant="outlined"
          placeholder="Please type in DELETE to confirm"
          value={confirmationText}
          onChange={(e) => {
            setConfirmationText(e.target.value);
          }}
        />
      </CustomModal>
    </>
  );
};

export default RemoveFormModal;
