import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid rgba(7, 114, 229, 0.12);
  box-shadow: 0px 4px 20px 0px rgba(175, 182, 229, 0.24);
`;

export const AvailableLicensesLabel = styled.p`
  font: normal normal normal 14px Roboto;
  color: rgba(0, 0, 0, 0.87));
  margin-right: 30px;
`;

export const ProductContainer = styled.div`
  display: flex;
  margin-right: 30px;
  align-items: center;
`;

export const ProductName = styled.p`
  font: normal normal normal 12px Roboto;
  color: #6d7883;
  line-height: 14px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const LicensesDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LicenseStatusText = styled.p`
  font: normal normal normal 12px Roboto;
  color: rgba(0, 0, 0, 0.87);
`;

export const LicenseAvailableText = styled.b`
  font: 12px Roboto;
  font-weight: 700;
`;
