/* eslint-disable max-len */
import React from 'react';

export function ARPIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73553)">
        <path
          d="M11.01 8.49C12.48 7.8 13.5 6.27 13.5 4.5C13.5 2.04 11.49 0 9 0C6.51 0 4.5 2.04 4.5 4.5C4.5 6.24 5.49 7.74 6.93 8.46C2.85 9.33 0 13.11 0 17.31V17.61C0 17.82 0.18 18 0.39 18H7.32C7.53 18 7.71 17.85 7.71 17.64V16.89C7.71 16.68 7.53 16.5 7.32 16.5H2.01C1.74 16.5 1.56 16.29 1.59 16.05C2.19 12.48 5.28 9.75 9 9.75C9.72 9.75 10.41 9.87 11.1 10.08C11.22 10.11 11.37 10.08 11.46 9.99L12.06 9.36C12.24 9.18 12.18 8.85 11.94 8.76C11.64 8.64 11.34 8.55 11.01 8.49ZM6 4.5C6 2.85 7.35 1.5 9 1.5C10.65 1.5 12 2.85 12 4.5C12 6.15 10.65 7.5 9 7.5C7.35 7.5 6 6.18 6 4.5Z"
          fill="white"
          fillOpacity="0.56"
        />
        <path
          d="M17.5792 10.95L16.4992 9.87C15.8992 9.3 14.9692 9.3 14.3992 9.87L9.53922 14.7C9.53922 14.7 9.44922 14.88 9.44922 14.97V17.64C9.44922 17.82 9.59922 18 9.80922 18H12.4492C12.4492 18 12.6592 17.97 12.7192 17.88L17.5792 13.05C18.1492 12.48 18.1492 11.52 17.5792 10.95ZM16.2292 12.27L12.0292 16.44C12.0292 16.44 11.9692 16.5 11.9092 16.5H11.0992C11.0992 16.5 10.9492 16.44 10.9492 16.35V15.54C10.9492 15.54 10.9492 15.42 10.9492 15.39L15.1492 11.22C15.1492 11.22 15.5392 11.07 15.6592 11.22L16.1992 11.73C16.3492 11.88 16.3492 12.12 16.1992 12.27H16.2292Z"
          fill="white"
          fillOpacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73553">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ActiveARPIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_14025_73541)">
        <path
          d="M12.45 8.97C12.72 9.06 12.75 9.39 12.57 9.57L8.49 13.65C8.13 13.98 7.92 14.49 7.92 14.97V17.64C7.92 17.85 7.77 18 7.56 18H0.33C0.15 18 0 17.85 0 17.67C0 15.48 0.75 13.35 2.04 11.7C3.24 10.11 4.98 8.97 6.96 8.49C5.49 7.77 4.5 6.27 4.5 4.5C4.5 2.01 6.51 0 9 0C11.49 0 13.5 2.01 13.5 4.5C13.5 6.27 12.48 7.8 11.01 8.49C11.52 8.61 12 8.79 12.45 8.97Z"
          fill="white"
        />
        <path
          d="M9.44992 17.61C9.44992 17.61 9.44992 17.82 9.53992 17.88C9.47992 17.82 9.41992 17.7 9.41992 17.61V14.97C9.41992 14.97 9.47992 14.76 9.53992 14.7C9.47992 14.76 9.44992 14.88 9.44992 14.97V17.61Z"
          fill="white"
        />
        <path
          d="M17.5799 10.95H17.5499C17.8499 11.22 17.9999 11.61 17.9999 12C17.9999 11.61 17.8799 11.22 17.5799 10.95ZM17.5499 13.05H17.5799C17.8799 12.75 17.9999 12.36 17.9999 12C17.9999 12.36 17.8499 12.75 17.5499 13.05ZM16.4999 9.87H16.4699L17.5499 10.95L16.4999 9.87ZM9.44992 14.97C9.44992 14.97 9.47992 14.76 9.53992 14.7C9.47992 14.76 9.41992 14.88 9.41992 14.97V17.61C9.41992 17.61 9.47992 17.82 9.53992 17.88C9.47992 17.82 9.44992 17.7 9.44992 17.61V14.97Z"
          fill="white"
        />
        <path
          d="M17.5807 10.95H17.5507C17.8507 11.22 18.0007 11.61 18.0007 12C18.0007 11.61 17.8807 11.22 17.5807 10.95ZM17.5507 13.05H17.5807C17.8807 12.75 18.0007 12.36 18.0007 12C18.0007 12.36 17.8507 12.75 17.5507 13.05ZM16.5007 9.87H16.4707L17.5507 10.95L16.5007 9.87Z"
          fill="white"
        />
        <path
          d="M17.5788 10.95H17.5488C17.8488 11.22 17.9988 11.61 17.9988 12C17.9988 11.61 17.8788 11.22 17.5788 10.95ZM17.5488 13.05H17.5788C17.8788 12.75 17.9988 12.36 17.9988 12C17.9988 12.36 17.8488 12.75 17.5488 13.05ZM16.4988 9.87H16.4688L17.5488 10.95L16.4988 9.87Z"
          fill="white"
        />
        <path
          d="M17.5492 10.95L16.4692 9.87004C15.8692 9.27004 14.9692 9.27004 14.3992 9.87004L14.2792 9.96004L9.53922 14.7C9.53922 14.7 9.44922 14.88 9.44922 14.97V17.61C9.44922 17.61 9.47922 17.82 9.53922 17.88C9.59922 17.94 9.71922 18 9.80922 18H12.4492C12.4492 18 12.6592 17.94 12.7192 17.88L17.1592 13.47L17.5492 13.05C17.8492 12.75 17.9992 12.36 17.9992 12C17.9992 11.61 17.8492 11.22 17.5492 10.95ZM16.2292 12.27L12.0292 16.44C12.0292 16.44 11.9692 16.5 11.9092 16.5H11.0992C11.0992 16.5 10.9492 16.41 10.9492 16.35V15.51C10.9492 15.51 10.9492 15.42 10.9792 15.39L15.1792 11.22C15.1792 11.22 15.4492 11.07 15.5692 11.13C15.5992 11.13 15.5992 11.16 15.5992 11.16C15.6292 11.16 15.6592 11.19 15.6892 11.22L16.2292 11.73C16.2292 11.73 16.3492 11.94 16.3492 12.06C16.3192 12.12 16.2892 12.21 16.2292 12.27Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14025_73541">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
