import { InfoBox, } from '@react-google-maps/api';
import { observer } from 'mobx-react';
import React from 'react';
import Typography from '@mui/material/Typography';

import { formatDuration, formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { StyledPaper, LocationPosition, LocationAddress, TimeToEnd } from './styles';
import { LocationDetailsDialogProps } from './types';

const LocationDetailsDialog: React.FunctionComponent<LocationDetailsDialogProps> = ({
  details,
}): React.ReactElement | null => {
  const position = {
    lng: Number(details.lng),
    lat: Number(details.lat),
  };
  const [duration, setDuration] = React.useState('');

  React.useEffect(() => {
    const interval = setInterval(() => {
      const seconds = (new Date(details.amberAlert?.endTime as string).getTime() - Date.now()) / 1000;
      if (seconds > 0) {
        setDuration(formatDuration(seconds));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [details.amberAlert?.endTime]);

  return (
    <InfoBox
      // @ts-ignore 
      position={position}
      options={{
        closeBoxURL: ``,
        enableEventPropagation: true,
        maxWidth: 300,
        boxStyle: {
          overflow: 'visible',
        },
        alignBottom: true,
        pixelOffset: new google.maps.Size(25, -10),
      }}
    >
      <StyledPaper>
        <Typography color="initial" variant="body1" display="block">
          <strong>{`${details.user.name} | ${formatDateTime(details.occurrenceTime)}`}</strong>
        </Typography>
        {details.amberAlert && duration && (
          <TimeToEnd>
            <b>{duration}</b> left to alarm{' '}
          </TimeToEnd>
        )}
        {details.address && <LocationAddress>{details.address}</LocationAddress>}
        <LocationPosition>{`${position.lng}, ${position.lat}`}</LocationPosition>
      </StyledPaper>
    </InfoBox>
  );
};

export default observer(LocationDetailsDialog);
