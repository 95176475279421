import React from 'react';
import { observer } from 'mobx-react';

import { useCSVDownloader, jsonToCSV } from 'react-papaparse';

import './styles.css';
import { useStore } from 'utils/hooks/store';
import { CSVImportColumns, UsersCreationInBulkParams } from 'utils/api/types';
import { UserRole } from 'core/constants';

type UploadUserType = Record<CSVImportColumns, string> & { error?: string; account?: string };

const DownloadErrors: React.FC = (): React.ReactElement => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { userList, session } = useStore();

  const exportUsersToCSV = (
    data: (UsersCreationInBulkParams & {
      error: string;
    })[]
  ): UploadUserType[] => {
    const userRoles: Record<string, string> = {
      user: 'user',
      manager: 'manager',
      account_owner: 'account owner',
    };

    const transformedData = data.map((user) => {
      const defaultUser: UploadUserType = {
        [CSVImportColumns.firstName]: user.firstName || '',
        [CSVImportColumns.lastName]: user.lastName || '',
        [CSVImportColumns.email]: user.email || '',
        [CSVImportColumns.role]: user.role ? userRoles[user.role] : '',
        [CSVImportColumns.dateOfBirth]: user.dateOfBirth || '',
        [CSVImportColumns.phoneNumber]: user.phoneNumber || '',
        [CSVImportColumns.teams]: user.teams && user.teams.length ? user.teams.join(',') : '',
        [CSVImportColumns.memorableWord]: user.memorableWord || '',
        [CSVImportColumns.loneWorkingLicense]: user.loneWorkingLicense ? 'True' : 'False',
        [CSVImportColumns.auditsLicense]: user.auditsLicense ? 'True' : 'False',
        [CSVImportColumns.incidentsLicense]: user.incidentsLicense ? 'True' : 'False',
      };
      if (session.user?.role === UserRole.Admin) {
        return {
          ...defaultUser,
          account: user.account || '',
          error: user.error,
        };
      }
      return {
        ...defaultUser,
        error: user.error,
      };
    });

    return transformedData;
  };

  return (
    <CSVDownloader
      className="csvDownloader"
      type={Type.Link}
      bom
      filename="error_log"
      delimiter=","
      data={jsonToCSV(exportUsersToCSV(userList.uploadErrors))}
    >
      Download Error Log (CSV)
    </CSVDownloader>
  );
};

export default observer(DownloadErrors);
