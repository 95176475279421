import styled from 'styled-components';

import VatixTheme from 'theme/vatix';

import { BaseFieldWrapper } from '../styles';

export const TextareaAutosizeWrapper = styled(BaseFieldWrapper)`
  textarea {
    width: 100%;
    margin: 0;
    padding: 8px 0;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${VatixTheme.palette.text.secondary};
    background: transparent;
    border: none;
    outline: none;
    resize: none;
  }

  textarea:focus {
    outline: none;
  }

  textarea:invalid {
    box-shadow: none;
    outline: none;
  }

  textarea::placeholder {
    opacity: 0.8;
    color: ${VatixTheme.palette.text.secondary};
  }

  textarea:disabled {
    opacity: 0.38;
  }
`;
