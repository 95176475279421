/* eslint-disable max-len */
import * as React from 'react';

export function EraserIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <g clipPath="url(#clip0_19866_198003)">
        <path
          d="M10.245 3.8434C10.0497 3.64815 10.0497 3.33155 10.245 3.1363C10.4403 2.94105 10.7569 2.94105 10.9521 3.1363L13.3116 5.49583C13.5069 5.69108 13.5069 6.00768 13.3116 6.20293C13.214 6.30055 13.086 6.3494 12.9581 6.3494C12.8302 6.3494 12.7022 6.30055 12.6046 6.20293L10.245 3.8434ZM16.5 15.4464C16.5 15.7225 16.2762 15.9464 16 15.9464C16 15.9464 6.14498 15.948 6.14495 15.948C5.61367 15.9481 5.08076 15.7786 4.63157 15.4983C4.43379 15.3749 4.25079 15.2274 4.08601 15.0626L1.38535 12.3619C0.304355 11.281 0.19848 9.62799 1.14926 8.67718L9.12542 0.701021C10.0763 -0.249792 11.7293 -0.143854 12.8102 0.937115L15.5109 3.63777C16.5919 4.71877 16.6977 6.37174 15.7469 7.32255L8.12307 14.9464H16C16.2762 14.9464 16.5 15.1702 16.5 15.4464ZM3.8192 7.42143L9.02651 12.6287L15.0398 6.61543C15.6007 6.05452 15.4948 5.03593 14.8037 4.34483L12.1031 1.64418C11.7106 1.25168 11.2123 1.04793 10.7524 1.04793C10.4025 1.04793 10.0748 1.16577 9.83245 1.40808L3.8192 7.42143ZM4.7931 14.3555C5.48417 15.0466 6.50273 15.1525 7.0637 14.5916L8.31945 13.3358L3.1121 8.12852L1.85635 9.38427C1.29545 9.94518 1.40135 10.9637 2.09245 11.6548L4.7931 14.3555Z"
          fill="#0772E5"
        />
      </g>
      <defs>
        <clipPath id="clip0_19866_198003">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
