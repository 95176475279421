import styled from 'styled-components';

import VatixTheme from 'theme/vatix';
import SelfMonitoring from 'components/Icons/SelfMonitoring';
import VatixMonitoring from 'components/Icons/VatixMonitoring';

export const ResponseProfileWrapper = styled('div')`
  color: ${VatixTheme.palette.text.secondary};
  height: 44px;
  display: flex;
  font-size: 1.4rem;
  justify-content: flex-start;
  align-items: center;
`;

const StyleMonitoringStatus = `
  fill: #0772e5;
  font-size: 24px;
  background-color: #e6f1fc;
  border-radius: 24px;
`;

export const StyledSelfMonitoringStatus = styled(SelfMonitoring)`
  ${StyleMonitoringStatus}
`;

export const StyledVatixMonitoringStatus = styled(VatixMonitoring)`
  ${StyleMonitoringStatus}
`;
