import React from 'react';

import { UserRoleNames } from 'core/constants';

import Chip from 'components/Chip';

const UserRoleCell: React.FunctionComponent<{ role: string }> = ({ role }) => (
  <Chip
    key={role}
    label={UserRoleNames[role]}
    id={`table-tag-${role}`}
    variant="outlined"
    size="small"
    colors={{
      background: 'transparent',
      foreground: 'rgba(0, 0, 0, 0.87)',
      border: 'rgba(0, 0, 0, 0.26)',
    }}
  />
);

export default UserRoleCell;
