import { InfoBox } from '@react-google-maps/api';
import { observer } from 'mobx-react';
import React from 'react';
import Typography from '@mui/material/Typography';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { StyledPaper, LocationPosition, LocationAddress } from './styles';
import { AlarmDetailsDialogProps } from './types';

const AlarmDetailsDialog: React.FunctionComponent<AlarmDetailsDialogProps> = ({
  details,
}): React.ReactElement | null => {
  const position = {
    lng: Number(details.location.lng),
    lat: Number(details.location.lat),
  };

  return (
    <InfoBox
      // @ts-ignore
      position={position}
      options={{
        closeBoxURL: ``,
        enableEventPropagation: true,
        maxWidth: 300,
        boxStyle: {
          overflow: 'visible',
        },
        alignBottom: true,
        pixelOffset: new google.maps.Size(25, -10),
      }}
    >
      <StyledPaper>
        <Typography color="initial" variant="body1" display="block">
          <strong>{`${details.user.name} | ${formatDateTime(details.activated)}`}</strong>
        </Typography>
        {details.location.address && <LocationAddress>{details.location.address}</LocationAddress>}
        <LocationPosition>{`${position.lng}, ${position.lat}`}</LocationPosition>
      </StyledPaper>
    </InfoBox>
  );
};

export default observer(AlarmDetailsDialog);
