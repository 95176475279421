/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import { SvgIconProps } from '@mui/material';
import React from 'react';

export function ProtectorIcon(props: SvgIconProps): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        d="M10.5 4.16663C8.43333 4.16663 6.75 5.84996 6.75 7.91663C6.75 8.94996 7.16667 9.86663 7.83333 10.55C8.51667 11.2333 9.46667 11.6666 10.5 11.6666C11.5333 11.6666 12.4833 11.2333 13.1667 10.55C13.8333 9.86663 14.25 8.94996 14.25 7.91663C14.25 5.84996 12.5667 4.16663 10.5 4.16663ZM8.41667 7.91663C8.41667 6.76663 9.35 5.83329 10.5 5.83329C11.65 5.83329 12.5833 6.76663 12.5833 7.91663C12.5833 9.06663 11.65 9.99996 10.5 9.99996C9.35 9.99996 8.41667 9.06663 8.41667 7.91663Z"
        fill={props.fill ? props.fill : '#C0C7D3'}
      />
      <path
        d="M16.9331 14.9499C16.7497 14.2333 16.4497 13.5666 16.0497 12.9666C15.3497 11.9166 14.3497 11.0833 13.1664 10.5499C12.3497 10.1999 11.4497 9.99994 10.4997 9.99994C9.54974 9.99994 8.64974 10.1999 7.83307 10.5499C6.64974 11.0833 5.64974 11.9166 4.94974 12.9666C4.54974 13.5666 4.24974 14.2333 4.06641 14.9499C4.49974 15.7833 4.99974 16.5499 5.53307 17.2333C5.51641 17.0499 5.49974 16.8499 5.49974 16.6666C5.49974 15.9999 5.63307 15.3499 5.88307 14.7666C6.61641 12.9499 8.41641 11.6666 10.4997 11.6666C12.5831 11.6666 14.3831 12.9499 15.1164 14.7666C15.3664 15.3499 15.4997 15.9999 15.4997 16.6666C15.4997 16.8499 15.4831 17.0499 15.4664 17.2333C15.9997 16.5499 16.4997 15.7833 16.9331 14.9499Z"
        fill={props.fill ? props.fill : '#C0C7D3'}
      />
      <path
        d="M19.5162 1.9C17.9162 1.16667 14.7329 0 10.4995 0C6.2662 0 3.08287 1.16667 1.48287 1.9C0.799534 2.21667 0.399534 2.95 0.516201 3.7C0.916201 5.96667 1.94953 11.0667 4.0662 14.95C4.49954 15.7833 4.99953 16.55 5.53287 17.2333C6.8662 18.9 8.49953 20 10.4995 20C12.4995 20 14.1329 18.9 15.4662 17.2333C15.9995 16.55 16.4995 15.7833 16.9329 14.95C19.0495 11.0667 20.0829 5.96667 20.4829 3.7C20.5995 2.95 20.1995 2.21667 19.5162 1.9ZM16.0495 12.9667C15.7662 13.6167 15.4495 14.2167 15.1162 14.7667C13.8495 16.9167 12.2829 18.3333 10.4995 18.3333C8.7162 18.3333 7.14953 16.9167 5.88287 14.7667C5.54953 14.2167 5.23287 13.6167 4.94953 12.9667C3.64953 10.1667 2.73287 6.66667 2.18287 3.41667C3.59953 2.76667 6.54953 1.66667 10.4995 1.66667C14.4495 1.66667 17.3995 2.76667 18.8329 3.41667C18.2829 6.66667 17.3495 10.1667 16.0495 12.9667Z"
        fill={props.fill ? props.fill : '#C0C7D3'}
      />
    </svg>
  );
}

export function ActiveProtectorIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M16.0476 12.9622C16.4474 13.562 16.7473 14.2284 16.9306 14.9448C16.4974 15.7779 15.9976 16.5443 15.4644 17.2274C14.1316 18.8935 12.4988 19.9931 10.4995 19.9931C8.50017 19.9931 6.8674 18.8935 5.53452 17.2274C5.00137 16.5443 4.50154 15.7779 4.06836 14.9448C4.25163 14.2284 4.55153 13.562 4.95139 12.9622C5.65115 11.9126 6.6508 11.0795 7.83373 10.5464C7.16729 9.86326 6.75077 8.94691 6.75077 7.91393C6.75077 5.84798 8.43352 4.16522 10.4995 4.16522C12.5654 4.16522 14.2482 5.84798 14.2482 7.91393C14.2482 8.94691 13.8317 9.86326 13.1652 10.5464C14.3482 11.0795 15.3478 11.9126 16.0476 12.9622Z"
        fill="white"
      />
      <path
        d="M20.4799 3.69873C20.13 5.64806 19.3136 9.71332 17.7475 13.2788C17.5809 12.9122 17.381 12.5623 17.1644 12.2291C16.6146 11.3961 15.8981 10.6797 15.0818 10.1132C15.415 9.43009 15.5816 8.68035 15.5816 7.91394C15.5816 5.11491 13.299 2.83236 10.5 2.83236C7.70096 2.83236 5.41842 5.11491 5.41842 7.91394C5.41842 8.68035 5.58502 9.43009 5.91824 10.1132C5.10186 10.6797 4.38544 11.3961 3.83563 12.2291C3.61903 12.5623 3.4191 12.9122 3.25249 13.2788C1.68637 9.71332 0.86998 5.64806 0.520101 3.69873C0.403474 2.94899 0.803336 2.2159 1.48643 1.89935C3.08588 1.16627 6.26812 0 10.5 0C14.7319 0 17.9141 1.16627 19.5136 1.89935C20.1967 2.2159 20.5965 2.94899 20.4799 3.69873Z"
        fill="white"
      />
      <path
        d="M16.9306 14.9448C16.4974 15.7779 15.9976 16.5443 15.4644 17.2274C14.1316 18.8935 12.4988 19.9931 10.4995 19.9931C8.50017 19.9931 6.8674 18.8935 5.53452 17.2274C5.00137 16.5443 4.50154 15.7779 4.06836 14.9448C4.25163 14.2284 4.55153 13.562 4.95139 12.9622C5.65115 11.9126 6.6508 11.0795 7.83373 10.5464C7.16729 9.86326 6.75077 8.94691 6.75077 7.91393C6.75077 5.84798 8.43352 4.16522 10.4995 4.16522C12.5654 4.16522 14.2482 5.84798 14.2482 7.91393C14.2482 8.94691 13.8317 9.86326 13.1652 10.5464C14.3482 11.0795 15.3478 11.9126 16.0476 12.9622C16.4474 13.562 16.7473 14.2284 16.9306 14.9448Z"
        fill="white"
      />
    </svg>
  );
}
