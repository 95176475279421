/* eslint-disable no-nested-ternary */
import React from 'react';

import { Collapse } from '@mui/material';

import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { AnswerType, AnswerTypes, JSONSchemaType, ProtectorType } from 'utils/api/types';

import IncidentForm from 'containers/Incidents/components/IncidentForm';

import { SectionIncidentOverview, SectionContainerHeader, SectionTitle, SectionDescription } from './styles';

const CommonSection: React.FC<{
  section: JSONSchemaType;
  updateField: (field: string, value: AnswerTypes) => void;
  incidentContent: AnswerType | undefined;
}> = ({ section, updateField, incidentContent }): React.ReactElement => (
  <div style={{ padding: '0 20px 10px' }}>
    <SectionDescription>{section.description}</SectionDescription>
    {section.order
      .filter((i) => (section.properties || {})[i].protectorType !== ProtectorType.Status)
      .map((j) => (
        <IncidentForm
          key={`${j}_${(section.properties || {})[j].protectorType}`}
          editable
          properties={(section.properties || {})[j]}
          required={section.required ? section.required.includes(j) : false}
          title={j}
          updateField={updateField}
          incidentContent={(incidentContent || {})[j]}
        />
      ))}
  </div>
);

const ReportIncidentSection: React.FC<{
  onlyOneSection: boolean;
  section: JSONSchemaType;
  updateField: (field: string, value: AnswerTypes) => void;
  incidentContent: AnswerType | undefined;
}> = ({ onlyOneSection, section, updateField, incidentContent }): React.ReactElement => {
  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  const commonSection = <CommonSection section={section} updateField={updateField} incidentContent={incidentContent} />;

  const sectionContainerHeader = (
    <SectionContainerHeader $onlyOneSection={onlyOneSection} onClick={handleExpandClick}>
      <SectionTitle>{section.title}</SectionTitle>
      {onlyOneSection ? null : expanded ? (
        <ExpandLess aria-labelledby="show less" />
      ) : (
        <ExpandMore aria-labelledby="show more" />
      )}
    </SectionContainerHeader>
  );

  return (
    <SectionIncidentOverview>
      {sectionContainerHeader}
      {onlyOneSection ? commonSection : <Collapse in={expanded}>{commonSection}</Collapse>}
    </SectionIncidentOverview>
  );
};

export default ReportIncidentSection;
