import React from 'react';
import { ErrorOutline } from '@mui/icons-material';

import { FormErrorContainer, InnerFormErrorContainer, FormInfoContainer } from './styles';
import { FormStatusProps } from './types';

const FormStatus: React.FunctionComponent<FormStatusProps> = ({ status }) => (
  <>
    {/* @ts-ignore */}
    {status && status.errors ? (
      <FormErrorContainer>
        <ErrorOutline htmlColor="rgba(211, 47, 47, 1)" sx={{ alignSelf: 'flex-start', marginTop: '8px' }} />
        <InnerFormErrorContainer>
          {/* @ts-ignore */}
          {status.errors}
        </InnerFormErrorContainer>
      </FormErrorContainer>
    ) : null}
    {/* @ts-ignore */}
    {status && status.info ? <FormInfoContainer>{status.info}</FormInfoContainer> : null}
  </>
);

export default FormStatus;
