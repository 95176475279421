import React from 'react';

import { observer } from 'mobx-react';
import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';

import TaskActivities from 'stores/TaskDetails/TaskActivities';
import API from 'utils/api';
import { useStore } from 'utils/hooks/store';

const TaskActivitiesFooter: React.FunctionComponent = (): React.ReactElement => {
  const { taskDetails } = useStore();
  const activities = taskDetails.activities as TaskActivities;

  return (
    <AddCommentActivities
      placeholder="Add a comment or drag and drop files"
      uploadFile={activities.uploadFile}
      usersSearch={API.searchUsers}
      sendMessage={activities.addMessage}
    />
  );
};

export default observer(TaskActivitiesFooter);
