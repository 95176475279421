import styled from 'styled-components';

export const InformationContainer = styled.div<{ $error: boolean }>`
  display: flex;
  align-items: center;

  background: ${(props) => (props.$error ? '#fbeaea' : '#EAF2EA')};
  color: ${(props) => (props.$error ? '#541313' : '#123214')};
  border-radius: 8px;
  padding: 6px 16px;
  margin-bottom: 16px;
  p {
    margin: 0;
    margin-left: 12px;
    padding: 8px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
`;

export const DownloadInfoText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 0;
`;
