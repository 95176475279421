import * as React from 'react';

function QuestionIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M10.0834 14.8541C10.3056 14.8541 10.4931 14.7777 10.6459 14.625C10.7987 14.4722 10.8751 14.2847 10.8751 14.0625C10.8751 13.8402 10.7987 13.6527 10.6459 13.5C10.4931 13.3472 10.3056 13.2708 10.0834 13.2708C9.86119 13.2708 9.67369 13.3472 9.52092 13.5C9.36814 13.6527 9.29175 13.8402 9.29175 14.0625C9.29175 14.2847 9.36814 14.4722 9.52092 14.625C9.67369 14.7777 9.86119 14.8541 10.0834 14.8541ZM9.35425 11.8125H10.5834C10.5834 11.4513 10.6286 11.1215 10.7188 10.8229C10.8091 10.5243 11.0904 10.1805 11.5626 9.79163C11.9931 9.43052 12.2987 9.07635 12.4792 8.72913C12.6598 8.3819 12.7501 7.99996 12.7501 7.58329C12.7501 6.84718 12.5105 6.2569 12.0313 5.81246C11.5522 5.36802 10.9167 5.14579 10.1251 5.14579C9.44453 5.14579 8.84383 5.31593 8.323 5.65621C7.80217 5.99649 7.42369 6.46524 7.18758 7.06246L8.29175 7.47913C8.44453 7.09024 8.67369 6.78815 8.97925 6.57288C9.2848 6.3576 9.64592 6.24996 10.0626 6.24996C10.5348 6.24996 10.9167 6.37843 11.2084 6.63538C11.5001 6.89232 11.6459 7.22218 11.6459 7.62496C11.6459 7.93051 11.5556 8.21871 11.3751 8.48954C11.1945 8.76038 10.9306 9.04163 10.5834 9.33329C10.1667 9.6944 9.85772 10.052 9.65633 10.4062C9.45494 10.7604 9.35425 11.2291 9.35425 11.8125ZM10.0001 18.3333C8.86119 18.3333 7.7848 18.1145 6.77092 17.677C5.75703 17.2395 4.87161 16.6423 4.11466 15.8854C3.35772 15.1284 2.7605 14.243 2.323 13.2291C1.8855 12.2152 1.66675 11.1388 1.66675 9.99996C1.66675 8.84718 1.8855 7.76385 2.323 6.74996C2.7605 5.73607 3.35772 4.85413 4.11466 4.10413C4.87161 3.35413 5.75703 2.76038 6.77092 2.32288C7.7848 1.88538 8.86119 1.66663 10.0001 1.66663C11.1529 1.66663 12.2362 1.88538 13.2501 2.32288C14.264 2.76038 15.1459 3.35413 15.8959 4.10413C16.6459 4.85413 17.2397 5.73607 17.6772 6.74996C18.1147 7.76385 18.3334 8.84718 18.3334 9.99996C18.3334 11.1388 18.1147 12.2152 17.6772 13.2291C17.2397 14.243 16.6459 15.1284 15.8959 15.8854C15.1459 16.6423 14.264 17.2395 13.2501 17.677C12.2362 18.1145 11.1529 18.3333 10.0001 18.3333Z"
        fill="#4BA0FD"
      />
    </svg>
  );
}

export default QuestionIcon;
