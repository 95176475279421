/* eslint-disable max-len */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowLeft = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon width="16" height="21" viewBox="0 0 16 21" {...props}>
    <path d="M12.6663 9.66671H2.52467L7.18301 5.00837L5.99967 3.83337L-0.666992 10.5L5.99967 17.1667L7.17467 15.9917L2.52467 11.3334H12.6663V9.66671Z" fill="#0772E5"/>
  </SvgIcon>
);

export default ArrowLeft;
